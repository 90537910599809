// React
import React, { ReactElement } from "react";
import { renderToString } from "react-dom/server";
// Translations
import translations from "./translations";

// Typings
type TranslationEntry = keyof typeof translations;

// Langs
type Lang = "en";

// Default Lang
export const defaultLang: Lang = "en";

/**
 * Returns translated matching the provided entry
 * @param key - translation entry name
 * @param values - parameters to include in translation
 * @param lang - language of the translation
 * @returns string / ReactElement
 */
export const lang = (
  key: TranslationEntry,
  values?: (string | number) | (string | number)[] | ReactElement | ReactElement[],
  lang?: Lang
): string => {
  // Type casting on parameters to any values
  const l = lang || defaultLang;
  const langObj = translations[key];
  const langValue = langObj && langObj[l];
  const computedValues = (values instanceof Array ? values : [values]) || [];

  const isReactNode = !["undefined", "string", "number"].includes(typeof computedValues[0]);
  const initialValue: string = langValue || `{${key}}`;

  let result: (undefined | string | number | ReactElement)[] = [initialValue];

  computedValues.forEach((value, index) => {
    result = result
      .map((v) => {
        if (typeof v !== "string") return v;

        const parsed = v.split(`{${index}}`).map((part, i) => (i === 0 ? [part] : [value, part]));

        return parsed;
      })
      .flat(2);
  });

  return isReactNode ? renderToString(<>{result}</>) : result.join("");
};
