// React
import { useMemo } from "react";
// Typings
import { Validators, ValidationData } from "@advicefront/fe-infra-form-schema-renderer";
import { FormModuleInputInstance } from "@forms/modules/types";
// Utils
import * as CC from "change-case";
import { handleDependentFields } from "@forms/utils/handle-dependent-fields";
// Translations
import { lang } from "@lang/index";

export const useValidators: FormModuleInputInstance["useValidators"] = () =>
  useMemo(
    (): Validators => [
      /**
       * Interest Rate
       * Applied on Debt (GBR)
       */
      {
        valueKey: "interestRate",
        validator: ({ value }): ValidationData => {
          // Check if less than 0
          if (value && parseFloat(value.toString()) < 0) {
            return {
              status: "error",
              description: lang("FORM_VALIDATOR_INTEREST_RATE"),
            };
          }

          return undefined;
        },
      },

      /**
       * Payment Duration
       * Applied on Debt (GBR and SGP)
       */
      {
        valueKey: "paymentDuration",
        validator: ({ field, value }): ValidationData => {
          // Check if less than 0
          if (Number(value) < 0) {
            return {
              status: "error",
              description: lang("FORM_VALIDATOR_LESS_THAN", [CC.capitalCase(field.key), 0]),
            };
          }

          // Check if more than 999
          if (Number(value) > 999) {
            return {
              status: "error",
              description: lang("FORM_VALIDATOR_MORE_THAN", [CC.capitalCase(field.key), 999]),
            };
          }

          // Check if number has decimals
          if (value && !Number.isInteger(parseFloat(value.toString()))) {
            return {
              status: "error",
              description: lang("FORM_VALIDATOR_NO_DECIMALS", CC.capitalCase(field.key)),
            };
          }

          return undefined;
        },
      },

      /**
       * Outstanding Balance and Repayment Amount
       * Applied on Debt (GBR and SGP)
       */
      {
        valueKey: ["outstandingBalance", "repaymentAmount"],
        validator: ({ field, value }): ValidationData => {
          // Check if less than 0
          if (Number(value) < 0) {
            return {
              status: "error",
              description: lang("FORM_VALIDATOR_LESS_THAN", [CC.capitalCase(field.key), 0]),
            };
          }

          return undefined;
        },
      },

      /**
       * Handle dependent fields
       * These fields are optional but if a value is provided for one of them the others become required
       */
      ...handleDependentFields(["bonusAmount", "bonusAmountPeriodicity"]),
      ...handleDependentFields(["dividendsAmount", "dividendsAmountPeriodicity"]),
      ...handleDependentFields(["benefitsAmount", "benefitsAmountPeriodicity"]),
      ...handleDependentFields(["otherAmount", "otherAmountPeriodicity"]),
      ...handleDependentFields([
        "regularContributions",
        "contributionPeriodicity",
        "contributionsGrowthRate",
      ]),
    ],
    []
  );
