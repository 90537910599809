// React
import { useContext, useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { FormModuleInstance, UseSchemaProps } from "@forms/modules/types";
import { FormKeys } from "@typings/routing";
// Context
import { AppOptionsContext } from "@context/app-options";
// Schemas
import { clientSchema, dependentSchema } from "@forms/modules/people/schemas";

export const useSchema: FormModuleInstance["useSchema"] = ({ formKey }) => {
  // Store
  const people = Store.useSelector((state) => state.people);

  // Context
  const { clientNamingMode } = useContext(AppOptionsContext);

  // Get current person schema based on formKey
  const personSchema = useMemo(() => {
    switch (formKey) {
      // Owner and Partner
      case FormKeys.Owner:
      case FormKeys.Partner:
        return clientSchema(clientNamingMode);
      // Dependent
      case FormKeys.Dependent:
        return dependentSchema(clientNamingMode);
    }
  }, [formKey, clientNamingMode]);

  return useMemo(
    (): UseSchemaProps => ({
      loading: !people.data,
      data: personSchema,
    }),
    [personSchema, people.data]
  );
};
