// Typings
import { FormModuleInstance } from "@forms/modules/types";
// Modules
import { TemplateFormModule } from "@forms/modules/_template";
// Utils
import { CreateComponentInstance } from "@forms/index";
// Hooks
import {
  useInitialValues,
  useInputRenderer,
  useSchema,
  useSubmit,
  useValidators,
} from "@forms/modules/people/hooks";

/**
 * People Module
 */
export class PeopleFormModule extends TemplateFormModule {
  // React hook to fetch schema data
  public useSchema: FormModuleInstance["useSchema"] = useSchema;
  // React hook to expose field keys to input types and styles
  public useInputRenderer: FormModuleInstance["useInputRenderer"] = useInputRenderer;
  // React hook to set initial values (edit mode)
  public useInitialValues: FormModuleInstance["useInitialValues"] = useInitialValues;
  // React hook to expose field validation
  public useValidators: FormModuleInstance["useValidators"] = useValidators;
  // React hook to submit the data from the form
  public useSubmit: FormModuleInstance["useSubmit"] = useSubmit;
  // React component that renders the form fields
  public Component: FormModuleInstance["Component"] = CreateComponentInstance(this);
}
