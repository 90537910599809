/**
 * Inputs
 * Naming convention: INPUT_ELEMENT
 */
export const inputs = {
  INPUT_INCOME_EMPLOYMENT: {
    en: "Employment",
  },
  INPUT_INCOME_SELF_EMPLOYMENT: {
    en: "Self Employment",
  },
  INPUT_INCOME_ANNUITIES: {
    en: "Annuities",
  },
  INPUT_INCOME_FINAL_SALARY_SCHEME: {
    en: "Final Salary Scheme",
  },
  INPUT_INCOME_PROPERTY: {
    en: "Property Income",
  },
  INPUT_INCOME_OTHER: {
    en: "Other Income",
  },
  INPUT_INCOME_STATE_PENSION: {
    en: "State Pension",
  },
  INPUT_PENSION_CPF_ORDINARY: {
    en: "CPF Ordinary",
  },
  INPUT_PENSION_CPF_SPECIAL: {
    en: "CPF Special",
  },
  INPUT_PENSION_CPF_MEDISAVE: {
    en: "CPF Medisave",
  },
  INPUT_PENSION_SRS: {
    en: "SRS",
  },
  INPUT_PENSION_RETIREMENT: {
    en: "Retirement Account",
  },
  INPUT_PENSION_DEPENDENT: {
    en: "Dependent Scheme",
  },
  INPUT_PENSION_INCOME: {
    en: "Income",
  },
  INPUT_PENSION_EMPLOYER: {
    en: "Employer",
  },
  INPUT_PENSION_PERSONAL: {
    en: "Personal",
  },
  INPUT_ACCOUNT_BONDS: {
    en: "Bonds",
  },
  INPUT_ACCOUNT_CIS_ACCOUNT: {
    en: "Cis Account",
  },
  INPUT_ACCOUNT_CURRENT_ACCOUNT: {
    en: "Current Account",
  },
  INPUT_ACCOUNT_OTHER: {
    en: "Other",
  },
  INPUT_ACCOUNT_SAVINGS_ACCOUNT: {
    en: "Savings Account",
  },
  INPUT_ACCOUNT_SHARES: {
    en: "Shares",
  },
  INPUT_ACCOUNT_TERM_DEPOSIT: {
    en: "Term Deposit",
  },
  INPUT_ACCOUNT_UNIT_TRUSTS: {
    en: "Unit Trusts",
  },
  INPUT_ACCOUNT_GIA: {
    en: "Gia",
  },
  INPUT_ACCOUNT_CASH_ISA: {
    en: "Cash Isa",
  },
  INPUT_ACCOUNT_STOCK_SHARES_ISA: {
    en: "Stock Shares Isa",
  },
  INPUT_ACCOUNT_LIFETIME_ISA: {
    en: "Lifetime Isa",
  },
  INPUT_ACCOUNT_JISA: {
    en: "Jisa",
  },
  INPUT_ASSETS_MAIN_RESIDENCE: {
    en: "Main Residence",
  },
  INPUT_ASSETS_OTHER: {
    en: "Other",
  },
  INPUT_ASSETS_PROPERTY: {
    en: "Property",
  },
  INPUT_DEBT_BANK_LOAN: {
    en: "Bank Loan",
  },
  INPUT_DEBT_BANK_OVERDRAFT: {
    en: "Bank Overdraft",
  },
  INPUT_DEBT_CAR_LOAN: {
    en: "Car Loan",
  },
  INPUT_DEBT_CREDIT_CARD: {
    en: "Credit Card",
  },
  INPUT_DEBT_LOAN: {
    en: "Loan",
  },
  INPUT_DEBT_MORTGAGE: {
    en: "Mortgage",
  },
  INPUT_DEBT_OTHER: {
    en: "Other",
  },
};
