// Redux
import { StoreState, Store } from "@store/index";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import { API } from "@services/api";
import { ResponsePlanV2Dto } from "@advicefront/plan-client-axios";
// Initial State
import { initialState } from "./initial-state";
// Types
import { AccountLimitUpdateProps, StateProps } from "./types";
// Utils
import { getSubmitSuccessAction } from "@store/utils/submit-success";
// Translations
import { lang } from "@lang/index";

/**
 * Internal Actions
 * ---------------------------------------------------------------------
 */

const { submitSuccessAction } = getSubmitSuccessAction<StateProps>(
  "accounts/set-submit-success-state"
);

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state
 * @example dispatch(Accounts.reset())
 */

export const reset = createAction("accounts/reset");

/**
 * Fetch Accounts
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Accounts.fetch(\{
 *  authToken,
 *  planId
 * \}));
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: ResponsePlanV2Dto["_id"];
  }>,
  { state: StoreState }
>("accounts/fetch", async ({ authToken, planId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;
  let data: StateProps["data"] = initialState.data;

  try {
    data = (
      await API.Inputs(authToken).findAllInputsV2({
        planId: planId,
        isLiquidatableInput: true,
        orderBy: "label",
        orderDirection: "asc",
        limit: 50,
      })
    ).data.data;
  } catch (e) {
    error = API.parseError(e);
  }

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Accounts
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Accounts.update(\{
 *  authToken,
 *  planId,
 * updateInputsDto
 * \}));
 */

export const update = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: ResponsePlanV2Dto["_id"];
    updateInputsDto: AccountLimitUpdateProps[];
  }>,
  { state: StoreState }
>("accounts/update", async ({ authToken, planId, updateInputsDto }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    const fetchUpdateInputs = async (
      input: AccountLimitUpdateProps
    ): Promise<NonNullable<StateProps["data"]>[number]> => {
      const currentInput = { accountLimit: input.accountLimit };

      const res = await API.Inputs(authToken).updateInput({
        planId: planId,
        inputId: input._id,
        updateInputRequest: currentInput,
      });

      return res.data;
    };

    await Promise.all((updateInputsDto || []).map(fetchUpdateInputs));
  } catch (e) {
    error = API.parseError(e);
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_UPDATED", lang("NOTIFICATION_LABEL_ACCOUNT_LIMITS")),
        description: "",
      })
    );

    // Refresh accounts data (side effect)
    void dispatch(
      Store.accounts.fetch({
        authToken,
        planId,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
  };
});
