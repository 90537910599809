// Typings
import { ClientNamingMode } from "@typings/client-naming-mode";
import { CreatePlanV2Dto } from "@advicefront/plan-client-axios";
import { Field } from "@advicefront/fe-infra-form-schema";
// Schemas
import { blankSchema } from "@forms/modules/plan/schemas";

// DTO which the form refers without fields that are not shown on form
type FormDto = Pick<CreatePlanV2Dto, "name" | "startYear"> & CreatePlanV2Dto["owner"];

// DTO properties and keys to display as fields
type FormDtoKey = keyof FormDto;

// Schema used for Import Plan
export const importSchema = (
  clientNamingMode: ClientNamingMode
): Partial<Record<FormDtoKey, Field>> => {
  // Get blank schema and remove unused keys on the import plan
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { net, ...defaultSchema } = blankSchema(clientNamingMode);

  return defaultSchema;
};
