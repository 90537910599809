// Styles
import "./styles/index.scss";
// React
import React, { useCallback, useEffect } from "react";
// Router
import { useModalRouting } from "@hooks/modal-routing";
// Typings
import { FormActions } from "@typings/routing";
// Hooks
import { useDialogDelete } from "@components/dialogs/delete/hooks";
// Translations
import { lang } from "@lang/index";
// Components
import { AfButton } from "@advicefront/ds-button";
import { AfDialog } from "@advicefront/ds-dialog";
import { AfTypography } from "@advicefront/ds-typography";
import { LoaderOverlaySpinner } from "@components/loaders/overlay-spinner";

export const DialogDelete = (): React.ReactElement => {
  // Route
  const { id, type, referral, openModal, closeModal } = useModalRouting();

  // Hook to get current dialog delete data
  const { title, name, loading, submitting, submitSuccess, handleDelete } = useDialogDelete({
    type,
    id,
  });

  // Handle cancel
  // If referral exists then it will reopen the edit modal
  // If not then it will just close the modal
  const handleCancel = useCallback(
    () =>
      referral
        ? openModal({
            id,
            type,
            action: FormActions.edit,
            referral: undefined,
          })
        : closeModal(),
    [id, type, referral, openModal, closeModal]
  );

  // Handle submit success side effects
  useEffect(() => {
    if (!submitSuccess) return;
    closeModal();
  }, [submitSuccess, closeModal]);

  return (
    <AfDialog
      isOpen
      skin="warning"
      secondaryAction={
        <AfButton
          type="button"
          skin="ghost"
          disabled={loading || submitting}
          onClick={handleCancel}
        >
          {lang("ACTION_CANCEL_DELETE")}
        </AfButton>
      }
      primaryAction={
        <AfButton
          type="button"
          skin="danger"
          disabled={loading || submitting}
          onClick={handleDelete}
        >
          {lang("ACTION_CONFIRM_DELETE")}
        </AfButton>
      }
    >
      <div className="align-center">
        <LoaderOverlaySpinner active={loading || submitting} bordered={false} size="m">
          <AfTypography type="h4" className="dialogs-delete__title">
            {lang("DIALOG_TITLE_DELETE", title)}
          </AfTypography>
          {lang("DIALOG_DESCRIPTION_DELETE", `"${name}"`)}
        </LoaderOverlaySpinner>
      </div>
    </AfDialog>
  );
};
