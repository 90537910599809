// Redux
import { Store, StoreState } from "@store/index";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import {
  ResponsePlanV2Dto,
  UpdateIllnessCalculatorDto,
  UpdateProtectionCalculatorDto,
  UpdateTDPCalculatorDto,
  CalculatorsApiFindOneCalculatorRequest,
} from "@advicefront/plan-client-axios";
import { API } from "@services/api";
// Initial State
import { initialState } from "./initial-state";
// Types
import { StateProps } from "./types";
// Utils
import {
  isIllnessCalculator,
  isProtectionCalculator,
  isTpdCalculator,
} from "@utils/type-guards/protection";
import { getSubmitSuccessAction } from "@store/utils/submit-success";
// Translations
import { lang } from "@lang/index";

/**
 * Internal Actions
 * ---------------------------------------------------------------------
 */

const { submitSuccessAction } = getSubmitSuccessAction<StateProps>(
  "calculators/set-submit-success-state"
);

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state
 * @example dispatch(Calculators.reset())
 */

export const reset = createAction("calculators/reset");

/**
 * Fetch Calculators
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Calculators.fetch(\{
 *  authToken,
 *  planId
 * \}));
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: ResponsePlanV2Dto["_id"];
  }>,
  { state: StoreState }
>("calculators/fetch", async ({ authToken, planId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;
  let data: StateProps["data"] = initialState.data;

  const calculators: CalculatorsApiFindOneCalculatorRequest["type"][] = [
    "ProtectionCalculator",
    "IllnessCalculator",
    "TDPCalculator",
  ];

  const promises = calculators.map((type) =>
    API.Calculators(authToken).findOneCalculator({
      planId,
      type,
    })
  );

  try {
    // Await request in parallel
    const [protectionCalculator, illnessCalculator, tpdCalculator] = await Promise.all(promises);

    data = {
      protectionCalculator: isProtectionCalculator(protectionCalculator.data)
        ? protectionCalculator.data
        : undefined,
      illnessCalculator: isIllnessCalculator(illnessCalculator.data)
        ? illnessCalculator.data
        : undefined,
      tpdCalculator: isTpdCalculator(tpdCalculator.data) ? tpdCalculator.data : undefined,
    };
  } catch (e) {
    error = API.parseError(e);
  }

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Illness Calculator
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Calculators.updateIllness(\{
 *  authToken,
 *  planId,
 * updatedCalculatorData
 * \}));
 */

export const updateIllness = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: ResponsePlanV2Dto["_id"];
    updatedCalculatorData: UpdateIllnessCalculatorDto;
  }>,
  { state: StoreState }
>(
  "calculators/update-illness",
  async ({ authToken, planId, updatedCalculatorData }, { dispatch }) => {
    let error: StateProps["error"] = initialState.error;

    try {
      await API.Calculators(authToken).updateCalculator({
        planId,
        type: "IllnessCalculator",
        updateCalculatorRequest: updatedCalculatorData,
      });
    } catch (e) {
      error = API.parseError(e);
    }

    const submitSuccess = !error;

    void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

    if (error) {
      // Trigger error notification
      void dispatch(
        Store.notifications.addNotification({
          type: "error",
          title: lang("NOTIFICATION_TITLE_ERROR"),
          description: error,
        })
      );
    }

    if (submitSuccess) {
      // Trigger success notification
      void dispatch(
        Store.notifications.addNotification({
          type: "success",
          title: lang("NOTIFICATION_TITLE_UPDATED", lang("NOTIFICATION_LABEL_MAJOR_ILLNESS")),
          description: "",
        })
      );

      // Refresh calculators data (side effect)
      void dispatch(Store.calculators.fetch({ authToken, planId }));
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      error,
      submitSuccess,
    };
  }
);

/**
 * Update Protection Calculator
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Calculators.updateProtection(\{
 *  authToken,
 *  planId,
 * updatedCalculatorData
 * \}));
 */

export const updateProtection = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: ResponsePlanV2Dto["_id"];
    updatedCalculatorData: UpdateProtectionCalculatorDto;
  }>,
  { state: StoreState }
>(
  "calculators/update-protection",
  async ({ authToken, planId, updatedCalculatorData }, { dispatch }) => {
    let error: StateProps["error"] = initialState.error;

    try {
      await API.Calculators(authToken).updateCalculator({
        planId,
        type: "ProtectionCalculator",
        updateCalculatorRequest: updatedCalculatorData,
      });
    } catch (e) {
      error = API.parseError(e);
    }

    const submitSuccess = !error;

    void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

    if (error) {
      // Trigger error notification
      void dispatch(
        Store.notifications.addNotification({
          type: "error",
          title: lang("NOTIFICATION_TITLE_ERROR"),
          description: error,
        })
      );
    }

    if (submitSuccess) {
      // Trigger success notification
      void dispatch(
        Store.notifications.addNotification({
          type: "success",
          title: lang("NOTIFICATION_TITLE_UPDATED", lang("NOTIFICATION_LABEL_PROTECTION")),
          description: "",
        })
      );

      // Refresh calculators data (side effect)
      void dispatch(Store.calculators.fetch({ authToken, planId }));
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      error,
      submitSuccess,
    };
  }
);

/**
 * Update Tpd Calculator
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Calculators.updateTpd(\{
 *  authToken,
 *  planId,
 * updatedCalculatorData
 * \}));
 */

export const updateTpd = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: ResponsePlanV2Dto["_id"];
    updatedCalculatorData: UpdateTDPCalculatorDto;
  }>,
  { state: StoreState }
>("calculators/update-tpd", async ({ authToken, planId, updatedCalculatorData }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.Calculators(authToken).updateCalculator({
      planId,
      type: "TDPCalculator",
      updateCalculatorRequest: updatedCalculatorData,
    });
  } catch (e) {
    error = API.parseError(e);
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_UPDATED", lang("NOTIFICATION_LABEL_TPD")),
        description: "",
      })
    );

    // Refresh calculators data (side effect)
    void dispatch(Store.calculators.fetch({ authToken, planId }));
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
  };
});
