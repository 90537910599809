// Props
interface RetryProps {
  fn: () => Promise<void>;
  error: (err: string) => void;
  retries: number;
  delay: number;
}

/**
 * Wait Method
 * Pause next iteration for a specific amount of time
 * @param delay - timeout em milliseconds
 * @returns promise resolve after delay
 */
const wait = (delay: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, delay));

/**
 * Retry Method
 * Retry a function automatically for a limited number of times
 * or until success and with a certain delay between retries
 * @param fn - function to be executed
 * @param error - callback to be executed after failed retries
 * @param retries - amount of retries
 * @param delay - timeout em milliseconds for next retry
 * @returns success retry or error
 */
export const retry = async (props: RetryProps): Promise<void> =>
  await props.fn().catch((err) => {
    const retriesLeft = --props.retries;

    if (retriesLeft <= 0) {
      return props.error(err);
    }

    return wait(props.delay).then(() => retry(props));
  });
