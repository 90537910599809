// Styles
import "./styles/index.scss";
// React
import React from "react";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfCard } from "@advicefront/ds-card";
import { AfSkeleton } from "@advicefront/ds-skeleton";

// Props
interface LoaderSkeletonCardProps {
  media?: boolean;
}

export const LoaderSkeletonCard = ({ media }: LoaderSkeletonCardProps): React.ReactElement => (
  <AfCard className="loader-skeleton__card">
    <AfCard.Content>
      <AfRow spacing="lg">
        <AfCol size={6}>
          <AfSkeleton shape="line" size="xl" />
        </AfCol>
      </AfRow>
      {media && (
        <AfRow spacing="lg">
          <AfCol size="auto">
            <AfSkeleton shape="line" size="xl" className="loader-skeleton__card-media" />
          </AfCol>
        </AfRow>
      )}
      <AfRow spacing="lg">
        <AfCol size={10}>
          <AfSkeleton shape="line" size="l" />
        </AfCol>
      </AfRow>
      <AfRow spacing="lg">
        <AfCol size={8}>
          <AfSkeleton shape="line" size="m" />
        </AfCol>
      </AfRow>
    </AfCard.Content>
  </AfCard>
);
