import { Dispatch } from "@reduxjs/toolkit";
import { Store } from "@store/index";

/**
 * Helper function to reset all plan related data
 * @param dispatch - Store dispatch
 */
export const resetPlanData = (dispatch: Dispatch): void => {
  [
    Store.assumptions.reset,
    Store.accounts.reset,
    Store.calculators.reset,
    Store.forecast.reset,
    Store.forms.reset,
    Store.inputs.reset,
    Store.liquidation.reset,
    Store.people.reset,
    Store.plan.reset,
  ].forEach((resetFn) => void dispatch(resetFn()));
};
