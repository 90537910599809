// React
import { useMemo } from "react";
// Typings
import { FormModuleInputInstance } from "@forms/modules/types";
import { FormTypes } from "@advicefront/plan-client-axios";
import { RenderedSchemaStructure } from "@advicefront/fe-infra-form-schema-renderer";
import { isFormType } from "@routes/utils/modal-routing-guards";
// Hooks
import { useDtoKey } from "@forms/modules/input/hooks";

export const useSchemaStructure: FormModuleInputInstance["useSchemaStructure"] = (props) => {
  // Get Dto form key
  const dtoKey = useDtoKey(props);

  return useMemo((): RenderedSchemaStructure | undefined => {
    // Return undefined if Dto key is not defined or is not a form type
    if (!dtoKey || !isFormType(dtoKey)) return;

    // Define order of each form typw
    switch (dtoKey) {
      // GBR: Annuities Income
      // GBR: Final Salary Income
      // GBR: State Pension Income
      // SGP: Expense
      case FormTypes.GbrAnnuitiesIncomeInput:
      case FormTypes.GbrFinalSalarySchemeIncomeInput:
      case FormTypes.GbrStatePensionIncomeInput:
      case FormTypes.SgpExpenseInput:
        return [
          "label",
          "amount",
          "amountPeriodicity",
          "growthRate",
          "ownership",
          "startPeriod",
          "endPeriod",
        ];
      // GBR: Employment Income
      case FormTypes.GbrEmploymentIncomeInput: {
        return [
          "label",
          "amount",
          "amountPeriodicity",
          "bonusAmount",
          "bonusAmountPeriodicity",
          "benefitsAmount",
          "benefitsAmountPeriodicity",
          "dividendsAmount",
          "dividendsAmountPeriodicity",
          "growthRate",
          "ownership",
          "startPeriod",
          "endPeriod",
        ];
      }
      // GBR: Employment Other Income
      case FormTypes.GbrOtherIncomeInput:
        return [
          "label",
          "amount",
          "amountPeriodicity",
          "growthRate",
          "taxExempt",
          "ownership",
          "startPeriod",
          "endPeriod",
        ];
      // GBR: Employment Property Income
      // SGP: Employment Income
      case FormTypes.GbrPropertyIncomeInput:
      case FormTypes.SgpIncomeInput:
        return [
          "label",
          "asset",
          "amount",
          "amountPeriodicity",
          "growthRate",
          "ownership",
          "startPeriod",
          "endPeriod",
        ];

      // GBR: Self Employment Income
      case FormTypes.GbrSelfEmploymentIncomeInput:
        return [
          "label",
          "amount",
          "amountPeriodicity",
          "otherAmount",
          "otherAmountPeriodicity",
          "growthRate",
          "ownership",
          "startPeriod",
          "endPeriod",
        ];

      // GBR: Expense
      case FormTypes.GbrExpenseInput:
        return [
          "label",
          "amount",
          "amountPeriodicity",
          "growthRate",
          "taxDeductible",
          "ownership",
          "startPeriod",
          "endPeriod",
        ];

      // GBR: Account
      case FormTypes.GbrCurrentAccountInput:
      case FormTypes.GbrSavingsAccountInput:
      case FormTypes.GbrCashIsaAccountInput:
      case FormTypes.GbrLifetimeIsaAccountInput:
      case FormTypes.GbrStockSharesIsaAccountInput:
      case FormTypes.GbrGiaAccountInput:
      case FormTypes.GbrJisaAccountInput:
        return [
          "label",
          "currentValue",
          "capitalGrowth",
          "dividendsYield",
          "reinvestDividends",
          "interestYield",
          "reinvestInterest",
          "feeRate",
          "regularContributions",
          "contributionPeriodicity",
          "contributionsGrowthRate",
          "ownership",
          "startPeriod",
          "endPeriod",
          "accountLimit",
        ];

      // GBR: Asset
      case FormTypes.GbrMainResidenceAssetInput:
      case FormTypes.GbrPropertyAssetInput:
      case FormTypes.GbrOtherAssetInput:
        return [
          "label",
          "purchaseValue",
          "marketValue",
          "growthRate",
          "account",
          "ownership",
          "startPeriod",
          "endPeriod",
        ];

      // GBR: Pension
      case FormTypes.GbrPersonalPensionInput:
      case FormTypes.GbrEmployerPensionInput:
        return [
          "label",
          "currentValue",
          "growthRate",
          "contributionSource",
          "employerContribution",
          "employeeContribution",
          "personalContributions",
          "personalContributionsGrowth",
          "ownership",
          "startPeriod",
          "endPeriod",
          "accountLimit",
        ];

      // GBR: Debt
      case FormTypes.GbrLoanDebtInput:
      case FormTypes.GbrMortgageDebtInput:
        return [
          "label",
          "asset",
          "outstandingBalance",
          "repaymentAmount",
          "repaymentPeriodicity",
          "paymentDuration",
          "interestRate",
          "taxDeductible",
          "ownership",
          "startPeriod",
        ];

      // SGP: Employment Income
      case FormTypes.SgpEmploymentIncomeInput:
        return [
          "label",
          "amount",
          "amountPeriodicity",
          "growthRate",
          "bonusAmount",
          "bonusAmountPeriodicity",
          "bonusGrowthRate",
          "ownership",
          "startPeriod",
          "endPeriod",
        ];

      // SGP: Account
      case FormTypes.SgpUnitTrustsAccountInput:
      case FormTypes.SgpTermDepositAccountInput:
      case FormTypes.SgpSavingsAccountInput:
      case FormTypes.SgpCurrentAccountInput:
      case FormTypes.SgpCisAccountInput:
      case FormTypes.SgpSharesAccountInput:
      case FormTypes.SgpOtherAccountInput:
      case FormTypes.SgpBondsAccountInput:
        return [
          "label",
          "currentValue",
          "growthRate",
          "feeRate",
          "regularContributions",
          "contributionPeriodicity",
          "contributionsGrowthRate",
          "ownership",
          "startPeriod",
          "endPeriod",
          "accountLimit",
        ];

      // SGP: Asset
      case FormTypes.SgpMainResidenceAssetInput:
      case FormTypes.SgpPropertyAssetInput:
      case FormTypes.SgpOtherAssetInput:
        return [
          "label",
          "marketValue",
          "growthRate",
          "account",
          "ownership",
          "startPeriod",
          "endPeriod",
        ];

      // SGP: CPF Pension
      case FormTypes.SgpCpfOrdinaryPensionInput:
      case FormTypes.SgpCpfSpecialPensionInput:
      case FormTypes.SgpCpfMedisavePensionInput:
        return [
          "label",
          "currentValue",
          "income",
          "ownership",
          "startPeriod",
          "endPeriod",
          "accountLimit",
        ];

      // SGP: Dependent Scheme Pension
      // SGP: Retirement Account Pension
      case FormTypes.SgpDependentSchemePensionInput:
      case FormTypes.SgpRetirementAccountPensionInput:
        return [
          "label",
          "currentValue",
          "growthRate",
          "regularContributions",
          "contributionPeriodicity",
          "contributionsGrowthRate",
          "ownership",
          "startPeriod",
          "endPeriod",
          "accountLimit",
        ];

      // SGP: SRS Pension
      case FormTypes.SgpSrsPensionInput:
        return [
          "label",
          "currentValue",
          "growthRate",
          "regularContributions",
          "contributionPeriodicity",
          "contributionsGrowthRate",
          "nationalResident",
          "ownership",
          "startPeriod",
          "endPeriod",
          "accountLimit",
        ];

      // SGP: Debt
      case FormTypes.SgpMortgageDebtInput:
      case FormTypes.SgpLoanDebtInput:
      case FormTypes.SgpCarLoanDebtInput:
      case FormTypes.SgpCreditCardDebtInput:
      case FormTypes.SgpBankLoanDebtInput:
      case FormTypes.SgpBankOverdraftDebtInput:
      case FormTypes.SgpOtherDebtInput:
        return [
          "label",
          "outstandingBalance",
          "repaymentAmount",
          "repaymentPeriodicity",
          "paymentDuration",
          "interestRate",
          "ownership",
          "startPeriod",
        ];

      // Default
      default:
        return undefined;
    }
  }, [dtoKey]);
};
