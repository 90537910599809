// Typings
import { AfColProps } from "@advicefront/ds-grid";
import { Field } from "@advicefront/fe-infra-form-schema";
import { FormRendererOptions } from "@forms/renderer/types";

/**
 * Define column prop units with valid units from
 * AfColProps removing the nullable value
 */
type ColumnPropUnits<T extends keyof AfColProps> = {
  [key in NonNullable<AfColProps[T]>]?: Field["key"][];
};

/**
 * Define column props map used on
 * "inputPropsMap" column prop
 */
export interface ColumnPropsMap {
  size?: ColumnPropUnits<"size">;
  offsetBefore?: ColumnPropUnits<"offsetBefore">;
  offsetAfter?: ColumnPropUnits<"offsetAfter">;
  overrides?: {
    [key in NonNullable<FormRendererOptions["formDto"]>]?: Omit<ColumnPropsMap, "overrides">;
  };
}

/**
 * Define column props output used on the
 * renderer FormSchema AfCol component
 */
interface ColumnPropsOutput {
  size: AfColProps["size"];
  offsetBefore: AfColProps["offsetBefore"];
  offsetAfter: AfColProps["offsetAfter"];
}

/**
 * Define initial column props
 */
const INITIAL_COLUMN_PROPS: ColumnPropsOutput = {
  size: 6,
  offsetBefore: null,
  offsetAfter: null,
};

/**
 * Get Column Prop Value
 * @param columnProp - column property
 * @param fieldKey - field key from FormSchema instance
 * @param defaultProps - object with default props
 * @param overrideProps - object with override props
 * @returns - column prop value
 */
const getColumnPropValue = <T extends keyof ColumnPropsOutput>(
  columnProp: T,
  fieldKey: Field["key"],
  defaultProps: ColumnPropsMap,
  overrideProps: ColumnPropsMap
): ColumnPropsOutput[T] => {
  // Get prop value from overrides or default props
  const getPropValue = (propUnits: ColumnPropUnits<T> | undefined): ColumnPropsOutput[T] => {
    if (!propUnits) return;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (Object.keys(propUnits) as unknown as (keyof typeof propUnits)[]).find((unit) =>
      propUnits[unit]?.includes(fieldKey)
    );
  };

  // Return the override, default or initial prop value for the fieldKey column
  return (
    getPropValue(overrideProps[columnProp]) ||
    getPropValue(defaultProps[columnProp]) ||
    INITIAL_COLUMN_PROPS[columnProp]
  );
};

/**
 * Get Column Props
 * @param columnProps - object with interface of column props map
 * @param fieldKey - field key from FormSchema instance
 * @returns - column props output
 */
export const getColumnProps = (
  columnProps: ColumnPropsMap | undefined,
  formDto: FormRendererOptions["formDto"],
  fieldKey: Field["key"]
): ColumnPropsOutput => {
  // Return initial column props if not defined
  if (!columnProps) {
    return INITIAL_COLUMN_PROPS;
  }

  // Get override and default props from column props
  const { overrides, ...defaultProps } = columnProps;

  // Define override props as an empty object
  let overrideProps: ColumnPropsMap = {};

  // Check if overrides contains the formDto and set the props
  if (formDto && overrides && overrides[formDto]) {
    overrideProps = overrides[formDto] || {};
  }

  // Return all column props such size and offsets
  return Object.keys(INITIAL_COLUMN_PROPS).reduce((result, prop) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const columnProp = prop as keyof ColumnPropsOutput;
    // For each column prop set override, default or initial prop value
    result[columnProp] = getColumnPropValue(columnProp, fieldKey, defaultProps, overrideProps);
    // Return column prop result
    return result;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  }, {} as ColumnPropsOutput);
};
