// Styles
import "./styles/index.scss";
// React
import React, { useCallback, useContext, useEffect, useState } from "react";
// Store
import { Store } from "@store/index";
// Context
import { AppOptionsContext } from "@context/app-options";
// Hooks
import {
  CalculatorProtectionsModuleField,
  CalculatorProtectionsModuleTable,
  useCalculatorProtections,
} from "@components/tables/protections/hooks";
// Typings
import {
  TableProtectionsModulesName,
  TableProtectionsModulesPayload,
} from "@typings/tables/protections";
import { InputNodeFormat } from "@typings/input-node-format";
// Utils
import { formatPercentage, maskPercentage, unmaskPercentage } from "@utils/format-percentage";
import { maskCurrency, unmaskCurrency } from "@utils/format-currency";
// Translations
import { lang } from "@lang/index";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfTextField, AfTextFieldProps } from "@advicefront/ds-text-field";
import { AfTooltip, AfTooltipRenderProps } from "@advicefront/ds-tooltip";
import { AfButton } from "@advicefront/ds-button";
import { AfIcon } from "@advicefront/ds-icon";
import { AfTable } from "@advicefront/ds-table";
import { AfTypography } from "@advicefront/ds-typography";
import { LoaderSkeletonTable } from "@components/loaders/skeleton";

// Props
export interface TableProtectionsProps {
  title: string;
  name: TableProtectionsModulesName;
  handleSubmit: (payload: TableProtectionsModulesPayload) => void;
}

export const TableProtections = ({
  title,
  name,
  handleSubmit,
}: TableProtectionsProps): React.ReactElement => {
  // Store
  const plan = Store.useSelector((state) => state.plan);
  const calculators = Store.useSelector((state) => state.calculators);

  // Hook to get current calculator module data
  const { tables, gap, fetching, submitPayload } = useCalculatorProtections({
    name,
    data: calculators.data,
  });

  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // State to update calculator tables while editing
  const [calculatorTables, setCalculatorTables] = useState<CalculatorProtectionsModuleTable[]>([]);

  // Set calculator tables with initial values
  const setInitialValues = useCallback((): void => {
    // Stringify and parse to prevent referential equality
    setCalculatorTables(structuredClone(tables));
  }, [tables]);

  // Get props for text field component
  const getTextFieldProps = (
    field: CalculatorProtectionsModuleField
  ): Pick<AfTextFieldProps, "type" | "value" | "after" | "before"> => {
    // Define default props with empty object
    const props: Partial<AfTextFieldProps> = {};

    // Set props based on field type
    switch (field.type) {
      // Number
      case InputNodeFormat.number:
        props.type = "number";
        props.value = field.value;
        props.before = field.before;
        props.after = field.after;
        break;

      // Percent
      case InputNodeFormat.percent:
        props.type = "number";
        props.value = maskPercentage(field.value);
        props.before = "%";
        break;

      // Currency
      case InputNodeFormat.currency:
        props.value = maskCurrency(field.value);
        props.before = currencySymbol;
        break;
    }
    // Return text field props
    return props;
  };

  // Get text preview formatted
  const getTextPreview = (field: CalculatorProtectionsModuleField): string | undefined => {
    switch (field.type) {
      // Number
      case InputNodeFormat.number:
        return `${field.before || ""} ${field.value} ${field.after || ""}`;

      // Percent
      case InputNodeFormat.percent:
        return `${formatPercentage(field.value)}%`;

      // Currency
      case InputNodeFormat.currency:
        return maskCurrency(field.value, currencySymbol);
    }
  };

  // Handle change
  const handleChange = (
    value: CalculatorProtectionsModuleField["value"],
    tableIndex: number,
    rowIndex: number
  ): void => {
    setCalculatorTables((prevState) => {
      // Copy array to prevent a referential equality issue
      const newState = [...prevState];
      // Get current field
      const field = newState[tableIndex].entries[rowIndex].field;
      // Set field value based on type
      switch (field.type) {
        // Percent
        case InputNodeFormat.percent:
          field.value = unmaskPercentage(value);
          break;

        // Currency
        case InputNodeFormat.currency:
          field.value = unmaskCurrency(value);
          break;

        // Number
        default:
          field.value = value;
      }
      // Return the updated state
      return newState;
    });
  };

  // Handle calculate
  const handleCalculate = useCallback((): void => {
    // Convert calculator table entries to match with dto for submit action
    handleSubmit(submitPayload(calculatorTables));
  }, [handleSubmit, submitPayload, calculatorTables]);

  // Set calculator tables with initial values on init
  useEffect(() => {
    setInitialValues();
  }, [setInitialValues]);

  return (
    <>
      <AfTypography type="h2" className="table-protections__title">
        {title}
      </AfTypography>

      <AfRow orientation="vertical">
        {calculatorTables.map((table, tableIndex) => (
          <AfCol key={`table-${tableIndex}`}>
            {fetching && <LoaderSkeletonTable rows={4} columns={3} size="xl" />}

            {!fetching && (
              <AfTable border={["wrapper", "row"]} size="s">
                <thead>
                  <tr>
                    <th align="left" colSpan={2} className="table-protections__thead-col">
                      <AfTypography hasMargin={false} type="h3">
                        {table.title}
                      </AfTypography>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {table.entries.map((entry, rowIndex) => (
                    <tr key={`body-row-${tableIndex}-${rowIndex}`}>
                      <td align="left" className="table-protections__tbody-col">
                        {entry.label}
                        {entry.tooltip && (
                          <AfTooltip
                            position="right-center"
                            render={(
                              props: AfTooltipRenderProps<HTMLSpanElement>
                            ): React.ReactElement => (
                              <span {...props} className="table-protections__tbody-tooltip">
                                <AfIcon
                                  name="basic-info"
                                  size="s"
                                  nativeProps={{ className: "text-light" }}
                                />
                              </span>
                            )}
                          >
                            {entry.tooltip}
                          </AfTooltip>
                        )}
                      </td>

                      <td align="right" className="table-protections__tbody-col">
                        {!plan.data?.archived ? (
                          <AfTextField
                            {...getTextFieldProps(entry.field)}
                            placeholder={lang("FORM_PLACEHOLDER_VALUE")}
                            onChange={(ev): void => {
                              handleChange(ev.target.value, tableIndex, rowIndex);
                            }}
                          />
                        ) : (
                          <>{getTextPreview(entry.field)}</>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>

                <tfoot className="background-light">
                  {table.totals.map((total, rowIndex) => (
                    <tr key={`foot-row-${tableIndex}-${rowIndex}`}>
                      <td align="left" className="table-protections__tfoot-col">
                        <AfTypography hasMargin={false} type="body-bold">
                          {total.label}
                        </AfTypography>
                      </td>

                      <td align="right" className="table-protections__tfoot-col">
                        {!!calculators.loading && (
                          <AfTypography hasMargin={false} skin="text-light">
                            {lang("TABLE_LABEL_CALCULATING")}
                          </AfTypography>
                        )}
                        {!calculators.loading && (
                          <AfTypography hasMargin={false} type="body-bold">
                            {maskCurrency(total.value, currencySymbol)}
                          </AfTypography>
                        )}
                      </td>
                    </tr>
                  ))}
                </tfoot>
              </AfTable>
            )}
          </AfCol>
        ))}

        <AfCol>
          {fetching && <LoaderSkeletonTable rows={2} columns={3} size="xl" />}

          {!fetching && (
            <AfTable border={["wrapper", "row"]} size="l" className="background-light">
              <thead>
                <tr>
                  <th align="left">
                    <AfTypography hasMargin={false} type="h3">
                      {lang("TABLE_TITLE_PROTECTION_GAP")}
                    </AfTypography>
                  </th>

                  <th align="right">
                    {!!calculators.loading && (
                      <AfTypography hasMargin={false} type="h3" skin="text-light">
                        {lang("TABLE_LABEL_CALCULATING")}
                      </AfTypography>
                    )}
                    {!calculators.loading && (
                      <AfTypography
                        hasMargin={false}
                        type="h3"
                        skin={gap < 0 ? "danger" : undefined}
                      >
                        {maskCurrency(gap, currencySymbol)}
                      </AfTypography>
                    )}
                  </th>
                </tr>
              </thead>

              <tfoot>
                <tr>
                  <td align="right" colSpan={2}>
                    <AfTooltip
                      position="left-center"
                      render={(
                        props: AfTooltipRenderProps<HTMLSpanElement>
                      ): React.ReactElement => (
                        <span {...(plan.data?.archived && props)}>
                          <AfButton disabled={plan.data?.archived} onClick={handleCalculate}>
                            {lang("ACTION_CALCULATE")}
                          </AfButton>
                        </span>
                      )}
                    >
                      {lang("TOOLTIP_PLAN_ARCHIVED")}
                    </AfTooltip>
                  </td>
                </tr>
              </tfoot>
            </AfTable>
          )}
        </AfCol>
      </AfRow>
    </>
  );
};
