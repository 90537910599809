// List of query params related to the form modal
export enum FormModalParams {
  action = "action",
  type = "type",
  subType = "subType",
  id = "id",
  referral = "referral",
  formSelection = "formSelection",
  fromFormSelection = "fromFormSelection",
}

/**
 * Enums for props used on location state object
 * Used to define fromApp prop in the location object.
 * Allows us to know if a modal was opened from inside the app.
 * Set on openModal function in {@link useModalRouting} so we only show
 * the previous button when the modal was opened from inside the app
 */
export enum LocationStateEntries {
  fromApp = "fromApp",
}

// Enums for form actions
export enum FormActions {
  create = "create",
  edit = "edit",
  delete = "delete",
  duplicate = "duplicate",
}

// Enums for form keys to render modal content
export enum FormKeys {
  // People
  People = "People",
  Owner = "Owner",
  Partner = "Partner",
  Dependent = "Dependent",
  // Plan
  Plan = "Plan",
  PlanName = "PlanName",
  PlanImportErrors = "PlanImportErrors",
  // Inputs
  Tax = "Tax",
  AccountTransferInput = "AccountTransferInput",
  EventInput = "EventInput",
  AccountInput = "AccountInput",
  AssetInput = "AssetInput",
  PensionIncomeInput = "PensionIncomeInput",
  CpfPensionInput = "CpfPensionInput",
  DebtInput = "DebtInput",
  MortgageDebtInput = "MortgageDebtInput",
  LoanDebtInput = "LoanDebtInput",
  IncomeInput = "IncomeInput",
  PropertyIncomeInput = "PropertyIncomeInput",
  EmploymentIncomeInput = "EmploymentIncomeInput",
  OtherIncomeInput = "OtherIncomeInput",
  ExpenseInput = "ExpenseInput",
  PensionInput = "PensionInput",
  SrsPensionInput = "SrsPensionInput",
  SelfEmploymentIncomeInput = "SelfEmploymentIncomeInput",
}
