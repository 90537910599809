/**
 * Is Empty
 * Method to check if value is empty
 * @param value - string, array, object, undefined or null
 * @example isEmpty("") = true
 * @example isEmpty([]) = true
 * @example isEmpty(`{}`) = true
 * @example isEmpty(null) = true
 * @example isEmpty(undefined) = true
 * @returns true if value is not defined, empty array or empty object
 */
export function isEmpty<T>(value: T | undefined | null): value is T {
  return value === undefined || value === null || !Object.keys(value).length;
}
