// Typings
import { ForecastInputGroups } from "@advicefront/plan-client-axios";
import { TaxGroups } from "@typings/tax-groups";
// Translations
import { lang } from "@lang/index";

// Props
type GroupTypes = ForecastInputGroups | TaxGroups;

// Map each name for forecast and tax groups
const GROUP_NAMES: Partial<Record<GroupTypes, string>> = {
  // Forecast
  [ForecastInputGroups.IncomeInput]: lang("CHART_INCOMES"),
  [ForecastInputGroups.ExpenseInput]: lang("CHART_EXPENSES"),
  [ForecastInputGroups.AssetInput]: lang("CHART_PROPERTIES_AND_ASSETS"),
  [ForecastInputGroups.AccountInput]: lang("CHART_SAVINGS_AND_INVESTMENTS"),
  [ForecastInputGroups.PensionInput]: lang("CHART_PENSIONS"),
  [ForecastInputGroups.DebtInput]: lang("CHART_DEBTS"),
  [ForecastInputGroups.Tax]: lang("CHART_TAX"),
  // Tax
  [TaxGroups.TaxNonSavingsIncome]: lang("CHART_NON_SAVINGS_INCOME"),
  [TaxGroups.TotalNationalInsurance]: lang("CHART_NATIONAL_INSURANCE"),
  [TaxGroups.SavingsTaxSavingsIncome]: lang("CHART_SAVINGS_INCOME"),
  [TaxGroups.TaxDividends]: lang("CHART_DIVIDENDS"),
  [TaxGroups.TotalCapitalGainsResidential]: lang("CHART_CAPITAL_GAINS"),
};

/**
 * Get group name
 * Util to get group name to be displayed on chart legend and graph
 * @param value - value to check from forecast or tax groups
 * @returns group name or undefined
 */
export const getGroupName = (value: GroupTypes | undefined): string | undefined =>
  value ? GROUP_NAMES[value] : undefined;
