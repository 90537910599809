// React
import { useCallback, useContext, useMemo } from "react";
// Router
import { asFormKey } from "@routes/utils/modal-routing-guards";
// Store
import { Store } from "@store/index";
// Typings
import {
  TableBasicModuleEntry,
  TableBasicModuleSchema,
  UseTableBasicModule,
} from "@components/tables/basic/hooks";
import { Periodicity } from "@advicefront/plan-client-axios";
// Context
import { AppOptionsContext } from "@context/app-options";
// Utils
import { calculatePeriodicity } from "@utils/calculate-periodicity";
import { getClientsNames } from "@utils/get-clients";
import { getInputName } from "@utils/get-input-name";
import { maskCurrency } from "@utils/format-currency";
// Translations
import { lang } from "@lang/index";

export const useTableIncomeModule: UseTableBasicModule = () => {
  // Store
  const people = Store.useSelector((state) => state.people);
  const inputs = Store.useSelector((state) => state.inputs);

  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // Get amount
  const getAmount = useCallback(
    (amount: string, periodicity: Periodicity): string =>
      maskCurrency(calculatePeriodicity(amount, periodicity), currencySymbol),
    [currencySymbol]
  );

  // Return table basic data object
  return useMemo(
    (): TableBasicModuleSchema => ({
      head: {
        [lang("TABLE_COLUMN_NAME")]: "left",
        [lang("TABLE_COLUMN_TYPE")]: "left",
        [lang("TABLE_COLUMN_OWNER")]: "left",
        [lang("TABLE_COLUMN_AMOUNT")]: "right",
      },
      entries: [
        ...(inputs.data?.income || []).map(
          (income): TableBasicModuleEntry => ({
            values: [
              income.label,
              getInputName(income.__st),
              getClientsNames(people.data?.clients, income.ownership),
              getAmount(income.totalAmount, income.amountPeriodicity),
            ],
            action: {
              id: income._id,
              type: asFormKey(income.__bt),
              subType: income.__st,
            },
          })
        ),
      ],
      fetching: !inputs.data,
      empty: {
        title: lang("EMPTY_TITLE_FINANCIAL_SUMMARY"),
      },
    }),
    [inputs.data, people.data?.clients, getAmount]
  );
};
