// React
import React from "react";
// Typings
import { TableScrollableModules } from "@typings/tables/scrollable";
// Translations
import { lang } from "@lang/index";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfTypography } from "@advicefront/ds-typography";
import { TableScrollable } from "@components/tables/scrollable";

export const CashflowTable = (): React.ReactElement => (
  <AfRow orientation="vertical">
    <AfCol size="auto">
      <AfTypography hasMargin={false} type="h2">
        {lang("TABLE_TITLE_CASHFLOW_FORECAST")}
      </AfTypography>
    </AfCol>

    <AfCol size="auto">
      <TableScrollable name={TableScrollableModules.Cashflow} />
    </AfCol>
  </AfRow>
);
