// React
import React from "react";
// Router
import { View } from "@routes/index";
import { ViewRedirect } from "@routes/components/ViewRedirect";
// Store
import { Store } from "@store/index";
// Layouts
import { PlanDetailsLayout } from "@layouts/plan-details";
// Views
import { TaxChart } from "@views/tax/components/Chart";
import { TaxTable } from "@views/tax/components/Table";
import { overview } from "@views/overview";

// Define element for the view
const Element = (): React.ReactElement => {
  // Store
  const plan = Store.useSelector((state) => state.plan);

  // If is net plan type redirect user to general assumptions page
  // This will prevent user to see tax tab by changing the url
  if (plan.data?.net) return <ViewRedirect route={overview} />;

  return (
    <PlanDetailsLayout>
      <TaxChart />
      <TaxTable />
    </PlanDetailsLayout>
  );
};

// Route view options
export const tax: View = {
  name: "Tax",
  options: {
    path: "/plan/:id/tax",
    element: <Element />,
  },
};
