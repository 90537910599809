// Styles
import "./styles/index.scss";
// React
import React, { useState } from "react";
// Store
import { Store } from "@store/index";
import { selectFinancialSummary } from "@store/selectors/financial-summary";
// Translations
import { lang } from "@lang/index";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfButton } from "@advicefront/ds-button";
import { AfCard } from "@advicefront/ds-card";
import { AfCollapse } from "@advicefront/ds-collapse";
import { AfIcon } from "@advicefront/ds-icon";
import { AfTypography } from "@advicefront/ds-typography";
import { LoaderSkeletonTable } from "@components/loaders/skeleton";
import { TableBasic } from "@components/tables/basic";

export const InputsFinancialSummary = (): React.ReactElement => {
  // Store
  const inputs = Store.useSelector((state) => state.inputs);
  const summary = Store.useSelector(selectFinancialSummary());

  // States for expand and collapse all or an item
  const [expandAll, setExpandAll] = useState<boolean>(false);
  const [expandItem, setExpandItem] = useState<Record<string, boolean>>({});

  // Expand or collapse an item
  const toggleItem = (key: string): void => {
    setExpandItem((prevState) => ({
      ...prevState,
      [key]: !expandItem[key],
    }));
  };

  // Expand or collapse all items
  const toggleAll = (value: boolean): void => {
    setExpandAll(value);
    setExpandItem(() =>
      value ? summary.reduce((prev, item) => ({ ...prev, [item.module]: true }), {}) : {}
    );
  };

  return (
    <>
      <AfRow align="center" justify="spread" spacing="lg">
        <AfCol>
          <AfTypography hasMargin={false} type="h2">
            {lang("TABLE_TITLE_FINANCIAL_SUMMARY")}
          </AfTypography>
        </AfCol>
        <AfCol>
          <AfButton
            type="button"
            skin="ghost"
            icon={<AfIcon name={`arrow-circle-${expandAll ? "up" : "down"}`} />}
            onClick={(): void => toggleAll(!expandAll)}
          >
            {expandAll ? lang("ACTION_COLLAPSE_ALL") : lang("ACTION_EXPAND_ALL")}
          </AfButton>
        </AfCol>
      </AfRow>
      <AfRow spacing="lg">
        <AfCol size="auto">
          {!inputs.data && <LoaderSkeletonTable rows={6} columns={3} size="xl" />}

          {inputs.data && (
            <AfRow orientation="vertical">
              {summary.map((entry) => (
                <AfCol key={entry.module}>
                  <AfCard padding="none">
                    <AfCard.Header border={!!expandItem[entry.module]}>
                      <AfRow align="center" className="inputs-financial-summary__section">
                        <AfCol>
                          <AfIcon name={entry.icon} size="m" />
                        </AfCol>
                        <AfCol size="auto">
                          <AfTypography hasMargin={false} type="h3">
                            {`${entry.title} (${entry.count})`}
                          </AfTypography>
                        </AfCol>
                        <AfCol>
                          <AfButton
                            skin="secondary"
                            size="s"
                            icon={
                              <AfIcon
                                name={`basic-arrow-${expandItem[entry.module] ? "up" : "down"}`}
                              />
                            }
                            onClick={(): void => toggleItem(entry.module)}
                          />
                        </AfCol>
                      </AfRow>
                    </AfCard.Header>
                    <AfCollapse open={expandItem[entry.module]}>
                      <TableBasic name={entry.module} border="row" />
                    </AfCollapse>
                  </AfCard>
                </AfCol>
              ))}
            </AfRow>
          )}
        </AfCol>
      </AfRow>
    </>
  );
};
