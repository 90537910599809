// Styles
import "./styles/index.scss";
// React
import React, { useMemo } from "react";
// Router
import { useLocation, useParams, generatePath, Link } from "react-router-dom";
import { getRoute } from "@routes/utils/get-route";
// Store
import { Store } from "@store/index";
// Typings
import { Regions } from "@advicefront/plan-client-axios";
import { RouteIndex } from "@routes/index";
// Translations
import { lang } from "@lang/index";
// Layouts
import { PlanDetailsDropdownAddInputs } from "@layouts/plan-details/components/DropdownAddInputs";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfTabs } from "@advicefront/ds-tabs";
import { AfTypography } from "@advicefront/ds-typography";
import { DropdownPlanOptions } from "@components/dropdowns/PlanOptions";

export const PlanDetailsHeader = (): React.ReactElement => {
  // Store
  const plan = Store.useSelector((state) => state.plan);

  // Route
  const params = useParams();
  const { pathname } = useLocation();

  // List of all tabs
  const tabs = useMemo(() => {
    // Add Tax tab for GBR region and if it is a gross basis net plan
    const showTaxTab = plan.data?.region === Regions.Gbr && !plan.data?.net;

    // Add Protections tab for SGP region
    const showProtectionsTab = plan.data?.region === Regions.Sgp;

    // Define order of tabs
    const tabsOrdered: Record<string, RouteIndex> = {
      [lang("TAB_OVERVIEW")]: "overview",
      [lang("TAB_INPUTS")]: "inputs",
      [lang("TAB_CASHFLOW")]: "cashflow",
      [lang("TAB_ASSETS_AND_LIABILITIES")]: "assetsAndLiabilities",
      ...(showTaxTab && { [lang("TAB_TAX")]: "tax" }),
      [lang("TAB_ASSUMPTIONS")]: "assumptions",
      ...(showProtectionsTab && { [lang("TAB_PROTECTIONS")]: "protections" }),
    };

    // Return list of tabs ordered with title and route
    return Object.entries(tabsOrdered).map(([title, routeIndex]) => ({
      title,
      route: generatePath(getRoute(routeIndex), params),
    }));
  }, [params, plan.data]);

  return (
    <div className="sticky-header">
      <AfRow justify="spread" align="center">
        <AfCol size="auto">
          <AfTypography hasMargin={false} type="h1">
            {`${plan.data?.name} ${plan.data?.archived ? lang("PLAN_TITLE_ARCHIVED") : ""}`}
          </AfTypography>
        </AfCol>
        {plan.data && (
          <AfCol>
            <DropdownPlanOptions buttonSkin="secondary" currentPlan={plan.data} />
          </AfCol>
        )}
        <AfCol>
          <PlanDetailsDropdownAddInputs />
        </AfCol>
      </AfRow>
      <AfRow spacing="sm" className="plan-details-header__tabs">
        <AfCol size="auto">
          <AfTabs variation="marker">
            {tabs.map((tab) => (
              <AfTabs.Item
                key={tab.route}
                active={pathname.startsWith(tab.route)}
                component={<Link to={tab.route} />}
              >
                {tab.title}
              </AfTabs.Item>
            ))}
          </AfTabs>
        </AfCol>
      </AfRow>
    </div>
  );
};
