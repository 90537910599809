// Typings
import { FormSchema } from "@advicefront/fe-infra-form-schema";

/**
 * Is Form Schema
 * Type guard to check if it's FormSchema type
 * @param data - CoreFieldBase object properties
 * @returns boolean - true if data it's FormSchema type
 */
export function isFormSchema(data: unknown): data is FormSchema {
  return (
    data !== null &&
    typeof data === "object" &&
    !Object.values(data)
      .map((field) => "key" in field && "fieldType")
      .includes(false)
  );
}
