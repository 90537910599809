// Store
import { StoreState } from "@store/index";
// Typings
import {
  TableAssumptionsModules,
  TableAssumptionsModulesData,
  TableAssumptionsModulesName,
  TableAssumptionsModulesPayload,
} from "@typings/tables/assumptions";
import { InputNodeFormat } from "@typings/input-node-format";
import { UpdatePlanAssumptionsRequest } from "@advicefront/plan-client-axios";
// Modules
import {
  useTableAccountsModule,
  useTableCpfCapsModule,
  useTableCpfEmployeeEmployerModule,
  useTableCpfThresholdsModule,
  useTableDividendsModule,
  useTableInflationModule,
  useTableIsaLimitsModule,
  useTableMortalityModule,
  useTableNationalInsuranceC1Module,
  useTableNationalInsuranceC2Module,
  useTableNationalInsuranceC4Module,
  useTableNonSavingsIncomeModule,
  useTableSavingsIncomeModule,
  useTableSrsCapsModule,
} from "@components/tables/assumptions/hooks/modules";

// Props: Hooks
type UseTableAssumptions = (props: {
  name: TableAssumptionsModulesName;
  data: TableAssumptionsModulesData;
  firmPreferences: StoreState["firmPreferences"];
}) => TableAssumptionsModuleSchema;

export type UseTableAssumptionsModule = UseTableAssumptions;

// Props: Modules
export interface TableAssumptionsModuleEntry {
  label: string;
  fields: TableAssumptionsModuleField[];
}

export interface TableAssumptionsModuleField {
  type: InputNodeFormat;
  value: string | number | boolean | null | undefined;
  before?: string;
  after?: string;
  preview?: string;
  hidden?: boolean;
}

export interface TableAssumptionsModuleSchema {
  title: string;
  head: {
    label: string;
    align: "left" | "center" | "right";
  }[];
  entries: TableAssumptionsModuleEntry[];
  customise: {
    checked: boolean;
    payload?: UpdatePlanAssumptionsRequest;
  };
  reset?: {
    allowed: boolean;
    payload: UpdatePlanAssumptionsRequest;
  };
  empty?: {
    title: string;
    description?: string;
  };
  submitPayload: (updatedData: TableAssumptionsModuleEntry[]) => TableAssumptionsModulesPayload;
}

/**
 * Maps each assumption module to its corresponding table hook module
 * Each assumption module has its own specific hook to retrieve its table data
 */
const TableAssumptionsMap: Record<TableAssumptionsModulesName, UseTableAssumptionsModule> = {
  [TableAssumptionsModules.Mortality]: useTableMortalityModule,
  [TableAssumptionsModules.Inflation]: useTableInflationModule,
  [TableAssumptionsModules.IsaLimits]: useTableIsaLimitsModule,
  [TableAssumptionsModules.CpfThresholds]: useTableCpfThresholdsModule,
  [TableAssumptionsModules.CpfEmployeeEmployer]: useTableCpfEmployeeEmployerModule,
  [TableAssumptionsModules.CpfCaps]: useTableCpfCapsModule,
  [TableAssumptionsModules.SrsCaps]: useTableSrsCapsModule,
  [TableAssumptionsModules.NonSavingsIncome]: useTableNonSavingsIncomeModule,
  [TableAssumptionsModules.NationalInsuranceC1]: useTableNationalInsuranceC1Module,
  [TableAssumptionsModules.NationalInsuranceC2]: useTableNationalInsuranceC2Module,
  [TableAssumptionsModules.NationalInsuranceC4]: useTableNationalInsuranceC4Module,
  [TableAssumptionsModules.SavingsIncome]: useTableSavingsIncomeModule,
  [TableAssumptionsModules.Dividends]: useTableDividendsModule,
  [TableAssumptionsModules.Accounts]: useTableAccountsModule,
};

/**
 * Returns table data for a specific assumption module
 * Determines which module hook to use based on the assumption module name
 * The hook will receive the current module name, store data and the firm preferences
 * @param name - name of the assumption module
 * @param data - current store data
 * @returns the table data for the specified assumption module
 */
export const useTableAssumptions: UseTableAssumptions = ({ name, data, firmPreferences }) =>
  TableAssumptionsMap[name]({
    name,
    data,
    firmPreferences,
  });
