// React
import { useMemo, useEffect } from "react";
// Router
import { useNavigate, useLocation } from "react-router-dom";
import { generatePath } from "react-router";
import { getRoute } from "@routes/utils/get-route";
// Store
import { Store } from "@store/index";
import { selectPlanById } from "@store/selectors/plan-by-id";
// Typings
import { DialogDeleteModuleSchema, UseDialogDeleteModule } from "@components/dialogs/delete/hooks";
// Translations
import { lang } from "@lang/index";

export const useDialogPlanModule: UseDialogDeleteModule = (id) => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);
  const plans = Store.useSelector((state) => state.plans);

  // Routing
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isPlansListRoute = useMemo(() => pathname.startsWith(getRoute("plansList")), [pathname]);

  // Selected plan from list or detail
  const selectedPlan = Store.useSelector((state) =>
    isPlansListRoute ? selectPlanById(id)(state) : plan.data
  );

  // Navigate to plans list if plan was deleted from detail
  useEffect(() => {
    if (!plan.submitSuccess || isPlansListRoute) return;
    navigate(generatePath(getRoute("plansList")));
  }, [plan.submitSuccess, navigate, isPlansListRoute]);

  // Return title, name, loading, submitting, submit success, and delete action
  return useMemo(
    (): DialogDeleteModuleSchema => ({
      title: lang("DIALOG_TITLE_DELETE_PLAN"),
      name: selectedPlan?.name,
      loading: isPlansListRoute ? !plans.data : !plan.data,
      submitting: !!plan.loading,
      // On plan detail it will navigate to plans list after submission
      submitSuccess: plan.submitSuccess && isPlansListRoute,
      handleDelete: (): void => {
        if (!selectedPlan?._id || !auth.clientGroupId) {
          throw new Error("Plan Id or Client Group Id is not defined");
        }

        void dispatch(
          Store.plan.deletePlan({
            authToken: auth.authToken,
            clientGroupId: auth.clientGroupId,
            planId: selectedPlan._id,
          })
        );
      },
    }),
    [
      auth,
      dispatch,
      isPlansListRoute,
      plans.data,
      plan.data,
      plan.loading,
      plan.submitSuccess,
      selectedPlan?._id,
      selectedPlan?.name,
    ]
  );
};
