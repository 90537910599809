// React
import React, { Suspense, useContext } from "react";
// Router
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { routes } from "../";
// Context
import { AppOptionsContext } from "@context/app-options";

/**
 * Returns a router component according to the render context
 * (client / server)
 * @param props - children slot
 * @returns component
 */

export const RouterComponent = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  const { planRouterBaseName } = useContext(AppOptionsContext);

  /**
   * Checks if there is a DOM context
   */

  const hasDom = typeof document !== "undefined";

  /**
   * Disables browser router usage for SSR
   * In case of SSR context, a static router should be applied on the server
   */

  return (
    <>
      {hasDom && <BrowserRouter basename={planRouterBaseName}>{children}</BrowserRouter>}

      {!hasDom && <>{children}</>}
    </>
  );
};

/**
 * Main / global router component
 */

export const DefaultRouter = (): React.ReactElement => (
  <RouterComponent>
    <Suspense fallback="Loading...">
      <Routes>
        {routes.map(({ options }, k) => (
          <Route key={k} {...options} />
        ))}
      </Routes>
    </Suspense>
  </RouterComponent>
);
