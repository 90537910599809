/**
 * Dialogs
 * Naming convention: DIALOG_TITLE_ELEMENT or DIALOG_DESCRIPTION_ELEMENT
 */
export const dialogs = {
  // Delete
  DIALOG_TITLE_DELETE: {
    en: "Delete {0}",
  },
  DIALOG_DESCRIPTION_DELETE: {
    en: "Are you sure you want to delete {0}?",
  },
  DIALOG_TITLE_DELETE_INPUT: {
    en: "Input",
  },
  DIALOG_TITLE_DELETE_PERSON: {
    en: "Person",
  },
  DIALOG_TITLE_DELETE_PLAN: {
    en: "Plan",
  },

  // Changes Lost
  DIALOG_DESCRIPTION_CHANGES_LOST: {
    en: "If you do, your changes will be lost",
  },

  // Close Form
  DIALOG_TITLE_CLOSE_FORM: {
    en: "Close {0} form",
  },
  DIALOG_DESCRIPTION_CLOSE_FORM: {
    en: "Are you sure you want to close the {0} form?",
  },

  // Go Back
  DIALOG_TITLE_GO_BACK: {
    en: "Go back to {0} selection",
  },
  DIALOG_DESCRIPTION_GO_BACK: {
    en: "Are you sure you want to perform this action?",
  },

  // Close Import Errors
  DIALOG_TITLE_CLOSE_IMPORT_ERRORS: {
    en: "Discard Error Report",
  },
  DIALOG_DESCRIPTION_CLOSE_IMPORT_ERRORS: {
    en: "Are you sure you want to dismiss the error report? You'll no longer be able to view errors from the Fact-Find Import",
  },
};
