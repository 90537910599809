// React
import { useCallback, useContext, useMemo } from "react";
// Typings
import {
  TableAssumptionsModuleSchema,
  UseTableAssumptionsModule,
} from "@components/tables/assumptions/hooks";
import { InputNodeFormat } from "@typings/input-node-format";
// Context
import { AppOptionsContext } from "@context/app-options";
// Utils
import { isAccountsAssumptions, isGbrAssumptions } from "@utils/type-guards/assumptions";
import { isEqual } from "@utils/is-equal";
// Translations
import { lang } from "@lang/index";

export const useTableIsaLimitsModule: UseTableAssumptionsModule = ({
  name,
  data,
  firmPreferences,
}) => {
  // Throw error if data is not valid for Isa Limits module
  if (data && (isAccountsAssumptions(data) || !isGbrAssumptions(data))) {
    throw new Error(`${name} data not valid`);
  }

  // Define firm data as GBR region
  const firmData = isGbrAssumptions(firmPreferences.data) ? firmPreferences.data : undefined;

  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // Define submit payload function
  const submitPayload = useCallback<TableAssumptionsModuleSchema["submitPayload"]>(
    (updatedData) => ({
      isa: {
        lifetime: Number(updatedData[0].fields[0].value),
        inflationAdjustedLifetime: !!updatedData[0].fields[1].value,
        totalContribution: Number(updatedData[1].fields[0].value),
        inflationAdjustedTotal: !!updatedData[1].fields[1].value,
      },
    }),
    []
  );

  // Return table assumptions data object
  return useMemo(
    (): TableAssumptionsModuleSchema => ({
      title: lang("TABLE_TITLE_ISA_LIMITS_ASSUMPTION"),
      head: [
        {
          label: lang("TABLE_COLUMN_NAME"),
          align: "left",
        },
        {
          label: lang("TABLE_COLUMN_LIMIT_PER_YEAR", currencySymbol),
          align: "right",
        },
        {
          label: lang("TABLE_COLUMN_ADJUST_INFLATION"),
          align: "center",
        },
      ],
      entries: [
        {
          label: lang("TABLE_LABEL_LIFETIME_ISA_LIMIT"),
          fields: [
            {
              type: InputNodeFormat.currency,
              value: data?.isa.lifetime,
            },
            {
              type: InputNodeFormat.boolean,
              value: data?.isa.inflationAdjustedLifetime,
            },
          ],
        },
        {
          label: lang("TABLE_LABEL_TOTAL_ISA_LIMIT"),
          fields: [
            {
              type: InputNodeFormat.currency,
              value: data?.isa.totalContribution,
            },
            {
              type: InputNodeFormat.boolean,
              value: data?.isa.inflationAdjustedTotal,
            },
          ],
        },
      ],
      customise: {
        checked: !!data?.settings.isa,
        payload: {
          settings: {
            isa: !firmData?.settings.isa,
          },
        },
      },
      reset: {
        allowed: !isEqual(data?.isa, firmData?.isa),
        payload: {
          isa: {
            lifetime: firmData?.isa.lifetime,
            inflationAdjustedLifetime: firmData?.isa.inflationAdjustedLifetime,
            totalContribution: firmData?.isa.totalContribution,
            inflationAdjustedTotal: firmData?.isa.inflationAdjustedTotal,
          },
        },
      },
      submitPayload,
    }),
    [
      currencySymbol,
      submitPayload,
      data?.settings.isa,
      data?.isa,
      firmData?.settings.isa,
      firmData?.isa,
    ]
  );
};
