// React
import React from "react";
// Router
import { View } from "@routes/index";
import { ViewRedirect } from "@routes/components/ViewRedirect";
// Views
import { assumptionsGeneral } from "@views/assumptions/general";

// Export all views
export * from "./accounts";

export * from "./general";

export * from "./liquidation";

export * from "./tax";

// Route view options
export const assumptions: View = {
  name: "Assumptions",
  options: {
    path: "/plan/:id/assumptions",
    element: <ViewRedirect route={assumptionsGeneral} />,
  },
};
