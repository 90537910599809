// Styles
import "./styles/index.scss";
// React
import React, { useCallback, useContext, useMemo, useState } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { ChartRingModules, ChartRingModulesName } from "@typings/charts/ring";
// Context
import { AppOptionsContext } from "@context/app-options";
// Utils
import classNames from "classnames";
import { maskCurrency } from "@utils/format-currency";
// Translations
import { lang } from "@lang/index";
// Components
import { AfRow, AfCol } from "@advicefront/ds-grid";
import { AfCard } from "@advicefront/ds-card";
import { AfSelect } from "@advicefront/ds-select";
import { AfTypography } from "@advicefront/ds-typography";
import { ChartRing } from "@components/charts/ring";
import { LoaderOverlaySpinner } from "@components/loaders/overlay-spinner";
import { LoaderSkeletonCard } from "@components/loaders/skeleton";

// Props
interface OverviewChartTypes {
  cashflow: ChartRingModules.Inflow | ChartRingModules.Outflow;
  netWorth: ChartRingModules.Assets | ChartRingModules.Liabilities;
}

interface OverviewChartCard {
  title: string;
  type: keyof OverviewChartTypes;
  amount: number;
  options: {
    label: string;
    value: ChartRingModulesName;
    total: number;
  }[];
}

export const OverviewCharts = (): React.ReactElement => {
  // Store
  const forecast = Store.useSelector((state) => state.forecast);

  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // State to define current charts for cashflow and netWorth
  const [currentChart, setCurrentChart] = useState<OverviewChartTypes>({
    cashflow: ChartRingModules.Inflow,
    netWorth: ChartRingModules.Assets,
  });

  // Set charts cards for cashflow and netWorth
  const chartCards = useMemo(
    (): OverviewChartCard[] => [
      // Cashflow
      {
        title: lang("CHART_CASH_FLOW"),
        type: "cashflow",
        amount: forecast.data?.cashflow.surplusAndShortfall[0] || 0,
        options: [
          {
            label: lang("CHART_INFLOW"),
            value: ChartRingModules.Inflow,
            total: Math.abs(forecast.data?.cashflow.totalIncomes[0] || 0),
          },
          {
            label: lang("CHART_OUTFLOW"),
            value: ChartRingModules.Outflow,
            total: Math.abs(forecast.data?.cashflow.totalExpenses[0] || 0),
          },
        ],
      },
      // NetWorth
      {
        title: lang("CHART_NET_WORTH"),
        type: "netWorth",
        amount: forecast.data?.balance.netWorth[0] || 0,
        options: [
          {
            label: lang("CHART_ASSETS"),
            value: ChartRingModules.Assets,
            total: Math.abs(forecast.data?.balance.totalAssetValues[0] || 0),
          },
          {
            label: lang("CHART_LIABILITIES"),
            value: ChartRingModules.Liabilities,
            total: Math.abs(forecast.data?.balance.totalLiabilityValues[0] || 0),
          },
        ],
      },
    ],
    [forecast.data?.cashflow, forecast.data?.balance]
  );

  // Handle change
  const handleChange = useCallback((type: OverviewChartCard["type"], value?: string): void => {
    setCurrentChart(
      (prevState): OverviewChartTypes => ({
        ...prevState,
        [type]: value,
      })
    );
  }, []);

  return (
    <AfRow
      responsive={{
        xs: {
          orientation: "vertical",
        },
        sm: {
          orientation: "horizontal",
        },
      }}
    >
      {chartCards.map((card, cardIndex) => (
        <AfCol size="auto" key={`overview-card-${cardIndex}`}>
          {!forecast.data && <LoaderSkeletonCard media={true} />}

          {forecast.data && (
            <LoaderOverlaySpinner
              active={!!forecast.polling}
              title={lang("LOADER_TITLE_CALCULATING")}
              description={lang("LOADER_DESCRIPTION_CALCULATING")}
              size="m"
            >
              <AfCard padding="l">
                <AfCard.Content>
                  <AfRow orientation="vertical">
                    <AfCol className="align-center">
                      <AfTypography hasMargin={false} type="h3">
                        {card.title}
                      </AfTypography>
                      <AfTypography type="h1">
                        {maskCurrency(card.amount, currencySymbol)}
                      </AfTypography>
                      <AfRow justify="center">
                        {card.options.map((option, optionIndex) => (
                          <AfCol key={`overview-card-options-${cardIndex}-${optionIndex}`}>
                            <div
                              className={classNames("overview-charts__summary", {
                                ["overview-charts__summary--divider"]: optionIndex === 0,
                              })}
                            >
                              <AfTypography hasMargin={false} skin="text-light">
                                {option.label}
                              </AfTypography>
                              <AfTypography hasMargin={false} type="large-semi">
                                {maskCurrency(option.total, currencySymbol)}
                              </AfTypography>
                            </div>
                          </AfCol>
                        ))}
                      </AfRow>
                    </AfCol>
                    <AfCol>
                      <AfSelect
                        options={card.options}
                        value={currentChart[card.type]}
                        onChange={(value): void => handleChange(card.type, value)}
                      />
                      <AfRow justify="center">
                        <AfCol>
                          <ChartRing name={currentChart[card.type]} />
                        </AfCol>
                      </AfRow>
                    </AfCol>
                  </AfRow>
                </AfCard.Content>
              </AfCard>
            </LoaderOverlaySpinner>
          )}
        </AfCol>
      ))}
    </AfRow>
  );
};
