// Styles
import "./styles/index.scss";
// React
import React, { useEffect, useMemo } from "react";
// Router
import { DefaultRouter } from "@routes/router/Default";
// UI Components
import { AfRoot } from "@advicefront/ds-base";
import { AfSnackbar } from "@advicefront/ds-snackbar";
// Redux
import { Store } from "@store/index";
// Context
import { AppOptionsContext, AppOptionsContextProps } from "@context/app-options";
// API
import { API } from "@services/api";
import { ROOT_ELEMENT_ID } from "./config";
// Utils
import { GlobalState } from "@advicefront/fe-infra-global-state";
// Types
import { ClientGroupInfo } from "@typings/client-group-info";
import { ClientNamingMode } from "@typings/client-naming-mode";
import { Regions } from "@advicefront/plan-client-axios";
import { SessionGateProps } from "@advicefront/fe-infra-auth";

export const App = (): React.ReactElement => {
  // Store global App options in context
  const globalAppOptions: AppOptionsContextProps = useMemo(
    (): AppOptionsContextProps => ({
      planApiUrl: GlobalState.get<string | undefined>("plan-api-url"),
      planRouterBaseName: GlobalState.get<string | undefined>("plan-router-basename"),
      planInitialRoute: GlobalState.get<string>("plan-initial-route", { requireDefined: true }),
      currencySymbol: GlobalState.get<string>("currencySymbol", {
        requireDefined: true,
      }),
      clientNamingMode: GlobalState.get<ClientNamingMode>("client-naming-mode", {
        requireDefined: true,
      }),
      clientRegion: GlobalState.get<Regions>("client-region", {
        requireDefined: true,
      }),
      getAuthToken: GlobalState.get<SessionGateProps["getAuthToken"]>("get-auth-token", {
        requireDefined: true,
      }),
      getClientGroupId: GlobalState.get<() => Promise<string | null>>("get-client-group-id", {
        requireDefined: true,
      }),
      getClientGroupInfo: GlobalState.get<() => Promise<ClientGroupInfo[] | null>>(
        "get-client-group-info",
        {
          requireDefined: true,
        }
      ),
    }),
    []
  );

  useEffect(() => {
    // Setup base api options
    API.configure({
      plans: {
        basePath: GlobalState.get<string>("plan-api-url", {
          requireDefined: true,
        }),
      },
    });
  }, []);

  return (
    <Store.Provider>
      <AppOptionsContext.Provider value={globalAppOptions}>
        <AfRoot
          cssScope={`${ROOT_ELEMENT_ID}-scope microfrontend-scope`}
          classNamesPrefix={`${ROOT_ELEMENT_ID}-component`}
        >
          <AfSnackbar>
            <DefaultRouter />
          </AfSnackbar>
        </AfRoot>
      </AppOptionsContext.Provider>
    </Store.Provider>
  );
};
