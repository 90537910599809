// React
import { useCallback, useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import {
  TableBasicModuleEntry,
  TableBasicModuleSchema,
  UseTableBasicModule,
} from "@components/tables/basic/hooks";
import { ClientType } from "@advicefront/plan-client-axios";
import { FormKeys } from "@typings/routing";
// Constants
import { EMPTY_VALUE } from "@constants/index";
// Translations
import { lang } from "@lang/index";

export const useTableHouseholdModule: UseTableBasicModule = () => {
  // Store
  const people = Store.useSelector((state) => state.people);

  // Get current client age
  const getClientAge = useCallback(
    (yearOfBirth: number): number => new Date().getFullYear() - yearOfBirth,
    []
  );

  // Return table basic data object
  return useMemo(
    (): TableBasicModuleSchema => ({
      head: {
        [lang("TABLE_COLUMN_NAME")]: "left",
        [lang("TABLE_COLUMN_TYPE")]: "left",
        [lang("TABLE_COLUMN_BIRTH_YEAR")]: "left",
        [lang("TABLE_COLUMN_CURRENT_AGE")]: "left",
        [lang("TABLE_COLUMN_RETIREMENT_AGE")]: "left",
      },
      entries: [
        // Clients
        ...(people.data?.clients || []).map(
          (client): TableBasicModuleEntry => ({
            values: [
              `${client.firstName} ${client.lastName || ""}`,
              client.clientType,
              client.yearOfBirth,
              getClientAge(client.yearOfBirth),
              client.retirementAge || EMPTY_VALUE,
            ],
            action: {
              id: client._id,
              type: FormKeys[client.clientType],
            },
            disabled: {
              delete: client.clientType === ClientType.Owner,
            },
          })
        ),

        // Dependents
        ...(people.data?.dependents || []).map(
          (dependent): TableBasicModuleEntry => ({
            values: [
              `${dependent.firstName} ${dependent.lastName || ""}`,
              dependent.relationshipType,
              dependent.yearOfBirth,
              getClientAge(dependent.yearOfBirth),
              EMPTY_VALUE,
            ],
            action: {
              id: dependent._id,
              type: FormKeys.Dependent,
            },
          })
        ),
      ],
      fetching: !people.data,
    }),
    [people.data, getClientAge]
  );
};
