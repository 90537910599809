// Store
import { StoreState } from "@store/index";
// Services
import { API } from "@services/api";
// Typings
import { FormModuleHookProps } from "@forms/modules/types";
import { FormSchemaRendererOnChangeProps } from "@advicefront/fe-infra-form-schema-renderer";
import { Periodicity } from "@advicefront/plan-client-axios";
// Constants
import { EMPTY_VALUE } from "@constants/index";

// Props
interface InterestRateCalculatorOptions {
  authToken: StoreState["auth"]["authToken"];
  changedData: FormSchemaRendererOnChangeProps;
  formData: FormModuleHookProps["formData"];
  setValues: FormModuleHookProps["setValues"];
}

interface InterestRateCalculatorService {
  update: () => void;
}

export class InterestRateCalculator implements InterestRateCalculatorService {
  /**
   * Constructor
   * @param options - {@link InterestRateCalculatorOptions}
   */
  constructor(private options: InterestRateCalculatorOptions) {}

  /**
   * Is Interest Rate Field
   * Check if field is one of the required fields to calculate interest rate
   * @returns - true if is an interest rate field
   */
  private isInterestRateField = (): boolean => {
    if (!this.options.changedData.valueKey) return false;

    return [
      "outstandingBalance",
      "repaymentAmount",
      "repaymentPeriodicity",
      "paymentDuration",
    ].includes(this.options.changedData.valueKey);
  };

  /**
   * Has Interest Rate Fields Empty
   * Check if some of the required fields is empty
   * @returns true if an interest rate field is empty
   */
  private hasInterestRateFieldsEmpty = (): boolean => {
    if (!this.options.formData) return true;

    return [
      this.options.formData["outstandingBalance"],
      this.options.formData["repaymentAmount"],
      this.options.formData["repaymentPeriodicity"],
      this.options.formData["paymentDuration"],
    ].some((value) => value === undefined || value === null || value === "");
  };

  /**
   * Update
   * Calculate interest rate based on the outstanding balance,
   * repayment amount, repayment periodicity and payment duration fields
   * @returns the interest rate value and updates the form values
   */
  public update = (): void => {
    // Destructure options object
    const { authToken, formData, setValues } = this.options;

    // Stop if "authToken" or "formData" is not defined or if is not an interest rate field
    if (!authToken || !formData || !this.isInterestRateField()) {
      return;
    }

    // Set empty value if some field is empty
    if (this.hasInterestRateFieldsEmpty()) {
      setValues({
        ...formData,
        interestRate: EMPTY_VALUE,
      });
      return;
    }

    // Calculate interest rate value
    void (async (): Promise<void> => {
      try {
        // Call the API method to calculate the debt rate
        const value = (
          await API.Math(authToken).calculateDebtRate({
            dto: {
              period: Number(formData["paymentDuration"]),
              outstandingBalance: Number(formData["outstandingBalance"]),
              repaymentAmount: -Number(formData["repaymentAmount"]),
              repaymentPeriodicity:
                formData["repaymentPeriodicity"] === Periodicity.Yearly
                  ? Periodicity.Yearly
                  : Periodicity.Monthly,
            },
          })
        ).data.rate;

        // Set new interest rate value
        setValues({
          ...formData,
          interestRate: value.toString(),
        });
      } catch (e) {
        // Set empty value if an error occurred
        setValues({
          ...formData,
          interestRate: EMPTY_VALUE,
        });

        // Catch any errors that occur during the API call and throw message
        throw new Error(`Calculating debt rate: ${API.parseError(e)}`);
      }
    })();
  };
}
