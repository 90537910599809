// React
import { useCallback, useContext, useMemo } from "react";
// Typings
import {
  TableAssumptionsModuleSchema,
  UseTableAssumptionsModule,
} from "@components/tables/assumptions/hooks";
import { InputNodeFormat } from "@typings/input-node-format";
// Context
import { AppOptionsContext } from "@context/app-options";
// Utils
import { isAccountsAssumptions, isGbrAssumptions } from "@utils/type-guards/assumptions";
import { isEqual } from "@utils/is-equal";
// Translations
import { lang } from "@lang/index";

export const useTableSavingsIncomeModule: UseTableAssumptionsModule = ({
  name,
  data,
  firmPreferences,
}) => {
  // Throw error if data is not valid for SavingsIncome module
  if (data && (isAccountsAssumptions(data) || !isGbrAssumptions(data))) {
    throw new Error(`${name} data not valid`);
  }

  // Define firm data as GBR region
  const firmData = isGbrAssumptions(firmPreferences.data) ? firmPreferences.data : undefined;

  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // Define payload for submission to match with dto
  const submitPayload = useCallback<TableAssumptionsModuleSchema["submitPayload"]>(
    (updatedData) => ({
      taxes: {
        savingsIncome: {
          savingsStartRate: Number(updatedData[0].fields[0].value) || 0,
          personalBasicAllowance: Number(updatedData[1].fields[0].value) || 0,
          personalHigherAllowance: Number(updatedData[2].fields[0].value) || 0,
        },
      },
    }),
    []
  );

  // Return table assumptions data object
  return useMemo(
    (): TableAssumptionsModuleSchema => ({
      title: lang("TABLE_TITLE_SAVINGS_INCOME"),
      head: [
        {
          label: lang("TABLE_COLUMN_NAME"),
          align: "left",
        },
        {
          label: lang("TABLE_COLUMN_VALUE_UNIT", currencySymbol),
          align: "right",
        },
      ],
      entries: [
        {
          label: lang("TABLE_LABEL_SAVINGS_RATE_BAND"),
          fields: [
            {
              type: InputNodeFormat.currency,
              value: data?.taxes.savingsIncome.savingsStartRate,
            },
          ],
        },
        {
          label: lang("TABLE_LABEL_PERSONAL_BASIC_ALLOWANCE"),
          fields: [
            {
              type: InputNodeFormat.currency,
              value: data?.taxes.savingsIncome.personalBasicAllowance,
            },
          ],
        },
        {
          label: lang("TABLE_LABEL_PERSONAL_HIGHER_ALLOWANCE"),
          fields: [
            {
              type: InputNodeFormat.currency,
              value: data?.taxes.savingsIncome.personalHigherAllowance,
            },
          ],
        },
      ],
      customise: {
        checked: !!data?.settings.savingsIncome,
        payload: {
          settings: {
            savingsIncome: !firmData?.settings.savingsIncome,
          },
        },
      },
      reset: {
        allowed: !isEqual(data?.taxes.savingsIncome, firmData?.taxes.savingsIncome),
        payload: {
          taxes: {
            savingsIncome: firmData?.taxes.savingsIncome,
          },
        },
      },
      submitPayload,
    }),
    [
      currencySymbol,
      submitPayload,
      data?.settings.savingsIncome,
      data?.taxes.savingsIncome,
      firmData?.settings.savingsIncome,
      firmData?.taxes.savingsIncome,
    ]
  );
};
