// Styles
import "./styles/index.scss";
// React
import React, { useMemo } from "react";
// Router
import { useLocation, Link } from "react-router-dom";
import { getRoute } from "@routes/utils/get-route";
// Translations
import { lang } from "@lang/index";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfTabs } from "@advicefront/ds-tabs";
import { AfTypography } from "@advicefront/ds-typography";
import { DropdownCreatePlan } from "@components/dropdowns/CreatePlan";

// Props
interface PlansListHeaderProps {
  hasPlans: boolean;
}

export const PlansListHeader = ({ hasPlans }: PlansListHeaderProps): React.ReactElement => {
  // Route
  const { pathname } = useLocation();

  // List of all tabs
  const tabs = useMemo(
    () => [
      {
        title: lang("TAB_ACTIVE"),
        route: getRoute("plansList"),
      },
      {
        title: lang("TAB_ARCHIVED"),
        route: getRoute("plansListArchived"),
      },
    ],
    []
  );

  return (
    <div className="sticky-header">
      <AfRow justify="spread" align="center">
        <AfCol size="auto">
          <AfTypography hasMargin={false} type="h1">
            {lang("TABLE_TITLE_PLANS")}
          </AfTypography>
        </AfCol>
        {hasPlans && (
          <AfCol>
            <DropdownCreatePlan />
          </AfCol>
        )}
      </AfRow>
      <AfRow spacing="sm" className="plans-list-header__tabs">
        <AfCol size="auto">
          <AfTabs variation="marker">
            {tabs.map((tab) => (
              <AfTabs.Item
                key={tab.route}
                active={pathname.startsWith(tab.route)}
                component={<Link to={tab.route} />}
              >
                {tab.title}
              </AfTabs.Item>
            ))}
          </AfTabs>
        </AfCol>
      </AfRow>
    </div>
  );
};
