// React
import React, { useMemo, useRef, useState } from "react";
// Router
import { useLocation } from "react-router-dom";
import { useModalRouting } from "@hooks/modal-routing";
// Redux
import { Store, StoreState } from "@store/index";
// Typings
import { FormActions, FormKeys } from "@typings/routing";
// Utils
import { getRoute } from "@routes/utils/get-route";
// Translations
import { lang } from "@lang/index";
// Components
import { AfButton, AfButtonProps } from "@advicefront/ds-button";
import { AfIcon, AfIconProps } from "@advicefront/ds-icon";
import { AfActionList } from "@advicefront/ds-action-list";
import { AfDropdown } from "@advicefront/ds-dropdown";

// Props
interface DropdownPlanOptionsProps {
  buttonSkin: AfButtonProps["skin"];
  currentPlan: NonNullable<StoreState["plan"]["data"]>;
}

interface DropdownPlanOptionsItem {
  label: string;
  className?: string;
  disabled?: boolean;
  iconName: AfIconProps["name"];
  visible: boolean;
  handleClick: () => void;
}

export const DropdownPlanOptions = ({
  buttonSkin,
  currentPlan,
}: DropdownPlanOptionsProps): React.ReactElement => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);

  // State to handle dropdown open state
  const [isOpen, setIsOpen] = useState(false);

  // Dropdown trigger ref
  const triggerRef = useRef(null);

  // Route
  const { pathname } = useLocation();
  const { openModal } = useModalRouting();

  // Check if is active or archived plans list route
  const isPlansListRoute = useMemo(
    () =>
      pathname.startsWith(getRoute("plansList")) ||
      pathname.startsWith(getRoute("plansListArchived")),
    [pathname]
  );

  // List of items ordered
  const planOptionsItems = useMemo(
    (): DropdownPlanOptionsItem[] => [
      // Edit plan name
      {
        iconName: "content-edit-line",
        label: lang("ACTION_EDIT_NAME"),
        visible: !currentPlan.archived,
        handleClick: (): void =>
          openModal({
            action: FormActions.edit,
            type: FormKeys.PlanName,
            id: currentPlan._id,
          }),
      },

      // Duplicate plan
      {
        iconName: "files-folder-add",
        label: lang("ACTION_DUPLICATE"),
        visible: true,
        handleClick: (): void =>
          openModal({
            action: FormActions.duplicate,
            type: FormKeys.PlanName,
            id: currentPlan._id,
          }),
      },

      // Download PDF plan
      {
        iconName: "arrow-import",
        label: lang("ACTION_DOWNLOAD"),
        disabled: plan.downloadLoading,
        visible: true,
        handleClick: (): void => {
          void dispatch(
            Store.plan.exportPlanPdf({
              authToken: auth.authToken,
              planId: currentPlan._id,
            })
          );

          setIsOpen(false);
        },
      },

      // Archive and unarchive
      {
        iconName: "essentional-archive",
        label: currentPlan.archived ? lang("ACTION_UNARCHIVE") : lang("ACTION_ARCHIVE"),
        visible: true,
        handleClick: (): void => {
          if (!auth.clientGroupId) return;

          void dispatch(
            Store.plan.updatePlan({
              authToken: auth.authToken,
              clientGroupId: auth.clientGroupId,
              planId: currentPlan._id,
              archived: currentPlan.archived,
              updatedPlanData: {
                archived: !currentPlan.archived,
              },
              options: {
                isPlansListRoute,
                // Unarchived notification
                ...(currentPlan.archived && {
                  notificationTitle: lang("NOTIFICATION_TITLE_UNARCHIVED"),
                  notificationDescription: lang("NOTIFICATION_DESCRIPTION_UNARCHIVED"),
                }),
                // Archived notification
                ...(!currentPlan.archived && {
                  notificationTitle: lang("NOTIFICATION_TITLE_ARCHIVED"),
                  notificationDescription: lang("NOTIFICATION_DESCRIPTION_ARCHIVED"),
                }),
              },
            })
          );

          setIsOpen(false);
        },
      },

      // Delete plan
      {
        iconName: "basic-trash",
        label: lang("ACTION_DELETE"),
        className: "text-danger",
        visible: !currentPlan.archived,
        handleClick: (): void =>
          openModal({
            action: FormActions.delete,
            type: FormKeys.Plan,
            id: currentPlan._id,
          }),
      },
    ],
    [
      dispatch,
      openModal,
      isPlansListRoute,
      auth,
      currentPlan.archived,
      currentPlan._id,
      plan.downloadLoading,
    ]
  );

  return (
    <>
      <AfButton
        ref={triggerRef}
        skin={buttonSkin}
        icon={<AfIcon name="basic-more" />}
        onClick={(): void => setIsOpen(!isOpen)}
      />
      <AfDropdown
        isOpen={isOpen}
        onClose={(): void => setIsOpen(false)}
        anchorRef={triggerRef}
        position="bottom-end"
      >
        <AfActionList>
          {planOptionsItems
            .filter(({ visible }) => visible)
            .map((item, i) => (
              <AfActionList.Item
                key={`${item.label}-${i}`}
                className={item.className}
                disabled={item.disabled}
                leftNode={<AfIcon size="s" name={item.iconName} />}
                onClick={item.handleClick}
              >
                {item.label}
              </AfActionList.Item>
            ))}
        </AfActionList>
      </AfDropdown>
    </>
  );
};
