/**
 * Table Columns
 * Naming convention: TABLE_COLUMN_ELEMENT
 */
export const tableColumns = {
  // Default
  TABLE_COLUMN_NAME: {
    en: "Name",
  },
  TABLE_COLUMN_VALUE: {
    en: "Value",
  },
  TABLE_COLUMN_VALUE_UNIT: {
    en: "Value ({0})",
  },
  TABLE_COLUMN_TYPE: {
    en: "Type",
  },
  TABLE_COLUMN_OWNER: {
    en: "Owner",
  },

  // Events
  TABLE_COLUMN_OWNER_AGE: {
    en: "Owner Age",
  },
  TABLE_COLUMN_YEAR: {
    en: "Year",
  },

  // Financial Summary
  TABLE_COLUMN_BALANCE: {
    en: "Balance",
  },
  TABLE_COLUMN_AMOUNT: {
    en: "Amount",
  },

  // Household
  TABLE_COLUMN_BIRTH_YEAR: {
    en: "Birth Year",
  },
  TABLE_COLUMN_CURRENT_AGE: {
    en: "Current Age",
  },
  TABLE_COLUMN_RETIREMENT_AGE: {
    en: "Retirement Age",
  },

  // Assumptions
  TABLE_COLUMN_LIMIT_PER_YEAR: {
    en: "Limit per year ({0})",
  },
  TABLE_COLUMN_ADJUST_INFLATION: {
    en: "Adj. w/ Inflation",
  },
  TABLE_COLUMN_ACCOUNT: {
    en: "Account",
  },
  TABLE_COLUMN_ACCOUNT_TYPE: {
    en: "Account Type",
  },
  TABLE_COLUMN_MAX_WITHDRAWAL: {
    en: "Max Withdrawal per year",
  },
  TABLE_COLUMN_MIN_ACCOUNT_BALANCE: {
    en: "Minimum Account Balance",
  },
  TABLE_COLUMN_AVAILABLE_FROM: {
    en: "Available from",
  },
  TABLE_COLUMN_ADJUST_WITHDRAWAL_INFLATION: {
    en: "Adj. Withdrawal w/ Inflation",
  },
  TABLE_COLUMN_ADJUST_BALANCE_INFLATION: {
    en: "Adj. Balance w/ Inflation",
  },
  TABLE_COLUMN_EMPLOYEE: {
    en: "Employee (%)",
  },
  TABLE_COLUMN_EMPLOYER: {
    en: "Employer (%)",
  },
  TABLE_COLUMN_AGE_RANGES: {
    en: "Age Ranges",
  },
  TABLE_COLUMN_AGE_LIMITS: {
    en: "Age Limits (Years)",
  },
  TABLE_COLUMN_CPF_ORDINARY: {
    en: "CPF Ordinary (%)",
  },
  TABLE_COLUMN_CPF_SPECIAL: {
    en: "CPF Special (%)",
  },
  TABLE_COLUMN_CPF_MEDISAVE: {
    en: "CPF Medisave (%)",
  },
};
