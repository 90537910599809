// React
import { useRef, useEffect } from "react";
// Typings
import { Field } from "@advicefront/fe-infra-form-schema";
import { FormRendererValidation } from "@forms/renderer/types";

/**
 * Use Scroll To Error
 * A custom React hook that scrolls to the first form field
 * with an error in the specified "formValidation" object, if any
 * @param fieldKey - key of the form field to scroll to
 * @param formValidation - object containing info about the form validation status
 * @returns ref for the component that should be scrolled to
 */
export const useScrollToError = <T extends HTMLElement = HTMLDivElement>(
  fieldKey: Field["key"],
  formValidation: FormRendererValidation | undefined
): React.MutableRefObject<T | null> | undefined => {
  // Create a reference to the component to be used for scrolling
  const componentRef = useRef<T | null>(null);

  useEffect(() => {
    // Return if form validation was not set
    if (!formValidation) return;

    // Extract properties from form validation
    const { valid, invalidFields } = formValidation;

    // Don't scroll if form is valid or if is not the first invalid field
    if (valid || fieldKey !== invalidFields.at(0) || !componentRef.current) return;

    // Scroll the first form field that has an error
    componentRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, [componentRef, fieldKey, formValidation]);

  // Return the component reference to be used by the calling component
  return componentRef;
};
