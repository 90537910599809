// Styles
import "./styles/index.scss";
// React
import React from "react";
// Component
import { AfTooltip, AfTooltipRenderProps } from "@advicefront/ds-tooltip";
import { AfIcon } from "@advicefront/ds-icon";

// Props
interface LabelFieldProps {
  tooltip?: string;
  children: React.ReactNode;
}

export const LabelField = ({ tooltip, children }: LabelFieldProps): React.ReactElement => (
  <>
    {children}
    {tooltip && (
      <AfTooltip
        position="right-center"
        render={(props: AfTooltipRenderProps<HTMLSpanElement>): React.ReactElement => (
          <span {...props} className="form-label-field__tooltip">
            <AfIcon name="basic-info" size="xs" nativeProps={{ className: "text-light" }} />
          </span>
        )}
      >
        <div className="form-label-field__tooltip-content">{tooltip}</div>
      </AfTooltip>
    )}
  </>
);
