// React
import React from "react";
// Router
import { View } from "@routes/index";
// Layouts
import { PlanDetailsLayout } from "@layouts/plan-details";
// Views
import { InputsFinancialSummary } from "@views/inputs/components/financial-summary";
import { InputsHouseHold } from "@views/inputs/components/HouseHold";

// Define element for the view
const Element = (): React.ReactElement => (
  <PlanDetailsLayout>
    <InputsHouseHold />
    <InputsFinancialSummary />
  </PlanDetailsLayout>
);

// Route view options
export const inputs: View = {
  name: "Inputs",
  options: {
    path: "/plan/:id/inputs",
    element: <Element />,
  },
};
