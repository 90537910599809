// React
import { useContext, useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import {
  TableScrollableModuleEntry,
  TableScrollableModuleSchema,
  UseTableScrollableModule,
} from "@components/tables/scrollable/hooks";
import { ForecastInputGroups } from "@advicefront/plan-client-axios";
// Context
import { AppOptionsContext } from "@context/app-options";
// Utils
import { maskCurrency } from "@utils/format-currency";
// Translations
import { lang } from "@lang/index";

export const useTableCashflowModule: UseTableScrollableModule = () => {
  // Store
  const inputs = Store.useSelector((state) => state.inputs);
  const forecast = Store.useSelector((state) => state.forecast);

  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // Check if all cashflow data is empty ignoring the Tax group
  const isEmpty = useMemo(
    (): boolean =>
      !forecast.data?.cashflow.incomes.length &&
      !forecast.data?.cashflow.expenses.filter(
        ({ baseType }) => baseType !== ForecastInputGroups.Tax
      ).length &&
      !!forecast.data?.cashflow.surplusAndShortfall.every((value) => value === 0),
    [forecast.data?.cashflow]
  );

  // Check if data was already loaded
  const isFetching = useMemo(
    (): boolean => !forecast.data || !inputs.data,
    [forecast.data, inputs.data]
  );

  // Return table scrollable data object
  return useMemo(
    (): TableScrollableModuleSchema => ({
      // Add empty value for first column
      head: ["", ...(forecast.data?.years || [])],
      entries: !isEmpty
        ? [
            // People
            ...[forecast.data?.clients.owner, forecast.data?.clients.partner]
              .filter((client) => !!client)
              .map(
                (client): TableScrollableModuleEntry => ({
                  label: lang("TABLE_LABEL_AGE", client?.name.split(" ")[0]),
                  values: client?.ageRange,
                })
              ),

            // Inflow Detailed
            {
              label: lang("TABLE_LABEL_INFLOW_DETAILED", currencySymbol),
              type: "body-bold",
            },
            ...(forecast.data?.cashflow.incomes || []).map(
              (income): TableScrollableModuleEntry => ({
                label: income?.label,
                values: income.values.map((value) => maskCurrency(Math.abs(value))),
                action: {
                  type: income.baseType,
                  subType: income.type,
                  id: income.id,
                },
              })
            ),
            {
              label: lang("TABLE_LABEL_TOTAL_INFLOW"),
              type: "body-bold",
              values: forecast.data?.cashflow.totalIncomes.map((value) =>
                maskCurrency(Math.abs(value))
              ),
              highlighted: true,
            },

            // Outflow Detailed
            {
              label: lang("TABLE_LABEL_OUTFLOW_DETAILED", currencySymbol),
              type: "body-bold",
            },
            ...(forecast.data?.cashflow.expenses || []).map(
              (expense): TableScrollableModuleEntry => ({
                label: expense?.label,
                values: expense.values.map((value) => maskCurrency(Math.abs(value))),
                // Tax Expense
                ...(expense.baseType === ForecastInputGroups.Tax && {
                  tooltip: lang("TOOLTIP_TAX"),
                }),
                // Others Expenses
                ...(expense.baseType !== ForecastInputGroups.Tax && {
                  action: {
                    type: expense.baseType,
                    subType: expense.type,
                    id: expense.id,
                  },
                }),
              })
            ),
            {
              label: lang("TABLE_LABEL_TOTAL_OUTFLOW"),
              type: "body-bold",
              values: forecast.data?.cashflow.totalExpenses.map((value) =>
                maskCurrency(Math.abs(value))
              ),
              highlighted: true,
            },

            // Surplus / Shortfall
            {
              label: lang("TABLE_LABEL_SURPLUS_SHORTFALL"),
              type: "body-bold",
              values: forecast.data?.cashflow.surplusAndShortfall.map((value) =>
                maskCurrency(value || 0)
              ),
              highlighted: true,
            },
          ]
        : [],
      fetching: isFetching,
      polling: !!forecast.polling,
      empty: {
        title: lang("EMPTY_TITLE_PLAN_INPUTS"),
      },
    }),
    [
      forecast.polling,
      forecast.data?.years,
      forecast.data?.clients,
      forecast.data?.cashflow,
      isEmpty,
      isFetching,
      currencySymbol,
    ]
  );
};
