// Typings
import { ForecastTaxEntryDto } from "@advicefront/plan-client-axios";
// Highcharts
import Highcharts from "highcharts";
// Utils
import { getGroupColor, getGroupName } from "@utils/charts";
import { asTaxGroup } from "@utils/type-guards/tax";

/**
 * Get Taxes Series
 * Retrieves the taxes series data for generating highcharts series options
 * @param taxes - array of forecast tax mapping
 * @returns array of highcharts "series" for the taxes
 */
export const getTaxesSeries = (
  taxes: ForecastTaxEntryDto[] | undefined
): Highcharts.SeriesOptionsType[] | undefined => {
  // Return if there are no taxes
  if (!taxes) return;
  // Return array of taxes in highcharts series structure
  return taxes.map((entry, index) => ({
    type: "column",
    id: getGroupName(asTaxGroup(entry.label)),
    name: getGroupName(asTaxGroup(entry.label)),
    color: getGroupColor(asTaxGroup(entry.label)),
    data: entry.values.map((value) => Math.abs(Number(value))),
    index: taxes.length - index,
    legendIndex: index,
  }));
};
