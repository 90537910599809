// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { ChartColumnModuleSchema, UseChartColumnModule } from "@components/charts/column/hooks";
import { ChartColumnOptions, ChartColumnTabs } from "@typings/charts/column";
import { Colors } from "@typings/colors";
// Utils
import { getClientsRetirement, getInputsSeries } from "@utils/charts";
// Translations
import { lang } from "@lang/index";

// Props
type ChartTabs = ChartColumnTabs.Assets | ChartColumnTabs.Liabilities;

type ChartViews = Record<ChartTabs, NonNullable<ChartColumnModuleSchema["series"]>>;

export const useChartAssetsAndLiabilitiesModule: UseChartColumnModule = ({ tab, detailed }) => {
  // Store
  const forecast = Store.useSelector((state) => state.forecast);

  // Get selected tab to render specific chart data
  // By default it will be "Assets" if no tab is checked on the "tabs" prop
  const selectedTab = useMemo(
    (): ChartTabs =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (tab as ChartTabs) || ChartColumnTabs.Assets,
    [tab]
  );

  // Get clients retirement "xLines" and "series" data
  const clientsRetirement = useMemo(
    () => getClientsRetirement(forecast.data?.clients),
    [forecast.data?.clients]
  );

  // Default view for Assets and Liabilities
  const defaultView = useMemo(
    (): ChartViews => ({
      [ChartColumnTabs.Assets]: [
        // Assets
        {
          type: "column",
          id: lang("CHART_ASSETS"),
          name: lang("CHART_ASSETS"),
          color: Colors.Olenna,
          data: forecast.data?.balance.totalAssetValues.map((value) => Math.abs(value)),
          index: 1,
          zIndex: 1,
          legendIndex: 50,
        },

        // Liabilities
        {
          type: "line",
          id: lang("CHART_LIABILITIES"),
          name: lang("CHART_LIABILITIES"),
          color: Colors.Text,
          data: forecast.data?.balance.totalLiabilityValues.map((value) => Math.abs(value)),
          index: 2,
          zIndex: 2,
          legendIndex: 55,
          lineWidth: 2,
          step: "center",
          marker: {
            enabled: false,
          },
        },
      ],

      [ChartColumnTabs.Liabilities]: [
        // Liabilities
        {
          type: "column",
          id: lang("CHART_LIABILITIES"),
          name: lang("CHART_LIABILITIES"),
          color: Colors.Olenna,
          data: forecast.data?.balance.totalLiabilityValues.map((value) => Math.abs(value)),
          index: 1,
          zIndex: 1,
          legendIndex: 50,
        },

        // Assets
        {
          type: "line",
          id: lang("CHART_ASSETS"),
          name: lang("CHART_ASSETS"),
          color: Colors.Text,
          data: forecast.data?.balance.totalAssetValues.map((value) => Math.abs(value)),
          index: 2,
          zIndex: 2,
          legendIndex: 55,
          lineWidth: 2,
          step: "center",
          marker: {
            enabled: false,
          },
        },
      ],
    }),
    // Add "selectedTab" dependency to force refresh chart when changing tab
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedTab,
      forecast.data?.balance.totalAssetValues,
      forecast.data?.balance.totalLiabilityValues,
    ]
  );

  // Detailed view for Assets and Liabilities
  const detailedView = useMemo(
    (): ChartViews => ({
      [ChartColumnTabs.Assets]: [
        // Inputs
        ...getInputsSeries(forecast?.data?.balance.assetValues),
        // Liabilities
        defaultView.assets[1],
      ],

      [ChartColumnTabs.Liabilities]: [
        // Inputs
        ...getInputsSeries(forecast?.data?.balance.liabilityValues),
        // Assets
        defaultView.liabilities[1],
      ],
    }),
    // Add "detailed" and "selectedTab" dependencies to force refresh
    // chart when changing tab or switching detailed option
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      detailed,
      selectedTab,
      defaultView,
      forecast?.data?.balance.assetValues,
      forecast?.data?.balance.liabilityValues,
    ]
  );

  // Return chart data object
  return useMemo(
    (): ChartColumnModuleSchema => ({
      tabs: [
        {
          label: lang("CHART_ASSETS"),
          value: ChartColumnTabs.Assets,
          checked: true,
        },
        {
          label: lang("CHART_LIABILITIES"),
          value: ChartColumnTabs.Liabilities,
          checked: false,
        },
      ],
      options: [
        {
          label: lang("ACTION_DETAILED"),
          value: ChartColumnOptions.Detailed,
          checked: false,
        },
        {
          label: lang("ACTION_ZOOM"),
          value: ChartColumnOptions.Zoom,
          checked: false,
        },
      ],
      categories: {
        title: lang("CHART_YEARS"),
        values: forecast.data?.years.map((year) => year.toString()),
      },
      subCategories: {
        titles: [
          forecast.data?.clients.owner.name?.split(" ")[0],
          forecast.data?.clients.partner?.name?.split(" ")[0],
        ],
        values: [
          forecast.data?.clients.owner.ageRange?.map((value) => value.toString()),
          forecast.data?.clients.partner?.ageRange?.map((value) => value.toString()),
        ],
      },
      xLines: clientsRetirement?.xLines,
      series: [
        ...(detailed ? detailedView[selectedTab] : defaultView[selectedTab]),
        ...(clientsRetirement?.series || []),
      ],
    }),
    [
      detailed,
      selectedTab,
      defaultView,
      detailedView,
      clientsRetirement,
      forecast.data?.years,
      forecast.data?.clients,
    ]
  );
};
