/**
 * Form Titles
 * Naming convention: FORM_TITLE_ELEMENT
 */
export const formTitles = {
  FORM_TITLE_OWNERS_INFO: {
    en: "Owner's Info",
  },
  FORM_TITLE_CONTRIBUTIONS: {
    en: "Contributions",
  },
  FORM_TITLE_TIMEFRAME: {
    en: "Timeframe",
  },
  FORM_TITLE_EMPLOYMENT_CONTRIBUTIONS: {
    en: "Employment Contributions (Optional)",
  },
  FORM_TITLE_PERSONAL_CONTRIBUTIONS: {
    en: "Personal Contributions (Optional)",
  },
};
