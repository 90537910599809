import {
  createAction,
  PayloadActionCreator,
  ActionReducerMapBuilder,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit";
import { StoreState } from "@store/index";

interface SubmitSuccessState {
  submitSuccess: boolean;
}

type ReturnedData = SubmitSuccessState;

type ActionName = `${string}/set-submit-success-state`;

// Define actions with type support for parameters as RTKs PrepareAction type does not support
type StoreAction<Payload, Args extends Array<unknown>> = (...args: Args) => {
  payload: Payload;
};

type Action<ReturnedData> = StoreAction<
  ReturnedData,
  [
    SubmitSuccessState & {
      dispatch: ThunkDispatch<StoreState, unknown, AnyAction>;
    }
  ]
>;

type ActionCreator<ReturnedData> = PayloadActionCreator<ReturnedData, string, Action<ReturnedData>>;

export const getSubmitSuccessAction = <StateProps>(
  actionName: ActionName
): {
  submitSuccessAction: ActionCreator<ReturnedData>;
  applySubmitSuccessCases: (builder: ActionReducerMapBuilder<StateProps>) => void;
} => {
  const submitSuccessAction = createAction<Action<ReturnedData>, ActionName>(
    actionName,
    ({ dispatch, submitSuccess }) => {
      // reset after
      if (submitSuccess)
        setTimeout(() => dispatch(submitSuccessAction({ dispatch, submitSuccess: false })), 0);

      return {
        payload: {
          submitSuccess,
        },
      };
    }
  );

  const applySubmitSuccessCases = (builder: ActionReducerMapBuilder<StateProps>): void => {
    builder.addCase(submitSuccessAction, (state, action) => ({
      ...state,
      ...action.payload,
    }));
  };

  return { submitSuccessAction, applySubmitSuccessCases };
};
