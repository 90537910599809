// Redux
import { Store, StoreState } from "@store/index";
// Types
import { ClientType, ResponseClientPersonDto } from "@advicefront/plan-client-axios";

/**
 * Selector to get plan owner
 */

export const selectByClientType = Store.createSelector<
  ClientType,
  ResponseClientPersonDto | undefined
>(
  (clientType: ClientType) =>
    (state: StoreState): ResponseClientPersonDto | undefined =>
      state.people.data?.clients.find((i) => i.clientType === clientType)
);
