// React
import { useCallback, useMemo } from "react";
// Typings
import {
  TableAssumptionsModuleEntry,
  TableAssumptionsModuleSchema,
  UseTableAssumptionsModule,
} from "@components/tables/assumptions/hooks";
import { InputNodeFormat } from "@typings/input-node-format";
// Utils
import { isAccountsAssumptions, isSgpAssumptions } from "@utils/type-guards/assumptions";
import { isEqual } from "@utils/is-equal";
// Translations
import { lang } from "@lang/index";

export const useTableCpfThresholdsModule: UseTableAssumptionsModule = ({
  name,
  data,
  firmPreferences,
}) => {
  // Throw error if data is not valid for CpfThresholds module
  if (data && (isAccountsAssumptions(data) || !isSgpAssumptions(data))) {
    throw new Error(`${name} data not valid`);
  }

  // Define firm data as SGP region
  const firmData = isSgpAssumptions(firmPreferences.data) ? firmPreferences.data : undefined;

  // Get age limit to be displayed on preview mode
  const getAgeLimit = useCallback(
    (age: number, index: number): string | undefined => {
      // Return if no data
      if (!data) return;
      // Get contribution thresholds data
      const { contributionThresholds } = data.contributionCaps.cpf;
      // Get age label based on index
      switch (index) {
        case contributionThresholds.length - 1:
          return `${age}+`;
        default:
          return `${age} - ${contributionThresholds[index + 1].age - 1}`;
      }
    },
    [data]
  );

  // Define payload for submission to match with dto
  const submitPayload = useCallback<TableAssumptionsModuleSchema["submitPayload"]>(
    (updatedData) => ({
      contributionCaps: {
        cpf: {
          contributionThresholds: updatedData.map((row) => ({
            age: Number(row.fields[0].value),
            ordinary: Number(row.fields[1].value),
            special: Number(row.fields[2].value),
            mediSave: Number(row.fields[3].value),
          })),
        },
      },
    }),
    []
  );

  // Return table assumptions data object
  return useMemo(
    (): TableAssumptionsModuleSchema => ({
      title: lang("TABLE_TITLE_CPF_CONTRIBUTIONS_THRESHOLDS"),
      head: [
        {
          label: lang("TABLE_COLUMN_AGE_RANGES"),
          align: "left",
        },
        {
          label: lang("TABLE_COLUMN_AGE_LIMITS"),
          align: "right",
        },
        {
          label: lang("TABLE_COLUMN_CPF_ORDINARY"),
          align: "right",
        },
        {
          label: lang("TABLE_COLUMN_CPF_SPECIAL"),
          align: "right",
        },
        {
          label: lang("TABLE_COLUMN_CPF_MEDISAVE"),
          align: "right",
        },
      ],
      entries: (data?.contributionCaps.cpf.contributionThresholds || []).map(
        (values, index): TableAssumptionsModuleEntry => ({
          label: lang("TABLE_LABEL_RANGE", index + 1),
          fields: [
            {
              type: InputNodeFormat.number,
              value: values.age,
              after: lang("TABLE_LABEL_YEARS"),
              preview: getAgeLimit(values.age, index),
            },
            {
              type: InputNodeFormat.percent,
              value: values.ordinary,
            },
            {
              type: InputNodeFormat.percent,
              value: values.special,
            },
            {
              type: InputNodeFormat.percent,
              value: values.mediSave,
            },
          ],
        })
      ),
      customise: {
        checked: !!data?.settings.cpfThresholds,
        payload: {
          settings: {
            cpfThresholds: !firmData?.settings.cpfThresholds,
          },
        },
      },
      reset: {
        allowed: !isEqual(
          data?.contributionCaps.cpf.contributionThresholds,
          firmData?.contributionCaps.cpf.contributionThresholds
        ),
        payload: {
          contributionCaps: {
            cpf: {
              contributionThresholds: firmData?.contributionCaps.cpf.contributionThresholds,
            },
          },
        },
      },
      submitPayload,
    }),
    [
      getAgeLimit,
      submitPayload,
      data?.settings.cpfThresholds,
      data?.contributionCaps.cpf.contributionThresholds,
      firmData?.settings.cpfThresholds,
      firmData?.contributionCaps.cpf.contributionThresholds,
    ]
  );
};
