// Typings
import { TableBasicModules, TableBasicModulesName } from "@typings/tables/basic";
import { FormKeys } from "@typings/routing";
// Modules
import {
  useTableDebtModule,
  useTableEventsModule,
  useTableExpenseModule,
  useTableHouseholdModule,
  useTableIncomeModule,
  useTablePensionModule,
  useTablePropertyAndAssetsModule,
  useTableSavingsAndInvestmentsModule,
} from "@components/tables/basic/hooks/modules";

// Props: Hooks
type UseTableBasic = (name: TableBasicModulesName) => TableBasicModuleSchema;

export type UseTableBasicModule = () => TableBasicModuleSchema;

// Props: Modules
export interface TableBasicModuleAction {
  id: string | undefined;
  type: FormKeys;
  subType?: string;
}

export interface TableBasicModuleEntry {
  values: (string | number | undefined)[];
  action: TableBasicModuleAction;
  disabled?: {
    edit?: boolean;
    delete?: boolean;
  };
}

export interface TableBasicModuleSchema {
  head: Record<string, "left" | "center" | "right">;
  entries: TableBasicModuleEntry[];
  fetching: boolean;
  empty?: {
    title: string;
    description?: string;
  };
}

/**
 * Maps each basic module to its corresponding table hook module
 * Each basic module has its own specific hook to retrieve its table data
 */
const TableBasicMap: Record<TableBasicModulesName, UseTableBasicModule> = {
  [TableBasicModules.Events]: useTableEventsModule,
  [TableBasicModules.Household]: useTableHouseholdModule,
  // Financial Summary
  [TableBasicModules.Income]: useTableIncomeModule,
  [TableBasicModules.Expense]: useTableExpenseModule,
  [TableBasicModules.SavingsAndInvestments]: useTableSavingsAndInvestmentsModule,
  [TableBasicModules.PropertyAndAssets]: useTablePropertyAndAssetsModule,
  [TableBasicModules.Pension]: useTablePensionModule,
  [TableBasicModules.Debt]: useTableDebtModule,
};

/**
 * Returns table data for a specific basic module
 * Determines which module hook to use based on the basic module name
 * The hook will receive the current module name
 * @param name - name of the basic module
 * @returns the table data for the specified basic module
 */
export const useTableBasic: UseTableBasic = (name) => TableBasicMap[name]();
