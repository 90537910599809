// Store
import { Store, StoreState } from "@store/index";
// Typings
import { ForecastTaxEntryDto, ResponseForecastDtoTax } from "@advicefront/plan-client-axios";

// Props
// TODO: Implement joint tax when we have a use case for it
type TaxClient = keyof Omit<ResponseForecastDtoTax, "joint">;

interface TaxDetailsData {
  chart: {
    total: ForecastTaxEntryDto | undefined;
    detailed: ForecastTaxEntryDto[];
  };
}

type TaxDetailsOutput = StoreState["forecast"] & {
  [key in TaxClient]: TaxDetailsData | undefined;
} & {
  empty: boolean;
};

export const selectTaxDetails = Store.createSelector(
  () =>
    (state: StoreState): TaxDetailsOutput => {
      // Get forecast data
      const { data } = state.forecast;

      // Group all sub sections tax entries from a client
      const getEntriesGrouped = (client: TaxClient): ForecastTaxEntryDto[] =>
        (data?.tax?.[client] || [])
          .flatMap(({ subSections }) => subSections.map(({ entries }) => entries))
          .flat();

      // Extract client tax chart total where the type is TotalYearTaxLiability
      const getChartTotal = (client: TaxClient): ForecastTaxEntryDto | undefined =>
        getEntriesGrouped(client).find((entry) => entry.label === data?.taxMapping?.total?.type);

      // Extract client tax chart detailed values from all sections types
      const getChartDetailed = (client: TaxClient): ForecastTaxEntryDto[] => {
        const sectionsTypes = data?.taxMapping?.sections.map((section) => section.type);
        // Returns only sections that have filled values
        return getEntriesGrouped(client).filter(
          (entry) =>
            sectionsTypes?.includes(entry.label) && !entry.values.every((value) => value === "0")
        );
      };

      // Check if data is empty from both owner and partner
      const isEmpty = (): boolean => {
        const owner = getChartTotal("owner");
        const partner = getChartTotal("partner");

        return (
          (!owner || owner?.values.every((value) => value === "0")) &&
          (!partner || partner?.values.every((value) => value === "0"))
        );
      };

      // Extract all client tax data
      const getData = (client: TaxClient): TaxDetailsData => ({
        chart: {
          total: getChartTotal(client),
          detailed: getChartDetailed(client),
        },
      });

      return {
        ...state.forecast,
        // TODO: Implement joint tax when we have a use case for it
        owner: data && getData("owner"),
        partner: data && getData("partner"),
        empty: !!(data && isEmpty()),
      };
    }
);
