// React
import React from "react";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfSkeleton, AfSkeletonProps } from "@advicefront/ds-skeleton";
import { AfCard } from "@advicefront/ds-card";

// Props
interface LoaderSkeletonTableProps {
  rows?: number;
  columns?: number;
  size?: Exclude<AfSkeletonProps["size"], "xs" | "s">;
}

export const LoaderSkeletonTable = ({
  rows = 1,
  columns = 1,
  size = "l",
}: LoaderSkeletonTableProps): React.ReactElement => (
  <AfCard>
    <AfCard.Content>
      {Array.from(Array(rows).keys()).map((row) => (
        <AfRow key={`loader-skeleton-table-row-${row}`} spacing="lg">
          {Array.from(Array(columns).keys()).map((col) => (
            <AfCol key={`loader-skeleton-table-col-${col}`} size={col === 0 ? 6 : "auto"}>
              <AfSkeleton shape="line" size={size} />
            </AfCol>
          ))}
        </AfRow>
      ))}
    </AfCard.Content>
  </AfCard>
);
