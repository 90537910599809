// Typings
import {
  Configuration,
  ConfigurationParameters,
  PlansApi,
  ForecastApi,
  InputsApi,
  PeopleApi,
  CalculatorsApi,
  AssumptionsApi,
  MathApi,
  RenderApi,
  FormsApi,
} from "@advicefront/plan-client-axios";
// Translations
import { lang } from "@lang/index";

// Props
export interface APIConfig {
  plans: ConfigurationParameters;
  authToken?: string;
}

// API
export class API {
  static instance?: API;

  static plansApi?: PlansApi;
  static peopleApi?: PeopleApi;
  static inputsApi?: InputsApi;
  static forecastApi?: ForecastApi;
  static calculatorsApi?: CalculatorsApi;
  static assumptionsApi?: AssumptionsApi;
  static mathApi?: MathApi;
  static renderApi?: RenderApi;
  static formsApi?: FormsApi;

  static config: APIConfig;

  static configure = (config: Partial<APIConfig>): void => {
    API.config = { ...API.config, ...config };

    const apiConfig = new Configuration({
      ...API.config.plans,
      baseOptions: {
        headers: {
          ...(API.config.authToken && {
            Authorization: `Bearer ${API.config.authToken}`,
          }),
        },
      },
    });

    API.plansApi = new PlansApi(apiConfig);
    API.peopleApi = new PeopleApi(apiConfig);
    API.inputsApi = new InputsApi(apiConfig);
    API.forecastApi = new ForecastApi(apiConfig);
    API.calculatorsApi = new CalculatorsApi(apiConfig);
    API.assumptionsApi = new AssumptionsApi(apiConfig);
    API.mathApi = new MathApi(apiConfig);
    API.renderApi = new RenderApi(apiConfig);
    API.formsApi = new FormsApi(apiConfig);
  };

  static setAuthToken = (authToken: APIConfig["authToken"]): void => {
    API.configure({
      authToken,
    });
  };

  static get Plans(): (authToken: APIConfig["authToken"]) => PlansApi {
    return (authToken): PlansApi => {
      if (!API.plansApi) throw new Error(lang("ERROR_API_CONFIG"));
      API.setAuthToken(authToken);
      return API.plansApi;
    };
  }

  static get People(): (authToken: APIConfig["authToken"]) => PeopleApi {
    return (authToken): PeopleApi => {
      if (!API.peopleApi) throw new Error(lang("ERROR_API_CONFIG"));
      API.setAuthToken(authToken);
      return API.peopleApi;
    };
  }

  static get Inputs(): (authToken: APIConfig["authToken"]) => InputsApi {
    return (authToken): InputsApi => {
      if (!API.inputsApi) throw new Error(lang("ERROR_API_CONFIG"));
      API.setAuthToken(authToken);
      return API.inputsApi;
    };
  }

  static get Forecast(): (authToken: APIConfig["authToken"]) => ForecastApi {
    return (authToken): ForecastApi => {
      if (!API.forecastApi) throw new Error(lang("ERROR_API_CONFIG"));
      API.setAuthToken(authToken);
      return API.forecastApi;
    };
  }

  static get Calculators(): (authToken: APIConfig["authToken"]) => CalculatorsApi {
    return (authToken): CalculatorsApi => {
      if (!API.calculatorsApi) throw new Error(lang("ERROR_API_CONFIG"));
      API.setAuthToken(authToken);
      return API.calculatorsApi;
    };
  }

  static get Assumptions(): (authToken: APIConfig["authToken"]) => AssumptionsApi {
    return (authToken): AssumptionsApi => {
      if (!API.assumptionsApi) throw new Error(lang("ERROR_API_CONFIG"));
      API.setAuthToken(authToken);
      return API.assumptionsApi;
    };
  }

  static get Math(): (authToken: APIConfig["authToken"]) => MathApi {
    return (authToken): MathApi => {
      if (!API.mathApi) throw new Error(lang("ERROR_API_CONFIG"));
      API.setAuthToken(authToken);
      return API.mathApi;
    };
  }

  static get Render(): (authToken: APIConfig["authToken"]) => RenderApi {
    return (authToken): RenderApi => {
      if (!API.renderApi) throw new Error(lang("ERROR_API_CONFIG"));
      API.setAuthToken(authToken);
      return API.renderApi;
    };
  }

  static get Forms(): (authToken: APIConfig["authToken"]) => FormsApi {
    return (authToken): FormsApi => {
      if (!API.formsApi) throw new Error(lang("ERROR_API_CONFIG"));
      API.setAuthToken(authToken);
      return API.formsApi;
    };
  }

  /**
   * Parse Error
   * Function to parses an error object and returns
   * the corresponding error message
   * @param err - error object to parse
   * @returns the parsed error message
   */
  static parseError(err: unknown): string {
    // Initial message text
    let messageText = "";

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const error = err as import("axios").AxiosError<{
      message?: string;
      details?: string | string[];
    }>;

    // Extract details from the error response
    const details = error.response?.data.details;

    // Get the error message from the response data, if available
    messageText += error.response?.data.message || "";

    // Add details to the messageText if they exist
    messageText += details?.length
      ? [" - ", ...(details instanceof Array ? details : [details])].join("\n")
      : "";

    // Return the final error message or a default message if it is empty
    return messageText.length ? messageText : lang("ERROR_API_REQUEST");
  }
}
