// React
import React from "react";
// Typings
import { ClientGroupInfo } from "@typings/client-group-info";
import { ClientNamingMode } from "@typings/client-naming-mode";
import { Regions } from "@advicefront/plan-client-axios";
import { SessionGateProps } from "@advicefront/fe-infra-auth";

export interface AppOptionsContextProps {
  planApiUrl?: string;
  planRouterBaseName?: string;
  planInitialRoute?: string;
  currencySymbol: string;
  clientNamingMode: ClientNamingMode;
  clientRegion: Regions;
  getAuthToken: SessionGateProps["getAuthToken"];
  getClientGroupId: () => Promise<string | null>;
  getClientGroupInfo: () => Promise<ClientGroupInfo[] | null>;
}

export const AppOptionsContext = React.createContext<AppOptionsContextProps>({
  planApiUrl: undefined,
  planRouterBaseName: undefined,
  planInitialRoute: undefined,
  currencySymbol: "",
  clientNamingMode: ClientNamingMode.firstLast,
  clientRegion: Regions.Gbr,
  getAuthToken: () => Promise.resolve(undefined),
  getClientGroupId: () => Promise.resolve(null),
  getClientGroupInfo: () => Promise.resolve(null),
});
