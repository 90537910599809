// React
import React from "react";
// Router
import { View } from "@routes/index";
// Layouts
import { AssumptionsLayout } from "@layouts/assumptions";
// Views
import { AssumptionsGeneralTable } from "@views/assumptions/general/components/Table";

// Define element for the view
const Element = (): React.ReactElement => (
  <AssumptionsLayout>
    <AssumptionsGeneralTable />
  </AssumptionsLayout>
);

// Route view options
export const assumptionsGeneral: View = {
  name: "Assumptions General",
  options: {
    path: "/plan/:id/assumptions/general",
    element: <Element />,
  },
};
