// Typings
import {
  UpdateIllnessCalculatorDto,
  UpdateProtectionCalculatorDto,
  UpdateTDPCalculatorDto,
} from "@advicefront/plan-client-axios";

// Represents the different types of data formats that can be used to
// update the protections calculators modules in a client's plan
export type TableProtectionsModulesPayload =
  | UpdateIllnessCalculatorDto
  | UpdateProtectionCalculatorDto
  | UpdateTDPCalculatorDto;

// Enum defining all supported protections calculators modules
// Each module is represented by a unique key and a corresponding string value
export enum TableProtectionsModules {
  Illness = "Illness",
  Protection = "Protection",
  Tpd = "Tpd",
}

// Type alias for the keys of the TableProtectionsModules enum
// This is used to ensure type safety when working with protections calculators in the codebase
export type TableProtectionsModulesName = keyof typeof TableProtectionsModules;
