// React
import React, { useCallback, useMemo, useState } from "react";
// Router
import { useBeforeUnload, useNavigate, useLocation } from "react-router-dom";
import { useModalRouting } from "@hooks/modal-routing";
// Typings
import { FormActions, FormKeys, LocationStateEntries } from "@typings/routing";
import { PlanCreationModes } from "@typings/plan-creation-modes";
// Forms
import { FormModules } from "@forms/modules";
// Utils
import * as CC from "change-case";
import { asFormKey, asSubtype } from "@routes/utils/modal-routing-guards";
import { getInputLabel } from "@utils/get-input-label";
// Translations
import { lang } from "@lang/index";
// Components
import { AfButton } from "@advicefront/ds-button";
import { AfIcon } from "@advicefront/ds-icon";
import { AfModal } from "@advicefront/ds-modal";
import { DialogCloseForm } from "@components/dialogs/CloseForm";
import { DialogGoBack } from "@components/dialogs/GoBack";

// Props
interface ModalFormConfirmDialogs {
  goBack: boolean;
  closeForm: boolean;
}

export const ModalForm = (): React.ReactElement => {
  // Form id for button is fetched from form component as its generated there
  const [formId, setFormId] = useState<string | undefined>(undefined);
  // State to handle if form has changed
  const [formChanged, setFormChanged] = useState<boolean>(false);
  // State to handle confirm dialogs render for go back and close form
  const [confirmDialog, showConfirmDialog] = useState<ModalFormConfirmDialogs>({
    goBack: false,
    closeForm: false,
  });

  // Route
  const navigate = useNavigate();
  const location = useLocation();
  const { action, type, subType, id, fromFormSelection, closeModal, openModal } = useModalRouting();

  // Throw error if form type is not defined
  if (!type) throw new Error("Form type is not defined");

  // Get current form module component
  const FormModuleComponent = useMemo(() => FormModules[asFormKey(type)].Component, [type]);

  // Get input name label
  const inputLabel = useMemo(() => getInputLabel(type), [type]);

  // Set modal title based on each subtype
  const modalTitle = useMemo(() => {
    switch (subType) {
      // Blank
      case PlanCreationModes.Blank:
        return lang("ACTION_CREATE_BLANK_PLAN");
      // Import
      case PlanCreationModes.Import:
        return lang("ACTION_CREATE_IMPORT_PLAN");
      // Duplicate
      case PlanCreationModes.Duplicate:
        return lang("ACTION_CREATE_DUPLICATE_PLAN");
      // People and Inputs
      default:
        return CC.capitalCase(`${action} ${inputLabel}}`);
    }
  }, [action, inputLabel, subType]);

  // Helper to know if the modal was opened from inside the app
  // and if it display the  previous button
  const openedFromInsideApp = useMemo(() => {
    if (!location.state) return false;
    return Object.keys(location.state).map((key) => {
      if (key === LocationStateEntries.fromApp) return true;
    });
  }, [location]);

  // Handle delete
  const handleDelete = useCallback(
    () =>
      openModal({
        action: FormActions.delete,
        type,
        id,
        referral: FormActions.edit,
      }),
    [id, openModal, type]
  );

  // Handle go back
  // Used for previous button and dialog actions
  // Close confirm dialog if action was handled from the dialog
  const handleGoBack = useCallback(
    (canGoBack: boolean, fromDialog = true): void => {
      showConfirmDialog(
        (prevState): ModalFormConfirmDialogs => ({
          ...prevState,
          goBack: fromDialog ? false : !canGoBack,
        })
      );

      if (canGoBack) {
        navigate(-1);
      }
    },
    [navigate]
  );

  // Handle close form
  // Used for close button and dialog actions
  // Close confirm dialog if action was handled from the dialog
  const handleCloseForm = useCallback(
    (canCloseForm: boolean, fromDialog = true): void => {
      showConfirmDialog(
        (prevState): ModalFormConfirmDialogs => ({
          ...prevState,
          closeForm: fromDialog ? false : !canCloseForm,
        })
      );

      if (canCloseForm) {
        closeModal();
      }
    },
    [closeModal]
  );

  // Before Unload
  // Check if form was changed and not saved before reloading the page
  useBeforeUnload((ev) => {
    // Return if form was not changed
    if (!formChanged) return;
    // Display browser prompt
    ev.preventDefault();
    ev.returnValue = "";
  });

  return (
    <>
      <AfModal
        closeOnClickOutside={false}
        onClose={(): void => handleCloseForm(!formChanged, false)}
      >
        <AfModal.Header heading={modalTitle} />
        <AfModal.Content
          scrollableContainerProps={{
            className: "form-modal",
            variation: ["vertical"],
          }}
        >
          <FormModuleComponent
            formKey={type}
            formDto={subType ? asSubtype(subType) : undefined}
            itemId={id || undefined}
            setFormId={setFormId}
            setFormChanged={setFormChanged}
            onSubmitSuccess={closeModal}
          />
        </AfModal.Content>
        <AfModal.Footer
          actions={[
            <AfButton key="cancel" skin="ghost" onClick={closeModal}>
              {lang("ACTION_CANCEL")}
            </AfButton>,
            <AfButton
              key="confirm"
              skin="primary"
              type="submit"
              form={formId}
              disabled={!formChanged}
            >
              {lang("ACTION_CONFIRM")}
            </AfButton>,
          ]}
        >
          {action === FormActions.create && openedFromInsideApp && fromFormSelection && (
            <AfButton
              skin="ghost"
              icon={<AfIcon name="basic-arrow-tail-left" />}
              onClick={(): void => handleGoBack(!formChanged, false)}
            >
              {lang("ACTION_PREVIOUS")}
            </AfButton>
          )}
          {action === FormActions.edit && type !== FormKeys.Owner && (
            <AfButton
              skin="ghost"
              icon={<AfIcon name="basic-trash" />}
              className="text-danger"
              onClick={handleDelete}
            >
              {lang("ACTION_DELETE")}
            </AfButton>
          )}
        </AfModal.Footer>
      </AfModal>

      {confirmDialog.goBack && (
        <DialogGoBack
          title={inputLabel}
          handleCancel={(): void => handleGoBack(false)}
          handleConfirm={(): void => handleGoBack(true)}
        />
      )}

      {confirmDialog.closeForm && (
        <DialogCloseForm
          title={inputLabel}
          handleCancel={(): void => handleCloseForm(false)}
          handleConfirm={(): void => handleCloseForm(true)}
        />
      )}
    </>
  );
};
