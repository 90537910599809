// React
import React, { ReactElement } from "react";
// Router
import { View } from "@routes/index";
// Layouts
import { PlanDetailsLayout } from "@layouts/plan-details";
// Views
import { AssetsAndLiabilitiesChart } from "@views/assets-liabilities/components/Chart";
import { AssetsAndLiabilitiesTable } from "@views/assets-liabilities/components/Table";

// Define element for the view
const Element = (): ReactElement => (
  <PlanDetailsLayout>
    <AssetsAndLiabilitiesChart />
    <AssetsAndLiabilitiesTable />
  </PlanDetailsLayout>
);

// Route view options
export const assetsAndLiabilities: View = {
  name: "Assets & Liabilities",
  options: {
    path: "/plan/:id/assets-&-liabilities",
    element: <Element />,
  },
};
