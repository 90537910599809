/**
 * Modals
 * Naming convention: MODAL_TITLE_ELEMENT, MODAL_LABEL_ELEMENT or MODAL_DESCRIPTION_ELEMENT
 */
export const modals = {
  // Import Errors
  MODAL_TITLE_IMPORT_ERRORS: {
    en: "Error Report",
  },
  MODAL_DESCRIPTION_IMPORT_ERRORS: {
    en: "The below inputs we're not able to be imported into this plan due to errors.",
  },
  MODAL_DESCRIPTION_IMPORT_ERRORS_ACTIONS: {
    en: "Recommended Actions:",
  },
  MODAL_DESCRIPTION_IMPORT_ERRORS_FIRST_ACTION: {
    en: "Add the missing inputs manually in this plan.",
  },
  MODAL_DESCRIPTION_IMPORT_ERRORS_SECOND_ACTION: {
    en: "Correct the data in the Fact-find and re-import into a new Plan.",
  },
  MODAL_LABEL_IMPORT_ERRORS_UNTITLED: {
    en: "Untitled",
  },
};
