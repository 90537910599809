// Form Translations
import { formDescriptions } from "./descriptions";
import { formLabels } from "./labels";
import { formPlaceholders } from "./placeholders";
import { formTitles } from "./titles";
import { formTooltips } from "./tooltips";
import { formValidators } from "./validators";

// Export all form translations
export default {
  ...formTitles,
  ...formLabels,
  ...formPlaceholders,
  ...formDescriptions,
  ...formTooltips,
  ...formValidators,
};
