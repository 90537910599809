// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Components
import { AfButton } from "@advicefront/ds-button";
import { AfDialog } from "@advicefront/ds-dialog";

// Props
interface DialogGoBackProps {
  title: string;
  handleCancel: () => void;
  handleConfirm: () => void;
}

export const DialogGoBack = ({
  title,
  handleCancel,
  handleConfirm,
}: DialogGoBackProps): React.ReactElement => (
  <AfDialog
    isOpen
    skin="warning"
    title={lang("DIALOG_TITLE_GO_BACK", title)}
    secondaryAction={
      <AfButton type="button" skin="ghost" onClick={handleCancel}>
        {lang("ACTION_CANCEL_GO_BACK")}
      </AfButton>
    }
    primaryAction={
      <AfButton type="button" skin="danger" onClick={handleConfirm}>
        {lang("ACTION_CONFIRM_GO_BACK")}
      </AfButton>
    }
  >
    <div className="align-center">
      {lang("DIALOG_DESCRIPTION_GO_BACK")}
      <br />
      {lang("DIALOG_DESCRIPTION_CHANGES_LOST")}
    </div>
  </AfDialog>
);
