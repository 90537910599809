// Typings
import { FormTypes } from "@advicefront/plan-client-axios";
// Translations
import { lang } from "@lang/index";

// Props
type InputFormTypes = Exclude<
  FormTypes,
  FormTypes.EventInput | FormTypes.SgpExpenseInput | FormTypes.GbrExpenseInput
>;

// Define all form types input names
const inputNames: Record<InputFormTypes, string> = {
  // Income
  [FormTypes.GbrEmploymentIncomeInput]: lang("INPUT_INCOME_EMPLOYMENT"),
  [FormTypes.GbrSelfEmploymentIncomeInput]: lang("INPUT_INCOME_SELF_EMPLOYMENT"),
  [FormTypes.GbrAnnuitiesIncomeInput]: lang("INPUT_INCOME_ANNUITIES"),
  [FormTypes.GbrFinalSalarySchemeIncomeInput]: lang("INPUT_INCOME_FINAL_SALARY_SCHEME"),
  [FormTypes.GbrPropertyIncomeInput]: lang("INPUT_INCOME_PROPERTY"),
  [FormTypes.GbrOtherIncomeInput]: lang("INPUT_INCOME_OTHER"),
  [FormTypes.GbrStatePensionIncomeInput]: lang("INPUT_INCOME_STATE_PENSION"),
  [FormTypes.SgpIncomeInput]: lang("INPUT_INCOME_OTHER"),
  [FormTypes.SgpEmploymentIncomeInput]: lang("INPUT_INCOME_EMPLOYMENT"),

  // Pensions
  [FormTypes.SgpCpfOrdinaryPensionInput]: lang("INPUT_PENSION_CPF_ORDINARY"),
  [FormTypes.SgpCpfSpecialPensionInput]: lang("INPUT_PENSION_CPF_SPECIAL"),
  [FormTypes.SgpCpfMedisavePensionInput]: lang("INPUT_PENSION_CPF_MEDISAVE"),
  [FormTypes.SgpSrsPensionInput]: lang("INPUT_PENSION_SRS"),
  [FormTypes.SgpRetirementAccountPensionInput]: lang("INPUT_PENSION_RETIREMENT"),
  [FormTypes.SgpDependentSchemePensionInput]: lang("INPUT_PENSION_DEPENDENT"),
  [FormTypes.GbrEmployerPensionInput]: lang("INPUT_PENSION_EMPLOYER"),
  [FormTypes.GbrPersonalPensionInput]: lang("INPUT_PENSION_PERSONAL"),

  // Accounts
  [FormTypes.SgpBondsAccountInput]: lang("INPUT_ACCOUNT_BONDS"),
  [FormTypes.SgpCisAccountInput]: lang("INPUT_ACCOUNT_CIS_ACCOUNT"),
  [FormTypes.SgpCurrentAccountInput]: lang("INPUT_ACCOUNT_CURRENT_ACCOUNT"),
  [FormTypes.SgpOtherAccountInput]: lang("INPUT_ACCOUNT_OTHER"),
  [FormTypes.SgpSavingsAccountInput]: lang("INPUT_ACCOUNT_SAVINGS_ACCOUNT"),
  [FormTypes.SgpSharesAccountInput]: lang("INPUT_ACCOUNT_SHARES"),
  [FormTypes.SgpTermDepositAccountInput]: lang("INPUT_ACCOUNT_TERM_DEPOSIT"),
  [FormTypes.SgpUnitTrustsAccountInput]: lang("INPUT_ACCOUNT_UNIT_TRUSTS"),
  [FormTypes.GbrCurrentAccountInput]: lang("INPUT_ACCOUNT_CURRENT_ACCOUNT"),
  [FormTypes.GbrGiaAccountInput]: lang("INPUT_ACCOUNT_GIA"),
  [FormTypes.GbrJisaAccountInput]: lang("INPUT_ACCOUNT_JISA"),
  [FormTypes.GbrSavingsAccountInput]: lang("INPUT_ACCOUNT_SAVINGS_ACCOUNT"),
  [FormTypes.GbrCashIsaAccountInput]: lang("INPUT_ACCOUNT_CASH_ISA"),
  [FormTypes.GbrStockSharesIsaAccountInput]: lang("INPUT_ACCOUNT_STOCK_SHARES_ISA"),
  [FormTypes.GbrLifetimeIsaAccountInput]: lang("INPUT_ACCOUNT_LIFETIME_ISA"),

  // Assets
  [FormTypes.SgpMainResidenceAssetInput]: lang("INPUT_ASSETS_MAIN_RESIDENCE"),
  [FormTypes.SgpOtherAssetInput]: lang("INPUT_ASSETS_OTHER"),
  [FormTypes.SgpPropertyAssetInput]: lang("INPUT_ASSETS_PROPERTY"),
  [FormTypes.GbrMainResidenceAssetInput]: lang("INPUT_ASSETS_MAIN_RESIDENCE"),
  [FormTypes.GbrOtherAssetInput]: lang("INPUT_ASSETS_OTHER"),
  [FormTypes.GbrPropertyAssetInput]: lang("INPUT_ASSETS_PROPERTY"),

  // Debts
  [FormTypes.SgpBankLoanDebtInput]: lang("INPUT_DEBT_BANK_LOAN"),
  [FormTypes.SgpBankOverdraftDebtInput]: lang("INPUT_DEBT_BANK_OVERDRAFT"),
  [FormTypes.SgpCarLoanDebtInput]: lang("INPUT_DEBT_CAR_LOAN"),
  [FormTypes.SgpCreditCardDebtInput]: lang("INPUT_DEBT_CREDIT_CARD"),
  [FormTypes.SgpLoanDebtInput]: lang("INPUT_DEBT_LOAN"),
  [FormTypes.SgpMortgageDebtInput]: lang("INPUT_DEBT_MORTGAGE"),
  [FormTypes.SgpOtherDebtInput]: lang("INPUT_DEBT_OTHER"),
  [FormTypes.GbrLoanDebtInput]: lang("INPUT_DEBT_LOAN"),
  [FormTypes.GbrMortgageDebtInput]: lang("INPUT_DEBT_MORTGAGE"),
};

/**
 * Get Input Name
 * Method to get input name based on string value
 * @param value - value to check
 * @returns input name translation or undefined
 */
export const getInputName = (value: string): string | undefined =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  inputNames[value as InputFormTypes];
