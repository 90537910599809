// React
import React, { useCallback } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { TableProtectionsModules } from "@typings/tables/protections";
import { UpdateProtectionCalculatorDto } from "@advicefront/plan-client-axios";
// Translations
import { lang } from "@lang/index";
// Components
import { TableProtections, TableProtectionsProps } from "@components/tables/protections";

export const ProtectionsProtectionTable = (): React.ReactElement => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);

  // Handle submit
  const handleSubmit: TableProtectionsProps["handleSubmit"] = useCallback(
    (payload) => {
      if (!auth.authToken || !plan.data) return;

      // Update calculators protection
      void dispatch(
        Store.calculators.updateProtection({
          authToken: auth.authToken,
          planId: plan.data._id,
          // Type assertion to ensure payload is of type UpdateProtectionCalculatorDto
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          updatedCalculatorData: payload as UpdateProtectionCalculatorDto,
        })
      );
    },
    [dispatch, auth.authToken, plan.data]
  );

  return (
    <TableProtections
      title={lang("TABLE_TITLE_PROTECTION_GAP_CALCULATOR")}
      name={TableProtectionsModules.Protection}
      handleSubmit={handleSubmit}
    />
  );
};
