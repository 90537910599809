// Redux
import { Store, StoreState } from "@store/index";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import { API } from "@services/api";
import { ResponsePlanV2Dto } from "@advicefront/plan-client-axios";
// Initial State
import { initialState } from "./initial-state";
// Types
import { StateProps } from "./types";
// Utils
import { isEqual } from "@utils/is-equal";
import { retry } from "@store/utils/retry";
// Translations
import { lang } from "@lang/index";

/**
 * Local variable for EventSource instance
 * ---------------------------------------------------------------------
 */

// TODO: re-implement when Eventsource is supported by BE / DevOps infrastructure
// let subscribe: EventSource<string> | undefined = undefined;

// Alt solution ---------------------------------
let polling: StateProps["polling"] = initialState.polling;
let pollingTO: ReturnType<typeof setTimeout | typeof clearTimeout> = undefined;

/**
 * Reset to initial data state
 * @example dispatch(Forecast.reset())
 */

export const reset = createAction("forecast/reset", () => {
  killForecastPolling();

  return {
    payload: initialState,
  };
});

/**
 * Get Forecast data
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Forecast.fetch(\{
 *  authToken,
 *  planId,
 * \}));
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  Required<{ authToken: StoreState["auth"]["authToken"]; planId: ResponsePlanV2Dto["_id"] }>,
  { state: StoreState }
>("forecast/fetch", async ({ authToken, planId }, { dispatch }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  await retry({
    fn: async () => {
      const res = await API.Forecast(authToken).findOneForecastV2({ planId });
      data = res.data;
    },
    error: (e) => {
      error = API.parseError(e);
    },
    retries: 12,
    delay: 2000,
  });

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  return {
    data,
    error,
  };
});

/**
 * Poll Forecast data
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Forecast.poll(\{
 *  authToken,
 *  planId,
 * \}));
 */

export const poll = createAsyncThunk<
  Partial<StateProps | void>,
  Required<{ authToken: StoreState["auth"]["authToken"]; planId: ResponsePlanV2Dto["_id"] }>,
  { state: StoreState }
>("forecast/poll", async ({ authToken, planId }, { dispatch, getState }) => {
  const prevState = getState().forecast.data;

  if (pollingTO !== undefined) pollingTO = clearTimeout(pollingTO);

  // enable polling
  polling += 1;

  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  try {
    const res = await API.Forecast(authToken).findOneForecastV2({ planId });
    data = res.data;
  } catch (e) {
    error = API.parseError(e);
  }

  // Stop polling on forecast update -> when prevData is no longer equal to the new response data
  if (!isEqual(prevState, data)) {
    polling -= 1;
    if (pollingTO !== undefined) pollingTO = clearTimeout(pollingTO);
  }

  // Add 2s delay to prevent too many API requests
  pollingTO = setTimeout(() => {
    // Keep requesting new forecasts until timeout is killed or if polling is disabled
    if (polling) {
      polling -= 1;
      void dispatch(poll({ authToken, planId }));
    }
  }, 2000);

  return {
    polling,
    data,
    error,
  };
});

const killForecastPolling = (): void => {
  // TODO: re-implement when Eventsource is supported by BE / DevOps infrastructure
  // if (subscribe) subscribe.close();
  // Alt solution ---------------------------------

  // Disable polling and kill possibly pending timeout
  polling = initialState.polling;
  if (pollingTO !== undefined) pollingTO = clearTimeout(pollingTO);
};
