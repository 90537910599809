// React
import React from "react";
// Router
import { View } from "@routes/index";
// Layouts
import { ProtectionLayout } from "@layouts/protections";
// Views
import { ProtectionsProtectionTable } from "@views/protections/protection/components/Table";

// Define element for the view
const Element = (): React.ReactElement => (
  <ProtectionLayout>
    <ProtectionsProtectionTable />
  </ProtectionLayout>
);

// Route view options
export const protectionProtection: View = {
  name: "Protections Protection",
  options: {
    path: "/plan/:id/protections/protection",
    element: <Element />,
  },
};
