// React
import React from "react";
// Typings
import { FormRendererOptions } from "@forms/renderer/types";
import { SchemaRenderer } from "@advicefront/fe-infra-form-schema-renderer";
// Utils
import { getColumnProps } from "@forms/utils/get-column-props";
import { useComponentFromInputType } from "@forms/utils/input-types-map";
// Hooks
import { useScrollToError } from "@forms/hooks";
// Components
import { AfCheckbox, AfCheckboxGroup } from "@advicefront/ds-checkbox";
import { AfCol } from "@advicefront/ds-grid";

// Props
type SelectionFieldMultipleProps = Parameters<SchemaRenderer["SelectionFieldMultiple"]>[0] & {
  options: FormRendererOptions;
};

export const SelectionFieldMultiple = ({
  field,
  setValue,
  options,
}: SelectionFieldMultipleProps): React.ReactElement => {
  // Create component reference to be able to scroll to a field with an error
  const componentRef = useScrollToError(field.key, options.formValidation);

  // Define on change handler where it will update the form values state accordingly
  const onChange: React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
    setValue(
      Array.from(ev.currentTarget.options)
        .filter((option) => option.selected)
        .map((option) => option.value)
    );
  };

  // Get input props column set on the input renderer hook of each module
  const { size, offsetBefore, offsetAfter } = getColumnProps(
    options.inputRenderer.inputProps?.column,
    options.formDto,
    field.key
  );

  // Define component to be render according the field key and input types
  const component = useComponentFromInputType(field.key, options.inputRenderer.inputTypes, [
    // Select
    // NOTE: Component not implemented
    {
      name: "select",
      component: (
        <select multiple onChange={onChange}>
          {field.options.map((option, index) => (
            <option key={`select-${option.value}-${index}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ),
    },

    // Checkbox
    // NOTE: Component not implemented
    {
      name: "boolean",
      component: (
        <AfCheckboxGroup direction="horizontal">
          {field.options.map((option, index) => (
            <AfCheckbox key={`checkbox-${option.value}-${index}`} value={option.value}>
              {option.label}
            </AfCheckbox>
          ))}
        </AfCheckboxGroup>
      ),
    },
  ]);

  return (
    <AfCol ref={componentRef} size={size} offsetBefore={offsetBefore} offsetAfter={offsetAfter}>
      {component}
    </AfCol>
  );
};
