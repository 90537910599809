// React
import React, { useEffect } from "react";
// Router
import { useParams } from "react-router-dom";
// Store
import { Store } from "@store/index";
import { resetPlanData } from "@store/utils/reset-plan-data";
// Typings
import { LayoutProps } from "@layouts/types";
// Layouts
import { AuthProtectedLayout } from "@layouts/AuthProtected";
import { DefaultLayout } from "@layouts/Default";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { PlanDetailsHeader } from "@layouts/plan-details/components/header";
import { PlanDetailsImportErrors } from "@layouts/plan-details/components/import-errors";

export const PlanDetailsLayout = ({ children }: LayoutProps): React.ReactElement => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);
  const people = Store.useSelector((state) => state.people);
  const forms = Store.useSelector((state) => state.forms);
  const forecast = Store.useSelector((state) => state.forecast);
  const inputs = Store.useSelector((state) => state.inputs);
  const assumptions = Store.useSelector((state) => state.assumptions);

  // Route
  const { id } = useParams();

  // Reset plan data if a new plan was loaded
  // Eg.: when duplicating from plan detail
  useEffect(() => {
    if (plan.data && plan.data?._id !== id) {
      resetPlanData(dispatch);
    }
  }, [dispatch, id, plan.data]);

  // Fetch current plan
  useEffect(() => {
    if (auth.authToken && id && !plan.data && !plan.loading && !plan.error) {
      void dispatch(
        Store.plan.fetchPlan({
          planId: id,
          authToken: auth.authToken,
        })
      );
    }
  }, [dispatch, auth.authToken, id, plan.data, plan.loading, plan.error]);

  // Fetch people
  useEffect(() => {
    if (auth.authToken && plan.data?._id && !people.data && !people.loading && !people.error) {
      void dispatch(
        Store.people.fetch({
          authToken: auth.authToken,
          planId: plan.data._id,
        })
      );
    }
  }, [dispatch, auth.authToken, plan.data?._id, people.data, people.loading, people.error]);

  // Fetch Forms
  useEffect(() => {
    if (auth.authToken && plan.data?.region && !forms.data && !forms.loading && !forms.error) {
      void dispatch(
        Store.forms.fetch({
          authToken: auth.authToken,
          region: plan.data?.region,
        })
      );
    }
  }, [dispatch, auth.authToken, plan.data?.region, forms.data, forms.loading, forms.error]);

  // Fetch Forecast
  useEffect(() => {
    if (
      auth.authToken &&
      plan.data?._id &&
      !forecast.data &&
      !forecast.loading &&
      !forecast.error
    ) {
      void dispatch(
        Store.forecast.fetch({
          authToken: auth.authToken,
          planId: plan.data._id,
        })
      );
    }
  }, [dispatch, auth.authToken, plan.data?._id, forecast.data, forecast.loading, forecast.error]);

  // Fetch Inputs
  useEffect(() => {
    if (auth.authToken && plan.data?._id && !inputs.data && !inputs.loading && !inputs.error) {
      void dispatch(
        Store.inputs.fetch({
          authToken: auth.authToken,
          planId: plan.data._id,
        })
      );
    }
  }, [dispatch, auth.authToken, plan.data?._id, inputs.data, inputs.loading, inputs.error]);

  // Fetch Assumptions
  useEffect(() => {
    if (
      auth.authToken &&
      plan.data?._id &&
      !assumptions.data &&
      !assumptions.loading &&
      !assumptions.error
    ) {
      void dispatch(
        Store.assumptions.fetch({
          authToken: auth.authToken,
          planId: plan.data._id,
        })
      );
    }
  }, [
    dispatch,
    auth.authToken,
    plan.data?._id,
    assumptions.data,
    assumptions.loading,
    assumptions.error,
  ]);

  return (
    <AuthProtectedLayout>
      {plan.data?.importDetails && !plan.data.importDetails.userDismissed && (
        <PlanDetailsImportErrors />
      )}
      <DefaultLayout>
        <PlanDetailsHeader />
        <AfRow orientation="vertical" spacing="lg">
          {React.Children.map(children, (child, key) => (
            <AfCol key={key}>{child}</AfCol>
          ))}
        </AfRow>
      </DefaultLayout>
    </AuthProtectedLayout>
  );
};
