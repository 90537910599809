// React
import { useMemo } from "react";
// Typings
import { FormModuleInputInstance } from "@forms/modules/types";
import { Formatters } from "@advicefront/fe-infra-form-schema-renderer";
// Utils
import { maskCurrency, unmaskCurrency } from "@utils/format-currency";
import { maskPercentage, unmaskPercentage } from "@utils/format-percentage";
import { getValueKeysFromType } from "@forms/utils/input-types-map";
// Hooks
import { useInputRenderer, useSchema } from "@forms/modules/input/hooks";

export const useFormatters: FormModuleInputInstance["useFormatters"] = (props) => {
  // Get the input types
  const { inputTypes } = useInputRenderer(props);

  // Get the form schema
  const schema = useSchema(props);

  return useMemo((): Formatters => {
    // Return empty array if input types map is not set
    if (!inputTypes) return [];

    // Get all currency fields from schema
    const currencyFieldKeys = getValueKeysFromType("currency", inputTypes, schema?.data);

    // Get all percentage fields from schema
    const percentageFieldKeys = getValueKeysFromType("percentage", inputTypes, schema?.data);

    return [
      // Currency
      {
        valueKey: currencyFieldKeys,
        apply: (inputValue): string => maskCurrency(inputValue),
        revert: (inputValue): string => unmaskCurrency(inputValue),
      },

      // Percentage
      {
        valueKey: percentageFieldKeys,
        apply: (inputValue): string => maskPercentage(inputValue),
        revert: (inputValue): string => unmaskPercentage(inputValue),
      },

      // Interest Rate
      // Applied on Debt (GBR)
      {
        valueKey: "interestRate",
        apply: (inputValue): string =>
          // BE accepts only 2 decimals
          inputValue.length && !isNaN(Number(inputValue))
            ? (Number(inputValue) * 100).toFixed(2).toString() + "%"
            : inputValue,
        revert: (inputValue): string =>
          inputValue.length && !isNaN(Number(inputValue))
            ? (Number(inputValue) / 100).toString().split("")[0]
            : inputValue,
      },
    ];
  }, [inputTypes, schema?.data]);
};
