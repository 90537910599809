/**
 * Tooltips
 * Naming convention: TOOLTIP_ELEMENT
 */
export const tooltips = {
  // Tax
  TOOLTIP_TAX: {
    en: "Tax outflow is calculated automatically",
  },

  // Plan
  TOOLTIP_PLAN_ARCHIVED: {
    en: "This plan cannot be changed because it is archived",
  },

  // Liquidation
  TOOLTIP_LIQUIDATION_ORDER: {
    en: "The Liquidation order manages in which order funds will be withdrawn from Savings & Investments (if available) to cover shortfalls in a given period.",
  },

  // Protections
  TOOLTIP_INFLATION_ADJUSTED: {
    en: "Calculation formula: ((1+rate of return)/(1+living inflation))-1",
  },
};
