// Styles
import "./styles/index.scss";
// React
import React from "react";
// Store
import { Store } from "@store/index";
// Hooks
import { useModalRouting } from "@hooks/modal-routing";
import { useTableBasic } from "@components/tables/basic/hooks";
// Typings
import { FormActions } from "@typings/routing";
import { TableBasicModulesName } from "@typings/tables/basic";
// Translations
import { lang } from "@lang/index";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfIcon, AfIconProps } from "@advicefront/ds-icon";
import { AfTable, AfTableProps } from "@advicefront/ds-table";
import { AfTooltip, AfTooltipRenderProps } from "@advicefront/ds-tooltip";
import { AfButton } from "@advicefront/ds-button";
import { AfCard } from "@advicefront/ds-card";
import { AfEmptyState } from "@advicefront/ds-empty-state";
import { LoaderSkeletonTable } from "@components/loaders/skeleton";

// Props
interface TableBasicProps {
  name: TableBasicModulesName;
  border?: AfTableProps["border"];
}

interface TableBasicActionItem {
  action: FormActions;
  icon: AfIconProps["name"];
}

export const TableBasic = ({
  name,
  border = ["row", "wrapper"],
}: TableBasicProps): React.ReactElement => {
  // Store
  const plan = Store.useSelector((state) => state.plan);

  // Hook to get current table module data
  const { head, entries, fetching, empty } = useTableBasic(name);

  // Route
  const { openModal } = useModalRouting();

  // Define props for actions items (edit and delete)
  const actionItems: TableBasicActionItem[] = [
    {
      action: FormActions.edit,
      icon: "content-edit",
    },
    {
      action: FormActions.delete,
      icon: "basic-trash",
    },
  ];

  return (
    <>
      {fetching && <LoaderSkeletonTable rows={4} columns={5} />}

      {!fetching && !entries.length && empty && (
        <>
          {border.includes("wrapper") ? (
            <AfCard padding="none">
              <AfEmptyState heading={empty.title}>{empty.description}</AfEmptyState>
            </AfCard>
          ) : (
            <AfEmptyState heading={empty.title}>{empty.description}</AfEmptyState>
          )}
        </>
      )}

      {!fetching && !!entries.length && (
        <AfTable border={border} className="table-basic">
          <thead>
            <tr>
              {Object.entries(head).map(([label, align], colIndex) => (
                <th key={`head-col-${colIndex}`} align={align}>
                  {label}
                </th>
              ))}
              <th>{/* Actions column */}</th>
            </tr>
          </thead>

          <tbody>
            {entries.map((entry, rowIndex) => (
              <tr key={`body-row-${rowIndex}`}>
                {entry.values.map((value, colIndex) => (
                  <td
                    key={`body-col-${rowIndex}-${colIndex}`}
                    align={Object.values(head)[colIndex]}
                  >
                    {value}
                  </td>
                ))}

                <td align="right">
                  <AfRow justify="end">
                    {actionItems.map(({ action, icon }, actionIndex) => (
                      <AfCol key={`body-col-${rowIndex}-${actionIndex}`}>
                        <AfTooltip
                          position="top-end"
                          render={(
                            props: AfTooltipRenderProps<HTMLDivElement>
                          ): React.ReactElement => (
                            // Only display tooltip if plan is archived
                            <div
                              {...(plan.data?.archived &&
                                ((action === FormActions.edit && !entry.disabled?.edit) ||
                                  (action === FormActions.delete && !entry.disabled?.delete)) &&
                                props)}
                            >
                              <AfButton
                                type="button"
                                skin="ghost"
                                size="s"
                                disabled={
                                  plan.data?.archived ||
                                  (action === FormActions.edit && entry.disabled?.edit) ||
                                  (action === FormActions.delete && entry.disabled?.delete)
                                }
                                icon={<AfIcon name={icon} />}
                                onClick={(): void =>
                                  openModal({
                                    action,
                                    type: entry.action.type,
                                    subType: entry.action.subType,
                                    id: entry.action.id,
                                  })
                                }
                              />
                            </div>
                          )}
                        >
                          {lang("TOOLTIP_PLAN_ARCHIVED")}
                        </AfTooltip>
                      </AfCol>
                    ))}
                  </AfRow>
                </td>
              </tr>
            ))}
          </tbody>
        </AfTable>
      )}
    </>
  );
};
