// React
import React from "react";
// Router
import { View } from "@routes/index";
// Layouts
import { PlansListLayout } from "@layouts/plans-list";
// Views
import { PlansListTable } from "@views/plans-list/components/Table";

// Define element for the view
const Element = (): React.ReactElement => (
  <PlansListLayout>
    <PlansListTable isArchived />
  </PlansListLayout>
);

// Route view options
export const plansListArchived: View = {
  name: "Plans List Archived",
  options: {
    path: "/archived-plans",
    element: <Element />,
  },
};
