// Styles
import "./styles/index.scss";
// React
import React, { useMemo, useContext } from "react";
// Store
import { Store } from "@store/index";
// Context
import { AppOptionsContext } from "@context/app-options";
// Utils
import { maskCurrency } from "@utils/format-currency";
// Translation
import { lang } from "@lang/index";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfBadge } from "@advicefront/ds-badge";
import { AfCard } from "@advicefront/ds-card";
import { AfIcon } from "@advicefront/ds-icon";
import { AfTypography } from "@advicefront/ds-typography";
import { LoaderOverlaySpinner } from "@components/loaders/overlay-spinner";
import { LoaderSkeletonCard } from "@components/loaders/skeleton";

export const OverviewCoverage = (): React.ReactElement => {
  // Store
  const inputs = Store.useSelector((state) => state.inputs);
  const forecast = Store.useSelector((state) => state.forecast);

  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // Shortfall gets only negative values
  const shortfalls = forecast.data?.cashflow.surplusAndShortfall.filter((amount) => amount < 0);

  // Get shortfall amount and text based on length
  const shortfallInfo = useMemo(
    () => ({
      amount: maskCurrency(Math.round(forecast.data?.cashflow.cashflowSum || 0), currencySymbol),
      description: shortfalls?.length
        ? lang("OVERVIEW_DESCRIPTION_SHORTFALL", shortfalls?.length)
        : lang("OVERVIEW_DESCRIPTION_NO_SHORTFALL"),
    }),
    [currencySymbol, forecast.data?.cashflow.cashflowSum, shortfalls?.length]
  );

  // Define empty state
  const isEmptyState = useMemo(
    () => inputs.data && Object.values(inputs.data).every((input) => !input.length),
    [inputs.data]
  );

  return (
    <>
      {!forecast.data && <LoaderSkeletonCard />}

      {forecast.data && (
        <LoaderOverlaySpinner
          active={!!forecast.polling}
          title={lang("LOADER_TITLE_CALCULATING")}
          description={lang("LOADER_DESCRIPTION_CALCULATING")}
          orientation="horizontal"
          size="m"
        >
          <AfCard>
            <AfCard.Content>
              {isEmptyState && (
                <>
                  <AfTypography hasMargin={false} type="large-semi">
                    {lang("OVERVIEW_TITLE_COVERAGE")}
                  </AfTypography>
                  <AfTypography type="h1" className="overview-coverage__value">
                    {lang("EMPTY_TITLE_COVERAGE_NA")}
                  </AfTypography>
                  <AfTypography hasMargin={false} type="small" skin="text-light">
                    {lang("EMPTY_DESCRIPTION_COVERAGE")}
                  </AfTypography>
                </>
              )}

              {!isEmptyState && (
                <>
                  <AfRow align="center">
                    <AfCol>
                      <AfTypography hasMargin={false} type="large-semi">
                        {lang("OVERVIEW_TITLE_COVERAGE")}
                      </AfTypography>
                    </AfCol>
                    {!!shortfalls?.length && (
                      <AfCol>
                        <AfBadge
                          skin="pale-pink"
                          size="s"
                          icon={<AfIcon name="essentional-danger" />}
                        >
                          {lang("CHART_SHORTFALL")}
                        </AfBadge>
                      </AfCol>
                    )}
                  </AfRow>
                  <AfTypography
                    type="h1"
                    skin={shortfalls?.length ? "danger" : undefined}
                    className="overview-coverage__value"
                  >
                    {shortfallInfo.amount}
                  </AfTypography>
                  <AfTypography hasMargin={false} type="small" skin="text-light">
                    {shortfallInfo.description}
                  </AfTypography>
                </>
              )}
            </AfCard.Content>
          </AfCard>
        </LoaderOverlaySpinner>
      )}
    </>
  );
};
