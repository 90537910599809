// React
import { useCallback, useMemo } from "react";
// Typings
import {
  TableAssumptionsModuleSchema,
  UseTableAssumptionsModule,
} from "@components/tables/assumptions/hooks";
import { InputNodeFormat } from "@typings/input-node-format";
// Utils
import { isAccountsAssumptions, isSgpAssumptions } from "@utils/type-guards/assumptions";
import { isEqual } from "@utils/is-equal";
// Translations
import { lang } from "@lang/index";

export const useTableCpfCapsModule: UseTableAssumptionsModule = ({
  name,
  data,
  firmPreferences,
}) => {
  // Throw error if data is not valid for CpfCaps module
  if (data && (isAccountsAssumptions(data) || !isSgpAssumptions(data))) {
    throw new Error(`${name} data not valid`);
  }

  // Define firm data as SGP region
  const firmData = isSgpAssumptions(firmPreferences.data) ? firmPreferences.data : undefined;

  // Define payload for submission to match with dto
  const submitPayload = useCallback<TableAssumptionsModuleSchema["submitPayload"]>(
    (updatedData) => ({
      contributionCaps: {
        cpf: {
          salary: Number(updatedData[0].fields[0].value) || 0,
          bonus: Number(updatedData[1].fields[0].value) || 0,
        },
      },
      growthRates: {
        cpf: {
          ordinaryGrowth: Number(updatedData[2].fields[0].value),
          specialGrowth: Number(updatedData[3].fields[0].value),
          mediSaveGrowth: Number(updatedData[4].fields[0].value),
          capGrowth: Number(updatedData[5].fields[0].value),
        },
      },
    }),
    []
  );

  // Return table assumptions data object
  return useMemo(
    (): TableAssumptionsModuleSchema => ({
      title: lang("TABLE_TITLE_CPF_CAPS"),
      head: [
        {
          label: lang("TABLE_COLUMN_NAME"),
          align: "left",
        },
        {
          label: lang("TABLE_COLUMN_VALUE"),
          align: "right",
        },
      ],
      entries: [
        {
          label: lang("TABLE_LABEL_CPF_CAP_SALARY"),
          fields: [
            {
              type: InputNodeFormat.currency,
              value: data?.contributionCaps.cpf.salary,
            },
          ],
        },
        {
          label: lang("TABLE_LABEL_CPF_CAP_BONUS"),
          fields: [
            {
              type: InputNodeFormat.currency,
              value: data?.contributionCaps.cpf.bonus,
            },
          ],
        },
        {
          label: lang("TABLE_LABEL_CPF_ORDINARY_GROWTH"),
          fields: [
            {
              type: InputNodeFormat.percent,
              value: data?.growthRates.cpf.ordinaryGrowth,
            },
          ],
        },
        {
          label: lang("TABLE_LABEL_CPF_SPECIAL_GROWTH"),
          fields: [
            {
              type: InputNodeFormat.percent,
              value: data?.growthRates.cpf.specialGrowth,
            },
          ],
        },
        {
          label: lang("TABLE_LABEL_CPF_MEDISAVE_GROWTH"),
          fields: [
            {
              type: InputNodeFormat.percent,
              value: data?.growthRates.cpf.mediSaveGrowth,
            },
          ],
        },
        {
          label: lang("TABLE_LABEL_CPF_CAP_GROWTH"),
          fields: [
            {
              type: InputNodeFormat.percent,
              value: data?.growthRates.cpf.capGrowth,
            },
          ],
        },
      ],
      customise: {
        checked: !!data?.settings.cpfCaps,
        payload: {
          settings: {
            cpfCaps: !firmData?.settings.cpfCaps,
          },
        },
      },
      reset: {
        allowed:
          !isEqual(data?.contributionCaps.cpf.bonus, firmData?.contributionCaps.cpf.bonus) ||
          !isEqual(data?.contributionCaps.cpf.salary, firmData?.contributionCaps.cpf.salary) ||
          !isEqual(data?.growthRates.cpf, firmData?.growthRates.cpf),
        payload: {
          contributionCaps: {
            cpf: {
              salary: firmData?.contributionCaps.cpf.salary,
              bonus: firmData?.contributionCaps.cpf.bonus,
            },
          },
          growthRates: {
            cpf: {
              ordinaryGrowth: firmData?.growthRates.cpf.ordinaryGrowth,
              specialGrowth: firmData?.growthRates.cpf.specialGrowth,
              mediSaveGrowth: firmData?.growthRates.cpf.mediSaveGrowth,
              capGrowth: firmData?.growthRates.cpf.capGrowth,
            },
          },
        },
      },
      submitPayload,
    }),
    [
      submitPayload,
      data?.settings.cpfCaps,
      data?.contributionCaps.cpf.bonus,
      data?.contributionCaps.cpf.salary,
      data?.growthRates.cpf,
      firmData?.settings.cpfCaps,
      firmData?.contributionCaps.cpf.bonus,
      firmData?.contributionCaps.cpf.salary,
      firmData?.growthRates.cpf,
    ]
  );
};
