// Typings
import {
  ChartColumnModules,
  ChartColumnModulesName,
  ChartColumnOptions,
  ChartColumnTabs,
} from "@typings/charts/column";
// Highcharts
import Highcharts from "highcharts";
// Modules
import {
  useChartAssetsAndLiabilitiesModule,
  useChartCashflowModule,
  useChartTaxModule,
} from "@components/charts/column/hooks/modules";

// Props : Hooks
type UseChartColumn = (props: {
  name: ChartColumnModulesName;
  tab: ChartColumnTabs | undefined;
  detailed: boolean;
}) => ChartColumnModuleSchema;

export type UseChartColumnModule = UseChartColumn;

// Props : Modules
interface ChartColumnModuleTab {
  label: string;
  value: ChartColumnTabs;
  checked: boolean;
}

interface ChartColumnModuleOption {
  label: string;
  value: ChartColumnOptions;
  checked: boolean;
}

export interface ChartColumnModuleSchema {
  tabs?: ChartColumnModuleTab[];
  options?: ChartColumnModuleOption[];
  categories?: {
    title?: string;
    values: Highcharts.XAxisOptions["categories"];
  };
  subCategories?: {
    titles?: (string | undefined)[];
    values?: Highcharts.XAxisOptions["categories"][];
  };
  xLines?: Highcharts.XAxisPlotLinesOptions[];
  yLines?: Highcharts.YAxisPlotLinesOptions[];
  series?: Highcharts.SeriesOptionsType[];
}

/**
 * Maps each chart column module to its corresponding chart hook module
 * Each chart column module has its own specific hook to retrieve its chart data
 */
const ChartColumnMap: Record<ChartColumnModulesName, UseChartColumnModule> = {
  [ChartColumnModules.Cashflow]: useChartCashflowModule,
  [ChartColumnModules.AssetsAndLiabilities]: useChartAssetsAndLiabilitiesModule,
  [ChartColumnModules.Tax]: useChartTaxModule,
};

/**
 * Returns chart data for a specific chart column module
 * Determines which module hook to use based on the chart column module name
 * The hook will receive the current chart column module name, selected tab name
 * and boolean to indicate whether details should be displayed
 * @param name - name of the chart column module
 * @param tab - name of tab selected from {@link ChartColumnTabs}
 * @param detailed - boolean indicating whether the details should be displayed
 * @returns the chart data for the specified chart column module
 */
export const useChartColumn: UseChartColumn = ({ name, tab, detailed }) =>
  ChartColumnMap[name]({ name, tab, detailed });
