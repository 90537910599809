// React
import { useContext, useMemo } from "react";
// Router
import { asFormKey } from "@routes/utils/modal-routing-guards";
// Store
import { Store } from "@store/index";
// Typings
import {
  TableBasicModuleEntry,
  TableBasicModuleSchema,
  UseTableBasicModule,
} from "@components/tables/basic/hooks";
// Context
import { AppOptionsContext } from "@context/app-options";
// Utils
import { getClientsNames } from "@utils/get-clients";
import { getInputName } from "@utils/get-input-name";
import { maskCurrency } from "@utils/format-currency";
// Translations
import { lang } from "@lang/index";

export const useTableSavingsAndInvestmentsModule: UseTableBasicModule = () => {
  // Store
  const people = Store.useSelector((state) => state.people);
  const inputs = Store.useSelector((state) => state.inputs);

  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // Return table basic data object
  return useMemo(
    (): TableBasicModuleSchema => ({
      head: {
        [lang("TABLE_COLUMN_NAME")]: "left",
        [lang("TABLE_COLUMN_TYPE")]: "left",
        [lang("TABLE_COLUMN_OWNER")]: "left",
        [lang("TABLE_COLUMN_BALANCE")]: "right",
      },
      entries: [
        ...(inputs.data?.account || []).map(
          (account): TableBasicModuleEntry => ({
            values: [
              account.label,
              getInputName(account.__st),
              getClientsNames(people.data?.clients, account.ownership),
              maskCurrency(account.currentValue, currencySymbol),
            ],
            action: {
              id: account._id,
              type: asFormKey(account.__bt),
              subType: account.__st,
            },
          })
        ),
      ],
      fetching: !inputs.data,
      empty: {
        title: lang("EMPTY_TITLE_FINANCIAL_SUMMARY"),
      },
    }),
    [inputs.data, people.data?.clients, currencySymbol]
  );
};
