// Redux
import { SetupRtkStore, SetupRtk, Modules } from "@advicefront/fe-infra-redux";
// Modules
import * as accounts from "@store/modules/accounts";
import * as assumptions from "@store/modules/assumptions";
import * as auth from "@store/modules/auth";
import * as calculators from "@store/modules/calculators";
import * as firmPreferences from "@store/modules/firm-preferences";
import * as forecast from "@store/modules/forecast";
import * as forms from "@store/modules/forms";
import * as inputs from "@store/modules/inputs";
import * as liquidation from "@store/modules/liquidation";
import * as people from "@store/modules/people";
import * as plan from "@store/modules/plan";
import * as plans from "@store/modules/plans";
import * as preferences from "@store/modules/preferences";

// Notification System Modulex
const notifications = Modules.Notifications;

const modules = {
  accounts,
  assumptions,
  auth,
  calculators,
  firmPreferences,
  forecast,
  forms,
  inputs,
  liquidation,
  notifications,
  people,
  plan,
  plans,
  preferences,
};

/**
 * Export store utils & redux modules
 */
export const Store = {
  ...new SetupRtkStore({
    modules,
  }),
  ...modules,
};

/**
 * Export global store state type
 */
export type StoreState = SetupRtk.AppRootState<typeof modules>;
