// React
import React, { useState, useMemo, useRef } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { FormActions, FormKeys } from "@typings/routing";
import { ClientType } from "@advicefront/plan-client-axios";
// Hooks
import { useModalRouting } from "@hooks/modal-routing";
// Utils
import { asFormKey, asInputGroup, isFormKey } from "@routes/utils/modal-routing-guards";
import { getInputLabel } from "@utils/get-input-label";
// Translations
import { lang } from "@lang/index";
// Components
import { AfIcon, AfIconProps } from "@advicefront/ds-icon";
import { AfTooltip, AfTooltipRenderProps } from "@advicefront/ds-tooltip";
import { AfActionList } from "@advicefront/ds-action-list";
import { AfButton } from "@advicefront/ds-button";
import { AfDropdown } from "@advicefront/ds-dropdown";

// Props
interface PlanDetailsDropdownAddInputsItem {
  icon: AfIconProps["name"];
  label: string;
  action: () => void;
}

// List of inputs icons
const INPUTS_ICONS: Record<string, AfIconProps["name"]> = {
  [FormKeys.IncomeInput]: "money-dollar-circle",
  [FormKeys.ExpenseInput]: "money-tag",
  [FormKeys.AccountInput]: "money-empty-wallet",
  [FormKeys.AssetInput]: "building-house",
  [FormKeys.PensionInput]: "security-user",
  [FormKeys.DebtInput]: "building-buildings",
};

export const PlanDetailsDropdownAddInputs = (): React.ReactElement => {
  // Store
  const plan = Store.useSelector((state) => state.plan);
  const people = Store.useSelector((state) => state.people);
  const forms = Store.useSelector((state) => state.forms);

  // Route
  const { openModal } = useModalRouting();

  // Handle dropdown
  const [isOpen, setIsOpen] = useState(false);

  // Dropdown anchor ref
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  // List of all dropdown inputs items
  const dropdownInputsItems = useMemo((): PlanDetailsDropdownAddInputsItem[] => {
    // Remove all unneeded form keys from the inputs list
    const inputsList = Object.entries(forms.data?.inputsList?.data || []).filter(
      ([name]) =>
        isFormKey(name) && ![FormKeys.AccountTransferInput, FormKeys.EventInput].includes(name)
    );

    // Check if people has already a partner
    const hasPartner = people.data?.clients.some(
      (client) => client.clientType === ClientType.Partner
    );

    return [
      // Handle inputs list items
      ...inputsList.map(([name, entries]) => {
        // Constants are defined outside of callback to throw error
        // as soon as they find an unsupported form type or input group
        const formKey = asFormKey(name);
        const formSelection = asInputGroup(name);

        return {
          icon: INPUTS_ICONS[formKey],
          label: getInputLabel(formKey),
          action: (): void => {
            setIsOpen(false);
            openModal({
              action: FormActions.create,
              type: formKey,
              formSelection: entries.length > 1 ? formSelection : undefined,
            });
          },
        };
      }),
      // Handle people item
      // "Add People" will display formSelection screen if there is no partner
      // "Add Dependent" will show if partner exists in the forecast
      {
        icon: "user",
        label: hasPartner ? lang("ACTION_CREATE_DEPENDENT") : lang("ACTION_CREATE_PEOPLE"),
        action: (): void => {
          openModal({
            action: FormActions.create,
            type: hasPartner ? FormKeys.Dependent : FormKeys.People,
            ...(!hasPartner && { formSelection: FormKeys.People }),
          });
        },
      },
    ];
  }, [openModal, people.data, forms.data?.inputsList]);

  return (
    <>
      <AfTooltip
        position="left-center"
        render={(props: AfTooltipRenderProps<HTMLDivElement>): React.ReactElement => (
          <div {...(plan.data?.archived && props)}>
            <AfButton
              ref={anchorRef}
              skin="primary"
              iconPosition="right"
              disabled={plan.data?.archived}
              icon={<AfIcon name={`basic-arrow-${isOpen ? "up" : "down"}`} size="s" />}
              onClick={(): void => setIsOpen(!isOpen)}
            >
              {lang("ACTION_ADD_INPUT")}
            </AfButton>
          </div>
        )}
      >
        {lang("TOOLTIP_PLAN_ARCHIVED")}
      </AfTooltip>
      <AfDropdown
        isOpen={isOpen}
        anchorRef={anchorRef}
        position="bottom-end"
        onClose={(): void => setIsOpen(false)}
      >
        <AfActionList>
          {dropdownInputsItems.map((item) => (
            <AfActionList.Item
              key={item.label}
              leftNode={<AfIcon name={item.icon} size="s" />}
              onClick={item.action}
            >
              {item.label}
            </AfActionList.Item>
          ))}
        </AfActionList>
      </AfDropdown>
    </>
  );
};
