// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import {
  Validator,
  Validators,
  ValidatorCallback,
} from "@advicefront/fe-infra-form-schema-renderer";
import { LegalGender } from "@advicefront/plan-client-axios";
// Utils
import * as CC from "change-case";
// Translations
import { lang } from "@lang/index";

/**
 * Use Gender Age Validation
 * A custom React hook that validates the gender and age fields
 * @param valueKey - key of the form field to validate
 * @returns error message if age is higher than the mortality age or undefined if validation passes
 */
export const useGenderAgeValidation = (valueKey: Validator["valueKey"]): Validators => {
  // Get mortality ages
  const mortalityAges = Store.useSelector(
    (state) =>
      // Use assumptions data when inside plan details
      state.assumptions.data?.livelihoodIndicators.averageLifespan ||
      // Use preferences data when inside plans list
      state.preferences.data?.livelihoodIndicators.averageLifespan
  );

  return useMemo((): Validators => {
    if (mortalityAges === undefined) return [];

    return [
      {
        valueKey,
        validator: ({ field, value }, formData): ReturnType<ValidatorCallback> => {
          // Get the legal gender from the form data
          const { legalGender } = formData.computedValues;

          // Return undefined if gender wasn't selected
          if (!legalGender) return;

          // Check if the entered age is higher than the mortality age for the current gender
          const isAgeHigherThanMortalityAge: boolean =
            (legalGender === LegalGender.M && Number(value) > mortalityAges.male) ||
            (legalGender === LegalGender.F && Number(value) > mortalityAges.female);

          // Return an error if the age is higher than the mortality age for the current gender
          if (isAgeHigherThanMortalityAge) {
            return {
              status: "error",
              description: lang("FORM_VALIDATOR_MORTALITY_ASSUMPTION", [
                CC.capitalCase(field.key),
                ...(legalGender === LegalGender.M
                  ? [lang("FORM_LABEL_MALE").toLowerCase(), mortalityAges.male]
                  : [lang("FORM_LABEL_FEMALE").toLowerCase(), mortalityAges.female]),
              ]),
            };
          }

          // Return undefined if the validation passes
          return;
        },
      },
    ];
  }, [valueKey, mortalityAges]);
};
