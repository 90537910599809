// React
import React from "react";
// Store
import { Store } from "@store/index";
// Typings
import { ChartColumnModules } from "@typings/charts/column";
// Translations
import { lang } from "@lang/index";
// Components
import { ChartColumn } from "@components/charts/column";
import { LoaderOverlaySpinner } from "@components/loaders/overlay-spinner";
import { LoaderSkeletonCard } from "@components/loaders/skeleton";

export const CashflowChart = (): React.ReactElement => {
  // Store
  const forecast = Store.useSelector((state) => state.forecast);

  return (
    <>
      {!forecast.data && <LoaderSkeletonCard media={true} />}

      {forecast.data && (
        <LoaderOverlaySpinner
          active={!!forecast.polling}
          title={lang("LOADER_TITLE_CALCULATING")}
          description={lang("LOADER_DESCRIPTION_CALCULATING")}
          size="m"
        >
          <ChartColumn name={ChartColumnModules.Cashflow} />
        </LoaderOverlaySpinner>
      )}
    </>
  );
};
