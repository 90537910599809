// Styles
import "./styles/index.scss";
// React
import React from "react";
// Utils
import classNames from "classnames";
// Components
import { AfCol, AfRow, AfRowProps } from "@advicefront/ds-grid";
import { AfSpinner, AfSpinnerProps } from "@advicefront/ds-spinner";
import { AfTypography } from "@advicefront/ds-typography";

// Props
interface LoaderOverlaySpinnerProps {
  active: boolean;
  title?: string;
  description?: string;
  orientation?: AfRowProps["orientation"];
  bordered?: boolean;
  size?: AfSpinnerProps["size"];
  children: React.ReactNode;
}

export const LoaderOverlaySpinner = ({
  active,
  title,
  description,
  orientation = "vertical",
  bordered = true,
  size,
  children,
}: LoaderOverlaySpinnerProps): React.ReactElement => (
  <div className="loader-overlay-spinner">
    {children}
    {active && (
      <div
        className={classNames("loader-overlay-spinner__container", {
          "loader-overlay-spinner__container--centered": orientation === "vertical",
          "loader-overlay-spinner__container--bordered": bordered,
        })}
      >
        <AfRow spacing="md" align="center" justify="center" orientation={orientation}>
          <AfCol>
            <AfSpinner size={size} />
          </AfCol>

          {(title || description) && (
            <AfCol>
              {title && (
                <AfTypography hasMargin={false} type="h3" skin="text-light">
                  {title}
                </AfTypography>
              )}
              {description && (
                <AfTypography hasMargin={false} type="small" skin="text-light">
                  {description}
                </AfTypography>
              )}
            </AfCol>
          )}
        </AfRow>
      </div>
    )}
  </div>
);
