// Styles
import "./styles/index.scss";
// React
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
// Highcharts
import HighchartsReact, { HighchartsReact as Chart } from "highcharts-react-official";
import Highcharts from "highcharts";
// Typings
import {
  ChartColumnModulesName,
  ChartColumnOptions,
  ChartColumnTabs,
} from "@typings/charts/column";
import { Colors } from "@typings/colors";
// Hooks
import { useChartColumn } from "@components/charts/column/hooks";
// Context
import { AppOptionsContext } from "@context/app-options";
// Constants
import { EMPTY_VALUE } from "@constants/index";
// Utils
import { abbreviateCurrency, maskCurrency } from "@utils/format-currency";
import classNames from "classnames";
// Translations
import { lang } from "@lang/index";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfActionList } from "@advicefront/ds-action-list";
import { AfButton } from "@advicefront/ds-button";
import { AfCard } from "@advicefront/ds-card";
import { AfDropdown } from "@advicefront/ds-dropdown";
import { AfIcon } from "@advicefront/ds-icon";
import { AfTabs } from "@advicefront/ds-tabs";
import { AfToggle } from "@advicefront/ds-toggle";
import { ChartColumnEmpty } from "@components/charts/column/Empty";

// Props
interface ChartColumnProps {
  name: ChartColumnModulesName;
}

export const ChartColumn = ({ name }: ChartColumnProps): React.ReactElement => {
  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // Reference for highcharts element
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  // Reference for dropdown menu
  const dropdownRef = useRef<HTMLButtonElement | null>(null);

  // State to handle dropdown options menu
  const [dropdownOpened, setDropdownOpened] = useState(false);

  // State to handle tabs menu
  const [tabSelected, setTabSelected] = useState<ChartColumnTabs | undefined>(undefined);

  // State to handle dropdown options
  const [optionsChecked, setOptionsChecked] = useState<ChartColumnOptions[]>([]);

  // State to detect if chart was zoomed
  const [isZoomed, setIsZoomed] = useState(false);

  // Hook to get current chart module data
  const { tabs, options, categories, subCategories, xLines, yLines, series } = useChartColumn({
    name,
    tab: tabSelected,
    detailed: optionsChecked.includes(ChartColumnOptions.Detailed),
  });

  // Method to handle options checked
  const handleOptionsChecked = (option: ChartColumnOptions): void => {
    // Create a copy of options
    const values = [...optionsChecked];
    // Find option index on values array
    const index = values.indexOf(option);
    // Remove if option exists or add it
    index > -1 ? values.splice(index, 1) : values.push(option);
    // Update the options state
    setOptionsChecked(values);
  };

  // Method to handle reset chart zoom
  const handleResetZoom = (): void => {
    if (chartRef.current) {
      chartRef.current.chart.zoomOut();
    }
  };

  // Reset zoom if option was unchecked
  useEffect(() => {
    if (!optionsChecked.includes(ChartColumnOptions.Zoom)) {
      handleResetZoom();
    }
  }, [optionsChecked]);

  // Set options and tab selected for initial render
  useEffect(() => {
    // Get all options checked for initial render
    const optionsChecked = options?.filter(({ checked }) => checked).map(({ value }) => value);
    // Update the options state
    optionsChecked?.length && setOptionsChecked(optionsChecked);
    // Get first tab selected for initial render
    const tabSelected = tabs?.find((tab) => tab.checked);
    // Update the tab state
    tabSelected && setTabSelected(tabSelected.value);
    // Don't add dependencies since is used for initial render only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check if the chart data is empty
  const isEmpty = useMemo((): boolean => {
    // Extract all data from the chart series filtering the 0 values
    const data = series?.flatMap((entry) =>
      entry.type === "column" || entry.type === "line" ? entry.data?.filter(Number) : []
    );
    // Return true if no data filtering the undefined or nullable values
    return !data?.filter(Boolean).length;
  }, [series]);

  // Define column chart options
  const chartOptions = useMemo(
    (): Highcharts.Options => ({
      // Defaults
      title: undefined,
      credits: undefined,
      accessibility: {
        enabled: false,
      },

      // Chart
      chart: {
        marginLeft: 52,
        zooming: {
          type: optionsChecked.includes(ChartColumnOptions.Zoom) ? "x" : undefined,
          resetButton: {
            position: {
              y: -999, // Hide default reset zoom button
            },
          },
        },
        events: {
          selection: function (ev): undefined {
            setIsZoomed(!!ev.xAxis);
            return;
          },
        },
      },

      // Plot
      plotOptions: {
        column: {
          stacking: "normal",
          groupPadding: 0,
          animation: {
            duration: 500,
          },
        },
      },

      // X Axis
      xAxis: {
        crosshair: true,
        categories: categories?.values,
        plotLines: xLines,
        lineColor: Colors.Border,
        tickWidth: 1,
        tickColor: Colors.Border,
        tickInterval: 2,
        labels: {
          useHTML: true,
          distance: 16,
          rotation: 0,
          style: {
            textAlign: "center",
          },
          formatter: function (): string {
            // Display all categories values and subcategories values if defined
            return renderToStaticMarkup(
              <>
                <div className="chart-column__axis-category">{this.value}</div>
                {subCategories?.values?.map((entry, key) => (
                  <React.Fragment key={`chart-column-axis-value-${key}`}>
                    {entry && (
                      <div className="chart-column__axis-sub-category">{entry[this.pos]}</div>
                    )}
                  </React.Fragment>
                ))}
              </>
            );
          },
        },
      },

      // Y Axis
      yAxis: {
        title: undefined,
        plotLines: yLines,
        softMax: 5,
        labels: {
          useHTML: true,
          distance: 16,
          style: {
            color: Colors.TextLight,
          },
          formatter: function (): string {
            // Define empty string for html markup
            let html = "";

            // Display categories and subcategories titles if defined
            if (this.isFirst && (categories?.title || subCategories?.titles)) {
              html += renderToStaticMarkup(
                <div className="chart-column__axis-titles">
                  <div className="chart-column__axis-category">
                    {categories?.title || EMPTY_VALUE}
                  </div>
                  {subCategories?.titles?.map((title, key) => (
                    <React.Fragment key={`chart-column-axis-title-${key}`}>
                      {title && <div className="chart-column__axis-sub-category">{title}</div>}
                    </React.Fragment>
                  ))}
                </div>
              );
            }

            // Display default value
            html += renderToStaticMarkup(
              <span className="chart-column__axis-sub-category">
                {abbreviateCurrency(this.value)}
              </span>
            );

            // Return html markup
            return html;
          },
        },
      },

      // Legend
      legend: {
        useHTML: true,
        itemMarginTop: 16,
        itemStyle: {
          color: Colors.TextLight,
        },
        itemHoverStyle: {
          color: Colors.Text,
        },
        itemHiddenStyle: {
          textDecoration: "none",
          color: Colors.TextInactive,
        },
        labelFormatter: function (): string {
          return renderToStaticMarkup(
            <span
              className={classNames("chart-column__legend", {
                [`chart-column__legend--${this.options.className}`]: !!this.options.className,
              })}
            >
              {this.options.id}
            </span>
          );
        },
      },

      // Tooltip
      tooltip: {
        shadow: false,
        outside: true,
        followPointer: false,
        padding: 8,
        borderRadius: 8,
        backgroundColor: Colors.Text,
        style: {
          color: Colors.Paper,
          zIndex: 999,
        },
        formatter: function (): string {
          return renderToStaticMarkup(
            <span className="chart-column__tooltip">
              <b>{this.series.name}</b>
              <br />
              {this.key}: <b>{maskCurrency(this.y || 0, currencySymbol)}</b>
            </span>
          );
        },
      },

      // Responsive
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 1280,
            },
            chartOptions: {
              xAxis: {
                tickInterval: 4,
              },
            },
          },
          {
            condition: {
              maxWidth: 768,
            },
            chartOptions: {
              xAxis: {
                tickInterval: 6,
              },
            },
          },
        ],
      },

      // Series
      series,
    }),
    [currencySymbol, optionsChecked, categories, subCategories, xLines, yLines, series]
  );

  // Display empty chart if no data
  if (isEmpty) {
    return <ChartColumnEmpty />;
  }

  return (
    <AfCard className="chart-column">
      <AfCard.Content>
        {(tabs || options) && (
          <div className="chart-column__menu">
            {tabs && (
              <AfTabs variation="segmented" className="chart-column__menu-tabs">
                {tabs.map((tab, key) => (
                  <AfTabs.Item
                    key={`chart-column-tab-${key}`}
                    active={tab.value === tabSelected}
                    onClick={(): void => setTabSelected(tab.value)}
                  >
                    {tab.label}
                  </AfTabs.Item>
                ))}
              </AfTabs>
            )}

            {options && (
              <AfRow align="center" justify="end" className="chart-column__menu-options">
                {isZoomed && (
                  <AfCol>
                    <AfButton
                      size="m"
                      skin="secondary"
                      iconPosition="right"
                      icon={<AfIcon name="arrow-refresh-circle" />}
                      onClick={handleResetZoom}
                    >
                      {lang("ACTION_RESET_ZOOM")}
                    </AfButton>
                  </AfCol>
                )}

                <AfCol>
                  <AfButton
                    ref={dropdownRef}
                    size="m"
                    skin="secondary"
                    iconPosition="right"
                    icon={<AfIcon name={`basic-arrow-${dropdownOpened ? "up" : "down"}`} />}
                    onClick={(): void => setDropdownOpened(!dropdownOpened)}
                  >
                    {lang("ACTION_VIEW_OPTIONS")}
                  </AfButton>
                  <AfDropdown
                    isOpen={dropdownOpened}
                    anchorRef={dropdownRef}
                    position="bottom-end"
                    onClose={(): void => setDropdownOpened(false)}
                  >
                    <AfActionList>
                      {options.map((option, key) => (
                        <AfActionList.Item key={`chart-column-option-${key}`}>
                          <AfToggle
                            inverted
                            size="m"
                            skin="default"
                            label={option.label}
                            checked={optionsChecked.includes(option.value)}
                            onChange={(): void => handleOptionsChecked(option.value)}
                          />
                        </AfActionList.Item>
                      ))}
                    </AfActionList>
                  </AfDropdown>
                </AfCol>
              </AfRow>
            )}
          </div>
        )}
        <Chart ref={chartRef} highcharts={Highcharts} options={chartOptions} />
      </AfCard.Content>
    </AfCard>
  );
};
