// Typings
import { ForecastCashflowElementDto, ForecastInputGroups } from "@advicefront/plan-client-axios";
// Highcharts
import Highcharts from "highcharts";
// Utils
import { getGroupColor, getGroupName } from "@utils/charts";

// Props
type GroupInputsByTypeOutput = Record<ForecastInputGroups, ForecastCashflowElementDto[]>;

/**
 * Group Inputs By Type
 * Iterates through the inputs array and groups the elements by their baseType
 * @param inputs - array of forecast inputs
 * @returns grouped inputs by type in {@link GroupInputsByTypeOutput} format
 */
const groupInputsByType = (
  inputs: ForecastCashflowElementDto[] | undefined
): GroupInputsByTypeOutput | undefined => {
  // Return if there are no inputs
  if (!inputs) return;

  // Create an object to store grouped inputs
  const groupedInputs = inputs.reduce(
    (result, input) => {
      // Add input to group if the baseType exists
      if (input.baseType) {
        // Create a new array for the input type if it doesn't exist in the result
        result[input.baseType] = result[input.baseType] || [];
        // Push the input into the corresponding group
        result[input.baseType].push(input);
      }
      // Return the updated result
      return result;
    },
    // Initialize the result as an empty object
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as GroupInputsByTypeOutput
  );

  // Sort the grouped inputs by baseType in descending order and return them
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.fromEntries(
    Object.entries(groupedInputs).sort(([keyA], [keyB]) =>
      // Compare the baseType values for sorting
      keyB.localeCompare(keyA)
    )
  ) as GroupInputsByTypeOutput;
};

/**
 * Get Inputs Series
 * Retrieves the input series data for generating highcharts series options
 * @param inputs - array of forecast inputs
 * @returns array of highcharts "series" for the inputs
 */
export const getInputsSeries = (
  inputs: ForecastCashflowElementDto[] | undefined
): Highcharts.SeriesOptionsType[] => {
  // Group inputs data by group
  const inputGroups = groupInputsByType(inputs) || [];

  // Counter for incremental index value
  let incremental = 0;

  // Iterate each input group and generate series options
  return Object.entries(inputGroups).flatMap(([baseType, group], groupIndex) =>
    // Loop through each entry in the group
    group.map((entry, entryIndex) => {
      // Increment the incremental value
      incremental++;
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const inputType = baseType as ForecastInputGroups;
      // Generate input id based on input type or entry type
      const inputId = getGroupName(inputType) || entry.type;
      // Return object in highcharts series structure
      return {
        type: "column",
        name: entry.label,
        color: getGroupColor(inputType),
        data: entry.values.map((value) => Math.abs(value)),
        index: incremental,
        // Define id to be shown on legend and set legend order
        ...(entryIndex === 0 && {
          id: inputId,
          legendIndex: Object.keys(inputGroups).length - groupIndex,
        }),
        // Link to column with the same id
        ...(entryIndex !== 0 && {
          id: `${inputId}-${incremental}`,
          linkedTo: inputId,
        }),
      };
    })
  );
};
