// React
import { useCallback, useContext, useMemo } from "react";
// Typings
import {
  TableAssumptionsModuleEntry,
  TableAssumptionsModuleSchema,
  UseTableAssumptionsModule,
} from "@components/tables/assumptions/hooks";
import { InputNodeFormat } from "@typings/input-node-format";
// Context
import { AppOptionsContext } from "@context/app-options";
// Utils
import { isAccountsAssumptions, isGbrAssumptions } from "@utils/type-guards/assumptions";
import { isEqual } from "@utils/is-equal";
// Translations
import { lang } from "@lang/index";

export const useTableNonSavingsIncomeModule: UseTableAssumptionsModule = ({
  name,
  data,
  firmPreferences,
}) => {
  // Throw error if data is not valid for NonSavingsIncome module
  if (data && (isAccountsAssumptions(data) || !isGbrAssumptions(data))) {
    throw new Error(`${name} data not valid`);
  }

  // Define firm data as GBR region
  const firmData = isGbrAssumptions(firmPreferences.data) ? firmPreferences.data : undefined;

  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // Define payload for submission to match with dto
  const submitPayload = useCallback<TableAssumptionsModuleSchema["submitPayload"]>(
    (updatedData) => ({
      taxes: {
        taxAllocation: [
          // First tax allocation shouldn't be changed as it's the starting value
          {
            taxableIncome: Number(data?.taxes.taxAllocation[0].taxableIncome) || 0,
            taxRate: Number(data?.taxes.taxAllocation[0].taxRate),
          },
          ...updatedData.map((row) => ({
            taxableIncome: Number(row.fields[0].value) || 0,
            taxRate: Number(row.fields[1].value),
          })),
        ],
      },
    }),
    [data?.taxes.taxAllocation]
  );

  // Get name base on the index of each tax allocation
  const getTableRowName = useMemo(
    (): {
      [key: number]: string;
    } => ({
      0: lang("TABLE_LABEL_BASIC_RATE"),
      1: lang("TABLE_LABEL_HIGHER_RATE"),
      2: lang("TABLE_LABEL_ADDITIONAL_RATE"),
    }),
    []
  );

  // Return table assumptions data object
  return useMemo(
    (): TableAssumptionsModuleSchema => ({
      title: lang("TABLE_TITLE_NON_SAVINGS_INCOME"),
      head: [
        {
          label: lang("TABLE_COLUMN_NAME"),
          align: "left",
        },
        {
          label: lang("TABLE_COLUMN_VALUE_UNIT", currencySymbol),
          align: "right",
        },
        {
          label: lang("TABLE_COLUMN_VALUE_UNIT", "%"),
          align: "right",
        },
      ],
      // Slice taxAllocation to create a new array that includes all entries
      // except the first one which shouldn't be changed as it's the starting value
      entries: (data?.taxes.taxAllocation || []).slice(1).map(
        (values, index): TableAssumptionsModuleEntry => ({
          label: getTableRowName[index],
          fields: [
            {
              type: InputNodeFormat.currency,
              value: values.taxableIncome,
            },
            {
              type: InputNodeFormat.percent,
              value: values.taxRate,
            },
          ],
        })
      ),
      customise: {
        checked: !!data?.settings.nonSavingsIncome,
        payload: {
          settings: {
            nonSavingsIncome: !firmData?.settings.nonSavingsIncome,
          },
        },
      },
      reset: {
        allowed: !isEqual(data?.taxes.taxAllocation, firmData?.taxes.taxAllocation),
        payload: {
          taxes: {
            taxAllocation: firmData?.taxes.taxAllocation,
          },
        },
      },
      submitPayload,
    }),
    [
      currencySymbol,
      getTableRowName,
      submitPayload,
      data?.settings.nonSavingsIncome,
      data?.taxes.taxAllocation,
      firmData?.settings.nonSavingsIncome,
      firmData?.taxes.taxAllocation,
    ]
  );
};
