// React
import React from "react";
// Router
import { useSearchParams } from "react-router-dom";
// Typings
import { FormActions, FormKeys, FormModalParams } from "@typings/routing";
// Utils
import { isFormKey } from "@routes/utils/modal-routing-guards";
// Components
import { DialogDelete } from "@components/dialogs/delete";
import { ModalForm } from "@components/modals/Form";
import { ModalFormSelection } from "@components/modals/FormSelection";
import { ModalPlanImportErrors } from "@components/modals/PlanImportErrors";
import { ModalPlanName } from "@components/modals/PlanName";

export const Modal = (): React.ReactElement | null => {
  // Url params
  const [urlParams] = useSearchParams();
  const action = urlParams.get(FormModalParams.action);
  const type = urlParams.get(FormModalParams.type);
  const id = urlParams.get(FormModalParams.id);
  const formSelection = urlParams.get(FormModalParams.formSelection);

  // Return null if no params
  if ([action, type, id].every((param) => param === null)) {
    return null;
  }

  // Form selection modal
  if (formSelection) {
    return <ModalFormSelection />;
  }

  // Form modal
  if (
    isFormKey(type) &&
    type !== FormKeys.PlanName &&
    (action === FormActions.create || (action === FormActions.edit && !!id))
  ) {
    return <ModalForm />;
  }

  // Plan name modal
  if (
    isFormKey(type) &&
    type === FormKeys.PlanName &&
    (action === FormActions.edit || action === FormActions.duplicate) &&
    !!id
  ) {
    return <ModalPlanName id={id} action={action} />;
  }

  // Delete dialog
  if (isFormKey(type) && action === FormActions.delete && !!id) {
    return <DialogDelete />;
  }

  // Plan import errors modal
  if (type === FormKeys.PlanImportErrors) {
    return <ModalPlanImportErrors />;
  }

  // Throw error if there is no modal to be render
  throw new Error("Unable to render modal");
};
