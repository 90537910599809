// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { FormModuleInputInstance, UseSchemaProps } from "@forms/modules/types";
import {
  isFormSchemaReactComponent,
  mapSchemaFields,
} from "@advicefront/fe-infra-form-schema-renderer";
import { FIELD_TYPES } from "@advicefront/fe-infra-form-schema";
import { FormSchema } from "@advicefront/fe-infra-form-schema-renderer";
import { Ownership } from "@advicefront/plan-client-axios";
// Constants
import { EMPTY_OPTION } from "@constants/index";
// Utils
import { isFormSchema } from "@forms/utils/type-guards/form-schema";
import { isFormType } from "@routes/utils/modal-routing-guards";
// Hooks
import { useDtoKey, useOptionsList } from "@forms/modules/input/hooks";

export const useSchema: FormModuleInputInstance["useSchema"] = (props) => {
  // Store
  const forms = Store.useSelector((state) => state.forms);

  // Get Dto form key
  const dtoKey = useDtoKey(props);

  // Get list of options for the selection fields
  const optionsList = useOptionsList(props);

  // Get input schema based on Dto key
  const inputSchema = useMemo(() => {
    // Return undefined if Dto key or data is not defined
    if (!dtoKey || !forms.data?.forms || !isFormType(dtoKey)) return;

    // Get form schema based Dto Key
    const schema = isFormSchema(forms.data.forms[dtoKey]) ? forms.data.forms[dtoKey] : undefined;

    // Return undefined if schema was not found
    if (!schema) return;

    // Iterate through all fields of the schema and modify fields
    return mapSchemaFields(schema, (valueKey, field) => {
      // Return field if is a react component
      if (isFormSchemaReactComponent(field)) return field;
      /**
       * Boolean Field
       * Sets a default value as false and marks the field as not required,
       * so that if the user doesn't check the value, it will be false and will
       * not trigger validation for the field being empty
       */
      if (field.fieldType === FormSchema.FIELD_TYPES.boolean) {
        return {
          ...field,
          defaultValue: false,
          validation: {
            required: false,
            errorMessage: null,
          },
        };
      }

      /**
       * Single Selection Field
       * Define list of options for the current "valueKey" field type
       * Const "selectField" is created in way to get the validation property
       */
      if (valueKey in optionsList) {
        const selectField: FormSchema.SelectionFieldSingle = {
          ...field,
          fieldType: FormSchema.FIELD_TYPES.singleSelection,
          defaultValue: undefined,
          options: [],
        };

        // Check if ownership options have "Joint" option - some inputs don't allow joint ownership
        if (
          valueKey === "ownership" &&
          field.fieldType === FormSchema.FIELD_TYPES.singleSelection
        ) {
          const hasJoint = field.options.find((entry) => entry.label === Ownership.Joint);

          return {
            ...selectField,
            // Filter out "Joint" option if input doesn't allow it
            options: hasJoint
              ? optionsList[valueKey]
              : optionsList[valueKey].filter((entry) => entry.label !== Ownership.Joint),
          };
        }

        return {
          ...selectField,
          options: selectField.validation?.required
            ? optionsList[valueKey]
            : [EMPTY_OPTION, ...optionsList[valueKey]],
        };
      }

      if (field.fieldType === FIELD_TYPES.singleSelection) {
        return {
          ...field,
          options: field.validation?.required ? field.options : [EMPTY_OPTION, ...field.options],
        };
      }

      /**
       * Group Field : Account Limits
       * Reorder the "accountLimit" nested fields
       */
      if (valueKey === "accountLimit" && FormSchema.isFieldGroup(field)) {
        return {
          ...field,
          fields: {
            maxWithdrawal: field.fields["maxWithdrawal"],
            adjustWithdrawalWithInflation: field.fields["adjustWithdrawalWithInflation"],
            minBalance: field.fields["minBalance"],
            adjustBalanceWithInflation: field.fields["adjustBalanceWithInflation"],
            availabilityAge: field.fields["availabilityAge"],
          },
        };
      }

      // Return current field
      return field;
    });
  }, [dtoKey, optionsList, forms.data?.forms]);

  return useMemo(
    (): UseSchemaProps => ({
      loading: !forms.data,
      data: inputSchema,
    }),
    [inputSchema, forms.data]
  );
};
