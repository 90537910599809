// Enum defining all supported ring charts modules
// Each module is represented by a unique key and a corresponding string value
export enum ChartRingModules {
  Inflow = "Inflow",
  Outflow = "Outflow",
  Assets = "Assets",
  Liabilities = "Liabilities",
}

// Type alias for the keys of the ChartRingModules enum
// This is used to ensure type safety when working with ring charts in the codebase
export type ChartRingModulesName = keyof typeof ChartRingModules;
