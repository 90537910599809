// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
import { selectInputById } from "@store/selectors/input-by-id";
// Typings
import { FormData, isFormData } from "@advicefront/fe-infra-form-schema";
import { FormModuleInputInstance } from "@forms/modules/types";
import { Ownership } from "@advicefront/plan-client-axios";
// Constants
import { EMPTY_VALUE } from "@constants/index";
// Utils
import { getInitialValues } from "@forms/utils/get-initial-values";
// Hooks
import { useSchema } from "@forms/modules/input/hooks";

export const useInitialValues: FormModuleInputInstance["useInitialValues"] = (props) => {
  // Get input dto on edit mode otherwise dto is undefined
  const inputDto = Store.useSelector(
    props.itemId ? selectInputById(props.itemId) : (): undefined => undefined
  );

  // Get the form schema
  const schema = useSchema(props);

  return useMemo((): FormData | undefined => {
    // Edit input
    if (props.itemId) {
      // Remove all Dto keys not presented in form schema and get initial values
      const formData = getInitialValues(inputDto, schema?.data);

      // Return undefined if the form data is not valid
      if (!isFormData(formData)) return;

      // Return the form data
      return formData;
    }

    // Create input
    if (!props.itemId && schema?.data) {
      // Define list of fields that should have default values
      const {
        ownership,
        interestRate,
        reinvestYield,
        taxExempt,
        taxDeductible,
        reinvestInterest,
        reinvestDividends,
        nationalResident,
        startPeriod,
      } = schema.data;

      // Define default values and remove all keys that have undefined values
      const formData = Object.fromEntries(
        Object.entries({
          ownership: ownership ? Ownership.Owner : undefined,
          interestRate: interestRate ? EMPTY_VALUE : undefined,
          reinvestYield: reinvestYield ? false : undefined,
          taxExempt: taxExempt ? false : undefined,
          taxDeductible: taxDeductible ? false : undefined,
          reinvestInterest: reinvestInterest ? false : undefined,
          reinvestDividends: reinvestDividends ? false : undefined,
          nationalResident: nationalResident ? false : undefined,
          startPeriod: startPeriod ? "0" : undefined,
        }).filter(([, value]) => value !== undefined)
      );

      // If the form data is not valid return undefined
      if (!isFormData(formData)) return;

      // Return the form data
      return formData;
    }
  }, [inputDto, props.itemId, schema?.data]);
};
