// React
import React, { ReactElement } from "react";
// Router
import { View } from "@routes/index";
// Layouts
import { PlanDetailsLayout } from "@layouts/plan-details";
// Views
import { CashflowChart } from "@views/cashflow/components/Chart";
import { CashflowTable } from "@views/cashflow/components/Table";

// Define element for the view
const Element = (): ReactElement => (
  <PlanDetailsLayout>
    <CashflowChart />
    <CashflowTable />
  </PlanDetailsLayout>
);

// Route view options
export const cashflow: View = {
  name: "Cashflow",
  options: {
    path: "/plan/:id/cashflow",
    element: <Element />,
  },
};
