// Types
import { StateProps } from "./types";

export const initialState: StateProps = {
  loading: 0,
  error: null,
  data: undefined,
  submitSuccess: false,
  submitSuccessMessage: "",
};
