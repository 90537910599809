// Typings
import { ClientType, Ownership, ResponseClientPersonDto } from "@advicefront/plan-client-axios";

/**
 * Get Clients
 * Filters an array of clients based on ownership type, and returns the filtered array
 * @param clients - array of client objects to filter
 * @param ownership - type of ownership to filter by
 * @returns filtered array of client objects based on the ownership type
 */
export const getClients = (
  clients: ResponseClientPersonDto[] | undefined,
  ownership: Ownership
): ResponseClientPersonDto[] =>
  (clients || []).filter(
    (client) =>
      // Display both clients
      ownership === Ownership.Joint ||
      // Display clients with the same client type as the client ownership
      (ownership === Ownership.Owner && client.clientType === ClientType.Owner) ||
      (ownership === Ownership.Partner && client.clientType === ClientType.Partner)
  );

/**
 * Get Clients Names
 * Filters an array of clients based on ownership type, returns the filtered array,
 * and concatenates the first names of the clients into a single string
 * @param clients - array of client objects to filter
 * @param ownership - type of ownership to filter by
 * @returns string containing the first names of the filtered clients, concatenated together
 */
export const getClientsNames = (
  clients: ResponseClientPersonDto[] | undefined,
  ownership: Ownership
): string =>
  getClients(clients, ownership)
    .map((client) => client.firstName) // Show first name only
    .join(", "); // Merge all names as single string
