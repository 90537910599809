// React
import React from "react";
// Router
import { View } from "@routes/index";
import { ViewRedirect } from "@routes/components/ViewRedirect";
// Views
import { protectionProtection } from "@views/protections/protection";

// Export all views
export * from "./illness";

export * from "./protection";

export * from "./tpd";

// Route view options
export const protections: View = {
  name: "Protections",
  options: {
    path: "/plan/:id/protections",
    element: <ViewRedirect route={protectionProtection} />,
  },
};
