// React
import React from "react";
// Store
import { Store } from "@store/index";
// Typings
import { FormActions, FormKeys } from "@typings/routing";
import { ChartTimelineModules } from "@typings/charts/timeline";
import { TableBasicModules } from "@typings/tables/basic";
// Hooks
import { useModalRouting } from "@hooks/modal-routing";
// Translations
import { lang } from "@lang/index";
// Components
import { AfRow, AfCol } from "@advicefront/ds-grid";
import { AfTooltip, AfTooltipRenderProps } from "@advicefront/ds-tooltip";
import { AfButton } from "@advicefront/ds-button";
import { AfIcon } from "@advicefront/ds-icon";
import { AfTypography } from "@advicefront/ds-typography";
import { ChartTimeline } from "@components/charts/timeline";
import { LoaderSkeletonCard } from "@components/loaders/skeleton";
import { TableBasic } from "@components/tables/basic";

export const OverviewEvents = (): React.ReactElement => {
  // Store
  const inputs = Store.useSelector((state) => state.inputs);
  const plan = Store.useSelector((state) => state.plan);

  // Route
  const { openModal } = useModalRouting();

  return (
    <>
      {!inputs.data && <LoaderSkeletonCard media={true} />}

      {inputs.data && (
        <>
          <AfRow align="center" justify="spread" spacing="lg">
            <AfCol>
              <AfTypography hasMargin={false} type="h2">
                {lang("TABLE_TITLE_EVENTS_AND_TIMELINE")}
              </AfTypography>
            </AfCol>
            <AfCol>
              <AfTooltip
                position="left-center"
                render={(props: AfTooltipRenderProps<HTMLDivElement>): React.ReactElement => (
                  <div {...(plan.data?.archived && props)}>
                    <AfButton
                      type="button"
                      skin="ghost"
                      disabled={plan.data?.archived}
                      icon={<AfIcon name="basic-add-circle" />}
                      onClick={(): void =>
                        openModal({
                          action: FormActions.create,
                          type: FormKeys.EventInput,
                        })
                      }
                    >
                      {lang("ACTION_ADD_EVENT")}
                    </AfButton>
                  </div>
                )}
              >
                {lang("TOOLTIP_PLAN_ARCHIVED")}
              </AfTooltip>
            </AfCol>
          </AfRow>
          <AfRow orientation="vertical" spacing="lg">
            <AfCol size="auto">
              <ChartTimeline name={ChartTimelineModules.Events} />
            </AfCol>
            <AfCol size="auto">
              <TableBasic name={TableBasicModules.Events} />
            </AfCol>
          </AfRow>
        </>
      )}
    </>
  );
};
