/**
 * Chips
 * Naming convention: CHIP_ELEMENT
 */
export const chips = {
  CHIP_TAX_OWNER: {
    en: "Owner's Tax",
  },
  CHIP_TAX_PARTNER: {
    en: "Partner's Tax",
  },
};
