// React
import React from "react";
// Components
import { AfCol, AfColProps, AfRow } from "@advicefront/ds-grid";
import { AfSkeleton } from "@advicefront/ds-skeleton";

export const LoaderSkeletonModal = (): React.ReactElement => {
  // Define list of columns sizes
  const colSizes: AfColProps["size"][] = [10, 6, 8, 10, 6];

  return (
    <>
      {Array.from(Array(colSizes.length).keys()).map((size, key) => (
        <AfRow key={`loader-skeleton-modal-${key}`} spacing="lg">
          <AfCol size={colSizes[size]}>
            <AfSkeleton shape="line" size="xl" />
          </AfCol>
        </AfRow>
      ))}
    </>
  );
};
