// Translations
import { lang } from "@lang/index";

// Reference for the select section title
export const SELECT_SECTION_TITLE = "SELECT_SECTION_TITLE";

// Empty text value
export const EMPTY_VALUE = "-";

// Empty select option value
export const EMPTY_OPTION_VALUE = "EMPTY_OPTION_VALUE";

// Empty select option field
export const EMPTY_OPTION = {
  label: lang("FORM_LABEL_EMPTY_OPTION"),
  value: EMPTY_OPTION_VALUE,
};
