// Styles
import "./styles/index.scss";
// React
import React, { useMemo } from "react";
import { renderToStaticMarkup } from "react-dom/server";
// Store
import { Store } from "@store/index";
// Highcharts
import { HighchartsReact as Chart } from "highcharts-react-official";
import Highcharts from "highcharts";
// Typings
import { Colors } from "@typings/colors";
// Utils
import { abbreviateCurrency } from "@utils/format-currency";
// Translations
import { lang } from "@lang/index";
// Components
import { AfCard } from "@advicefront/ds-card";

export const ChartColumnEmpty = (): React.ReactElement => {
  // Store
  const forecast = Store.useSelector((state) => state.forecast);

  // Define categories for empty chart
  const categories = useMemo(
    () => forecast.data?.years.map((year) => year.toString()),
    [forecast.data?.years]
  );

  // Define column empty chart options
  const chartOptions = useMemo(
    (): Highcharts.Options => ({
      // Defaults
      title: undefined,
      credits: undefined,
      accessibility: {
        enabled: false,
      },

      // Plot
      plotOptions: {
        column: {
          enableMouseTracking: false,
          groupPadding: 0,
          animation: {
            duration: 500,
          },
          events: {
            legendItemClick: () => false,
          },
        },
      },

      // X Axis
      xAxis: {
        categories,
        lineColor: Colors.Border,
        tickWidth: 1,
        tickColor: Colors.Border,
        tickInterval: 2,
        labels: {
          useHTML: true,
          distance: 16,
          rotation: 0,
          style: {
            textAlign: "center",
          },
          formatter: function (): string {
            return renderToStaticMarkup(
              <div className="chart-column__axis-category">{this.value}</div>
            );
          },
        },
      },

      // Y Axis
      yAxis: {
        title: undefined,
        labels: {
          useHTML: true,
          distance: 16,
          style: {
            color: Colors.TextLight,
          },
          formatter: function (): string {
            // Define empty string for html markup
            let html = "";

            // Display categories titles
            if (this.isFirst) {
              html += renderToStaticMarkup(
                <div className="chart-column__axis-titles">
                  <div className="chart-column__axis-category">{lang("CHART_YEARS")}</div>
                </div>
              );
            }

            // Display default value
            html += renderToStaticMarkup(
              <span className="chart-column__axis-sub-category">
                {abbreviateCurrency(this.value)}
              </span>
            );

            // Return html markup
            return html;
          },
        },
      },

      // Legend
      legend: {
        useHTML: true,
        itemMarginTop: 16,
        itemStyle: {
          color: Colors.TextLight,
        },
        itemHoverStyle: {
          color: Colors.TextLight,
        },
        labelFormatter: function (): string {
          return renderToStaticMarkup(
            <span className="chart-column__legend chart-column__legend--readonly">
              {lang("CHART_NO_DATA")}
            </span>
          );
        },
      },

      // Responsive
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 1280,
            },
            chartOptions: {
              xAxis: {
                tickInterval: 4,
              },
            },
          },
          {
            condition: {
              maxWidth: 768,
            },
            chartOptions: {
              xAxis: {
                tickInterval: 6,
              },
            },
          },
        ],
      },

      // Series
      series: [
        {
          type: "column",
          id: lang("CHART_NO_DATA"),
          name: lang("CHART_NO_DATA"),
          color: Colors.Background,
          data: categories?.map((_, index) => (index % 2 === 0 ? 55000 : 105000)),
        },
      ],
    }),
    [categories]
  );

  return (
    <AfCard className="chart-column chart-column--empty">
      <AfCard.Content>
        <Chart highcharts={Highcharts} options={chartOptions} />
      </AfCard.Content>
    </AfCard>
  );
};
