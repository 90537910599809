// React
import { useCallback, useMemo } from "react";
// Typings
import {
  CalculatorProtectionsModuleSchema,
  UseCalculatorProtectionsModule,
} from "@components/tables/protections/hooks";
import { InputNodeFormat } from "@typings/input-node-format";
// Translations
import { lang } from "@lang/index";

export const useCalculatorProtectionModule: UseCalculatorProtectionsModule = (data) => {
  // Get protection calculator data
  const protectionCalculator = data?.protectionCalculator;

  // Define payload for submission to match with dto
  const submitPayload = useCallback<CalculatorProtectionsModuleSchema["submitPayload"]>(
    (updatedData) => {
      // Destructure array to assign values
      const [income, liabilities, availableFunds] = updatedData;

      return {
        income: {
          desiredIncome: Number(income.entries[0].field.value) || 0,
          numberOfYears: Number(income.entries[1].field.value),
          inflationAdjustedRateOfReturn: Number(income.entries[2].field.value),
        },
        liabilities: {
          mortgage: Number(liabilities.entries[0].field.value) || 0,
          debt: Number(liabilities.entries[1].field.value) || 0,
          dependantExpenses: Number(liabilities.entries[2].field.value) || 0,
          fundsForDependantsEducation: Number(liabilities.entries[3].field.value) || 0,
          finalExpenses: Number(liabilities.entries[4].field.value) || 0,
          others: Number(liabilities.entries[5].field.value) || 0,
        },
        availableFunds: {
          lifeInsurance: Number(availableFunds.entries[0].field.value) || 0,
          pensionFund: Number(availableFunds.entries[1].field.value) || 0,
          cashAssets: Number(availableFunds.entries[2].field.value) || 0,
          investments: Number(availableFunds.entries[3].field.value) || 0,
          others: Number(availableFunds.entries[4].field.value) || 0,
        },
      };
    },
    []
  );

  return useMemo(
    (): CalculatorProtectionsModuleSchema => ({
      tables: [
        // Income
        {
          title: lang("TABLE_TITLE_INCOME"),
          entries: [
            {
              label: lang("TABLE_LABEL_DESIRED_INCOME"),
              field: {
                type: InputNodeFormat.currency,
                value: String(protectionCalculator?.income.desiredIncome),
              },
            },
            {
              label: lang("TABLE_LABEL_NUMBER_OF_YEARS"),
              field: {
                type: InputNodeFormat.number,
                value: String(protectionCalculator?.income.numberOfYears),
                after: lang("TABLE_LABEL_YEARS"),
              },
            },
            {
              label: lang("TABLE_LABEL_INFLATION_ADJUSTED"),
              tooltip: lang("TOOLTIP_INFLATION_ADJUSTED"),
              field: {
                type: InputNodeFormat.percent,
                value: String(protectionCalculator?.income?.inflationAdjustedRateOfReturn),
              },
            },
          ],
          totals: [
            {
              label: lang("TABLE_LABEL_FUNDS_REQUIRED"),
              value: String(protectionCalculator?.totals.incomeRequiredFunds),
            },
          ],
        },

        // Liabilities
        {
          title: lang("TABLE_TITLE_LIABILITIES"),
          entries: [
            {
              label: lang("TABLE_LABEL_MORTGAGE"),
              field: {
                type: InputNodeFormat.currency,
                value: String(protectionCalculator?.liabilities.mortgage),
              },
            },
            {
              label: lang("TABLE_LABEL_DEBT"),
              field: {
                type: InputNodeFormat.currency,
                value: String(protectionCalculator?.liabilities.debt),
              },
            },
            {
              label: lang("TABLE_LABEL_DEPENDENT_EXPENSES"),
              field: {
                type: InputNodeFormat.currency,
                value: String(protectionCalculator?.liabilities.dependantExpenses),
              },
            },
            {
              label: lang("TABLE_LABEL_DEPENDENT_EDUCATION"),
              field: {
                type: InputNodeFormat.currency,
                value: String(protectionCalculator?.liabilities.fundsForDependantsEducation),
              },
            },
            {
              label: lang("TABLE_LABEL_FINAL_EXPENSES"),
              field: {
                type: InputNodeFormat.currency,
                value: String(protectionCalculator?.liabilities.finalExpenses),
              },
            },
            {
              label: lang("TABLE_LABEL_OTHERS"),
              field: {
                type: InputNodeFormat.currency,
                value: String(protectionCalculator?.liabilities.others),
              },
            },
          ],
          totals: [
            {
              label: lang("TABLE_LABEL_SETTLE_LIABILITIES"),
              value: String(protectionCalculator?.totals.fundsRequiredToSettleLiabilities),
            },
            {
              label: lang("TABLE_LABEL_TOTAL_FUNDS_REQUIRED"),
              value: String(protectionCalculator?.totals.totalFundsRequired),
            },
          ],
        },

        // Available Funds
        {
          title: lang("TABLE_TITLE_AVAILABLE_FUNDS"),
          entries: [
            {
              label: lang("TABLE_LABEL_LIFE_INSURANCE"),
              field: {
                type: InputNodeFormat.currency,
                value: String(protectionCalculator?.availableFunds.lifeInsurance),
              },
            },
            {
              label: lang("TABLE_LABEL_PENSION_FUND"),
              field: {
                type: InputNodeFormat.currency,
                value: String(protectionCalculator?.availableFunds.pensionFund),
              },
            },
            {
              label: lang("TABLE_LABEL_CASH_ASSETS"),
              field: {
                type: InputNodeFormat.currency,
                value: String(protectionCalculator?.availableFunds.cashAssets),
              },
            },
            {
              label: lang("TABLE_LABEL_INVESTMENTS"),
              field: {
                type: InputNodeFormat.currency,
                value: String(protectionCalculator?.availableFunds.investments),
              },
            },
            {
              label: lang("TABLE_LABEL_OTHERS"),
              field: {
                type: InputNodeFormat.currency,
                value: String(protectionCalculator?.availableFunds.others),
              },
            },
          ],
          totals: [
            {
              label: lang("TABLE_LABEL_TOTAL_FUNDS_AVAILABLE"),
              value: String(protectionCalculator?.totals.totalFundsAvailable),
            },
          ],
        },
      ],
      gap: protectionCalculator?.protectionGap || 0,
      fetching: !protectionCalculator,
      submitPayload,
    }),
    [submitPayload, protectionCalculator]
  );
};
