// Redux
import { Store, StoreState } from "@store/index";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import {
  ResponsePlanV2Dto,
  ResponseSgpAssumptionsDto,
  UpdatePlanAssumptionsRequest,
} from "@advicefront/plan-client-axios";
import { API } from "@services/api";
// Initial State
import { initialState } from "./initial-state";
// Types
import { StateProps } from "./types";
// Utils
import { getSubmitSuccessAction } from "@store/utils/submit-success";
// Translations
import { lang } from "@lang/index";

/**
 * Internal Actions
 * ---------------------------------------------------------------------
 */

const { submitSuccessAction } = getSubmitSuccessAction<StateProps>(
  "assumptions/set-submit-success-state"
);

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state
 * @example dispatch(Assumptions.reset())
 */

export const reset = createAction("assumptions/reset");

/**
 * Fetch Assumptions
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Assumptions.fetch(\{
 *  authToken,
 *  planId
 * \}));
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: ResponsePlanV2Dto["_id"];
  }>,
  { state: StoreState }
>("assumptions/fetch", async ({ authToken, planId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;
  let data: StateProps["data"] = initialState.data;

  try {
    // FIXME: forcing type because we're not using UK Assumptions at the moment
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const res = await API.Assumptions(authToken).findPlanAssumptions({ planId });

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    data = res.data as ResponseSgpAssumptionsDto;
  } catch (e) {
    error = API.parseError(e);
  }

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Assumptions
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Assumptions.update(\{
 *  authToken,
 *  planId,
 * updatedAssumptions
 * \}));
 */

export const update = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: ResponsePlanV2Dto["_id"];
    updatedAssumptions: UpdatePlanAssumptionsRequest;
  }>,
  { state: StoreState }
>("assumptions/update", async ({ authToken, planId, updatedAssumptions }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.Assumptions(authToken).updatePlanAssumptions({
      planId,
      updatePlanAssumptionsRequest: updatedAssumptions,
    });
  } catch (e) {
    error = API.parseError(e);
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_UPDATED", lang("NOTIFICATION_LABEL_ASSUMPTIONS")),
        description: "",
      })
    );

    // Refresh assumptions data (side effect)
    void dispatch(
      Store.assumptions.fetch({
        authToken: authToken,
        planId,
      })
    );

    // Updating assumptions changes the forecast through a worker on the BE so we need to open polling
    // Polling will automatically close when a the updated forecast is retrieved
    void dispatch(
      Store.forecast.poll({
        authToken,
        planId,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
  };
});
