// Styles
import "./styles/index.scss";
// React
import React, { useState } from "react";
// Router
import { Link } from "react-router-dom";
// Store
import { Store } from "@store/index";
// Typings
import { FormKeys } from "@typings/routing";
// Hooks
import { useModalRouting } from "@hooks/modal-routing";
// Translations
import { lang } from "@lang/index";
// Components
import { AfAlert } from "@advicefront/ds-alert";
import { AfTypography } from "@advicefront/ds-typography";
import { DialogCloseImportErrors } from "@components/dialogs/close-import-errors";

export const PlanDetailsImportErrors = (): React.ReactElement => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);

  // Route
  const { linkToOpenModal } = useModalRouting();

  // State to control import errors close dialog
  const [closeDialog, showCloseDialog] = useState(false);

  // Handle dismiss errors
  const handleDismissErrors = (): void => {
    if (!auth.authToken || !plan.data) return;

    void dispatch(
      Store.plan.dismissImportErrors({
        authToken: auth.authToken,
        planId: plan.data._id,
      })
    );
  };

  return (
    <>
      <AfAlert
        nativeProps={{ className: "plan-details-import-errors__alert" }}
        skin="warning"
        title={lang("PLAN_TITLE_IMPORT_ERRORS", plan.data?.importDetails?.inputErrors?.length)}
        description={
          <>
            {lang("PLAN_DESCRIPTION_IMPORT_ERRORS")}
            <AfTypography
              type="link"
              component={
                <Link
                  to={linkToOpenModal({
                    type: FormKeys.PlanImportErrors,
                  })}
                />
              }
            >
              {lang("ACTION_SEE_DETAILS")}
            </AfTypography>
          </>
        }
        onClose={(): void => showCloseDialog(true)}
      />

      {closeDialog && (
        <DialogCloseImportErrors
          handleCancel={(): void => showCloseDialog(false)}
          handleConfirm={handleDismissErrors}
        />
      )}
    </>
  );
};
