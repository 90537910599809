// React
import React, { useEffect, useState } from "react";
// Store
import { Store } from "@store/index";
// Components
import { AfAlert } from "@advicefront/ds-alert";
import { useSnackbar } from "@advicefront/ds-snackbar";

/**
 * Global Notifications
 * Manage notification rendering and remove using DS snackbar
 * with data from notification store
 */
export const useGlobalNotifications = (): void => {
  // Store
  const dispatch = Store.useDispatch();
  const notifications = Store.useSelector((state) => state.notifications);

  // State to track whether the component is mounted or not
  const [isMounted, setIsMounted] = useState(false);

  // Hook to display or remove notifications from DS library
  const { addSnackbar, removeSnackbar } = useSnackbar();

  // Add snackbar and remove after 8 seconds
  useEffect(() => {
    // Return if is not mounted or it doesn't have any notification
    if (!isMounted || !notifications) return;

    // Loop through each notification
    notifications.data.forEach((notification) => {
      // Add notification
      const id = addSnackbar((id) => (
        <AfAlert
          title={notification.title}
          description={notification.description}
          skin={notification.type}
          onClose={(): void => removeSnackbar(id)}
        />
      ));

      // Remove notification from array state after displayed
      void dispatch(Store.notifications.removeNotification(notification.id));

      // Remove notification after 8 seconds
      const timeout = setTimeout(() => removeSnackbar(id), 8000);

      // Clear timeout if notification was removed
      return () => clearTimeout(timeout);
    });
  }, [dispatch, notifications, isMounted, addSnackbar, removeSnackbar]);

  // Remove notification on custom hook unmount
  // This avoid duplicate snackbar when changing views that use this same hook
  useEffect(
    () => () => {
      // Stop if there is no notifications
      if (!notifications.data) return;

      // Loop through each notification and remove it from array state
      notifications.data.forEach((notification) => {
        void dispatch(Store.notifications.removeNotification(notification.id));
      });
    },
    [dispatch, notifications.data]
  );

  // Set component mounted on initial render
  useEffect(() => {
    setIsMounted(true);
  }, [isMounted]);
};
