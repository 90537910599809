// Styles
import "./styles/index.scss";
// React
import React, { useCallback, useEffect, useState } from "react";
// Store
import { Store } from "@store/index";
// Drag And Drop
import { Droppable, Draggable, DropResult, DragDropContext } from "@hello-pangea/dnd";
// Hooks
import { TableDraggableModuleEntry, useTableDraggable } from "@components/tables/draggable/hooks";
// Typings
import { TableDraggableModulesName } from "@typings/tables/draggable";
// Utils
import classNames from "classnames";
// Translations
import { lang } from "@lang/index";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfTooltip, AfTooltipRenderProps } from "@advicefront/ds-tooltip";
import { AfButton } from "@advicefront/ds-button";
import { AfCard } from "@advicefront/ds-card";
import { AfEmptyState } from "@advicefront/ds-empty-state";
import { AfIcon } from "@advicefront/ds-icon";
import { AfTable } from "@advicefront/ds-table";
import { AfTypography } from "@advicefront/ds-typography";
import { LoaderOverlaySpinner } from "@components/loaders/overlay-spinner";
import { LoaderSkeletonTable } from "@components/loaders/skeleton";

// Props
export interface TableDraggableProps {
  name: TableDraggableModulesName;
  handleSubmit: (payload: TableDraggableModuleEntry[]) => void;
}

export const TableDraggable = ({ name, handleSubmit }: TableDraggableProps): React.ReactElement => {
  // Store
  const plan = Store.useSelector((state) => state.plan);

  // Hook to get current table module data
  const { title, tooltip, head, entries, fetching, loading, error, empty } =
    useTableDraggable(name);

  // State to update table entries while editing
  const [tableEntries, setTableEntries] = useState<TableDraggableModuleEntry[]>([]);

  // State to enable or disable edit mode
  const [isEditing, setIsEditing] = useState(false);

  // Set table entries with initial values
  const setInitialValues = useCallback((): void => {
    // Stringify and parse to prevent referential equality
    setTableEntries(structuredClone(entries));
  }, [entries]);

  // Handle drag end
  const handleDragEnd = (result: DropResult): void => {
    // Stop if was dropped outside the list or to the same place
    if (!result.destination || result.source.index === result.destination.index) return;
    // Get source and destination index value
    const { index: sourceIndex } = result.source;
    const { index: destinationIndex } = result.destination;
    // Update table fields with new order
    setTableEntries((prevState) => {
      // Copy array to prevent a referential equality issue
      const newState = [...prevState];
      // Remove current item from source index
      const [currentItem] = newState.splice(sourceIndex, 1);
      // Insert the current item into the destination index
      newState.splice(destinationIndex, 0, currentItem);
      // Return the updated state
      return newState;
    });
  };

  // Handle cancel
  const handleCancel = useCallback((): void => {
    // Restore to initial values
    setInitialValues();
    // Close edit mode
    setIsEditing(false);
  }, [setInitialValues]);

  // Handle save changes
  const handleSaveChanges = useCallback((): void => {
    // Send table entries state to submit action
    handleSubmit(tableEntries);
    // Close edit mode
    setIsEditing(false);
  }, [handleSubmit, tableEntries]);

  // Restore to initial values if there was an error on submit
  useEffect(() => {
    if (!loading && error) {
      setInitialValues();
    }
  }, [setInitialValues, loading, error]);

  // Close edit mode if plan was moved to archived while editing
  useEffect(() => {
    if (isEditing && plan.data?.archived) {
      setIsEditing(false);
    }
  }, [isEditing, plan.data?.archived]);

  // Set table entries with initial values on init after loading
  useEffect(() => {
    if (!loading) {
      setInitialValues();
    }
  }, [setInitialValues, loading]);

  return (
    <>
      {fetching && <LoaderSkeletonTable rows={3} columns={3} size="xl" />}

      {!fetching && !entries.length && empty && (
        <AfCard padding="none">
          <AfEmptyState heading={empty.title}>{empty.description}</AfEmptyState>
        </AfCard>
      )}

      {!fetching && !!entries.length && (
        <LoaderOverlaySpinner active={loading}>
          <AfTable border={["wrapper", "row"]} size="s">
            <thead>
              <tr>
                <th align="left" colSpan={2} className="table-draggable__thead-col">
                  <AfTypography hasMargin={false} type="h3">
                    {title}
                    {tooltip && (
                      <AfTooltip
                        position="right-center"
                        render={(
                          props: AfTooltipRenderProps<HTMLSpanElement>
                        ): React.ReactElement => (
                          <span {...props} className="table-draggable__thead-tooltip">
                            <AfIcon
                              name="basic-info"
                              size="s"
                              nativeProps={{ className: "text-light" }}
                            />
                          </span>
                        )}
                      >
                        <div className="table-draggable__thead-tooltip-content">{tooltip}</div>
                      </AfTooltip>
                    )}
                  </AfTypography>
                </th>
              </tr>

              <tr>
                {head.map((label, colIndex) => (
                  <th
                    key={`head-col-${colIndex}`}
                    align={colIndex === 0 ? "left" : "right"}
                    className="table-draggable__thead-labels"
                  >
                    {label}
                  </th>
                ))}
              </tr>
            </thead>

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={`body-table-${title}`}>
                {(provided): React.ReactElement => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {tableEntries.map((entry, rowIndex) => (
                      <Draggable
                        index={rowIndex}
                        key={`body-row-${rowIndex}-${entry.id}`}
                        draggableId={`body-row-${rowIndex}-${entry.id}`}
                        isDragDisabled={!isEditing}
                      >
                        {(provided, snapshot): React.ReactElement => (
                          <tr
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className={classNames({
                              "table-draggable__tbody-row--is-dragging": snapshot.isDragging,
                            })}
                          >
                            <td align="left" className="table-draggable__tbody-col">
                              <AfRow align="center">
                                {isEditing && (
                                  <AfCol>
                                    <AfIcon
                                      name="basic-move"
                                      nativeProps={{ className: "text-primary" }}
                                    />
                                  </AfCol>
                                )}
                                <AfCol>
                                  <AfTypography hasMargin={false} type="body-bold">
                                    {entry.label}
                                  </AfTypography>
                                </AfCol>
                              </AfRow>
                            </td>

                            <td align="right" className="table-draggable__tbody-col">
                              {entry.value}
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>

            <tfoot>
              <tr>
                <td align="right" colSpan={2} className="table-draggable__tfoot-col">
                  {isEditing && (
                    <AfRow justify="end" align="center">
                      <AfCol>
                        <AfButton skin="secondary" onClick={handleCancel}>
                          {lang("ACTION_CANCEL")}
                        </AfButton>
                      </AfCol>
                      <AfCol>
                        <AfButton onClick={handleSaveChanges}>
                          {lang("ACTION_SAVE_CHANGES")}
                        </AfButton>
                      </AfCol>
                    </AfRow>
                  )}

                  {!isEditing && (
                    <AfButton
                      skin="secondary"
                      disabled={plan.data?.archived}
                      icon={<AfIcon name="content-edit" />}
                      onClick={(): void => setIsEditing(true)}
                    >
                      {lang("ACTION_EDIT_SECTION")}
                    </AfButton>
                  )}
                </td>
              </tr>
            </tfoot>
          </AfTable>
        </LoaderOverlaySpinner>
      )}
    </>
  );
};
