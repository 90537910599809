// React
import React, { useEffect } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { LayoutProps } from "@layouts/types";
// Layouts
import { PlanDetailsLayout } from "@layouts/plan-details";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { ProtectionsTabs } from "@layouts/protections/components/Tabs";

export const ProtectionLayout = ({ children }: LayoutProps): React.ReactElement => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);
  const calculators = Store.useSelector((state) => state.calculators);

  // Fetch calculators
  useEffect(() => {
    if (
      auth.authToken &&
      plan.data?._id &&
      !calculators.data &&
      !calculators.loading &&
      !calculators.error
    ) {
      void dispatch(
        Store.calculators.fetch({
          authToken: auth.authToken,
          planId: plan.data._id,
        })
      );
    }
  }, [
    dispatch,
    auth.authToken,
    plan.data?._id,
    calculators.data,
    calculators.loading,
    calculators.error,
  ]);

  return (
    <PlanDetailsLayout>
      <AfRow>
        <AfCol>
          <ProtectionsTabs />
        </AfCol>
        <AfCol size="auto">{children}</AfCol>
      </AfRow>
    </PlanDetailsLayout>
  );
};
