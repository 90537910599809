// Typings
import { FormModuleInputInstance } from "@forms/modules/types";
// Utils
import { CreateComponentInstance } from "@forms/index";
// Hooks
import {
  useDtoKey,
  useFormatters,
  useInitialValues,
  useInputRenderer,
  useOnChange,
  useSchema,
  useSchemaStructure,
  useOptionsList,
  useSubmit,
  useValidators,
} from "@forms/modules/input/hooks";

/**
 * Input Module
 */
export class InputFormModule implements FormModuleInputInstance {
  // React hook to fetch schema data
  public useSchema: FormModuleInputInstance["useSchema"] = useSchema;
  // React hook to set form fields custom structure
  public useSchemaStructure: FormModuleInputInstance["useSchemaStructure"] = useSchemaStructure;
  // React hook to get schema form key prefixed with region
  public useDtoKey: FormModuleInputInstance["useDtoKey"] = useDtoKey;
  // React hook to expose field keys to input types and styles
  public useInputRenderer: FormModuleInputInstance["useInputRenderer"] = useInputRenderer;
  // React hook to define a list of options for the value key field type
  public useOptionsList: FormModuleInputInstance["useOptionsList"] = useOptionsList;
  // React hook to set initial values (edit mode)
  public useInitialValues: FormModuleInputInstance["useInitialValues"] = useInitialValues;
  // React hook to expose field value formatters
  public useFormatters: FormModuleInputInstance["useFormatters"] = useFormatters;
  // React hook to expose field validation
  public useValidators: FormModuleInputInstance["useValidators"] = useValidators;
  // React hook to handle form fields on change
  public useOnChange: FormModuleInputInstance["useOnChange"] = useOnChange;
  // React hook to submit the data from the form
  public useSubmit: FormModuleInputInstance["useSubmit"] = useSubmit;
  // React component that renders the form fields
  public Component: FormModuleInputInstance["Component"] = CreateComponentInstance(this);
}
