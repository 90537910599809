// React
import React, { useCallback, useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { AccountLimitUpdateProps, InputWithAccountLimit } from "@store/modules/accounts";
import { TableAssumptionsModules } from "@typings/tables/assumptions";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { TableAssumptions, TableAssumptionsProps } from "@components/tables/assumptions";

export const AssumptionsAccountsTable = (): React.ReactElement => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);

  // Get accounts
  const { data, error, loading } = Store.useSelector((state) => state.accounts);

  // Format accounts limit data
  const accountsLimitData = useMemo(
    () =>
      // Not all inputs data include this type so need to be asserted
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      data?.map((inputData) => inputData as InputWithAccountLimit),
    [data]
  );

  // Handle submit
  const handleSubmit: TableAssumptionsProps["handleSubmit"] = useCallback(
    (payload) => {
      if (!auth.authToken || !plan.data) return;

      // Update accounts
      void dispatch(
        Store.accounts.update({
          authToken: auth.authToken,
          planId: plan.data._id,
          // Type assertion to ensure payload is of type AccountLimitUpdateProps
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          updateInputsDto: payload as AccountLimitUpdateProps[],
        })
      );
    },
    [dispatch, auth.authToken, plan.data]
  );

  return (
    <AfRow orientation="vertical">
      <AfCol>
        <TableAssumptions
          name={TableAssumptionsModules.Accounts}
          data={accountsLimitData}
          loading={!!loading}
          error={!!error}
          handleSubmit={handleSubmit}
        />
      </AfCol>
    </AfRow>
  );
};
