/**
 * Loaders
 * Naming convention: LOADER_TITLE_ELEMENT or LOADER_DESCRIPTION_ELEMENT
 */
export const loaders = {
  // Forecast
  LOADER_TITLE_CALCULATING: {
    en: "Calculating...",
  },
  LOADER_DESCRIPTION_CALCULATING: {
    en: "The cashflow is being generated with the latest updates",
  },
};
