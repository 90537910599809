/**
 * Table Labels
 * Naming convention: TABLE_LABEL_ELEMENT
 */
export const tableLabels = {
  // Plans
  TABLE_LABEL_CREATED: {
    en: "Created, {0}",
  },
  TABLE_LABEL_UPDATED: {
    en: "Updated, {0}",
  },
  TABLE_LABEL_ARCHIVED: {
    en: "Archived, {0}",
  },
  TABLE_LABEL_NET_BASIS: {
    en: "Net Basis",
  },
  TABLE_LABEL_GROSS_BASIS: {
    en: "Gross Basis",
  },

  // Events
  TABLE_LABEL_PLAN_START: {
    en: "Plan Start",
  },
  TABLE_LABEL_PLAN_END: {
    en: "Plan End",
  },
  TABLE_LABEL_RETIREMENT: {
    en: "{0}'s retirement",
  },

  // Cashflow
  TABLE_LABEL_AGE: {
    en: "Age {0}",
  },
  TABLE_LABEL_INFLOW_DETAILED: {
    en: "Inflow Detailed ({0})",
  },
  TABLE_LABEL_TOTAL_INFLOW: {
    en: "Total Inflow",
  },
  TABLE_LABEL_OUTFLOW_DETAILED: {
    en: "Outflow Detailed ({0})",
  },
  TABLE_LABEL_TOTAL_OUTFLOW: {
    en: "Total Outflow",
  },
  TABLE_LABEL_SURPLUS_SHORTFALL: {
    en: "Surplus / Shortfall",
  },

  // Assets & Liabilities
  TABLE_LABEL_ASSETS_DETAILED: {
    en: "Assets Detailed ({0})",
  },
  TABLE_LABEL_TOTAL_ASSETS: {
    en: "Total Assets",
  },
  TABLE_LABEL_LIABILITIES_DETAILED: {
    en: "Liabilities Detailed ({0})",
  },
  TABLE_LABEL_TOTAL_LIABILITIES: {
    en: "Total Liabilities",
  },
  TABLE_LABEL_NET_WORTH: {
    en: "Net Worth",
  },

  // Assumptions & Protections
  TABLE_LABEL_YEARS: {
    en: "years",
  },

  // Assumptions
  TABLE_LABEL_FEMALE_MORTALITY: {
    en: "Female Mortality",
  },
  TABLE_LABEL_MALE_MORTALITY: {
    en: "Male Mortality",
  },
  TABLE_LABEL_INFLATION: {
    en: "Inflation",
  },
  TABLE_LABEL_LIFETIME_ISA_LIMIT: {
    en: "Lifetime ISA Limit",
  },
  TABLE_LABEL_TOTAL_ISA_LIMIT: {
    en: "Total ISA Limit",
  },
  TABLE_LABEL_BASIC_RATE: {
    en: "Basic Rate",
  },
  TABLE_LABEL_HIGHER_RATE: {
    en: "Higher Rate",
  },
  TABLE_LABEL_ADDITIONAL_RATE: {
    en: "Additional Rate",
  },
  TABLE_LABEL_LOWER_EARNINGS: {
    en: "Lower Earnings",
  },
  TABLE_LABEL_UPPER_EARNINGS: {
    en: "Upper Earnings",
  },
  TABLE_LABEL_ADDITIONAL_PERCENTAGE: {
    en: "Additional %",
  },
  TABLE_LABEL_PROFITS_EXCEED: {
    en: "Profits Exceed",
  },
  TABLE_LABEL_SAVINGS_RATE_BAND: {
    en: "Savings Rate Band",
  },
  TABLE_LABEL_PERSONAL_BASIC_ALLOWANCE: {
    en: "Personal Basic Allowance",
  },
  TABLE_LABEL_PERSONAL_HIGHER_ALLOWANCE: {
    en: "Personal Higher Allowance",
  },
  TABLE_LABEL_DIVIDENDS_TAX_FREE: {
    en: "Dividends Tax Free",
  },
  TABLE_LABEL_RANGE: {
    en: "Range {0}",
  },
  TABLE_LABEL_CPF_CAP_SALARY: {
    en: "CPF Cap: Salary",
  },
  TABLE_LABEL_CPF_CAP_BONUS: {
    en: "CPF Cap: Bonus",
  },
  TABLE_LABEL_CPF_ORDINARY_GROWTH: {
    en: "CPF Ordinary Growth",
  },
  TABLE_LABEL_CPF_SPECIAL_GROWTH: {
    en: "CPF Special Growth",
  },
  TABLE_LABEL_CPF_MEDISAVE_GROWTH: {
    en: "CPF MediSave Growth",
  },
  TABLE_LABEL_CPF_CAP_GROWTH: {
    en: "CPF Cap Growth (Affects both caps)",
  },
  TABLE_LABEL_SRS_CAP_NATIONAL: {
    en: "SRS Cap: National",
  },
  TABLE_LABEL_SRS_CAP_FOREIGNER: {
    en: "SRS Cap: Foreigner",
  },
  TABLE_LABEL_ASSUMPTIONS_NOT_EDITABLE: {
    en: "These assumptions have been defined as not editable.",
  },

  // Protections
  TABLE_LABEL_DESIRED_INCOME: {
    en: "Desired income requires to cover family expenses",
  },
  TABLE_LABEL_NUMBER_OF_YEARS: {
    en: "Number of years required",
  },
  TABLE_LABEL_INFLATION_ADJUSTED: {
    en: "Inflation-adjusted rate of return for protection",
  },
  TABLE_LABEL_FUNDS_REQUIRED: {
    en: "Funds required to provide income",
  },
  TABLE_LABEL_MORTGAGE: {
    en: "Mortgage",
  },
  TABLE_LABEL_DEBT: {
    en: "Debt (loans, credit cards, overdrafts etc)",
  },
  TABLE_LABEL_DEPENDENT_EXPENSES: {
    en: "Dependent expenses",
  },
  TABLE_LABEL_DEPENDENT_EDUCATION: {
    en: "Funds for dependents education",
  },
  TABLE_LABEL_FINAL_EXPENSES: {
    en: "Final expenses",
  },
  TABLE_LABEL_OTHERS: {
    en: "Others",
  },
  TABLE_LABEL_SETTLE_LIABILITIES: {
    en: "Funds required to settle liabilities",
  },
  TABLE_LABEL_TOTAL_FUNDS_REQUIRED: {
    en: "Total Funds required",
  },
  TABLE_LABEL_LIFE_INSURANCE: {
    en: "Life Insurance (group, personal, etc)",
  },
  TABLE_LABEL_PENSION_FUND: {
    en: "Pension Fund",
  },
  TABLE_LABEL_CASH_ASSETS: {
    en: "Cash Assets (savings, fixed deposits, etc)",
  },
  TABLE_LABEL_INVESTMENTS: {
    en: "Investments (bonds, unit trust shares, etc)",
  },
  TABLE_LABEL_TOTAL_FUNDS_AVAILABLE: {
    en: "Total Funds available",
  },
  TABLE_LABEL_DESIRED_ANNUAL_INCOME: {
    en: "Desired annual income to sustain lifestyle",
  },
  TABLE_LABEL_LUMP_SUM: {
    en: "Lump sum required to maintain lifestyle",
  },
  TABLE_LABEL_EMERGENCY_MEDICAL: {
    en: "Emergency fund for medical",
  },
  TABLE_LABEL_TOTAL_DISABILITY_NEEDS: {
    en: "Total disability needs",
  },
  TABLE_LABEL_TOTAL_MAJOR_ILLNESS_NEEDS: {
    en: "Total major illness needs",
  },
  TABLE_LABEL_CURRENT_COVERAGE: {
    en: "Current Coverage",
  },
  TABLE_LABEL_DESIRED_INCOME_ILLNESS: {
    en: "Desired income required to sustain lifestyle during illness",
  },
  TABLE_LABEL_ESTIMATE_AMOUNT_ILLNESS: {
    en: "Estimate amount required for illness",
  },
  TABLE_LABEL_CALCULATING: {
    en: "Calculating...",
  },
};
