// React
import { useCallback, useMemo } from "react";
// Typings
import {
  TableAssumptionsModuleEntry,
  TableAssumptionsModuleSchema,
  UseTableAssumptionsModule,
} from "@components/tables/assumptions/hooks";
import { InputNodeFormat } from "@typings/input-node-format";
// Utils
import { isAccountsAssumptions, isSgpAssumptions } from "@utils/type-guards/assumptions";
import { isEqual } from "@utils/is-equal";
// Translations
import { lang } from "@lang/index";

export const useTableCpfEmployeeEmployerModule: UseTableAssumptionsModule = ({
  name,
  data,
  firmPreferences,
}) => {
  // Throw error if data is not valid for CpfEmployeeEmployer module
  if (data && (isAccountsAssumptions(data) || !isSgpAssumptions(data))) {
    throw new Error(`${name} data not valid`);
  }

  // Define firm data as SGP region
  const firmData = isSgpAssumptions(firmPreferences.data) ? firmPreferences.data : undefined;

  // Get age limit to be displayed on preview mode
  const getAgeLimit = useCallback(
    (age: number, index: number): string | undefined => {
      // Return if no data
      if (!data) return;
      // Get contribution caps data
      const { contributionAllocation } = data.contributionCaps.cpf;
      // Get age label based on index
      switch (index) {
        case contributionAllocation.length - 1:
          return `${age}+`;
        default:
          return `${age} - ${contributionAllocation[index + 1].age - 1}`;
      }
    },
    [data]
  );

  // Define payload for submission to match with dto
  const submitPayload = useCallback<TableAssumptionsModuleSchema["submitPayload"]>(
    (updatedData) => ({
      contributionCaps: {
        cpf: {
          contributionAllocation: updatedData.map((row) => ({
            age: Number(row.fields[0].value),
            employer: Number(row.fields[1].value),
            employee: Number(row.fields[2].value),
          })),
        },
      },
    }),
    []
  );

  // Return table assumptions data object
  return useMemo(
    (): TableAssumptionsModuleSchema => ({
      title: lang("TABLE_TITLE_CPF_EMPLOYEE_EMPLOYER_CONTRIBUTIONS"),
      head: [
        {
          label: lang("TABLE_COLUMN_AGE_RANGES"),
          align: "left",
        },
        {
          label: lang("TABLE_COLUMN_AGE_LIMITS"),
          align: "right",
        },
        {
          label: lang("TABLE_COLUMN_EMPLOYER"),
          align: "right",
        },
        {
          label: lang("TABLE_COLUMN_EMPLOYEE"),
          align: "right",
        },
      ],
      entries: (data?.contributionCaps.cpf.contributionAllocation || []).map(
        (values, index): TableAssumptionsModuleEntry => ({
          label: lang("TABLE_LABEL_RANGE", index + 1),
          fields: [
            {
              type: InputNodeFormat.number,
              value: values.age,
              after: lang("TABLE_LABEL_YEARS"),
              preview: getAgeLimit(values.age, index),
            },
            {
              type: InputNodeFormat.percent,
              value: values.employer,
            },
            {
              type: InputNodeFormat.percent,
              value: values.employee,
            },
          ],
        })
      ),
      customise: {
        checked: !!data?.settings.cpfAllocation,
        payload: {
          settings: {
            cpfAllocation: !firmData?.settings.cpfAllocation,
          },
        },
      },
      reset: {
        allowed: !isEqual(
          data?.contributionCaps.cpf.contributionAllocation,
          firmData?.contributionCaps.cpf.contributionAllocation
        ),
        payload: {
          contributionCaps: {
            cpf: {
              contributionAllocation: firmData?.contributionCaps.cpf.contributionAllocation,
            },
          },
        },
      },
      submitPayload,
    }),
    [
      getAgeLimit,
      submitPayload,
      data?.settings.cpfAllocation,
      data?.contributionCaps.cpf.contributionAllocation,
      firmData?.settings.cpfAllocation,
      firmData?.contributionCaps.cpf.contributionAllocation,
    ]
  );
};
