// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Utils
import { getSubmitSuccessAction } from "@store/utils/submit-success";
// Actions
import { reset, fetch, createClient, createDependent, updatePerson, deletePerson } from "./actions";

const { applySubmitSuccessCases } = getSubmitSuccessAction<StateProps>(
  "accounts/set-submit-success-state"
);

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  applySubmitSuccessCases(builder);

  builder
    // Reset to initial data state
    .addCase(reset, () => initialState)
    // Get + Create client + Create dependend + Update + Delete fulfilled
    .addMatcher(
      isAnyOf(
        fetch.fulfilled,
        createClient.fulfilled,
        createDependent.fulfilled,
        updatePerson.fulfilled,
        deletePerson.fulfilled
      ),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    // Loading start
    .addMatcher(
      isAnyOf(
        fetch.pending,
        createClient.pending,
        createDependent.pending,
        updatePerson.pending,
        deletePerson.pending
      ),
      (state) => ({
        ...state,
        loading: state.loading + 1,
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        fetch.fulfilled,
        fetch.rejected,
        createClient.fulfilled,
        createClient.rejected,
        createDependent.fulfilled,
        createDependent.rejected,
        updatePerson.fulfilled,
        updatePerson.rejected,
        deletePerson.fulfilled,
        deletePerson.rejected
      ),
      (state) => ({
        ...state,
        loading: state.loading - 1,
      })
    );
});
