// React
import { useMemo } from "react";
// Typings
import { AfSelectOptionProps, AfSelectSectionProps } from "@advicefront/ds-select";
import { SelectionFieldOptions } from "@advicefront/fe-infra-form-schema";
// Constants
import { EMPTY_OPTION, SELECT_SECTION_TITLE } from "@constants/index";
// Utils
import { isEqual } from "@utils/is-equal";

// Props
type FieldSelectOptionsOutput = AfSelectOptionProps[] | AfSelectSectionProps[];

/**
 * Use Field Select Options
 * A custom react hook that formats an array of selection
 * field options into an array of sections with options
 * If no option with label SELECT_SECTION_TITLE is found
 * it will return the current options array
 * @param options - an array of selection field options
 * @returns an array of sections with options, formatted for use with the DS Select component
 */
export const useFieldSelectOptions = (options: SelectionFieldOptions): FieldSelectOptionsOutput =>
  useMemo((): FieldSelectOptionsOutput => {
    // Returns the current options if there is no option with label SELECT_SECTION_TITLE
    if (!options.find((option) => option.label === SELECT_SECTION_TITLE)) {
      return options;
    }

    // Initialize sections array
    const sections: AfSelectSectionProps[] = [];

    // Loop through each option in the options array
    options.map((option) => {
      // If the option has the label SELECT_SECTION_TITLE,
      // create a new section in the sections array with the option
      // value as the section title and an empty options array
      if (option.label === SELECT_SECTION_TITLE) {
        return sections.push({
          title: option.value,
          options: [],
        });
      }

      // If the option is an empty option, add it without a section title
      // This is used for non-required fields
      if (isEqual(option, EMPTY_OPTION)) {
        return sections.push({
          options: [option],
        });
      }

      // If the option label is not a SELECT_SECTION_TITLE,
      // get the current section from the end of the sections array
      const currentSection = sections.at(-1);

      // If the current section doesn't exist, stop the loop
      if (!currentSection) return;

      // Add the option to the current section's options array
      return (currentSection.options = [...currentSection.options, option]);
    });

    // Return the formatted options array
    return sections;
  }, [options]);
