// Store
import { Store, StoreState } from "@store/index";
import { selectByClientType } from "./client-type";
// Typings
import { ClientType } from "@advicefront/plan-client-axios";
// Utils
import { getClients, getClientsNames } from "@utils/get-clients";
// Translations
import { lang } from "@lang/index";

// Props
interface PlanEventsEntry {
  id: string | undefined;
  label: string;
  names: string;
  ages: string | number;
  ownerAge: number;
  partnerAge: number | undefined;
  year: number;
  period: string | number; // Index of plan years
}

interface PlanEventsOutput {
  data: PlanEventsEntry[];
  loading: boolean;
}

export const selectPlanEvents = Store.createSelector(
  () =>
    ({ inputs, plan, people, forecast }: StoreState): PlanEventsOutput => {
      // Store
      const owner = Store.useSelector(selectByClientType(ClientType.Owner));
      const partner = Store.useSelector(selectByClientType(ClientType.Partner));

      // Loading state
      const loading = !!inputs.loading || !!plan.loading || !!people.loading || !!forecast.loading;

      // Return empty data if there's no enough data to compute events
      if (!plan.data || !forecast.data || !owner) {
        return {
          loading,
          data: [],
        };
      }

      // Define start year plan
      const planStartYear = plan.data.startYear;

      // Plan Start
      const planStart: PlanEventsEntry = {
        id: undefined,
        label: lang("TABLE_LABEL_PLAN_START"),
        names: owner.firstName,
        ages: Number(forecast.data.clients.owner.ageRange.at(0)),
        ownerAge: Number(forecast.data.clients.owner.ageRange.at(0)),
        partnerAge: forecast.data.clients.partner?.ageRange.at(0),
        year: planStartYear,
        period: 0,
      };

      // Plan End
      const planEnd: PlanEventsEntry = {
        id: undefined,
        label: lang("TABLE_LABEL_PLAN_END"),
        names: owner.firstName,
        ages: Number(forecast.data.clients.owner.ageRange.at(-1)),
        ownerAge: Number(forecast.data.clients.owner.ageRange.at(-1)),
        partnerAge: forecast.data.clients.partner?.ageRange.at(-1),
        year: Number(forecast.data.years.at(-1)),
        period: forecast.data.years.length - 1,
      };

      // Retirements
      const retirements: PlanEventsEntry[] =
        people.data?.clients
          .filter((client) => client.retirementAge)
          .map((client): PlanEventsEntry => {
            const retirementAge = Number(client.retirementAge);
            const period = client.yearOfBirth + retirementAge - planStartYear;

            return {
              id: undefined,
              label: lang("TABLE_LABEL_RETIREMENT", client.firstName),
              names: client.firstName,
              ages: retirementAge,
              ownerAge: planStartYear - owner.yearOfBirth + period,
              partnerAge: partner ? planStartYear - partner.yearOfBirth + period : undefined,
              year: client.yearOfBirth + retirementAge,
              period,
            };
          }) || [];

      // Events
      const events: PlanEventsEntry[] =
        inputs.data?.event.map(
          (event): PlanEventsEntry => ({
            id: event._id,
            label: event.label,
            names: getClientsNames(people.data?.clients, event.ownership),
            ages: getClients(people.data?.clients, event.ownership)
              .map((owner) => planStartYear - owner.yearOfBirth + Number(event.startPeriod))
              .join(" / "),
            ownerAge: planStartYear - owner.yearOfBirth + Number(event.startPeriod),
            partnerAge: partner
              ? planStartYear - partner.yearOfBirth + Number(event.startPeriod)
              : undefined,
            year: planStartYear + Number(event.startPeriod),
            period: event.startPeriod,
          })
        ) || [];

      // Sort data by year
      const data = [planStart, ...retirements, ...events, planEnd].sort((a, b) =>
        a.year >= b.year ? 1 : -1
      );

      return {
        data,
        loading,
      };
    }
);
