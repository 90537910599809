// React
import React, { useEffect } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { LayoutProps } from "@layouts/types";
// Hooks
import { useResetPlanData } from "@hooks/reset-plan-data";
// Layouts
import { AuthProtectedLayout } from "@layouts/AuthProtected";
import { DefaultLayout } from "@layouts/Default";
// Components
import { PlansListHeader } from "@layouts/plans-list/components/header";

export const PlansListLayout = ({ children }: LayoutProps): React.ReactElement => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plans = Store.useSelector((state) => state.plans);
  const preferences = Store.useSelector((state) => state.preferences);

  // Fetch Plans
  useEffect(() => {
    if (auth.authToken && auth.clientGroupId && !plans.data && !plans.loading && !plans.error) {
      void dispatch(
        Store.plans.fetch({
          authToken: auth.authToken,
          clientGroupId: auth.clientGroupId,
        })
      );
    }
  }, [dispatch, auth, plans.data, plans.loading, plans.error]);

  // Fetch Preferences
  // Used on create plan modal to validate owner's retirement age
  useEffect(() => {
    if (auth.authToken && !preferences.data && !preferences.loading && !preferences.error) {
      void dispatch(
        Store.preferences.fetch({
          authToken: auth.authToken,
        })
      );
    }
  }, [dispatch, auth.authToken, preferences.data, preferences.loading, preferences.error]);

  // Handle reset plan data when navigating to plans list
  useResetPlanData();

  return (
    <AuthProtectedLayout>
      <DefaultLayout>
        <PlansListHeader hasPlans={!!plans.data?.length} />
        {children}
      </DefaultLayout>
    </AuthProtectedLayout>
  );
};
