// React
import { useCallback, useMemo } from "react";
// Typings
import {
  TableAssumptionsModuleEntry,
  TableAssumptionsModuleSchema,
  UseTableAssumptionsModule,
} from "@components/tables/assumptions/hooks";
import { InputNodeFormat } from "@typings/input-node-format";
// Constants
import { EMPTY_VALUE } from "@constants/index";
// Utils
import { isAccountsAssumptions } from "@utils/type-guards/assumptions";
// Translations
import { lang } from "@lang/index";

export const useTableAccountsModule: UseTableAssumptionsModule = ({ name, data }) => {
  // Throw error if data is not valid for AccountsLimit module
  if (data && !isAccountsAssumptions(data)) {
    throw new Error(`${name} data not valid`);
  }

  // Define payload for submission to match with dto
  const submitPayload = useCallback<TableAssumptionsModuleSchema["submitPayload"]>(
    (updatedData) =>
      updatedData.map((row, index) => {
        // Destructure array to assign values
        const [maxWithdrawal, minBalance, availabilityAge, adjustWithdrawal, adjustBalance] =
          row.fields;

        return {
          _id: data?.[index]?._id || "",
          _planId: data?.[index]?.plan || "",
          accountLimit: {
            maxWithdrawal: maxWithdrawal.value ? String(maxWithdrawal.value) : null,
            minBalance: minBalance.value ? String(minBalance.value) : null,
            availabilityAge: availabilityAge.value ? String(availabilityAge.value) : null,
            adjustWithdrawalWithInflation: !!adjustWithdrawal.value,
            adjustBalanceWithInflation: !!adjustBalance.value,
          },
        };
      }),
    [data]
  );

  // Return table assumptions data object
  return useMemo(
    (): TableAssumptionsModuleSchema => ({
      title: lang("TABLE_TITLE_ACCOUNT_LIMITS"),
      head: [
        {
          label: lang("TABLE_COLUMN_ACCOUNT"),
          align: "left",
        },
        {
          label: lang("TABLE_COLUMN_MAX_WITHDRAWAL"),
          align: "right",
        },
        {
          label: lang("TABLE_COLUMN_MIN_ACCOUNT_BALANCE"),
          align: "right",
        },
        {
          label: lang("TABLE_COLUMN_AVAILABLE_FROM"),
          align: "right",
        },
        {
          label: lang("TABLE_COLUMN_ADJUST_WITHDRAWAL_INFLATION"),
          align: "center",
        },
        {
          label: lang("TABLE_COLUMN_ADJUST_BALANCE_INFLATION"),
          align: "center",
        },
      ],
      entries: (data || []).map(
        (values): TableAssumptionsModuleEntry => ({
          label: values.label,
          fields: [
            {
              type: InputNodeFormat.currency,
              value: values.accountLimit?.maxWithdrawal,
              ...(!values.accountLimit?.maxWithdrawal && { preview: EMPTY_VALUE }),
            },
            {
              type: InputNodeFormat.currency,
              value: values.accountLimit?.minBalance,
              ...(!values.accountLimit?.minBalance && { preview: EMPTY_VALUE }),
            },
            {
              type: InputNodeFormat.number,
              value: values.accountLimit?.availabilityAge || "",
              after: lang("TABLE_LABEL_YEARS"),
              ...(!values.accountLimit?.availabilityAge && { preview: EMPTY_VALUE }),
            },
            {
              type: InputNodeFormat.boolean,
              value: !!values.accountLimit?.adjustWithdrawalWithInflation,
            },
            {
              type: InputNodeFormat.boolean,
              value: !!values.accountLimit?.adjustBalanceWithInflation,
            },
          ],
        })
      ),
      customise: {
        // Set to true since it's not customisable on firm preferences
        checked: true,
      },
      empty: {
        title: lang("EMPTY_TITLE_ACCOUNT_LIMITS"),
        description: lang("EMPTY_DESCRIPTION_ACCOUNT_LIMITS"),
      },
      submitPayload,
    }),
    [data, submitPayload]
  );
};
