// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import {
  ChartTimelineModuleSchema,
  UseChartTimelineModule,
} from "@components/charts/timeline/hooks";
// Translations
import { lang } from "@lang/index";

// Props
interface ChartEvents {
  name: string;
  period: number | undefined;
}

export const useChartEventsModule: UseChartTimelineModule = () => {
  // Store
  const forecast = Store.useSelector((state) => state.forecast);
  const inputs = Store.useSelector((state) => state.inputs);

  // Define plan, clients and inputs chart events
  const chartEvents = useMemo(
    (): ChartEvents[] => [
      // Plan: Start & End
      {
        name: lang("CHART_PLAN_START"),
        period: 0,
      },
      {
        name: lang("CHART_PLAN_END"),
        period: forecast.data?.years && forecast.data.years.length - 1,
      },

      // Retirement: Owner and Partner
      {
        name: lang("CHART_RETIREMENT", forecast.data?.clients.owner.name?.split(" ")[0]),
        period: forecast.data?.clients.owner.retirementPeriod || undefined,
      },
      {
        name: lang("CHART_RETIREMENT", forecast.data?.clients.partner?.name?.split(" ")[0]),
        period: forecast.data?.clients.partner?.retirementPeriod || undefined,
      },

      // Inputs
      ...(inputs.data?.event.map((entry) => ({
        name: entry.label,
        period: Number(entry.startPeriod),
      })) || []),
    ],
    [forecast.data?.years, forecast.data?.clients, inputs.data?.event]
  );

  // Define chart data for highcharts
  const chartData = useMemo(
    (): ChartTimelineModuleSchema["data"] =>
      chartEvents
        .filter((entry) => entry.period !== undefined)
        .map((entry) => {
          // Filter events within a range of 10 years before and after the current entry period
          // This will ensure that all events will be displayed without overlapping
          const eventsNear = chartEvents.filter(
            ({ period }) =>
              Number(period) >= Number(entry.period) - 10 &&
              Number(period) <= Number(entry.period) + 10
          );
          // Return entry formatted for highchart
          return {
            name: entry.name,
            x: entry.period,
            y: eventsNear.indexOf(entry) + 1,
          };
        })
        .sort((a, b) => Number(a.x) - Number(b.x)),
    [chartEvents]
  );

  // Return chart data object
  return useMemo(
    (): ChartTimelineModuleSchema => ({
      height: 150 + 25 * Math.max(...(chartData || []).map(({ y }) => Number(y))),
      categories: {
        title: lang("CHART_YEARS"),
        values: forecast.data?.years.map((year) => year.toString()),
      },
      subCategories: {
        titles: [
          forecast.data?.clients.owner.name?.split(" ")[0],
          forecast.data?.clients.partner?.name?.split(" ")[0],
        ],
        values: [
          forecast.data?.clients.owner.ageRange?.map((value) => value.toString()),
          forecast.data?.clients.partner?.ageRange?.map((value) => value.toString()),
        ],
      },
      data: chartData,
    }),
    [chartData, forecast.data?.years, forecast.data?.clients]
  );
};
