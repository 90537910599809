// Store
import { StoreState } from "@store/index";
// Typings
import {
  AssumptionTypes,
  ResponseGbrAssumptionsDto,
  ResponseSgpAssumptionsDto,
} from "@advicefront/plan-client-axios";
import { InputWithAccountLimit } from "@store/modules/accounts";
import { TableAssumptionsModulesData } from "@typings/tables/assumptions";

/**
 * Is SGP Assumptions
 * Type Guard for Sgp Assumptions type
 * @param data - data to check
 * @returns boolean - true if its Sgp Assumptions type
 */
export function isSgpAssumptions(
  data: StoreState["assumptions"]["data"]
): data is ResponseSgpAssumptionsDto {
  return data?.__t === AssumptionTypes.SgpAssumptions;
}

/**
 * Is GBR Assumptions
 * Type Guard for Gbr Assumptions type
 * @param data - data to check
 * @returns boolean - true if its Gbr Assumptions type
 */
export function isGbrAssumptions(
  data: StoreState["assumptions"]["data"]
): data is ResponseGbrAssumptionsDto {
  return data?.__t === AssumptionTypes.GbrAssumptions;
}

/**
 * Is Accounts Assumptions
 * Type Guard for Accounts Limits Assumptions type
 * @param data - data to check
 * @returns boolean - true if its Accounts Limits Assumptions type
 */
export function isAccountsAssumptions(
  data: TableAssumptionsModulesData
): data is InputWithAccountLimit[] {
  return (
    Array.isArray(data) && data.every((input): input is InputWithAccountLimit => "__bt" in input)
  );
}
