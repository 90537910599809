// React
import { useCallback, useMemo } from "react";
// Typings
import {
  CalculatorProtectionsModuleSchema,
  UseCalculatorProtectionsModule,
} from "@components/tables/protections/hooks";
import { InputNodeFormat } from "@typings/input-node-format";
// Translations
import { lang } from "@lang/index";

export const useCalculatorTpdModule: UseCalculatorProtectionsModule = (data) => {
  // Get TPD calculator data
  const tpdCalculator = data?.tpdCalculator;

  // Define payload for submission to match with dto
  const submitPayload = useCallback<CalculatorProtectionsModuleSchema["submitPayload"]>(
    (updatedData) => {
      // Destructure array to assign values
      const [disabilityNeeds, coverageAndAssets] = updatedData;

      return {
        disabilityNeeds: {
          desiredIncome: Number(disabilityNeeds.entries[0].field.value) || 0,
          numberOfYears: Number(disabilityNeeds.entries[1].field.value),
          inflationAdjustedRateOfReturn: Number(disabilityNeeds.entries[2].field.value),
          medicalEmergencyFund: Number(disabilityNeeds.entries[3].field.value) || 0,
        },
        coverageAndAssets: {
          coverage: Number(coverageAndAssets.entries[0].field.value) || 0,
          cashAssets: Number(coverageAndAssets.entries[1].field.value) || 0,
          investments: Number(coverageAndAssets.entries[2].field.value) || 0,
        },
      };
    },
    []
  );

  return useMemo(
    (): CalculatorProtectionsModuleSchema => ({
      tables: [
        // Disability Needs
        {
          title: lang("TABLE_TITLE_DISABILITY_NEEDS"),
          entries: [
            {
              label: lang("TABLE_LABEL_DESIRED_ANNUAL_INCOME"),
              field: {
                type: InputNodeFormat.currency,
                value: String(tpdCalculator?.disabilityNeeds.desiredIncome),
              },
            },
            {
              label: lang("TABLE_LABEL_NUMBER_OF_YEARS"),
              field: {
                type: InputNodeFormat.number,
                value: String(tpdCalculator?.disabilityNeeds.numberOfYears),
                after: lang("TABLE_LABEL_YEARS"),
              },
            },
            {
              label: lang("TABLE_LABEL_INFLATION_ADJUSTED"),
              tooltip: lang("TOOLTIP_INFLATION_ADJUSTED"),
              field: {
                type: InputNodeFormat.percent,
                value: String(tpdCalculator?.disabilityNeeds.inflationAdjustedRateOfReturn),
              },
            },
            {
              label: lang("TABLE_LABEL_EMERGENCY_MEDICAL"),
              field: {
                type: InputNodeFormat.currency,
                value: String(tpdCalculator?.disabilityNeeds.medicalEmergencyFund),
              },
            },
          ],
          totals: [
            {
              label: lang("TABLE_LABEL_LUMP_SUM"),
              value: String(tpdCalculator?.totals.lumpSum),
            },
            {
              label: lang("TABLE_LABEL_TOTAL_DISABILITY_NEEDS"),
              value: String(tpdCalculator?.totals.totalDisabilityNeeds),
            },
          ],
        },

        // Current Coverage & Assets
        {
          title: lang("TABLE_TITLE_CURRENT_COVERAGE_AND_ASSETS"),
          entries: [
            {
              label: lang("TABLE_LABEL_CURRENT_COVERAGE"),
              field: {
                type: InputNodeFormat.currency,
                value: String(tpdCalculator?.coverageAndAssets.coverage),
              },
            },
            {
              label: lang("TABLE_LABEL_CASH_ASSETS"),
              field: {
                type: InputNodeFormat.currency,
                value: String(tpdCalculator?.coverageAndAssets.cashAssets),
              },
            },
            {
              label: lang("TABLE_LABEL_INVESTMENTS"),
              field: {
                type: InputNodeFormat.currency,
                value: String(tpdCalculator?.coverageAndAssets.investments),
              },
            },
          ],
          totals: [
            {
              label: lang("TABLE_LABEL_TOTAL_ASSETS"),
              value: String(tpdCalculator?.totals.totalAssets),
            },
          ],
        },
      ],
      gap: tpdCalculator?.protectionGap || 0,
      fetching: !tpdCalculator,
      submitPayload,
    }),
    [submitPayload, tpdCalculator]
  );
};
