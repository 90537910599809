// Enum defining all supported basic tables modules
// Each module is represented by a unique key and a corresponding string value
export enum TableBasicModules {
  Events = "Events",
  Household = "Household",
  Income = "Income",
  Expense = "Expense",
  SavingsAndInvestments = "SavingsAndInvestments",
  PropertyAndAssets = "PropertyAndAssets",
  Pension = "Pension",
  Debt = "Debt",
}

// Type alias for the keys of the TableBasicModules enum
// This is used to ensure type safety when working with basic tables in the codebase
export type TableBasicModulesName = keyof typeof TableBasicModules;
