export enum Colors {
  // Charts
  Olenna = "#B291FF",
  Ellaria = "#FFD080",
  Lyanna = "#65D1CA",
  Cersei = "#F291C0",
  Brienne = "#79CEF2",
  Nymeria = "#FFA680",
  Mango = "#FFB580",
  Apricot = "#FFC480",
  BreadNButter = "#FFD280",
  JuneDay = "#FFE180",
  Margaery = "#D7E27D",
  Yara = "#A2B6E3",

  // Design System
  Paper = "#FFFFFF",
  Background = "#F2F3F4",
  Border = "#E2E3E7",
  TextInactive = "#C5C8CF",
  TextLight = "#868D9C",
  Text = "#3D404A",
  Primary = "#705EE0",
  Danger = "#E47474",
  Success = "#5ED79D",
}
