// React
import React, { useState, useEffect, useMemo } from "react";
// Router
import { useLocation, useNavigate, generatePath } from "react-router-dom";
import { getRoute } from "@routes/utils/get-route";
import { useModalRouting } from "@hooks/modal-routing";
// Store
import { Store, StoreState } from "@store/index";
// Typings
import { FormActions } from "@typings/routing";
// Translations
import { lang } from "@lang/index";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfButton } from "@advicefront/ds-button";
import { AfModal } from "@advicefront/ds-modal";
import { AfTextField } from "@advicefront/ds-text-field";
import { LoaderOverlaySpinner } from "@components/loaders/overlay-spinner";
import { LoaderSkeletonModal } from "@components/loaders/skeleton";

// Props
interface ModalPlanNameProps {
  id: NonNullable<StoreState["plan"]["data"]>["_id"];
  action: Exclude<FormActions, FormActions.create | FormActions.delete>;
}

// Form id for submit button
const FORM_ID = "ModalPlanName";

export const ModalPlanName = ({ id, action }: ModalPlanNameProps): React.ReactElement => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);
  const plans = Store.useSelector((state) => state.plans);

  // Route
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { closeModal } = useModalRouting();

  // Check if is active plans list route
  const isPlansListRoute = useMemo(() => pathname.startsWith(getRoute("plansList")), [pathname]);

  // Check if is currently loading plans or if plan has already data
  const isLoading = useMemo(
    (): boolean => (isPlansListRoute ? !!plans.loading : !plan.data),
    [isPlansListRoute, plans.loading, plan.data]
  );

  // State to handle plan name field
  const [planName, setPlanName] = useState("");

  // Get current plan from plans list or plan details
  const currentPlan = useMemo(
    (): StoreState["plan"]["data"] =>
      isPlansListRoute ? plans.data?.find((plan) => plan._id === id) : plan.data,
    [isPlansListRoute, plans.data, plan.data, id]
  );

  // Set modal content for edit and duplicate plan form
  const modalContent = useMemo(
    () => ({
      // Edit plan name
      [FormActions.edit]: {
        title: lang("ACTION_UPDATE_PLAN_NAME"),
        submitLabel: lang("ACTION_SAVE_CHANGES"),
        handleSubmit: (): void => {
          if (!auth.clientGroupId) return;

          void dispatch(
            Store.plan.updatePlan({
              authToken: auth.authToken,
              clientGroupId: auth.clientGroupId,
              planId: id,
              archived: currentPlan?.archived,
              updatedPlanData: {
                name: planName,
              },
              options: {
                isPlansListRoute: !plan.data?._id,
              },
            })
          );
        },
      },

      // Duplicate plan
      [FormActions.duplicate]: {
        title: lang("ACTION_DUPLICATE_PLAN"),
        submitLabel: lang("ACTION_DUPLICATE"),
        handleSubmit: (): void =>
          void dispatch(
            Store.plan.duplicatePlan({
              authToken: auth.authToken,
              planId: id,
              planName: {
                name: planName,
              },
            })
          ),
      },
    }),
    [dispatch, id, auth, planName, currentPlan?.archived, plan.data?._id]
  );

  // Set initial plan name field for edit plan form
  useEffect(() => {
    if (action === FormActions.edit && !!currentPlan?.name) {
      setPlanName(currentPlan.name);
    }
  }, [action, currentPlan?.name]);

  // Handle submit success side effects
  useEffect(() => {
    if (plan.submitSuccess) {
      switch (action) {
        // For duplicate action redirect automatically to plan
        case FormActions.duplicate:
          plan.duplicatedPlan &&
            navigate(generatePath(getRoute("overview"), { id: plan.duplicatedPlan.newPlanId }));
          break;

        // For edit action close modal
        default:
          closeModal();
      }
    }
  }, [navigate, action, plan, closeModal]);

  return (
    <AfModal closeOnClickOutside onClose={closeModal}>
      <AfModal.Header heading={modalContent[action].title} />
      <AfModal.Content
        scrollableContainerProps={{
          variation: ["vertical"],
        }}
      >
        {isLoading && <LoaderSkeletonModal />}

        {!isLoading && (
          <LoaderOverlaySpinner active={!!plan.loading} bordered={false}>
            <form
              id={FORM_ID}
              onSubmit={(ev): void => {
                ev.preventDefault();
                modalContent[action].handleSubmit();
              }}
            >
              <AfRow wrap>
                <AfCol size={12}>
                  <AfTextField
                    required
                    label={lang("FORM_LABEL_NAME")}
                    placeholder={lang("FORM_PLACEHOLDER_NAME")}
                    value={planName}
                    onChange={(ev): void => setPlanName(ev.target.value)}
                  />
                </AfCol>
              </AfRow>
            </form>
          </LoaderOverlaySpinner>
        )}
      </AfModal.Content>
      <AfModal.Footer
        actions={[
          <AfButton key="cancel" skin="ghost" onClick={closeModal}>
            {lang("ACTION_CANCEL")}
          </AfButton>,
          <AfButton key="submit" skin="primary" type="submit" form={FORM_ID}>
            {modalContent[action].submitLabel}
          </AfButton>,
        ]}
      />
    </AfModal>
  );
};
