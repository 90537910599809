// React
import React, { Component, ErrorInfo, ReactNode } from "react";
// App Signal
import Appsignal from "@appsignal/javascript";

// Props
interface ErrorBoundaryProps {
  children?: ReactNode;
}

interface ErrorBoundaryState {
  error?: Error;
}

/**
 * Error Boundary Component
 */
class ErrorBoundaryComponent extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: undefined,
    };
  }

  // Update state so the next render will show the fallback UI.
  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error };
  }

  // Report error
  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    void reportError({
      e: error,
      tags: {
        errorInfo: JSON.stringify(errorInfo),
      },
      namespace: "React App",
    });
  }

  public render(): React.ReactNode {
    if (this.state.error) {
      return (
        <>
          <h1>
            {this.state.error.name} - {this.state.error.message}
          </h1>
          <pre style={{ whiteSpace: "pre-wrap" }}>
            <code>{this.state.error.stack}</code>
          </pre>
        </>
      );
    }

    return this.props.children;
  }
}

/**
 * App Signal
 */
const appsignal = new Appsignal({
  key: process.env.APPSIGNAL_KEY,
});

/**
 * Error Boundary
 * @param children - react node element
 */
export const ErrorBoundary = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => <ErrorBoundaryComponent>{children}</ErrorBoundaryComponent>;

/**
 * Report Error
 * Send error to monitoring platform (AppSignal)
 * @param e - error object
 * @param tags - tags / data
 * @param namespace - error context
 */
export const reportError = async ({
  e,
  tags,
  namespace,
}: {
  e: Error;
  tags?: Record<string, string | undefined>;
  namespace?: string;
}): Promise<void> => {
  await appsignal.sendError(e, tags, namespace);
};
