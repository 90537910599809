// Typings
import { CreatePlanV2Dto, LegalGender, Regions } from "@advicefront/plan-client-axios";
import { Field, FIELD_TYPES } from "@advicefront/fe-infra-form-schema";
import { ClientNamingMode } from "@typings/client-naming-mode";
// Translations
import { lang } from "@lang/index";

// DTO which the form refers without fields that are not shown on form
type FormDto = Pick<CreatePlanV2Dto, "name" | "startYear" | "net"> & CreatePlanV2Dto["owner"];

// DTO properties and keys to display as fields
type FormDtoKey = keyof FormDto;

// Common validation
const validation = {
  required: true,
  errorMessage: lang("FORM_VALIDATOR_REQUIRED_FIELD"),
};

// Gender options
const genderOptions = {
  [LegalGender.M]: lang("FORM_LABEL_MALE"),
  [LegalGender.F]: lang("FORM_LABEL_FEMALE"),
};

// Net options
const netOptions = {
  netBasis: lang("FORM_LABEL_NET_BASIS"),
  grossBasis: lang("FORM_LABEL_GROSS_BASIS"),
};

// Start year options
const startYearOptions = Array.from({ length: 81 }, (_, num) => ({
  label: (num + new Date().getFullYear()).toString(),
  value: (num + new Date().getFullYear()).toString(),
}));

// Schema used for Blank Plan
export const blankSchema = (
  clientNamingMode: ClientNamingMode,
  clientRegion?: Regions
): Partial<Record<FormDtoKey, Field>> => ({
  name: {
    key: "name",
    fieldType: FIELD_TYPES.text,
    validation,
  },
  startYear: {
    key: "startYear",
    fieldType: FIELD_TYPES.singleSelection,
    options: startYearOptions,
    validation,
  },
  ...(clientRegion === Regions.Gbr && {
    net: {
      key: "net",
      fieldType: FIELD_TYPES.singleSelection,
      options: Object.entries(netOptions).map(([value, label]) => ({
        label,
        value,
      })),
      validation,
    },
  }),
  firstName: {
    key: "firstName",
    fieldType: FIELD_TYPES.text,
    validation,
  },
  ...(clientNamingMode === ClientNamingMode.firstLast && {
    lastName: {
      key: "lastName",
      fieldType: FIELD_TYPES.text,
    },
  }),
  yearOfBirth: {
    key: "yearOfBirth",
    fieldType: FIELD_TYPES.text,
    validation,
  },
  legalGender: {
    key: "legalGender",
    fieldType: FIELD_TYPES.singleSelection,
    options: Object.entries(genderOptions).map(([value, label]) => ({
      label,
      value,
    })),
    validation,
  },
  retirementAge: {
    key: "retirementAge",
    fieldType: FIELD_TYPES.text,
  },
});
