// Typings
import { FormModuleInputInstance, FormModuleInstance } from "@forms/modules/types";
import { FormKeys } from "@typings/routing";
// Modules
import { InputFormModule } from "@forms/modules/input";
import { PeopleFormModule } from "@forms/modules/people";
import { PlanFormModule } from "@forms/modules/plan";
import { TemplateFormModule } from "@forms/modules/_template";

// Class that map all form modules
type FormModulesMap = {
  [key in FormKeys]: FormModuleInstance | FormModuleInputInstance;
};

export const FormModules = new (class implements FormModulesMap {
  // Unimplemented
  public [FormKeys.PlanName] = new TemplateFormModule();
  public [FormKeys.PlanImportErrors] = new TemplateFormModule();
  public [FormKeys.People] = new TemplateFormModule();
  public [FormKeys.Tax] = new TemplateFormModule();
  // Plan
  public [FormKeys.Plan] = new PlanFormModule();
  // People
  public [FormKeys.Owner] = new PeopleFormModule();
  public [FormKeys.Partner] = new PeopleFormModule();
  public [FormKeys.Dependent] = new PeopleFormModule();
  // Inputs
  public [FormKeys.AccountInput] = new InputFormModule();
  public [FormKeys.AccountTransferInput] = new InputFormModule();
  public [FormKeys.AssetInput] = new InputFormModule();
  public [FormKeys.CpfPensionInput] = new InputFormModule();
  public [FormKeys.DebtInput] = new InputFormModule();
  public [FormKeys.EmploymentIncomeInput] = new InputFormModule();
  public [FormKeys.EventInput] = new InputFormModule();
  public [FormKeys.ExpenseInput] = new InputFormModule();
  public [FormKeys.IncomeInput] = new InputFormModule();
  public [FormKeys.LoanDebtInput] = new InputFormModule();
  public [FormKeys.MortgageDebtInput] = new InputFormModule();
  public [FormKeys.OtherIncomeInput] = new InputFormModule();
  public [FormKeys.PensionIncomeInput] = new InputFormModule();
  public [FormKeys.PensionInput] = new InputFormModule();
  public [FormKeys.PropertyIncomeInput] = new InputFormModule();
  public [FormKeys.SelfEmploymentIncomeInput] = new InputFormModule();
  public [FormKeys.SrsPensionInput] = new InputFormModule();
})();
