// Redux
import {
  CreatePlanV2Dto,
  DuplicatePlanDto,
  ResponsePlanListDto,
  UpdatePlanDto,
  ImportPlanDto,
} from "@advicefront/plan-client-axios";
import { Store, StoreState } from "@store/index";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import { API } from "@services/api";
// Initial State
import { initialState } from "./initial-state";
// Types
import { StateProps, UpdatePlanOptions } from "./types";
// Utils
import { getSubmitSuccessAction } from "@store/utils/submit-success";
// Translations
import { lang } from "@lang/index";

/**
 * Internal Actions
 * ---------------------------------------------------------------------
 */

const { submitSuccessAction } = getSubmitSuccessAction<StateProps>("plan/set-submit-success-state");

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state
 * @example dispatch(Plan.reset())
 */

export const reset = createAction("plan/reset");

/**
 * Fetch Plan
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Plan.fetchPlan(\{
 *  authToken,
 *  planData
 * \}));
 */

export const fetchPlan = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: NonNullable<StateProps["data"]>["_id"];
  }>,
  { state: StoreState }
>("plan/fetch", async ({ authToken, planId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;
  let data: StateProps["data"] = initialState.data;

  try {
    data = (await API.Plans(authToken).findOnePlanV2({ planId })).data;
  } catch (e) {
    error = API.parseError(e);
  }

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Create Plan
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Plan.createPlan(\{
 *  authToken,
 *  planData
 * \}));
 */

export const createPlan = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planData: CreatePlanV2Dto;
  }>,
  { state: StoreState }
>("plan/create", async ({ authToken, planData }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;
  let data: StateProps["data"] = initialState.data;

  try {
    data = (
      await API.Plans(authToken).createPlanV2({
        dto: planData,
      })
    ).data;
  } catch (e) {
    error = API.parseError(e);
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_CREATED", lang("NOTIFICATION_LABEL_PLAN")),
        description: "",
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
    submitSuccess,
  };
});

/**
 * Import Plan
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Plan.importPlan(\{
 *  authToken,
 *  planData
 * \}));
 */

export const importPlan = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planData: ImportPlanDto;
  }>,
  { state: StoreState }
>("plan/import", async ({ authToken, planData }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;
  let data: StateProps["data"] = initialState.data;

  try {
    data = (
      await API.Plans(authToken).importPlanV2({
        dto: planData,
      })
    ).data;
  } catch (e) {
    error = API.parseError(e);
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_CREATED", lang("NOTIFICATION_LABEL_PLAN")),
        description: "",
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
    submitSuccess,
  };
});

/**
 * Duplicate Plan
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Plan.duplicatePlan(\{
 *  authToken,
 *  planId,
 *  planName
 *  clientGroupId
 * \}));
 */

export const duplicatePlan = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: NonNullable<StateProps["data"]>["_id"];
    planName: DuplicatePlanDto;
  }>,
  { state: StoreState }
>("plan/duplicate", async ({ authToken, planId, planName }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;
  let duplicatedPlan: StateProps["duplicatedPlan"] = initialState.duplicatedPlan;

  try {
    duplicatedPlan = (
      await API.Plans(authToken).duplicatePlan({
        planId: planId,
        dto: planName,
      })
    ).data;
  } catch (e) {
    error = API.parseError(e);
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_DUPLICATED"),
        description: "",
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
    duplicatedPlan,
  };
});

/**
 * Delete Plan
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Plan.deletePlan(\{
 *  authToken,
 *  planId
 * \}));
 */

export const deletePlan = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: NonNullable<StateProps["data"]>["_id"];
    clientGroupId: string;
  }>,
  { state: StoreState }
>("plan/delete", async ({ authToken, planId, clientGroupId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.Plans(authToken).removePlan({ planId });
  } catch (e) {
    error = API.parseError(e);
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_DELETED", lang("NOTIFICATION_LABEL_PLAN")),
        description: "",
      })
    );

    // Refresh plans data (side effect)
    void dispatch(
      Store.plans.fetch({
        authToken: authToken,
        clientGroupId: clientGroupId,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
  };
});

/**
 * Update Plan
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Plan.updatePlan(\{
 *  authToken,
 *  planId,
 *  updatedPlanData
 * \}));
 */

export const updatePlan = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: NonNullable<StateProps["data"]>["_id"];
    clientGroupId: string;
    archived: ResponsePlanListDto["archived"];
    updatedPlanData: UpdatePlanDto;
  }> & {
    options?: UpdatePlanOptions;
  },
  { state: StoreState }
>(
  "plan/update",
  async ({ authToken, planId, updatedPlanData, clientGroupId, options }, { dispatch }) => {
    let error: StateProps["error"] = initialState.error;

    try {
      await API.Plans(authToken).updatePlan({
        planId: planId,
        dto: updatedPlanData,
      });
    } catch (e) {
      error = API.parseError(e);
    }

    const submitSuccess = !error;

    void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

    if (error) {
      // Trigger error notification
      void dispatch(
        Store.notifications.addNotification({
          type: "error",
          title: lang("NOTIFICATION_TITLE_ERROR"),
          description: error,
        })
      );
    }

    if (submitSuccess) {
      // Trigger success notification
      void dispatch(
        Store.notifications.addNotification({
          type: "success",
          title:
            options?.notificationTitle ||
            lang("NOTIFICATION_TITLE_UPDATED", lang("NOTIFICATION_LABEL_PLAN")),
          description: options?.notificationDescription || "",
        })
      );

      // Refresh plans data if action was triggered in plans list route (side effect)
      if (options?.isPlansListRoute) {
        void dispatch(
          Store.plans.fetch({
            authToken: authToken,
            clientGroupId: clientGroupId,
          })
        );
      } else {
        // Refresh plan data if action was not triggered in plans list route (side effect)
        void dispatch(
          Store.plan.fetchPlan({
            authToken: authToken,
            planId: planId,
          })
        );
      }

      // Updating a plan changes the forecast through a worker on the BE so we need to open polling
      // Polling will automatically close when a the updated forecast is retrieved
      void dispatch(
        Store.forecast.poll({
          authToken,
          planId,
        })
      );
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      error,
      submitSuccess,
    };
  }
);

/**
 * Dismiss Import Errors
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Plan.dismissImportErrors(\{
 *  authToken,
 *  planId,
 * \}));
 */

export const dismissImportErrors = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: NonNullable<StateProps["data"]>["_id"];
  }>,
  { state: StoreState }
>("plan/dismiss-import-errors", async ({ authToken, planId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;
  let data: StateProps["data"] = initialState.data;

  try {
    data = (
      await API.Plans(authToken).updatePlan({
        planId: planId,
        dto: {
          importDetails: {
            userDismissed: true,
          },
        },
      })
    ).data;
  } catch (e) {
    error = API.parseError(e);
  }

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Export Plan PDF
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Plan.exportPlanPdf(\{
 *  authToken,
 *  planId,
 * \}));
 */

export const exportPlanPdf = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: NonNullable<StateProps["data"]>["_id"];
  }>,
  { state: StoreState }
>("plan/export-pdf", async ({ authToken, planId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;
  // Retries variable to set a max number of retries while pdf is being generated
  let retries = 25;

  try {
    await (async function fetch(): Promise<void> {
      const res = await API.Render(authToken).renderPdf({
        planId,
      });

      // If no retries left set error and return
      if (retries <= 0) {
        // add to translation
        error = lang("ERROR_PDF_DOWNLOAD");
        return;
      }

      // Self invoke / call if status is 202 (means pdf is still being generated)
      if (res.status === 202) {
        // Decrease number of tries left
        retries -= 1;

        // Promise implementation used so we don't overload the BE with requests while the pdf is being generated
        await new Promise<void>((resolve) => {
          setTimeout(async () => {
            await fetch();
            resolve();
          }, 1000);
        });
      }
      // Download pdf if status is 201 (means pdf is ready)
      if (res.status === 201) {
        const link = document.createElement("a");
        link.href = res.data.url;
        link.click();
        link.remove();
      }
    })();
  } catch (e) {
    error = lang("ERROR_PDF_DOWNLOAD");
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_EXPORTED"),
        description: "",
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
  };
});
