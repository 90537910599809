// React
import { useContext, useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import {
  TableScrollableModuleEntry,
  TableScrollableModuleSchema,
  UseTableScrollableModule,
} from "@components/tables/scrollable/hooks";
// Context
import { AppOptionsContext } from "@context/app-options";
// Utils
import { maskCurrency } from "@utils/format-currency";
// Translations
import { lang } from "@lang/index";

export const useTableAssetsLiabilitiesModule: UseTableScrollableModule = () => {
  // Store
  const inputs = Store.useSelector((state) => state.inputs);
  const forecast = Store.useSelector((state) => state.forecast);

  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // Check if all balance data is empty
  const isEmpty = useMemo(
    (): boolean =>
      !forecast.data?.balance.assetValues.length && !forecast.data?.balance.liabilityValues.length,
    [forecast.data?.balance]
  );

  // Check if data was already loaded
  const isFetching = useMemo(
    (): boolean => !forecast.data || !inputs.data,
    [forecast.data, inputs.data]
  );

  // Return table scrollable data object
  return useMemo(
    (): TableScrollableModuleSchema => ({
      // Add empty value for first column
      head: ["", ...(forecast.data?.years || [])],
      entries: !isEmpty
        ? [
            // People
            ...[forecast.data?.clients.owner, forecast.data?.clients.partner]
              .filter((client) => !!client)
              .map(
                (client): TableScrollableModuleEntry => ({
                  label: lang("TABLE_LABEL_AGE", client?.name.split(" ")[0]),
                  values: client?.ageRange,
                })
              ),

            // Assets Detailed
            {
              label: lang("TABLE_LABEL_ASSETS_DETAILED", currencySymbol),
              type: "body-bold",
            },
            ...(forecast.data?.balance.assetValues || []).map(
              (asset): TableScrollableModuleEntry => ({
                label: asset?.label,
                values: asset.values.map((value) => maskCurrency(Math.abs(value))),
                action: {
                  type: asset.baseType,
                  subType: asset.type,
                  id: asset.id,
                },
              })
            ),
            {
              label: lang("TABLE_LABEL_TOTAL_ASSETS"),
              type: "body-bold",
              values: forecast.data?.balance.totalAssetValues.map((value) =>
                maskCurrency(Math.abs(value))
              ),
              highlighted: true,
            },

            // Liabilities Detailed
            {
              label: lang("TABLE_LABEL_LIABILITIES_DETAILED", currencySymbol),
              type: "body-bold",
            },
            ...(forecast.data?.balance.liabilityValues || []).map(
              (liabilities): TableScrollableModuleEntry => ({
                label: liabilities?.label,
                values: liabilities.values.map((value) => maskCurrency(Math.abs(value))),
                action: {
                  type: liabilities.baseType,
                  subType: liabilities.type,
                  id: liabilities.id,
                },
              })
            ),
            {
              label: lang("TABLE_LABEL_TOTAL_LIABILITIES"),
              type: "body-bold",
              values: forecast.data?.balance.totalLiabilityValues.map((value) =>
                maskCurrency(Math.abs(value))
              ),
              highlighted: true,
            },

            // NetWorth
            {
              label: lang("TABLE_LABEL_NET_WORTH"),
              type: "body-bold",
              values: forecast.data?.balance.netWorth.map((value) => maskCurrency(value || 0)),
              highlighted: true,
            },
          ]
        : [],
      fetching: isFetching,
      polling: !!forecast.polling,
      empty: {
        title: lang("EMPTY_TITLE_PLAN_INPUTS"),
      },
    }),
    [
      forecast.polling,
      forecast.data?.years,
      forecast.data?.clients,
      forecast.data?.balance,
      isEmpty,
      isFetching,
      currencySymbol,
    ]
  );
};
