// React
import React from "react";
// Typings
import { FormRendererOptions } from "@forms/renderer/types";
import { SchemaRenderer } from "@advicefront/fe-infra-form-schema-renderer";
// Components
import {
  BooleanField,
  GroupField,
  IncrementalField,
  SelectionFieldMultiple,
  SelectionFieldSingle,
  TextField,
} from "@forms/renderer/components";
import { AfRow } from "@advicefront/ds-grid";

/**
 * Form Renderer
 * The FormRenderer class is responsible for rendering a form based on a schema
 * It implements the SchemaRenderer interface, which defines the methods needed to render the form
 * @param options - object containing options for rendering the form
 */
export const FormRenderer = class implements SchemaRenderer {
  /**
   * Constructor
   * @param options - {@link FormRendererOptions}
   */
  constructor(public options: FormRendererOptions) {}

  /**
   * Fields
   * Renderers of all fields types
   */

  // Text Field
  TextField: SchemaRenderer["TextField"] = (props) => (
    <TextField {...props} options={this.options} />
  );

  // Selection Field Single
  SelectionFieldSingle: SchemaRenderer["SelectionFieldSingle"] = (props) => (
    <SelectionFieldSingle {...props} options={this.options} />
  );

  // Selection Field Multiple
  SelectionFieldMultiple: SchemaRenderer["SelectionFieldMultiple"] = (props) => (
    <SelectionFieldMultiple {...props} options={this.options} />
  );

  // Boolean Field
  BooleanField: SchemaRenderer["BooleanField"] = (props) => (
    <BooleanField {...props} options={this.options} />
  );

  // Incremental Field
  IncrementalField: SchemaRenderer["IncrementalField"] = (props) => (
    <IncrementalField {...props} options={this.options} />
  );

  // Group Field
  GroupField: SchemaRenderer["GroupField"] = (props) => (
    <GroupField {...props} options={this.options} />
  );

  /**
   * Forms
   * Renderers of all forms types
   */

  // Form
  Form: SchemaRenderer["Form"] = ({ children }) => <>{children}</>;

  // Form Single Step
  FormSingleStep: SchemaRenderer["FormSingleStep"] = ({ children }): React.ReactElement => (
    <AfRow wrap>{children}</AfRow>
  );

  // Form Multi Step
  // NOTE: Component not implemented
  FormMultiStep: SchemaRenderer["FormMultiStep"] = ({
    children,
    index,
    steps,
    stepKey,
    activeStepKey,
    prevStep,
    nextStep,
    isFirst,
    isLast,
  }): React.ReactElement => {
    // Hide all steps except the current
    if (stepKey !== activeStepKey) {
      return <section hidden>{children}</section>;
    }

    // Display current step
    return (
      <>
        <span>
          {stepKey} ({index + 1} / {steps.length})
        </span>
        {children}
        {!isFirst && <button onClick={prevStep}>Previous</button>}
        {!isLast && <button onClick={nextStep}>Next</button>}
      </>
    );
  };
};
