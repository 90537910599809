// Enum defining all supported column charts modules
// Each module is represented by a unique key and a corresponding string value
export enum ChartColumnModules {
  Cashflow = "Cashflow",
  AssetsAndLiabilities = "AssetsAndLiabilities",
  Tax = "Tax",
}

// Type alias for the keys of the ChartColumnModules enum
// This is used to ensure type safety when working with column charts in the codebase
export type ChartColumnModulesName = keyof typeof ChartColumnModules;

// Enum defining all supported column charts tabs of each module
// Each tab is represented by a unique key and a corresponding string value
export enum ChartColumnTabs {
  // Cashflow
  Inflow = "inflow",
  Outflow = "outflow",
  // Assets And Liabilities
  Assets = "assets",
  Liabilities = "liabilities",
  // Tax
  Owner = "owner",
  Partner = "partner",
}

// Enum defining all supported column charts options
// Each option is represented by a unique key and a corresponding string value
export enum ChartColumnOptions {
  Detailed = "detailed",
  Zoom = "zoom",
}
