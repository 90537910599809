// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// State
import { initialState } from "./initial-state";
// Types
import { StateProps } from "./types";
// Actions
import { reset, fetch, create, deleteInput, update } from "./actions";
// Utils
import { getSubmitSuccessAction } from "@store/utils/submit-success";

const { applySubmitSuccessCases } = getSubmitSuccessAction<StateProps>(
  "inputs/set-submit-success-state"
);

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  applySubmitSuccessCases(builder);

  builder
    // Reset to initial data state
    .addCase(reset, () => initialState)
    // Get + Create + Delete + Update fulfilled
    .addMatcher(
      isAnyOf(fetch.fulfilled, create.fulfilled, deleteInput.fulfilled, update.fulfilled),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    // Loading start
    .addMatcher(
      isAnyOf(fetch.pending, create.pending, deleteInput.pending, update.pending),
      (state) => ({
        ...state,
        loading: state.loading + 1,
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        fetch.fulfilled,
        fetch.rejected,
        create.fulfilled,
        create.rejected,
        deleteInput.fulfilled,
        deleteInput.rejected,
        update.fulfilled,
        update.rejected
      ),
      (state) => ({
        ...state,
        loading: state.loading - 1,
      })
    );
});
