// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
import { selectByClientType } from "@store/selectors/client-type";
import { selectPlanEvents } from "@store/selectors/plan-events";
// Typings
import { ClientType, FormTypes, Ownership } from "@advicefront/plan-client-axios";
import { FormModuleInputInstance, UseOptionsListProps } from "@forms/modules/types";
// Constants
import { SELECT_SECTION_TITLE } from "@constants/index";
// Utils
import { asFormType } from "@routes/utils/modal-routing-guards";
// Translations
import { lang } from "@lang/index";

export const useOptionsList: FormModuleInputInstance["useOptionsList"] = ({ formData }) => {
  // Store
  const forecast = Store.useSelector((state) => state.forecast);
  const inputs = Store.useSelector((state) => state.inputs);
  const owner = Store.useSelector(selectByClientType(ClientType.Owner));
  const partner = Store.useSelector(selectByClientType(ClientType.Partner));
  const planEvents = Store.useSelector(selectPlanEvents()).data;

  /**
   * Asset Options
   * Applied on Employment Property Income (GBR) and Employment Income (SGP)
   * Applied on Loan Debt and Mortgage Debt (GBR)
   */
  const assetOptions = useMemo(
    () =>
      inputs.data?.asset.map((i) => ({
        label: i.label,
        value: i._id,
      })) || [],
    [inputs.data?.asset]
  );

  /**
   * Account Options
   * Applied on Asset (SGP)
   */
  const accountOptions = useMemo(() => {
    // Get list of input accounts
    const accounts =
      inputs.data?.account.map((i) => ({
        label: i.label,
        value: i._id,
      })) || [];

    // Get list of input pensions
    const pensions =
      inputs.data?.pension.map((i) => ({
        label: i.label,
        value: i._id,
      })) || [];

    // Add group titles if both accounts and pensions exist
    if (accounts.length && pensions.length) {
      accounts.unshift({
        label: SELECT_SECTION_TITLE,
        value: lang("FORM_LABEL_ACCOUNTS"),
      });

      pensions.unshift({
        label: SELECT_SECTION_TITLE,
        value: lang("FORM_LABEL_PENSIONS"),
      });
    }

    return [...accounts, ...pensions];
  }, [inputs.data?.account, inputs.data?.pension]);

  /**
   * Employment Income options
   * Applied on CPF Pension (SGP)
   */
  const employmentIncomeOptions = useMemo(
    () =>
      inputs.data?.income
        .filter((income) =>
          [FormTypes.GbrEmploymentIncomeInput, FormTypes.SgpEmploymentIncomeInput].includes(
            asFormType(income.__st)
          )
        )
        .map((i) => ({
          label: i.label,
          value: i._id,
        })) || [],
    [inputs.data?.income]
  );

  /**
   * Timeframe Options
   * Applied on several form types
   */
  const timeframeOptions = useMemo(() => {
    let groupTitle: string;

    // Define main title
    switch (formData?.ownership) {
      case Ownership.Owner:
        groupTitle = `${owner?.firstName}`;
        break;
      case Ownership.Partner:
        groupTitle = `${partner?.firstName}`;
        break;
      default:
        groupTitle = lang("FORM_LABEL_TIMEFRAME_JOINT", [
          `${owner?.firstName}`,
          `${partner?.firstName}`,
        ]);
    }

    // Get list of events
    const events = [
      {
        label: SELECT_SECTION_TITLE,
        value: lang("FORM_LABEL_TIMEFRAME_EVENTS", groupTitle),
      },
      ...planEvents.map((event) => {
        let agesLabel: string;

        switch (formData?.ownership) {
          case Ownership.Partner:
            agesLabel = `${event.partnerAge}`;
            break;
          case Ownership.Joint:
            agesLabel = `${event.ownerAge} / ${event.partnerAge}`;
            break;
          default:
            agesLabel = `${event.ownerAge}`;
        }

        return {
          label: `${agesLabel} (${event.label})`,
          value: String(event.period),
        };
      }),
    ];

    // Get list of ages
    // Owners age minus starting age since this options is already in events
    const ages = [
      {
        label: SELECT_SECTION_TITLE,
        value: lang("FORM_LABEL_TIMEFRAME_AGE", groupTitle),
      },
      ...((owner &&
        forecast.data?.years
          .map((age, index) => {
            let agesLabel: string;

            // Get owner and partner age
            const ownerAge = age - owner.yearOfBirth;
            const partnerAge = partner ? age - partner.yearOfBirth : undefined;

            // Define age label
            switch (formData?.ownership) {
              case Ownership.Partner:
                agesLabel = `${partnerAge}`;
                break;
              case Ownership.Joint:
                agesLabel = `${ownerAge} / ${partnerAge}`;
                break;
              default:
                agesLabel = `${ownerAge}`;
            }

            return {
              label: agesLabel,
              value: String(index),
            };
          })
          .slice(1)) ||
        []),
    ];

    return [...events, ...ages];
  }, [owner, partner, planEvents, formData, forecast.data?.years]);

  /**
   * Ownership Options
   * Applied on several form types
   */
  const ownershipOptions = useMemo(
    () => [
      ...(owner
        ? [
            {
              label: owner.firstName,
              value: Ownership.Owner,
            },
          ]
        : []),
      ...(partner
        ? [
            {
              label: partner.firstName,
              value: Ownership.Partner,
            },
            {
              label: Ownership.Joint,
              value: Ownership.Joint,
            },
          ]
        : []),
    ],
    [owner, partner]
  );

  return useMemo(
    (): UseOptionsListProps => ({
      asset: assetOptions,
      account: accountOptions,
      income: employmentIncomeOptions,
      contributionSource: employmentIncomeOptions,
      startPeriod: timeframeOptions,
      endPeriod: timeframeOptions,
      ownership: ownershipOptions,
    }),
    [assetOptions, accountOptions, employmentIncomeOptions, timeframeOptions, ownershipOptions]
  );
};
