// Typings
import { Validators, ValidationData } from "@advicefront/fe-infra-form-schema-renderer";
// Constants
import { EMPTY_OPTION_VALUE } from "@constants/index";
// Translations
import { lang } from "@lang/index";

/**
 * Helper to generate form schema validators based on the field keys that are provided
 * Used for fields that are optional by default but required if the value for another field is defined
 * @param fieldKeys - array of field keys that are interdependent
 * @returns array of form schema validators
 */
export const handleDependentFields = (fieldKeys: string[]): Validators => {
  const validators: Validators = [];

  for (let i = 0; i < fieldKeys.length; i++) {
    for (let j = 0; j < fieldKeys.length; j++) {
      const valueKey = fieldKeys[i];
      const dependentKey = fieldKeys[j];

      if (valueKey !== dependentKey) {
        validators.push({
          valueKey,
          validator: (field, formData): ValidationData => {
            const { computedValues } = formData;

            if (
              !field.value &&
              !!computedValues[dependentKey] &&
              computedValues[dependentKey] !== EMPTY_OPTION_VALUE
            ) {
              return {
                status: "error",
                description: lang("FORM_VALIDATOR_REQUIRED_FIELD"),
              };
            }
          },
        });
      }
    }
  }

  return validators;
};
