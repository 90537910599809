// Router
import { useModalRouting } from "@hooks/modal-routing";
// Typings
import { FormModuleInstance } from "@forms/modules/types";

export class TemplateFormModule implements FormModuleInstance {
  // React hook to fetch schema data
  public useSchema: FormModuleInstance["useSchema"] = () => undefined;
  // React hook to set form fields custom structure
  public useSchemaStructure: FormModuleInstance["useSchemaStructure"] = () => undefined;
  // React hook to expose field keys to input types and styles
  public useInputRenderer: FormModuleInstance["useInputRenderer"] = () => ({});
  // React hook to set initial values (edit mode)
  public useInitialValues: FormModuleInstance["useInitialValues"] = () => undefined;
  // React hook to expose field value formatters
  public useFormatters: FormModuleInstance["useFormatters"] = () => [];
  // React hook to expose field validation
  public useValidators: FormModuleInstance["useValidators"] = () => [];
  // React hook to handle form fields on change
  public useOnChange: FormModuleInstance["useOnChange"] = () => (): void => undefined;
  // React hook to submit the data from the form
  public useSubmit: FormModuleInstance["useSubmit"] = () => undefined;
  // React component that renders the form fields
  public Component: FormModuleInstance["Component"] = () => {
    const { action, type } = useModalRouting();
    throw new Error(`Form to ${action} ${type} is not implemented`);
  };
}
