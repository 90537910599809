// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// State
import { initialState } from "./initial-state";
// Types
import { StateProps } from "./types";
// Actions
import { reset, fetch, updateIllness, updateProtection, updateTpd } from "./actions";
// Utils
import { getSubmitSuccessAction } from "@store/utils/submit-success";

const { applySubmitSuccessCases } = getSubmitSuccessAction<StateProps>(
  "inputs/set-submit-success-state"
);

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  applySubmitSuccessCases(builder);

  builder
    // Reset to initial data state
    .addCase(reset, () => initialState)
    // Get Illness + Get Protection + Get Tpd + Update Illness + Update Protection + Update Tpd fulfilled
    .addMatcher(
      isAnyOf(
        fetch.fulfilled,
        updateIllness.fulfilled,
        updateProtection.fulfilled,
        updateTpd.fulfilled
      ),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    // Loading start
    .addMatcher(
      isAnyOf(fetch.pending, updateIllness.pending, updateProtection.pending, updateTpd.pending),
      (state) => ({
        ...state,
        loading: state.loading + 1,
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        fetch.fulfilled,
        fetch.rejected,
        updateIllness.fulfilled,
        updateIllness.rejected,
        updateProtection.fulfilled,
        updateProtection.rejected,
        updateTpd.fulfilled,
        updateTpd.rejected
      ),
      (state) => ({
        ...state,
        loading: state.loading - 1,
      })
    );
});
