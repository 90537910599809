// React
import React from "react";
// Router
import { View } from "@routes/index";
// Layouts
import { AssumptionsLayout } from "@layouts/assumptions";
// Views
import { AssumptionsLiquidationTable } from "@views/assumptions/liquidation/components/Table";

// Define element for the view
const Element = (): React.ReactElement => (
  <AssumptionsLayout>
    <AssumptionsLiquidationTable />
  </AssumptionsLayout>
);

// Route view options
export const assumptionsLiquidation: View = {
  name: "Assumptions Liquidation",
  options: {
    path: "/plan/:id/assumptions/liquidation",
    element: <Element />,
  },
};
