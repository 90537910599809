/**
 * Empty
 * Naming convention: EMPTY_TITLE_ELEMENT or EMPTY_DESCRIPTION_ELEMENT
 */
export const empty = {
  // Plans
  EMPTY_DESCRIPTION_PLANS_ARCHIVED: {
    en: "You don't have any archived cashflow plans for this client",
  },
  EMPTY_DESCRIPTION_PLANS_ACTIVE: {
    en: "You don't have any active cashflow plans for this client",
  },

  // Coverage
  EMPTY_TITLE_COVERAGE_NA: {
    en: "N/A",
  },
  EMPTY_DESCRIPTION_COVERAGE: {
    en: "Suggestions will appear once you start adding inputs to the plan.",
  },

  // Financial Summary
  EMPTY_TITLE_FINANCIAL_SUMMARY: {
    en: "No data to display",
  },

  // Cashflow | Assets & Liabilities
  EMPTY_TITLE_PLAN_INPUTS: {
    en: "Please add plan inputs to start forecast",
  },

  // Tax
  EMPTY_TITLE_TAX: {
    en: "There are currently no inputs or taxes added",
  },

  // Liquidation
  EMPTY_TITLE_LIQUIDATION: {
    en: "This plan doesn't have any liquid assets yet.",
  },
  EMPTY_DESCRIPTION_LIQUIDATION: {
    en: "Please add Savings & Investments or Pension inputs to configure the liquidation order.",
  },

  // Account Limits
  EMPTY_TITLE_ACCOUNT_LIMITS: {
    en: "This plan doesn't have inputs that can have account limits yet.",
  },
  EMPTY_DESCRIPTION_ACCOUNT_LIMITS: {
    en: "Please add Savings & Investments or Pension inputs to generate account limits.",
  },
};
