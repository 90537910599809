// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Utils
import { getSubmitSuccessAction } from "@store/utils/submit-success";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import {
  fetchPlan,
  reset,
  exportPlanPdf,
  importPlan,
  createPlan,
  deletePlan,
  duplicatePlan,
  updatePlan,
  dismissImportErrors,
} from "./actions";

const { applySubmitSuccessCases } = getSubmitSuccessAction<StateProps>(
  "plan/set-submit-success-state"
);

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  applySubmitSuccessCases(builder);

  builder
    // Reset to initial data state
    .addCase(reset, () => initialState)
    // Export plan PDF loading start
    .addCase(exportPlanPdf.pending, (state) => ({
      ...state,
      downloadLoading: true,
    }))
    // Export plan PDF loading end
    .addMatcher(isAnyOf(exportPlanPdf.fulfilled, exportPlanPdf.rejected), (state) => ({
      ...state,
      downloadLoading: false,
    }))
    // Get + Import + Create + Duplicate + Delete + Update + toggle Plan Archived + Export to PDF fulfilled
    .addMatcher(
      isAnyOf(
        fetchPlan.fulfilled,
        importPlan.fulfilled,
        createPlan.fulfilled,
        duplicatePlan.fulfilled,
        deletePlan.fulfilled,
        updatePlan.fulfilled,
        dismissImportErrors.fulfilled,
        exportPlanPdf.fulfilled
      ),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    .addMatcher(
      isAnyOf(
        fetchPlan.pending,
        importPlan.pending,
        createPlan.pending,
        duplicatePlan.pending,
        deletePlan.pending,
        updatePlan.pending,
        dismissImportErrors.pending
      ),
      (state) => ({
        ...state,
        loading: state.loading + 1,
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        fetchPlan.fulfilled,
        fetchPlan.rejected,
        importPlan.fulfilled,
        importPlan.rejected,
        createPlan.fulfilled,
        createPlan.rejected,
        duplicatePlan.fulfilled,
        duplicatePlan.rejected,
        deletePlan.fulfilled,
        deletePlan.rejected,
        updatePlan.fulfilled,
        updatePlan.rejected,
        dismissImportErrors.fulfilled,
        dismissImportErrors.rejected
      ),
      (state) => ({
        ...state,
        loading: state.loading - 1,
      })
    );
});
