// React
import { useCallback, useMemo } from "react";
// Typings
import {
  TableAssumptionsModuleSchema,
  UseTableAssumptionsModule,
} from "@components/tables/assumptions/hooks";
import { InputNodeFormat } from "@typings/input-node-format";
// Utils
import { isAccountsAssumptions } from "@utils/type-guards/assumptions";
import { isEqual } from "@utils/is-equal";
// Translations
import { lang } from "@lang/index";

export const useTableMortalityModule: UseTableAssumptionsModule = ({
  name,
  data,
  firmPreferences,
}) => {
  // Throw error if data is not valid for Mortality module
  if (data && isAccountsAssumptions(data)) {
    throw new Error(`${name} data not valid`);
  }

  // Get firm data
  const firmData = firmPreferences.data;

  // Define payload for submission to match with dto
  const submitPayload = useCallback<TableAssumptionsModuleSchema["submitPayload"]>(
    (updatedData) => ({
      livelihoodIndicators: {
        averageLifespan: {
          female: Number(updatedData[0].fields[0].value),
          male: Number(updatedData[1].fields[0].value),
        },
      },
    }),
    []
  );

  // Return table assumptions data object
  return useMemo(
    (): TableAssumptionsModuleSchema => ({
      title: lang("TABLE_TITLE_MORTALITY_ASSUMPTION"),
      head: [
        {
          label: lang("TABLE_COLUMN_NAME"),
          align: "left",
        },
        {
          label: lang("TABLE_COLUMN_VALUE"),
          align: "right",
        },
      ],
      entries: [
        {
          label: lang("TABLE_LABEL_FEMALE_MORTALITY"),
          fields: [
            {
              type: InputNodeFormat.number,
              value: data?.livelihoodIndicators?.averageLifespan?.female,
              after: lang("TABLE_LABEL_YEARS"),
            },
          ],
        },
        {
          label: lang("TABLE_LABEL_MALE_MORTALITY"),
          fields: [
            {
              type: InputNodeFormat.number,
              value: data?.livelihoodIndicators?.averageLifespan?.male,
              after: lang("TABLE_LABEL_YEARS"),
            },
          ],
        },
      ],
      customise: {
        checked: !!data?.settings.livelihoodIndicators,
        payload: {
          settings: {
            livelihoodIndicators: !firmData?.settings.livelihoodIndicators,
          },
        },
      },
      reset: {
        allowed: !isEqual(data?.livelihoodIndicators, firmData?.livelihoodIndicators),
        payload: {
          livelihoodIndicators: {
            averageLifespan: {
              female: firmData?.livelihoodIndicators.averageLifespan.female,
              male: firmData?.livelihoodIndicators.averageLifespan.male,
            },
          },
        },
      },
      submitPayload,
    }),
    [
      submitPayload,
      data?.settings.livelihoodIndicators,
      data?.livelihoodIndicators,
      firmData?.settings.livelihoodIndicators,
      firmData?.livelihoodIndicators,
    ]
  );
};
