/**
 * Form Placeholders
 * Naming convention: FORM_PLACEHOLDER_ELEMENT
 */
export const formPlaceholders = {
  FORM_PLACEHOLDER_DEFAULT: {
    en: "Enter {0}",
  },
  FORM_PLACEHOLDER_NAME: {
    en: "Enter name",
  },
  FORM_PLACEHOLDER_VALUE: {
    en: "Value",
  },
  FORM_PLACEHOLDER_AMOUNT: {
    en: "Enter amount",
  },
  FORM_PLACEHOLDER_PERCENTAGE_VALUE: {
    en: "Enter percentage value",
  },
  FORM_PLACEHOLDER_NUMBER_OF_YEARS: {
    en: "Enter number of years",
  },
  FORM_PLACEHOLDER_SELECT_DEFAULT: {
    en: "Choose an option",
  },
  FORM_PLACEHOLDER_SELECT_YEAR: {
    en: "Choose a year",
  },
  FORM_PLACEHOLDER_SELECT_PLAN: {
    en: "Choose a plan",
  },
  FORM_PLACEHOLDER_SELECT_AGE: {
    en: "Choose an age",
  },
  FORM_PLACEHOLDER_SELECT_ACCOUNT: {
    en: "Choose an account or pension",
  },
  FORM_PLACEHOLDER_SELECT_INCOME: {
    en: "Choose an income",
  },
  FORM_PLACEHOLDER_EVENT_NAME: {
    en: "e.g. Wedding, World Trip",
  },
  FORM_PLACEHOLDER_INCOME_NAME: {
    en: "e.g. Company Name",
  },
  FORM_PLACEHOLDER_EXPENSE_NAME: {
    en: "e.g. Travel, Insurance, Entertainment",
  },
  FORM_PLACEHOLDER_ACCOUNT_NAME: {
    en: "Name of Account, Bank or Institution",
  },
  FORM_PLACEHOLDER_ASSET_NAME: {
    en: "e.g. Main Residence, Rental Property, Car, Boat, Jewelry, Art, Collection, etc",
  },
  FORM_PLACEHOLDER_PENSION_NAME: {
    en: "e.g. Name of Account, Pension, Provider, etc",
  },
  FORM_PLACEHOLDER_DEBT_NAME: {
    en: "e.g. Loan, Mortgage, Credit Card, etc",
  },
};
