// React
import React, { useMemo, useContext } from "react";
// Router
import { Navigate } from "react-router-dom";
// Utils
import { pathnameMatchedViews } from "../utils/pathname-matched-views";
import { requiredRoutePath } from "../utils/required-route-path";
// Context
import { AppOptionsContext } from "@context/app-options";
// Views
import * as Views from "@views/index";

// Array with the allowed home routes
const possibleHomeRoutes = [
  Views.preferencesGeneral,
  Views.plansList,
  Views.firmPreferencesGeneral,
];

/**
 * This component is used as React Router index {@link https://reactrouter.com/en/main/route/route#element | Route element}
 * Once this component get mounted, it will get the `plan-initial-route` from {@link GlobalState} and will redirect user to it
 * @param possibleHomeRoutes - {@link View} array of the allowed routes for the initial redirect
 * @returns - {@link ReactElement}
 */
export const IndexRedirect = (): React.ReactElement => {
  const { planInitialRoute } = useContext(AppOptionsContext);

  if (!planInitialRoute)
    throw new Error("`plan-initial-route` is empty. Please set a valid pathname");

  const homeRoute = useMemo(() => {
    const maybeHome = pathnameMatchedViews(planInitialRoute, possibleHomeRoutes);
    // get's the first matched route
    if (maybeHome) return maybeHome[0];
  }, [planInitialRoute]);

  if (!homeRoute) {
    throw new Error(
      `Unable to load frontend home route.\nReceived route: ${planInitialRoute}\nPossible routes: ${JSON.stringify(
        Object.entries(possibleHomeRoutes).map(([, view]) => view.options.path?.toString())
      )}`
    );
  }

  return <Navigate replace to={requiredRoutePath(homeRoute)} />;
};
