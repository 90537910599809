/**
 * Object Type
 * @param value - can receive any value
 * @returns type from "[object Type]"
 */
const objectType = <T>(value: T): string =>
  Object.prototype.toString.call(value).slice(8, -1).toLowerCase();

/**
 * Object Length
 * @param value - can receive any value
 * @returns length of array or object
 */
const objectLength = <T>(value: Required<T>): number =>
  (Array.isArray(value) && value.length) || Object.keys(value).length;

/**
 * Compare Objects
 * @param valueA - can receive any value
 * @param valueB - can receive any value
 * @returns false if didn't match
 */
const compareObjects = <T>(valueA: T, valueB: T): boolean | undefined => {
  // Compare recursively if is an object or array
  if (["array", "object"].indexOf(objectType(valueA)) >= 0) {
    if (!isEqual(valueA, valueB)) return false;
  } else {
    // Return false if different types
    if (objectType(valueA) !== objectType(valueB)) return false;
    // Return false if valueA different than valueB
    if (valueA !== valueB) return false;
  }
};

/**
 * Is Equal
 * Method do check if two values are equal
 * @param valueA - can receive any value
 * @param valueB - can receive any value
 * @example isEqual("A", "A") = true
 * @example isEqual([1,2], [1,2]) = true
 * @example isEqual(`{a: "A", b: "B"}`, `{a: "A", b: "B"}`) = true
 * @example isEqual(`[{a: "A"}, {b: "B"}]`, `[{a: "A"}, {b: "B"}]}`) = true
 * @returns true if both values are equal
 */
export const isEqual = <T>(valueA: T, valueB: T): boolean => {
  // Return false if no values, different types or length
  if (
    !valueA ||
    !valueB ||
    objectType(valueA) !== objectType(valueB) ||
    objectLength(valueA) !== objectLength(valueB)
  ) {
    return false;
  }
  // Compare string, number, array or object properties
  if (["string", "number"].indexOf(objectType(valueA)) >= 0) {
    if (valueA !== valueB) return false;
  } else if (Array.isArray(valueA) && Array.isArray(valueB)) {
    for (let i = 0; i < objectLength(valueA); i++) {
      if (compareObjects(valueA[i], valueB[i]) === false) return false;
    }
  } else {
    for (const key in valueA) {
      if (compareObjects(valueA[key], valueB[key]) === false) return false;
    }
  }

  // Return true if nothing failed
  return true;
};
