// React
import React from "react";
// Typings
import { FormRendererOptions } from "@forms/renderer/types";
import { SchemaRenderer } from "@advicefront/fe-infra-form-schema-renderer";
// Utils
import { getColumnProps } from "@forms/utils/get-column-props";
// Translations
import { lang } from "@lang/index";
// Components
import { AfCol } from "@advicefront/ds-grid";

// Props
type IncrementalFieldProps = Parameters<SchemaRenderer["IncrementalField"]>[0] & {
  options: FormRendererOptions;
};

export const IncrementalField = ({
  field,
  items,
  add,
  options,
}: IncrementalFieldProps): React.ReactElement => {
  // Get input props column set on the input renderer hook of each module
  const { size, offsetBefore, offsetAfter } = getColumnProps(
    options.inputRenderer.inputProps?.column,
    options.formDto,
    field.key
  );

  // NOTE: Component not implemented
  return (
    <AfCol size={size} offsetBefore={offsetBefore} offsetAfter={offsetAfter}>
      <fieldset>
        {items.map((item, index) => (
          <React.Fragment key={`incremental-item-${field.label}-${index}`}>
            <label>
              {field.label}
              {item.index + 1}
              {item.remove && (
                <button
                  type="button"
                  onClick={(): void => {
                    item.remove?.();
                  }}
                >
                  {lang("ACTION_REMOVE")}
                </button>
              )}
            </label>
            {item.input}
          </React.Fragment>
        ))}
        <button type="button" onClick={add}>
          Add {field.label}
        </button>
      </fieldset>
    </AfCol>
  );
};
