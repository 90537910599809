// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
import { selectPersonById } from "@store/selectors/person-by-id";
// Typings
import { DialogDeleteModuleSchema, UseDialogDeleteModule } from "@components/dialogs/delete/hooks";
// Translations
import { lang } from "@lang/index";

export const useDialogPersonModule: UseDialogDeleteModule = (id) => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);
  const people = Store.useSelector((state) => state.people);

  // Selected person
  const selectedPerson = Store.useSelector(selectPersonById(id));

  // Return title, name, loading, submitting, submit success, and delete action
  return useMemo(
    (): DialogDeleteModuleSchema => ({
      title: lang("DIALOG_TITLE_DELETE_PERSON"),
      name: selectedPerson?.firstName,
      loading: !people.data,
      submitting: !!people.loading,
      submitSuccess: people.submitSuccess,
      handleDelete: (): void => {
        if (!selectedPerson?._id || !plan.data?._id) {
          throw new Error("Person Id or Plan Id is not defined");
        }

        void dispatch(
          Store.people.deletePerson({
            authToken: auth.authToken,
            planId: plan.data._id,
            personId: selectedPerson._id,
          })
        );
      },
    }),
    [
      dispatch,
      people.data,
      people.loading,
      people.submitSuccess,
      auth.authToken,
      plan.data?._id,
      selectedPerson?._id,
      selectedPerson?.firstName,
    ]
  );
};
