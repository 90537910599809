// React
import React from "react";
// Typings
import { LayoutProps } from "@layouts/types";
// Hooks
import { useGlobalNotifications } from "@hooks/global-notifications";
// Components
import { AfContainer, AfSection } from "@advicefront/ds-grid";

export const DefaultLayout = ({ children }: LayoutProps): React.ReactElement => {
  // Notifications hook
  useGlobalNotifications();

  return (
    <AfSection>
      <AfContainer fullWidth>{children}</AfContainer>
    </AfSection>
  );
};
