// Redux
import { createAction, ActionCreatorWithPayload } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";

/**
 * Reset to initial data state
 * @example dispatch(Auth.reset())
 */

export const reset = createAction("auth/reset");

/**
 * Store a new token & client group id
 * @example dispatch(Auth.setCredentials(\{
 * clientGroupId
 * authToken
 * \}))
 */

export const setCredentials: ActionCreatorWithPayload<StateProps> = createAction(
  "auth/set-credentials",
  (value: StateProps) => ({ payload: value })
);
