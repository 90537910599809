/**
 * Tabs
 * Naming convention: TABS_ELEMENT
 */
export const tabs = {
  // List
  TAB_ACTIVE: {
    en: "Active",
  },
  TAB_ARCHIVED: {
    en: "Archived",
  },

  // Details
  TAB_OVERVIEW: {
    en: "Overview",
  },
  TAB_INPUTS: {
    en: "Inputs",
  },
  TAB_CASHFLOW: {
    en: "Cashflow",
  },
  TAB_ASSETS_AND_LIABILITIES: {
    en: "Assets & Liabilities",
  },
  TAB_TAX: {
    en: "Tax",
  },
  TAB_ASSUMPTIONS: {
    en: "Assumptions",
  },
  TAB_PROTECTIONS: {
    en: "Protections",
  },

  // Assumptions
  TAB_GENERAL: {
    en: "General",
  },
  TAB_LIQUIDATION: {
    en: "Liquidation",
  },
  TAB_ACCOUNT_LIMITS: {
    en: "Account Limits",
  },

  // Protections
  TAB_PROTECTION: {
    en: "Protection",
  },
  TAB_TPD: {
    en: "Total Permanent Disability",
  },
  TAB_MAJOR_ILLNESS: {
    en: "Major Illness",
  },
};
