// React
import { useCallback, useMemo } from "react";
// Router
import { useSearchParams, createSearchParams } from "react-router-dom";
import { getAllSearchParams } from "@routes/utils/get-all-params";
// Typings
import { FormModalParams, FormActions, FormKeys, LocationStateEntries } from "@typings/routing";
import { InputGroups } from "@advicefront/plan-client-axios";
// Utils
import {
  isFormKey,
  isFormAction,
  isInputGroupOrFormKeys,
} from "@routes/utils/modal-routing-guards";

// Props
interface ModalRoutingParams {
  [FormModalParams.id]?: string | null;
  [FormModalParams.action]?: FormActions | null;
  [FormModalParams.type]?: FormKeys | null;
  [FormModalParams.subType]?: string | null;
  [FormModalParams.referral]?: FormActions | null;
  [FormModalParams.formSelection]?: InputGroups | FormKeys | null;
  [FormModalParams.fromFormSelection]?: string | null;
}

export interface ModalRoutingProps extends Partial<ModalRoutingParams> {
  openModal: (params: ModalRoutingParams) => void;
  linkToOpenModal: (params: ModalRoutingParams) => string;
  closeModal: () => void;
}

export const useModalRouting = (): ModalRoutingProps => {
  // Url params
  const [urlParams, setUrlParams] = useSearchParams();

  // Get all url params
  const allParams = useMemo(() => getAllSearchParams(urlParams), [urlParams]);

  // Get each url params
  const id = urlParams.get(FormModalParams.id);
  const action = urlParams.get(FormModalParams.action);
  const type = urlParams.get(FormModalParams.type);
  const subType = urlParams.get(FormModalParams.subType);
  const referral = urlParams.get(FormModalParams.referral);
  const formSelection = isInputGroupOrFormKeys(urlParams.get(FormModalParams.formSelection));
  const fromFormSelection = urlParams.get(FormModalParams.fromFormSelection);

  // Handle url params
  // Helper to generate URL search params
  // Will add the provided params as URLSearchParams and remove if param value is null
  const handleUrlParams = useCallback(
    (params: ModalRoutingParams): URLSearchParams =>
      new URLSearchParams({
        ...Object.fromEntries(
          Object.entries(allParams).filter(([key]) => !Object.keys(params).includes(key))
        ),
        // Keep other (possibly) defined params
        ...Object.fromEntries(
          Object.entries(params)
            .filter(([, value]) => !!value)
            .map(([key, value]) => [key, value])
        ),
      }),
    [allParams]
  );

  // Open modal
  // Helper to add url Params through setUrlParams
  const openModal = useCallback<ModalRoutingProps["openModal"]>(
    (params) =>
      setUrlParams(handleUrlParams(params), {
        state: {
          [LocationStateEntries.fromApp]: true,
        },
      }),
    [handleUrlParams, setUrlParams]
  );

  // Link to open modal
  // Helper to add url Params through createSearchParams
  const linkToOpenModal = useCallback<ModalRoutingProps["linkToOpenModal"]>(
    (params) => "./?" + createSearchParams(handleUrlParams(params)).toString(),
    [handleUrlParams]
  );

  // Close modal
  // Helper to remove all params from url
  const closeModal = useCallback(
    (): void =>
      setUrlParams(
        Object.fromEntries(
          Object.entries(allParams).filter(([key]) => !Object.keys(FormModalParams).includes(key))
        )
      ),
    [allParams, setUrlParams]
  );

  // Throw error is an unrecognized action
  if (typeof action === "string" && !isFormAction(action)) {
    throw new Error("Unrecognized form action");
  }

  // Throw error is an unrecognized type
  if (typeof type === "string" && !isFormKey(type)) {
    throw new Error("Unrecognized form type");
  }

  // Throw error is an unrecognized referral
  if (typeof referral === "string" && !isFormAction(referral)) {
    throw new Error("Unrecognized form referral");
  }

  return {
    id,
    action,
    type,
    subType,
    referral,
    formSelection,
    fromFormSelection,
    openModal,
    closeModal,
    linkToOpenModal,
  };
};
