// Store
import { StoreState } from "@store/index";
// Typings
import { Field, FIELD_TYPES } from "@advicefront/fe-infra-form-schema";
// Translations
import { lang } from "@lang/index";

// DTO which the form refers
interface FormDto {
  planId: string;
  name: string;
}

// DTO properties and keys to display as fields
type FormDtoKey = keyof FormDto;

// Common validation
const validation = {
  required: true,
  errorMessage: lang("FORM_VALIDATOR_REQUIRED_FIELD"),
};

// Schema used for Duplicate Plan
export const duplicateSchema = (
  plansData: StoreState["plans"]["data"]
): Record<FormDtoKey, Field> => ({
  planId: {
    key: "planId",
    fieldType: FIELD_TYPES.singleSelection,
    options:
      plansData?.map((plan) => ({
        label: plan.name,
        value: plan._id,
      })) || [],
    validation,
  },
  name: {
    key: "name",
    fieldType: FIELD_TYPES.text,
    validation,
  },
});
