// Styles
import "./styles/index.scss";
// React
import React, { useContext, useMemo } from "react";
import { renderToStaticMarkup } from "react-dom/server";
// Highcharts
import { HighchartsReact as Chart } from "highcharts-react-official";
import Highcharts from "highcharts";
// Typings
import { ChartRingModulesName } from "@typings/charts/ring";
import { Colors } from "@typings/colors";
// Hooks
import { useChartRing } from "@components/charts/ring/hooks";
// Context
import { AppOptionsContext } from "@context/app-options";
// Utils
import { maskCurrency } from "@utils/format-currency";
// Translations
import { lang } from "@lang/index";

// Props
interface ChartRingProps {
  name: ChartRingModulesName;
}

export const ChartRing = ({ name }: ChartRingProps): React.ReactElement => {
  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // Hook to get current chart module data
  const chartData = useChartRing(name);

  // Define ring chart options
  const chartOptions = useMemo(
    (): Highcharts.Options => ({
      // Defaults
      title: undefined,
      credits: undefined,
      accessibility: {
        enabled: false,
      },

      // Chart
      chart: {
        width: 350,
        height: 200,
      },

      // Plot
      plotOptions: {
        pie: {
          size: 140,
          innerSize: 80,
          borderRadius: 0,
          showInLegend: true,
          dataLabels: {
            enabled: false,
          },
        },
      },

      // Legend
      legend: {
        useHTML: true,
        width: "50%",
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
        squareSymbol: false,
        symbolWidth: 22,
        symbolHeight: 6,
        itemMarginBottom: 8,
        itemStyle: {
          color: Colors.TextLight,
        },
        itemHoverStyle: {
          color: Colors.Text,
        },
        itemHiddenStyle: {
          textDecoration: "none",
          color: Colors.TextInactive,
        },
        navigation: {
          activeColor: Colors.Primary,
          inactiveColor: Colors.TextInactive,
          arrowSize: 8,
          style: {
            fontFamily: '"Af Design System Font", sans-serif',
            fontSize: "10px",
            transform: "translate(-2px, -2px)",
            color: Colors.TextLight,
          },
        },
        labelFormatter: function (): string {
          return renderToStaticMarkup(<span className="chart-ring__legend">{this.name}</span>);
        },
      },

      // Tooltip
      tooltip: {
        shadow: false,
        outside: true,
        followPointer: false,
        padding: 8,
        borderRadius: 8,
        backgroundColor: Colors.Text,
        style: {
          color: Colors.Paper,
          zIndex: 999,
        },
        formatter: function (): string {
          return renderToStaticMarkup(
            <span className="chart-ring__tooltip">
              {this.key}: <b>{maskCurrency(this.y || 0, currencySymbol)}</b>
            </span>
          );
        },
      },

      // Series
      series: [
        {
          type: "pie",
          data: chartData?.length
            ? chartData
            : [
                {
                  name: lang("CHART_NO_INPUTS"),
                  color: Colors.TextInactive,
                },
              ],
        },
      ],
    }),
    [chartData, currencySymbol]
  );

  return (
    <div className="chart-ring">
      <Chart highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};
