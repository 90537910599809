// React
import React from "react";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfCard } from "@advicefront/ds-card";
import { AfSkeleton } from "@advicefront/ds-skeleton";

export const LoaderSkeletonProfile = (): React.ReactElement => (
  <AfCard>
    <AfCard.Content>
      <AfRow align="center">
        <AfCol>
          <AfSkeleton size="l" shape="circle" />
        </AfCol>
        <AfCol size="auto">
          <AfRow spacing="lg">
            <AfCol size={10}>
              <AfSkeleton shape="line" size="xl" />
            </AfCol>
          </AfRow>
          <AfRow spacing="lg">
            <AfCol size={6}>
              <AfSkeleton shape="line" size="l" />
            </AfCol>
          </AfRow>
        </AfCol>
      </AfRow>
    </AfCard.Content>
  </AfCard>
);
