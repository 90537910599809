// Typings
import { TableDraggableModules, TableDraggableModulesName } from "@typings/tables/draggable";
// Modules
import { useTableLiquidationModule } from "@components/tables/draggable/hooks/modules";

// Props: Hooks
type UseTableDraggable = (name: TableDraggableModulesName) => TableDraggableModuleSchema;

export type UseTableDraggableModule = () => TableDraggableModuleSchema;

// Props: Modules
export interface TableDraggableModuleEntry {
  id: string;
  label: string;
  value: string;
}

export interface TableDraggableModuleSchema {
  title: string;
  tooltip?: string;
  head: string[];
  entries: TableDraggableModuleEntry[];
  fetching: boolean;
  loading: boolean;
  error: boolean;
  empty?: {
    title: string;
    description?: string;
  };
}

/**
 * Maps each draggable module to its corresponding table hook module
 * Each draggable module has its own specific hook to retrieve its table data
 */
const TableDraggableMap: Record<TableDraggableModulesName, UseTableDraggableModule> = {
  [TableDraggableModules.Liquidation]: useTableLiquidationModule,
};

/**
 * Returns table data for a specific draggable module
 * Determines which module hook to use based on the draggable module name
 * The hook will receive the current module name
 * @param name - name of the draggable module
 * @returns the table data for the specified draggable module
 */
export const useTableDraggable: UseTableDraggable = (name) => TableDraggableMap[name]();
