// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
import { selectTaxDetails } from "@store/selectors/tax-details";
// Typings
import { ChartColumnModuleSchema, UseChartColumnModule } from "@components/charts/column/hooks";
import { ChartColumnOptions, ChartColumnTabs } from "@typings/charts/column";
import { Colors } from "@typings/colors";
// Utils
import { getClientsRetirement, getTaxesSeries } from "@utils/charts";
// Translations
import { lang } from "@lang/index";

// Props
type ChartTabs = ChartColumnTabs.Owner | ChartColumnTabs.Partner;

type ChartViews = Record<ChartTabs, NonNullable<ChartColumnModuleSchema["series"]>>;

export const useChartTaxModule: UseChartColumnModule = ({ tab, detailed }) => {
  // Store
  const forecast = Store.useSelector((state) => state.forecast);

  // Get forecast with tax details for owner and partner
  const { owner, partner } = Store.useSelector(selectTaxDetails());

  // Get selected tab to render specific chart data
  // By default it will be "Owner" if no tab is checked on the "tabs" prop
  const selectedTab = useMemo(
    (): ChartTabs =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (tab as ChartTabs) || ChartColumnTabs.Owner,
    [tab]
  );

  // Get clients retirement "xLines" and "series" data
  const clientsRetirement = useMemo(
    () => getClientsRetirement(forecast.data?.clients),
    [forecast.data?.clients]
  );

  // Default view for Owner and Partner
  const defaultView = useMemo(
    (): ChartViews => ({
      [ChartColumnTabs.Owner]: [
        // Tax
        {
          type: "column",
          id: lang("CHART_TAX"),
          name: lang("CHART_TAX"),
          color: Colors.Olenna,
          data: owner?.chart.total?.values.map((value) => Math.abs(Number(value))),
          index: 1,
          zIndex: 1,
          legendIndex: 50,
        },

        // Partner
        {
          type: "line",
          id: lang("CHART_PARTNER_TAX"),
          name: lang("CHART_PARTNER_TAX"),
          color: Colors.Text,
          data: partner?.chart.total?.values.map((value) => Math.abs(Number(value))),
          visible: !!partner?.chart.total,
          showInLegend: !!partner?.chart.total,
          index: 2,
          zIndex: 2,
          legendIndex: 55,
          lineWidth: 2,
          step: "center",
          marker: {
            enabled: false,
          },
        },
      ],

      [ChartColumnTabs.Partner]: [
        // Tax
        {
          type: "column",
          id: lang("CHART_TAX"),
          name: lang("CHART_TAX"),
          color: Colors.Olenna,
          data: partner?.chart.total?.values.map((value) => Math.abs(Number(value))),
          index: 1,
          zIndex: 1,
          legendIndex: 50,
        },

        // Owner
        {
          type: "line",
          id: lang("CHART_OWNER_TAX"),
          name: lang("CHART_OWNER_TAX"),
          color: Colors.Text,
          data: owner?.chart.total?.values.map((value) => Math.abs(Number(value))),
          index: 2,
          zIndex: 2,
          legendIndex: 55,
          lineWidth: 2,
          step: "center",
          marker: {
            enabled: false,
          },
        },
      ],
    }),
    // Add "selectedTab" dependency to force refresh chart when changing tab
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTab, owner?.chart.total?.values, partner?.chart.total]
  );

  // Detailed view for Owner and Partner
  const detailedView = useMemo(
    (): ChartViews => ({
      [ChartColumnTabs.Owner]: [
        // Taxes
        ...(getTaxesSeries(owner?.chart.detailed) || []),
        // Partner
        defaultView.owner[1],
      ],

      [ChartColumnTabs.Partner]: [
        // Taxes
        ...(getTaxesSeries(partner?.chart.detailed) || []),
        // Owner
        defaultView.partner[1],
      ],
    }),
    // Add "detailed" and "selectedTab" dependencies to force refresh
    // chart when changing tab or switching detailed option
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailed, selectedTab, defaultView, owner?.chart.detailed, partner?.chart.detailed]
  );

  // Return chart data object
  return useMemo(
    (): ChartColumnModuleSchema => ({
      ...(partner?.chart.total && {
        tabs: [
          {
            label: lang("CHART_OWNER"),
            value: ChartColumnTabs.Owner,
            checked: true,
          },
          {
            label: lang("CHART_PARTNER"),
            value: ChartColumnTabs.Partner,
            checked: false,
          },
        ],
      }),
      options: [
        {
          label: lang("ACTION_DETAILED"),
          value: ChartColumnOptions.Detailed,
          checked: false,
        },
        {
          label: lang("ACTION_ZOOM"),
          value: ChartColumnOptions.Zoom,
          checked: false,
        },
      ],
      categories: {
        title: lang("CHART_YEARS"),
        values: forecast.data?.years.map((year) => year.toString()),
      },
      subCategories: {
        titles: [
          forecast.data?.clients.owner.name?.split(" ")[0],
          forecast.data?.clients.partner?.name?.split(" ")[0],
        ],
        values: [
          forecast.data?.clients.owner.ageRange?.map((value) => value.toString()),
          forecast.data?.clients.partner?.ageRange?.map((value) => value.toString()),
        ],
      },
      xLines: clientsRetirement?.xLines,
      series: [
        ...(detailed ? detailedView[selectedTab] : defaultView[selectedTab]),
        ...(clientsRetirement?.series || []),
      ],
    }),
    [
      detailed,
      selectedTab,
      defaultView,
      detailedView,
      partner,
      clientsRetirement,
      forecast.data?.years,
      forecast.data?.clients,
    ]
  );
};
