// Typings
import { CreateClientPersonDto, LegalGender } from "@advicefront/plan-client-axios";
import { Field, FIELD_TYPES } from "@advicefront/fe-infra-form-schema";
import { ClientNamingMode } from "@typings/client-naming-mode";
// Translations
import { lang } from "@lang/index";

// DTO which the form refers without fields that are not shown on form
type FormDto = Omit<Required<CreateClientPersonDto>, "clientType">;

// DTO properties and keys to display as fields
type FormDtoKey = keyof FormDto;

// Common validation
const validation = {
  required: true,
  errorMessage: lang("FORM_VALIDATOR_REQUIRED_FIELD"),
};

// Genders
// Other gender from BE will not be displayed
const genders = {
  [LegalGender.M]: lang("FORM_LABEL_MALE"),
  [LegalGender.F]: lang("FORM_LABEL_FEMALE"),
};

// Schema used for Owner and Partner
export const clientSchema = (
  clientNamingMode: ClientNamingMode
): Partial<Record<FormDtoKey, Field>> => ({
  firstName: {
    key: "firstName",
    fieldType: FIELD_TYPES.text,
    validation,
  },
  // Last name field will be only display on client naming mode "firstLast"
  ...(clientNamingMode === ClientNamingMode.firstLast && {
    lastName: {
      key: "lastName",
      fieldType: FIELD_TYPES.text,
    },
  }),
  yearOfBirth: {
    key: "yearOfBirth",
    fieldType: FIELD_TYPES.text,
    validation,
  },
  legalGender: {
    key: "gender",
    fieldType: FIELD_TYPES.singleSelection,
    options: Object.entries(genders).map(([value, label]) => ({
      label,
      value,
    })),
    validation,
  },
  retirementAge: {
    key: "retirementAge",
    fieldType: FIELD_TYPES.text,
  },
});
