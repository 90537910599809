// React
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
// Typings
import {
  FormModuleInstance,
  FormModuleComponentProps,
  FormModuleHookProps,
  FormModuleInputInstance,
} from "@forms/modules/types";
import {
  FormSchemaRenderer,
  FormSchemaRendererOnChangeProps,
  FormSchemaRendererRef,
} from "@advicefront/fe-infra-form-schema-renderer";
import { FormRenderer } from "@forms/renderer";
import { FormRendererValidation } from "@forms/renderer/types";
// Utils
import { isEqual } from "@utils/is-equal";
// Hooks
import { useUniqueId } from "@advicefront/ds-base";
// Translations
import { lang } from "@lang/index";
// Components
import { AfAlert } from "@advicefront/ds-alert";
import { LoaderOverlaySpinner } from "@components/loaders/overlay-spinner";
import { LoaderSkeletonModal } from "@components/loaders/skeleton";

// Props
type ComponentCreatorFromInstance = (
  instance: FormModuleInstance | FormModuleInputInstance
) => FormModuleInstance["Component"];

export const CreateComponentInstance: ComponentCreatorFromInstance = (instance) => {
  /**
   * React component that renders the form
   * @param props - form module component properties
   * @returns form schema renderer
   */
  const Component = (props: FormModuleComponentProps): React.ReactElement => {
    // Destructure props from form module components
    const { formKey, formDto, setFormId, setFormChanged } = props;

    // Create form renderer references for form schema
    const formRef = useRef<HTMLFormElement>(null);
    const formRendererRef = useRef<FormSchemaRendererRef>();
    const formRendererChangeRef = useRef<FormSchemaRendererOnChangeProps | undefined>(undefined);

    // Generate a unique identifier for the form
    const formId = useUniqueId();

    // State to control the form data
    // The "changeType" is needed to separate intentional from "effect" changes,
    // otherwise it would cause onChange to be called when it's not supposed
    const [formData, setFormData] = useState<
      | {
          changeType: "programmatic" | "user";
          data: FormModuleHookProps["formData"];
        }
      | undefined
    >(undefined);

    // State to control if form has initial values
    const [hasInitialValues, setHasInitialValues] = useState(false);

    // State to check form validation on submit and fields that are not valid
    const [formValidation, setFormValidation] = useState<undefined | FormRendererValidation>(
      undefined
    );

    // Function to set the form values to the provided data
    const setValues = useCallback<FormModuleHookProps["setValues"]>(
      (data) => {
        // Throw error if the form reference is not available
        if (!formRendererRef.current) {
          throw new Error("Unable to update values");
        }
        // Set the form values using the form reference
        formRendererRef.current.setValues(data);
        // Update the formData state with the new data
        setFormData({
          changeType: "programmatic",
          data,
        });
      },
      [setFormData]
    );

    // Define the hooks props used on instances
    const hookProps: FormModuleHookProps = {
      ...props,
      formData: formData?.data,
      setValues,
    };

    // Retrieve form data and behavior hooks from the form instance
    const schema = instance.useSchema(hookProps);
    const schemaStructure = instance.useSchemaStructure(hookProps);
    const inputRenderer = instance.useInputRenderer(hookProps);
    const initialValues = instance.useInitialValues(hookProps);
    const formatters = instance.useFormatters(hookProps);
    const validators = instance.useValidators(hookProps);
    const onChange = instance.useOnChange(hookProps);
    const submit = instance.useSubmit(hookProps);

    // Create form schema renderer instance
    const formRenderer = useMemo(
      () =>
        new FormRenderer({
          inputRenderer,
          formKey,
          formDto,
          formValidation,
        }),
      [formKey, formDto, inputRenderer, formValidation]
    );

    // Handle form submission
    const handleFormSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
      async (ev) => {
        // Prevent the default form submission behavior
        ev.preventDefault();
        // Return if the formRendererRef is not available
        if (!formRendererRef.current) return;
        // Get the current form values
        const formValues = formRendererRef.current.getValues();
        // Run validation on all form fields
        const formValidation = await formRendererRef.current.validate();
        // Extract any invalid fields from the validation data
        const invalidFields = formValidation.data
          .filter((item) => item.status === "error")
          .map((item) => item.valueKey);
        // Set the form validation state based on the validation result
        setFormValidation({
          valid: formValidation.valid,
          invalidFields,
        });
        // Submit form if is valid
        if (formValidation.valid) {
          submit?.handleSubmit(formValues);
        }
      },
      [formRendererRef, submit]
    );

    // Handle form on change
    const handleFormChange = useCallback(
      (data: FormSchemaRendererOnChangeProps): void => {
        // Store the form data changes in a ref
        formRendererChangeRef.current = data;
        // Get the current form values
        const formValues = formRendererRef.current?.getValues();
        // Update the formData state with the new form data
        setFormData({
          changeType: "user",
          data: formValues,
        });
        // Return if there are no form values
        if (!formValues) return;
        // Check if the form is empty
        const formEmpty = Object.values(formValues).every((value) => !value);
        // If the form is empty and the initial values weren't changed set formChanged to false
        if (formEmpty && !initialValues) {
          return setFormChanged(false);
        }
        // Check if the form values are different from the initial values and update the state accordingly
        return setFormChanged(!isEqual(formValues, initialValues));
      },
      [initialValues, setFormChanged]
    );

    // Set form id for submit reference
    useEffect(() => {
      setFormId(formId);
    }, [setFormId, formId]);

    // Set initial values for edit or create form actions
    useEffect(() => {
      // Return if formRendererRef is not set or if initialValues is not set
      if (!formRendererRef.current || !initialValues || hasInitialValues) {
        return;
      }
      // Merge initial values with existing values in the form
      setValues({
        ...formRendererRef.current.getValues(),
        ...initialValues,
      });
      // Set hasInitialValues to true to avoid executing this block again
      setHasInitialValues(true);
    }, [formRendererRef, initialValues, hasInitialValues, setValues]);

    // Call onChange function when the form data is updated by the user
    useEffect(() => {
      // Return if the change type was not caused by user interaction
      if (formData?.changeType !== "user") return;
      // Throw an error if the form renderer change event is not available
      if (!formRendererChangeRef.current) {
        throw new Error("Unable to get event data");
      }
      // Call the onChange callback function with the form renderer change event
      onChange(formRendererChangeRef.current);
    }, [formData, onChange]);

    // Return form component
    return (
      <>
        {!!schema?.loading && <LoaderSkeletonModal />}

        {!schema?.loading && !schema?.data && (
          <AfAlert skin="error" description={lang("ERROR_MISSING_FORM")} />
        )}

        {!schema?.loading && schema?.data && (
          <LoaderOverlaySpinner bordered={false} active={!!submit?.loading}>
            <form ref={formRef} id={formId} onSubmit={handleFormSubmit}>
              <FormSchemaRenderer
                ref={formRendererRef}
                wrapperRef={formRef}
                schemaRenderer={formRenderer}
                schema={schema.data}
                structure={schemaStructure}
                formatters={formatters}
                validators={validators}
                onChange={handleFormChange}
              />
            </form>
          </LoaderOverlaySpinner>
        )}
      </>
    );
  };

  return Component;
};
