// Translations
import { actions } from "./actions";
import { charts } from "./charts";
import { chips } from "./chips";
import { dialogs } from "./dialogs";
import { empty } from "./empty";
import { errors } from "./errors";
import forms from "./forms";
import { inputs } from "./inputs";
import { loaders } from "./loaders";
import { modals } from "./modals";
import { notifications } from "./notifications";
import tables from "./tables";
import { tabs } from "./tabs";
import { tooltips } from "./tooltips";
import views from "./views";

// Export all translations
export default {
  ...actions,
  ...charts,
  ...chips,
  ...empty,
  ...errors,
  ...dialogs,
  ...forms,
  ...tables,
  ...inputs,
  ...loaders,
  ...modals,
  ...notifications,
  ...tabs,
  ...tooltips,
  ...views,
};
