// React
import { useCallback, useEffect, useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { FormModuleInputInstance, UseSubmitProps } from "@forms/modules/types";
import { FormData } from "@advicefront/fe-infra-form-schema";
// Constants
import { EMPTY_OPTION_VALUE } from "@constants/index";
// Utils
import { asFormType } from "@routes/utils/modal-routing-guards";
// Hooks
import { useDtoKey } from "@forms/modules/input/hooks";

export const useSubmit: FormModuleInputInstance["useSubmit"] = (props) => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);
  const inputs = Store.useSelector((state) => state.inputs);

  // Get Dto form key
  const dtoKey = useDtoKey(props);

  // Destructure props object
  const { itemId, onSubmitSuccess } = props;

  // Check if form submission was successful and trigger external handler
  useEffect(() => {
    if (!inputs.submitSuccess) return;
    onSubmitSuccess();
  }, [onSubmitSuccess, inputs.submitSuccess]);

  // Handle form submission
  const handleSubmit = useCallback(
    (values: FormData): void => {
      // Ensure we have the necessary data to submit the form
      if (!auth.authToken || !dtoKey || !plan.data?._id) {
        throw new Error("Unable to submit form data: missing authToken dtoKey or planId");
      }

      // Convert all empty values and empty option value from payload object to null
      Object.keys(values).forEach(
        (k) => (values[k] = values[k] === "" || values[k] === EMPTY_OPTION_VALUE ? null : values[k])
      );

      // Force type matching of values to the expected object format to be received on API client
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const data = values as unknown as Parameters<typeof Store.inputs.create>[number]["inputData"];

      // Update input
      if (itemId) {
        void dispatch(
          Store.inputs.update({
            authToken: auth.authToken,
            planId: plan.data._id,
            inputData: data,
            inputId: itemId,
          })
        );
      }

      // Create input
      if (!itemId) {
        void dispatch(
          Store.inputs.create({
            authToken: auth.authToken,
            planId: plan.data._id,
            inputData: data,
            type: asFormType(dtoKey),
          })
        );
      }
    },
    [dispatch, dtoKey, itemId, auth.authToken, plan.data?._id]
  );

  return useMemo(
    (): UseSubmitProps => ({
      loading: !!inputs.loading || !!plan.loading,
      handleSubmit,
    }),
    [handleSubmit, inputs.loading, plan.loading]
  );
};
