/**
 * Format Date
 * Formats a date string into a localized date format
 * @param date - the input date string
 * @example formatDate("2023-05-22T11:36:34.028Z") = "May 22, 2023"
 * @example formatDate("2022-12-10") = "Dec 10, 2022"
 * @returns the formatted date string
 */
export const formatDate = (date: string): string => {
  // Create a new Date object from the input date string
  const newDate = new Date(date);

  // Format the date using the "en-US" locale and specific formatting options
  return newDate.toLocaleDateString("en-US", {
    year: "numeric",
    day: "numeric",
    month: "short",
  });
};
