// Typings
import { TableScrollableModules, TableScrollableModulesName } from "@typings/tables/scrollable";
import { AfTypographyProps } from "@advicefront/ds-typography";
import { ForecastInputGroups } from "@advicefront/plan-client-axios";
// Modules
import {
  useTableAssetsLiabilitiesModule,
  useTableCashflowModule,
  useTableTaxOwnerModule,
  useTableTaxPartnerModule,
} from "@components/tables/scrollable/hooks/modules";

// Props: Hooks
type UseTableScrollable = (name: TableScrollableModulesName) => TableScrollableModuleSchema;

export type UseTableScrollableModule = () => TableScrollableModuleSchema;

// Props: Modules
export interface TableScrollableModuleAction {
  type?: ForecastInputGroups;
  subType: string;
  id: string;
}

export interface TableScrollableModuleEntry {
  label: string;
  type?: AfTypographyProps["type"];
  values?: (string | number)[];
  highlighted?: boolean;
  tooltip?: string;
  action?: TableScrollableModuleAction;
}

export interface TableScrollableModuleSchema {
  head: (string | number)[];
  entries: TableScrollableModuleEntry[];
  fetching: boolean;
  polling?: boolean;
  empty?: {
    title: string;
    description?: string;
  };
}

/**
 * Maps each scrollable module to its corresponding table hook module
 * Each scrollable module has its own specific hook to retrieve its table data
 */
const TableScrollableMap: Record<TableScrollableModulesName, UseTableScrollableModule> = {
  [TableScrollableModules.AssetsLiabilities]: useTableAssetsLiabilitiesModule,
  [TableScrollableModules.Cashflow]: useTableCashflowModule,
  [TableScrollableModules.TaxOwner]: useTableTaxOwnerModule,
  [TableScrollableModules.TaxPartner]: useTableTaxPartnerModule,
};

/**
 * Returns table data for a specific scrollable module
 * Determines which module hook to use based on the scrollable module name
 * The hook will receive the current module name
 * @param name - name of the scrollable module
 * @returns the table data for the specified scrollable module
 */
export const useTableScrollable: UseTableScrollable = (name) => TableScrollableMap[name]();
