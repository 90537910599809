// React
import React from "react";
// Router
import { View } from "@routes/index";
// Layouts
import { PreferencesLayout } from "@layouts/preferences";
// Views
import { PreferencesGeneralTable } from "@views/preferences/general/components/Table";

// Define element for the view
const Element = (): React.ReactElement => (
  <PreferencesLayout isAdvisorPreferences={false}>
    <PreferencesGeneralTable isAdvisorPreferences={false} />
  </PreferencesLayout>
);

// Route view options
export const firmPreferencesGeneral: View = {
  name: "Firm Preferences General",
  options: {
    path: "/firm-preferences/general",
    element: <Element />,
  },
};
