// Redux
import { Store, StoreState } from "@store/index";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import { API } from "@services/api";
// Initial State
import { initialState } from "./intial-state";
// Types
import { StateProps } from "./types";
// Translations
import { lang } from "@lang/index";

/**
 * Reset to initial data state
 * @example dispatch(Plans.reset())
 */

export const reset = createAction("plans/reset");

/**
 * Get Plans
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Plans.fetch(\{
 *  authToken,
 *  clientGroupId,
 *  archived
 * \}));
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  Required<StoreState["auth"]>,
  { state: StoreState }
>("plans/fetch", async ({ authToken, clientGroupId }, { dispatch }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  try {
    await (async function load(): Promise<void> {
      const res = (
        await API.Plans(authToken).findAllPlans({
          limit: 10,
          orderBy: "updatedAt",
          orderDirection: "desc",
          clientGroupId,
          startAfter: data && data.length,
        })
      ).data;

      data = data ? [...data, ...res.data] : [...res.data];

      if (res.hasNextPage) await load(); // Self invoke / call if there are still items to load
    })();
  } catch (e) {
    error = API.parseError(e);
  }

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
