// Typings
import { FormKeys } from "@typings/routing";
import { InputGroups } from "@advicefront/plan-client-axios";
// Utils
import * as CC from "change-case";
// Translations
import { lang } from "@lang/index";

/**
 * Get Input Label
 * Method to format an input group or form key input label
 * @param value - input group or form key
 * @returns input label translation or formatted
 */
export const getInputLabel = (value: InputGroups | FormKeys): string => {
  switch (value) {
    // Saving & Investments
    case FormKeys.AccountInput:
      return lang("ACTION_CREATE_SAVINGS_AND_INVESTMENTS");

    // Property & Assets
    case FormKeys.AssetInput:
      return lang("ACTION_CREATE_PROPERTY_AND_ASSETS");

    // Expenses
    case FormKeys.ExpenseInput:
      return lang("ACTION_CREATE_EXPENSES");

    // Default
    default:
      return CC.capitalCase(value.replace("Input", ""));
  }
};
