// React
import React, { useMemo } from "react";
// Router
import { useParams, useLocation, generatePath, Link } from "react-router-dom";
import { getRoute } from "@routes/utils/get-route";
// Store
import { Store } from "@store/index";
// Typings
import { Regions } from "@advicefront/plan-client-axios";
import { RouteIndex } from "@routes/index";
// Translations
import { lang } from "@lang/index";
// Components
import { AfTabs } from "@advicefront/ds-tabs";

export const AssumptionsTabs = (): React.ReactElement => {
  // Store
  const plan = Store.useSelector((state) => state.plan);

  // Route
  const params = useParams();
  const { pathname } = useLocation();

  // List of all tabs
  const tabs = useMemo(() => {
    // Add Tax tab for GBR region and if it is a gross basis net plan
    const showTaxTab = plan.data?.region === Regions.Gbr && !plan.data?.net;

    // Define order of tabs
    const tabsOrdered: Record<string, RouteIndex> = {
      [lang("TAB_GENERAL")]: "assumptionsGeneral",
      ...(showTaxTab && { [lang("TAB_TAX")]: "assumptionsTax" }),
      [lang("TAB_LIQUIDATION")]: "assumptionsLiquidation",
      [lang("TAB_ACCOUNT_LIMITS")]: "assumptionsAccounts",
    };

    // Return list of tabs ordered with title and route
    return Object.entries(tabsOrdered).map(([title, routeIndex]) => ({
      title,
      route: generatePath(getRoute(routeIndex), params),
    }));
  }, [params, plan.data]);

  return (
    <AfTabs direction="vertical" className="sticky-tabs">
      {tabs.map((tab) => (
        <AfTabs.Item
          key={tab.route}
          active={pathname.endsWith(tab.route)}
          component={<Link to={tab.route} />}
        >
          {tab.title}
        </AfTabs.Item>
      ))}
    </AfTabs>
  );
};
