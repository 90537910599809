// React
import { useContext, useEffect, useRef } from "react";
// Typings
import { ClientType, LegalGender } from "@advicefront/plan-client-axios";
import { ClientGroupInfo } from "@typings/client-group-info";
import { FormModuleInstance } from "@forms/modules/types";
// Context
import { AppOptionsContext } from "@context/app-options";

// Map gender strings to LegalGender enum values
const GENDER_MAP = {
  male: LegalGender.M,
  female: LegalGender.F,
};

export const useInitialValues: FormModuleInstance["useInitialValues"] = () => {
  // Context
  const { getClientGroupInfo } = useContext(AppOptionsContext);

  // Use a ref to store the owner data
  const ownerData = useRef<ClientGroupInfo | null>();

  // Retrieve the client group info from mono repo
  useEffect(() => {
    void (async (): Promise<void> => {
      const data = (await getClientGroupInfo())?.find((client) => client.type === ClientType.Owner);
      ownerData.current = data;
    })();
  }, [getClientGroupInfo]);

  // Return form data
  return {
    firstName: ownerData.current?.firstName || "",
    lastName: ownerData.current?.lastName || "",
    legalGender: (ownerData.current?.gender && GENDER_MAP[ownerData.current?.gender]) || null,
    yearOfBirth: ownerData.current?.yearOfBirth?.toString() || null,
    retirementAge: ownerData.current?.retirementAge?.toString() || null,
  };
};
