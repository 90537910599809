// React
import React, { ReactElement } from "react";
// Router
import { View } from "@routes/index";
// Layouts
import { PlanDetailsLayout } from "@layouts/plan-details";
// Views
import { OverviewCharts } from "@views/overview/components/charts";
import { OverviewCoverage } from "@views/overview/components/coverage";
import { OverviewEvents } from "@views/overview/components/events";
import { OverviewPeople } from "@views/overview/components/people";

// Define element for the view
const Element = (): ReactElement => (
  <PlanDetailsLayout>
    <OverviewPeople />
    <OverviewCoverage />
    <OverviewCharts />
    <OverviewEvents />
  </PlanDetailsLayout>
);

// Route view options
export const overview: View = {
  name: "Overview",
  options: {
    path: "/plan/:id/overview",
    element: <Element />,
  },
};
