// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { ChartColumnModuleSchema, UseChartColumnModule } from "@components/charts/column/hooks";
import { ChartColumnOptions, ChartColumnTabs } from "@typings/charts/column";
import { Colors } from "@typings/colors";
// Utils
import { getClientsRetirement, getInputsSeries } from "@utils/charts";
// Translations
import { lang } from "@lang/index";

// Props
type ChartTabs = ChartColumnTabs.Inflow | ChartColumnTabs.Outflow;

type ChartViews = Record<ChartTabs, NonNullable<ChartColumnModuleSchema["series"]>>;

export const useChartCashflowModule: UseChartColumnModule = ({ tab, detailed }) => {
  // Store
  const forecast = Store.useSelector((state) => state.forecast);

  // Get selected tab to render specific chart data
  // By default it will be "Inflow" if no tab is checked on the "tabs" prop
  const selectedTab = useMemo(
    (): ChartTabs =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (tab as ChartTabs) || ChartColumnTabs.Inflow,
    [tab]
  );

  // Get clients retirement "xLines" and "series" data
  const clientsRetirement = useMemo(
    () => getClientsRetirement(forecast.data?.clients),
    [forecast.data?.clients]
  );

  // Default view for Inflow and Outflow
  const defaultView = useMemo(
    (): ChartViews => ({
      [ChartColumnTabs.Inflow]: [
        // Inflow
        {
          type: "column",
          id: lang("CHART_INFLOW"),
          name: lang("CHART_INFLOW"),
          color: Colors.Olenna,
          data: forecast.data?.cashflow.totalIncomes.map((value) => Math.abs(value)),
          index: 1,
          zIndex: 1,
          legendIndex: 50,
        },

        // Outflow
        {
          type: "line",
          id: lang("CHART_OUTFLOW"),
          name: lang("CHART_OUTFLOW"),
          color: Colors.Text,
          data: forecast.data?.cashflow.totalExpenses.map((value) => Math.abs(value)),
          index: 2,
          zIndex: 2,
          legendIndex: 55,
          lineWidth: 2,
          step: "center",
          marker: {
            enabled: false,
          },
        },

        // Shortfall
        {
          type: "column",
          id: lang("CHART_SHORTFALL"),
          name: lang("CHART_SHORTFALL"),
          color: Colors.Danger,
          // Get only negative values and convert them to positive for chart
          data: forecast.data?.cashflow.surplusAndShortfall.map((value) =>
            value > 0 ? 0 : Math.abs(value)
          ),
          index: 0,
          zIndex: 0,
          legendIndex: 60,
        },
      ],

      [ChartColumnTabs.Outflow]: [
        // Outflow
        {
          type: "column",
          id: lang("CHART_OUTFLOW"),
          name: lang("CHART_OUTFLOW"),
          color: Colors.Olenna,
          data: forecast.data?.cashflow.totalExpenses.map((value) => Math.abs(value)),
          index: 1,
          zIndex: 1,
          legendIndex: 50,
        },

        // Inflow
        {
          type: "line",
          id: lang("CHART_INFLOW"),
          name: lang("CHART_INFLOW"),
          color: Colors.Text,
          data: forecast.data?.cashflow.totalIncomes.map((value) => Math.abs(value)),
          index: 2,
          zIndex: 2,
          legendIndex: 55,
          lineWidth: 2,
          step: "center",
          marker: {
            enabled: false,
          },
        },

        // Surplus
        {
          type: "column",
          id: lang("CHART_SURPLUS"),
          name: lang("CHART_SURPLUS"),
          color: Colors.Success,
          // Get only positive values
          data: forecast.data?.cashflow.surplusAndShortfall.map((value) => (value > 0 ? value : 0)),
          index: 0,
          zIndex: 0,
          legendIndex: 60,
        },
      ],
    }),
    // Add "selectedTab" dependency to force refresh chart when changing tab
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedTab,
      forecast.data?.cashflow.totalIncomes,
      forecast.data?.cashflow.totalExpenses,
      forecast.data?.cashflow.surplusAndShortfall,
    ]
  );

  // Detailed view for Inflow and Outflow
  const detailedView = useMemo(
    (): ChartViews => ({
      [ChartColumnTabs.Inflow]: [
        // Inputs
        ...getInputsSeries(forecast?.data?.cashflow.incomes),
        // Outflow
        defaultView.inflow[1],
        // Shortfall
        defaultView.inflow[2],
      ],

      [ChartColumnTabs.Outflow]: [
        // Inputs
        ...getInputsSeries(forecast?.data?.cashflow.expenses),
        // Inflow
        defaultView.outflow[1],
        // Surplus
        defaultView.outflow[2],
      ],
    }),
    // Add "detailed" and "selectedTab" dependencies to force refresh
    // chart when changing tab or switching detailed option
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      detailed,
      selectedTab,
      defaultView,
      forecast?.data?.cashflow.incomes,
      forecast?.data?.cashflow.expenses,
    ]
  );

  // Return chart data object
  return useMemo(
    (): ChartColumnModuleSchema => ({
      tabs: [
        {
          label: lang("CHART_INFLOW"),
          value: ChartColumnTabs.Inflow,
          checked: true,
        },
        {
          label: lang("CHART_OUTFLOW"),
          value: ChartColumnTabs.Outflow,
          checked: false,
        },
      ],
      options: [
        {
          label: lang("ACTION_DETAILED"),
          value: ChartColumnOptions.Detailed,
          checked: false,
        },
        {
          label: lang("ACTION_ZOOM"),
          value: ChartColumnOptions.Zoom,
          checked: false,
        },
      ],
      categories: {
        title: lang("CHART_YEARS"),
        values: forecast.data?.years.map((year) => year.toString()),
      },
      subCategories: {
        titles: [
          forecast.data?.clients.owner.name?.split(" ")[0],
          forecast.data?.clients.partner?.name?.split(" ")[0],
        ],
        values: [
          forecast.data?.clients.owner.ageRange?.map((value) => value.toString()),
          forecast.data?.clients.partner?.ageRange?.map((value) => value.toString()),
        ],
      },
      xLines: clientsRetirement?.xLines,
      series: [
        ...(detailed ? detailedView[selectedTab] : defaultView[selectedTab]),
        ...(clientsRetirement?.series || []),
      ],
    }),
    [
      detailed,
      selectedTab,
      defaultView,
      detailedView,
      clientsRetirement,
      forecast.data?.years,
      forecast.data?.clients,
    ]
  );
};
