/**
 * Charts
 * Naming convention: CHART_ELEMENT
 */
export const charts = {
  // Default
  CHART_YEARS: {
    en: "Years",
  },
  CHART_INFLOW: {
    en: "Inflow",
  },
  CHART_OUTFLOW: {
    en: "Outflow",
  },
  CHART_ASSETS: {
    en: "Assets",
  },
  CHART_LIABILITIES: {
    en: "Liabilities",
  },
  CHART_OWNER: {
    en: "Owner",
  },
  CHART_PARTNER: {
    en: "Partner",
  },

  // Legends
  CHART_SHORTFALL: {
    en: "Shortfall",
  },
  CHART_SURPLUS: {
    en: "Surplus",
  },
  CHART_SAVINGS_AND_INVESTMENTS: {
    en: "Savings & Investments",
  },
  CHART_PROPERTIES_AND_ASSETS: {
    en: "Properties & Assets",
  },
  CHART_INCOMES: {
    en: "Incomes",
  },
  CHART_PENSIONS: {
    en: "Pensions",
  },
  CHART_DEBTS: {
    en: "Debts",
  },
  CHART_EXPENSES: {
    en: "Expenses",
  },
  CHART_TAX: {
    en: "Tax",
  },
  CHART_NON_SAVINGS_INCOME: {
    en: "Non Savings Income",
  },
  CHART_NATIONAL_INSURANCE: {
    en: "National Insurance",
  },
  CHART_SAVINGS_INCOME: {
    en: "Savings Income",
  },
  CHART_DIVIDENDS: {
    en: "Dividends",
  },
  CHART_CAPITAL_GAINS: {
    en: "Capital Gains",
  },
  CHART_OWNER_RETIREMENT: {
    en: "Owner's Retirement",
  },
  CHART_PARTNER_RETIREMENT: {
    en: "Partner's Retirement",
  },
  CHART_RETIREMENT: {
    en: "{0}'s retirement",
  },
  CHART_OWNER_TAX: {
    en: "Owner's Tax",
  },
  CHART_PARTNER_TAX: {
    en: "Partner's Tax",
  },
  CHART_PLAN_START: {
    en: "Plan Start",
  },
  CHART_PLAN_END: {
    en: "Plan End",
  },

  // Overview
  CHART_CASH_FLOW: {
    en: "Cash Flow",
  },
  CHART_NET_WORTH: {
    en: "Net Worth",
  },

  // Empty
  CHART_NO_DATA: {
    en: "No Data",
  },
  CHART_NO_INPUTS: {
    en: "No Inputs",
  },
};
