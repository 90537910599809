/**
 * Convert Values to String
 * Converts all numerical values in an object to string
 * This function is useful for converting DTO responses
 * to form schema data, where all form inputs expect strings as values
 * @param values - object to be converted
 * @returns an object with all values converted to string
 */
export function convertValuesToString<T extends object>(
  values: T
): Record<string, string | string[]> {
  return Object.fromEntries(
    Object.entries(values).map(([key, value]) => [
      String(key),
      Array.isArray(value)
        ? value.map((val) => (isNaN(parseFloat(val)) ? value : String(val)))
        : isNaN(parseFloat(value))
        ? value
        : String(value),
    ])
  );
}
