// Typings
import { CreateDependentPersonDto, RelationshipType } from "@advicefront/plan-client-axios";
import { Field, FIELD_TYPES } from "@advicefront/fe-infra-form-schema";
import { ClientNamingMode } from "@typings/client-naming-mode";
// Schemas
import { clientSchema } from "@forms/modules/people/schemas";
// Translations
import { lang } from "@lang/index";

// DTO which the form refers without fields that are not shown on form
type FormDto = Required<CreateDependentPersonDto>;

// DTO properties and keys to display as fields
type FormDtoKey = keyof FormDto;

// Common validation
const validation = {
  required: true,
  errorMessage: lang("FORM_VALIDATOR_REQUIRED_FIELD"),
};

// Schema used for Dependent
export const dependentSchema = (
  clientNamingMode: ClientNamingMode
): Partial<Record<FormDtoKey, Field>> => {
  // Get client schema and remove unused keys on the dependent schema
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { retirementAge, ...defaultSchema } = clientSchema(clientNamingMode);

  return {
    ...defaultSchema,
    relationshipType: {
      key: "relationshipType",
      fieldType: FIELD_TYPES.singleSelection,
      options: Object.entries(RelationshipType).map(([value, label]) => ({
        label,
        value,
      })),
      validation,
    },
    dependentUntilAge: {
      key: "dependentUntilAge",
      fieldType: FIELD_TYPES.text,
    },
  };
};
