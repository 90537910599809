// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
import { selectPlanEvents } from "@store/selectors/plan-events";
// Typings
import {
  TableBasicModuleEntry,
  TableBasicModuleSchema,
  UseTableBasicModule,
} from "@components/tables/basic/hooks";
import { FormKeys } from "@typings/routing";
// Translations
import { lang } from "@lang/index";

export const useTableEventsModule: UseTableBasicModule = () => {
  // Store
  const events = Store.useSelector(selectPlanEvents());

  // Return table basic data object
  return useMemo(
    (): TableBasicModuleSchema => ({
      head: {
        [lang("TABLE_COLUMN_NAME")]: "left",
        [lang("TABLE_COLUMN_OWNER")]: "left",
        [lang("TABLE_COLUMN_OWNER_AGE")]: "left",
        [lang("TABLE_COLUMN_YEAR")]: "left",
      },
      entries: events.data.map(
        (event): TableBasicModuleEntry => ({
          values: [event.label, event.names, event.ages, event.year],
          action: {
            id: event.id,
            type: FormKeys.EventInput,
          },
          disabled: {
            edit: !event.id,
            delete: !event.id,
          },
        })
      ),
      fetching: !events.data.length,
    }),
    [events.data]
  );
};
