// Types
import { StateProps } from "./types";

export const initialState: StateProps = {
  loading: 0,
  error: null,
  count: 0,
  data: undefined,
  submitSuccess: false,
};
