// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { ChartRingModuleSchema, UseChartRingModule } from "@components/charts/ring/hooks";
// Utils
import { getGroupColor } from "@utils/charts";

export const useChartLiabilitiesModule: UseChartRingModule = () => {
  // Store
  const forecast = Store.useSelector((state) => state.forecast);

  // Return chart data object
  return useMemo(
    (): ChartRingModuleSchema =>
      forecast.data?.balance.liabilityValues
        .map((liability) => ({
          name: liability.label,
          color: getGroupColor(liability.baseType),
          y: Math.abs(liability.values[0]), // Positive value
        }))
        .filter(({ y }) => y !== 0),
    [forecast.data?.balance.liabilityValues]
  );
};
