/**
 * Form Descriptions
 * Naming convention: FORM_DESCRIPTION_ELEMENT
 */
export const formDescriptions = {
  FORM_DESCRIPTION_ACCOUNT_LIMITS: {
    en: "Set withdrawal limits and minimum account balance",
  },
  FORM_DESCRIPTION_AMOUNT_PER_MONTH: {
    en: "Amount per month",
  },
  FORM_DESCRIPTION_AMOUNT_PER_YEAR: {
    en: "Amount per year",
  },
  FORM_DESCRIPTION_GROWTH_RATE: {
    en: "Growth rate",
  },
  FORM_DESCRIPTION_CURRENT_MARKET_VALUE: {
    en: "Current market value",
  },
};
