// React
import { createRoot, hydrateRoot } from "react-dom/client";
import React from "react";
// Config
import * as appConfig from "./config";
// App
import { AppWrapper } from "./AppWrapper";
// Package info
import packageJson from "../package.json";

const container =
  document.getElementById(appConfig.ROOT_ELEMENT_ID) ||
  ((): HTMLElement => {
    throw new Error(`${packageJson.name} - Missing root element (#${appConfig.ROOT_ELEMENT_ID})`);
  })();

if (container.hasChildNodes()) {
  hydrateRoot(container, <AppWrapper />);
} else {
  createRoot(container).render(<AppWrapper />);
}
