/**
 * Is Number Valid
 * Method to check if value is a valid number
 * Checks integer and decimal numbers
 * @param value - string to check
 * @returns true if number is valid
 */
function isNumberValid(value: string): boolean {
  return /^-?[0-9]+$/.test(value)
    ? !isNaN(Number(value)) // Integer
    : Number(value) - Math.floor(Number(value)) > 0; // Decimal
}

/**
 * Clamp
 * Clamps number within the inclusive upper bounds
 * The max range for toFixed is 100
 * @param value - number to check
 * @returns clamped number
 */
function clamp(value: number): number {
  return Math.min(100, Math.max(0, value));
}

/**
 * Mask Percentage
 * Method to format a value into percentage string
 * ### NOTE: used for editing percentage values
 * @example maskPercentage(0.0201) = "2,01"
 * @example maskPercentage(0.2) = "20"
 * @example maskPercentage(0.25) = "25"
 * @param value - string to format
 * @returns formatted value in percentage
 */
export const maskPercentage = (value: string): string => {
  // Return value when its not a valid number
  if (!isNumberValid(value)) return value;

  // Get decimals part
  const decimals = value.split(".")[1];

  // Keep forcing 2 decimals (decimals.length -2) when converting from "0.0120"
  if (decimals && decimals.length - 2 > 0) {
    return (Number(value) * 100).toFixed(clamp(decimals.length - 2));
  }

  // Float number in way to not convert "7" to "7,000000000000001"
  return parseFloat((Number(value) * 100).toFixed(10)).toString();
};

/**
 * Unmask Percentage
 * Method to reverse effect of "maskPercentage"
 * @example unmaskPercentage(2) = "0.02"
 * @example unmaskPercentage(20) = "0.2"
 * @example unmaskPercentage(25) = "0.25"
 * @param value - string to format
 * @returns unmasked value as string
 */
export const unmaskPercentage = (value: string): string => {
  // Return value when its not a valid number
  if (!isNumberValid(value)) return value;

  // Get decimals part
  const decimals = value.split(".")[1];

  // Keep forcing 4 decimals (decimals.length + 2) when converting from "1.20"
  if (decimals) {
    return (Number(value) / 100).toFixed(clamp(decimals.length + 2));
  }

  // Unmask integer number
  return (Number(value) / 100).toString();
};

/**
 * Format Percentage
 * Method to format a value into percentage string
 * NOTE: used for displaying percentage values with a max of 2 decimals (read-only)
 * @example formatPercentage(0.02012) = "2.01"
 * @example formatPercentage(0.5654) = "56.54"
 * @example formatPercentage(1.456) = "145.6"
 * @param value - string to format
 * @returns formatted value in percentage
 */
export const formatPercentage = (value: string): string => {
  // Return value when its not a valid number
  if (!isNumberValid(value)) return value;

  // Return percentage value formatted with max of 2 decimals
  return parseFloat((Number(value) * 100).toFixed(2)).toString();
};
