// Styles
import "./styles/index.scss";
// React
import React from "react";
// Router
import { Link } from "react-router-dom";
import { asFormKey } from "@routes/utils/modal-routing-guards";
// Store
import { Store } from "@store/index";
// Hooks
import { useModalRouting } from "@hooks/modal-routing";
import { useTableScrollable } from "@components/tables/scrollable/hooks";
// Typings
import { FormActions } from "@typings/routing";
import { TableScrollableModulesName } from "@typings/tables/scrollable";
// Utils
import classNames from "classnames";
// Translations
import { lang } from "@lang/index";
// Components
import { AfTooltip, AfTooltipRenderProps } from "@advicefront/ds-tooltip";
import { AfCard } from "@advicefront/ds-card";
import { AfEmptyState } from "@advicefront/ds-empty-state";
import { AfIcon } from "@advicefront/ds-icon";
import { AfScrollableContainer } from "@advicefront/ds-scrollable-container";
import { AfTable } from "@advicefront/ds-table";
import { AfTypography } from "@advicefront/ds-typography";
import { LoaderOverlaySpinner } from "@components/loaders/overlay-spinner";
import { LoaderSkeletonTable } from "@components/loaders/skeleton";

// Props
interface TableScrollableProps {
  name: TableScrollableModulesName;
}

export const TableScrollable = ({ name }: TableScrollableProps): React.ReactElement => {
  // Store
  const plan = Store.useSelector((state) => state.plan);

  // Hook to get current table module data
  const { head, entries, fetching, polling, empty } = useTableScrollable(name);

  // Route
  const { linkToOpenModal } = useModalRouting();

  return (
    <>
      {fetching && <LoaderSkeletonTable rows={10} columns={5} />}

      {!fetching && !entries.length && empty && (
        <AfCard padding="none">
          <AfEmptyState heading={empty.title}>{empty.description}</AfEmptyState>
        </AfCard>
      )}

      {!fetching && !!entries.length && (
        <LoaderOverlaySpinner
          active={!!polling}
          title={lang("LOADER_TITLE_CALCULATING")}
          description={lang("LOADER_DESCRIPTION_CALCULATING")}
          size="m"
        >
          <AfCard padding="none">
            <AfScrollableContainer
              render={({ intersecting: { left } }): React.ReactElement => (
                <AfTable border="row" className="table-scrollable">
                  <thead>
                    <tr>
                      {head.map((label, rowIndex) => (
                        <th
                          key={`head-row-${rowIndex}`}
                          align={rowIndex === 0 ? "left" : "right"}
                          className={classNames({
                            "table-scrollable--sticky": rowIndex === 0,
                            "table-scrollable--shadow": rowIndex === 0 && left,
                          })}
                        >
                          {label}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {entries.map((entry, rowIndex) => (
                      <tr
                        key={`body-row-${rowIndex}`}
                        className={classNames({
                          "table-scrollable--section": !entry.values,
                        })}
                      >
                        <td
                          align="left"
                          className={classNames("table-scrollable--sticky", {
                            "table-scrollable--shadow": left,
                            "table-scrollable--highlighted": entry.highlighted,
                          })}
                        >
                          {entry.action && !plan.data?.archived && (
                            <AfTypography
                              type="link"
                              className="table-scrollable__link"
                              component={
                                <Link
                                  to={linkToOpenModal({
                                    action: FormActions.edit,
                                    type: asFormKey(entry.action.type),
                                    subType: entry.action.subType,
                                    id: entry.action.id,
                                  })}
                                />
                              }
                            >
                              {entry.label}
                            </AfTypography>
                          )}

                          {(!entry.action || plan.data?.archived) && (
                            <AfTypography hasMargin={false} type={entry.type} component={<span />}>
                              {entry.label}
                            </AfTypography>
                          )}

                          {entry.tooltip && (
                            <AfTooltip
                              position="right-center"
                              render={(
                                props: AfTooltipRenderProps<HTMLSpanElement>
                              ): React.ReactElement => (
                                <span {...props} className="table-scrollable__tooltip">
                                  <AfIcon
                                    name="basic-info"
                                    size="s"
                                    nativeProps={{ className: "text-light" }}
                                  />
                                </span>
                              )}
                            >
                              {entry.tooltip}
                            </AfTooltip>
                          )}
                        </td>

                        {entry.values?.map((value, colIndex) => (
                          <td
                            key={`body-col-${rowIndex}-${colIndex}`}
                            align="right"
                            className={classNames({
                              "table-scrollable--highlighted": entry.highlighted,
                            })}
                          >
                            <AfTypography hasMargin={false} type={entry.type}>
                              {value}
                            </AfTypography>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </AfTable>
              )}
            />
          </AfCard>
        </LoaderOverlaySpinner>
      )}
    </>
  );
};
