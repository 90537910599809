// Enum defining all supported scrollable tables modules
// Each module is represented by a unique key and a corresponding string value
export enum TableScrollableModules {
  AssetsLiabilities = "AssetsLiabilities",
  Cashflow = "Cashflow",
  TaxOwner = "TaxOwner",
  TaxPartner = "TaxPartner",
}

// Type alias for the keys of the TableScrollableModules enum
// This is used to ensure type safety when working with scrollable tables in the codebase
export type TableScrollableModulesName = keyof typeof TableScrollableModules;
