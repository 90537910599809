// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { FormModuleHookProps, FormModuleInputInstance } from "@forms/modules/types";
import { FormBaseTypes } from "@advicefront/plan-client-axios";
// Utils
import { asFormType } from "@routes/utils/modal-routing-guards";

export const useDtoKey: FormModuleInputInstance["useDtoKey"] = ({ formDto, formKey }) => {
  // Store
  const forms = Store.useSelector((state) => state.forms);

  return useMemo((): FormModuleHookProps["formDto"] => {
    // Return by default "formDto" if defined
    if (formDto) return formDto;

    // Return undefined if "formKey" or inputs list data are not defined
    if (!formKey || !forms.data?.inputsList?.data) return;

    // Find the Dto name inside the inputs list
    const inputDto = forms.data.inputsList.data[formKey].find(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (entry) => entry.base === (formKey as unknown as FormBaseTypes)
    )?.dto;

    // Convert the "inputDto" value to a FormType or undefined
    return inputDto && asFormType(inputDto);
  }, [formDto, formKey, forms.data?.inputsList.data]);
};
