// Typings
import { ChartTimelineModules, ChartTimelineModulesName } from "@typings/charts/timeline";
// Highcharts
import Highcharts from "highcharts";
// Modules
import { useChartEventsModule } from "@components/charts/timeline/hooks/modules";

// Props : Hooks
type UseChartTimeline = (name: ChartTimelineModulesName) => ChartTimelineModuleSchema;

export type UseChartTimelineModule = () => ChartTimelineModuleSchema;

// Props : Modules
export interface ChartTimelineModuleSchema {
  height: number;
  categories?: {
    title?: string;
    values: Highcharts.XAxisOptions["categories"];
  };
  subCategories?: {
    titles?: (string | undefined)[];
    values?: Highcharts.XAxisOptions["categories"][];
  };
  data?: Highcharts.PointOptionsObject[];
}

/**
 * Maps each chart timeline module to its corresponding chart hook module
 * Each chart timeline module has its own specific hook to retrieve its chart data
 */
const ChartTimelineMap: Record<ChartTimelineModulesName, UseChartTimelineModule> = {
  [ChartTimelineModules.Events]: useChartEventsModule,
};

/**
 * Returns chart data for a specific chart timeline module
 * Determines which module hook to use based on the chart timeline module name
 * The hook will receive the current chart timeline module name
 * @param name - name of the chart timeline module
 * @returns the chart data for the specified chart timeline module
 */
export const useChartTimeline: UseChartTimeline = (name) => ChartTimelineMap[name]();
