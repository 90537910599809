// Redux
import { Store, StoreState } from "@store/index";
import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
// API
import { API } from "@services/api";
// Initial State
import { initialState } from "./initial-state";
// Types
import { StateProps, FetchProps } from "./types";
// Translations
import { lang } from "@lang/index";

/**
 * Reset to initial data state
 * @example dispatch(Liquidation.reset())
 */

export const reset = createAction("liquidation/reset");

/**
 * Get Liquidation data
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Liquidation.fetch(\{
 *  authToken,
 *  planId,
 *  accountLiquidationOrderIds
 * \}));
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  Required<FetchProps>,
  { state: StoreState }
>("liquidation/fetch", async ({ authToken, planId, accountLiquidationOrderIds }, { dispatch }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  try {
    data = (
      await API.Inputs(authToken).findAllInputsV2({
        planId: planId,
        isLiquidatableInput: true,
        limit: 50,
      })
    ).data.data;
  } catch (e) {
    error = API.parseError(e);
  }

  // Sort the array based on the account liquidation order ids
  data = data?.sort(
    (a, b) => accountLiquidationOrderIds.indexOf(a._id) - accountLiquidationOrderIds.indexOf(b._id)
  );

  // Trigger error notification
  if (error) {
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
