// Typings
import {
  CalculatorTypes,
  FindOneCalculator200Response,
  ResponseIllnessCalculatorDto,
  ResponseProtectionCalculatorDto,
  ResponseTDPCalculatorDto,
} from "@advicefront/plan-client-axios";

/**
 * Is Protection Calculator
 * Type Guard for Protection Calculator type
 * @param data - data to check
 * @returns boolean - true if its Protection Calculator type
 */
export const isProtectionCalculator = (
  data: FindOneCalculator200Response | ResponseProtectionCalculatorDto
): data is ResponseProtectionCalculatorDto => data.__t === CalculatorTypes.ProtectionCalculator;

/**
 * Is Illness Calculator
 * Type Guard for Illness Calculator type
 * @param data - data to check
 * @returns boolean - true if its Illness Calculator type
 */
export const isIllnessCalculator = (
  data: FindOneCalculator200Response | ResponseIllnessCalculatorDto
): data is ResponseIllnessCalculatorDto => data.__t === CalculatorTypes.IllnessCalculator;

/**
 * Is TPD Calculator
 * Type Guard for Total Permanent Disability Calculator type
 * @param data - data to check
 * @returns boolean - true if its Total Permanent Disability Calculator type
 */
export const isTpdCalculator = (
  data: FindOneCalculator200Response | ResponseTDPCalculatorDto
): data is ResponseTDPCalculatorDto => data.__t === CalculatorTypes.TdpCalculator;
