// React
import React from "react";
// Router
import { View } from "@routes/index";
// Translations
import { lang } from "@lang/index";
// Layouts
import { BlankLayout } from "@layouts/Blank";

// Define element for the view
const Element = (): React.ReactElement => (
  <BlankLayout>
    <h2>{lang("ERROR_TITLE")}</h2>
    <p>{lang("ERROR_DESCRIPTION")}</p>
  </BlankLayout>
);

// Route view options
export const error: View = {
  name: "Error",
  options: {
    path: "*",
    element: <Element />,
  },
};
