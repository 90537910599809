// React
import React, { useCallback } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { TableAssumptionsModules } from "@typings/tables/assumptions";
import { UpdatePlanAssumptionsRequest } from "@advicefront/plan-client-axios";
// Utils
import { isGbrAssumptions } from "@utils/type-guards/assumptions";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { TableAssumptions, TableAssumptionsProps } from "@components/tables/assumptions";

export const AssumptionsTaxTable = (): React.ReactElement | null => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);

  // Get tax assumptions
  const { data, error, loading } = Store.useSelector((state) => state.assumptions);

  // Handle submit
  const handleSubmit: TableAssumptionsProps["handleSubmit"] = useCallback(
    (payload) => {
      if (!auth.authToken || !plan.data) return;

      // Type assertion to ensure payload is of type UpdatePlanAssumptionsRequest
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const updatedPayload = payload as UpdatePlanAssumptionsRequest;

      // Update assumptions
      void dispatch(
        Store.assumptions.update({
          authToken: auth.authToken,
          planId: plan.data._id,
          updatedAssumptions: updatedPayload,
        })
      );
    },
    [dispatch, auth.authToken, plan.data]
  );

  // Define list of assumptions tax tables to be displayed
  const assumptionsTaxTables = [
    TableAssumptionsModules.NonSavingsIncome,
    TableAssumptionsModules.NationalInsuranceC1,
    TableAssumptionsModules.NationalInsuranceC4,
    TableAssumptionsModules.NationalInsuranceC2,
    TableAssumptionsModules.SavingsIncome,
    TableAssumptionsModules.Dividends,
  ];

  // Returns null if not the GBR assumptions data
  if (!isGbrAssumptions(data)) return null;

  return (
    <AfRow orientation="vertical">
      {assumptionsTaxTables.map((name, index) => (
        <AfCol key={`table-assumptions-${index}`}>
          <TableAssumptions
            name={name}
            data={data}
            loading={!!loading}
            error={!!error}
            handleSubmit={handleSubmit}
          />
        </AfCol>
      ))}
    </AfRow>
  );
};
