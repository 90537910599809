// Styles
import "./styles/index.scss";
// React
import React from "react";
// Store
import { Store } from "@store/index";
// Translations
import { lang } from "@lang/index";
// Components
import { AfButton } from "@advicefront/ds-button";
import { AfDialog } from "@advicefront/ds-dialog";
import { AfTypography } from "@advicefront/ds-typography";
import { LoaderOverlaySpinner } from "@components/loaders/overlay-spinner";

// Props
interface DialogCloseImportErrorsProps {
  handleCancel: () => void;
  handleConfirm: () => void;
}

export const DialogCloseImportErrors = ({
  handleCancel,
  handleConfirm,
}: DialogCloseImportErrorsProps): React.ReactElement => {
  // Store
  const plan = Store.useSelector((state) => state.plan);

  return (
    <AfDialog
      isOpen
      skin="warning"
      secondaryAction={
        <AfButton type="button" skin="ghost" onClick={handleCancel}>
          {lang("ACTION_CANCEL")}
        </AfButton>
      }
      primaryAction={
        <AfButton type="button" skin="danger" disabled={!!plan.loading} onClick={handleConfirm}>
          {lang("ACTION_DISCARD")}
        </AfButton>
      }
    >
      <div className="align-center">
        <LoaderOverlaySpinner active={!!plan.loading} bordered={false} size="m">
          <AfTypography type="h4" className="dialogs-close-import-errors__title">
            {lang("DIALOG_TITLE_CLOSE_IMPORT_ERRORS")}
          </AfTypography>
          {lang("DIALOG_DESCRIPTION_CLOSE_IMPORT_ERRORS")}
        </LoaderOverlaySpinner>
      </div>
    </AfDialog>
  );
};
