// React
import { useMemo, useState } from "react";
// Typings
import { AfFormFieldProps } from "@advicefront/ds-text-area/dist/types/form-field/src/AfFormField";
import { FormRendererValidation } from "@forms/renderer/types";
import { ValidationData } from "@advicefront/fe-infra-form-schema-renderer";

// Props
interface FieldValidationOutput {
  skin: AfFormFieldProps["skin"];
  validationDescription: AfFormFieldProps["validationDescription"];
  onBlur: () => void;
  onFocus: () => void;
}

/**
 * Use Field Validation
 * A custom React hook that provides a way to validate a form field
 * and display a validation message based on the validation status.
 * @param valid - boolean indicating whether the field is valid or not
 * @param validationData - object containing validation status and description
 * @returns an object containing validation properties
 */
export const useFieldValidation = (
  valid: FormRendererValidation["valid"] | undefined,
  validation: ValidationData
): FieldValidationOutput => {
  // State to control the display of the validation message
  const [shouldDisplayValidation, setShouldDisplayValidation] = useState<boolean>(false);

  // Check if field validation can be displayed
  const canDisplayValidation = valid === false || shouldDisplayValidation;

  // Get the appropriate skin based on the validation status
  const skin = useMemo(
    () => (canDisplayValidation && validation?.status === "error" ? "error" : "default"),
    [canDisplayValidation, validation?.status]
  );

  // Get the appropriate validation description based on the canDisplayValidation state
  const validationDescription = useMemo(
    () => canDisplayValidation && validation?.description,
    [canDisplayValidation, validation?.description]
  );

  // Define the onBlur and onFocus event handlers
  const onBlur = (): void => setShouldDisplayValidation(true);
  const onFocus = (): void => setShouldDisplayValidation(false);

  // Return an object containing the validation properties
  return { skin, validationDescription, onBlur, onFocus };
};
