// React
import React, { useEffect, useState, useContext } from "react";
// Store
import { Store, StoreState } from "@store/index";
// Typings
import { LayoutProps } from "@layouts/types";
// Context
import { AppOptionsContext } from "@context/app-options";
// Layouts
import { SessionGate, SessionGateProps } from "@advicefront/fe-infra-auth";
import { BlankLayout } from "@layouts/Blank";
// Components
import { Modal } from "@routes/router/Modal";

// Rendered on authentication error
const authErrorComponent = (
  <BlankLayout>
    <p>You should be authenticated to access this page.</p>
  </BlankLayout>
);

// Rendered when session expires
const sessionExpireComponent = (
  <BlankLayout>
    <p>Your session expired.</p>
  </BlankLayout>
);

// Rendered when session gate is awaiting session data
const loadingComponent = (
  <BlankLayout>
    <p>Loading...</p>
  </BlankLayout>
);

export const AuthProtectedLayout = ({ children }: LayoutProps): React.ReactElement => {
  // Store
  const dispatch = Store.useDispatch();

  // Context
  const { getClientGroupId, getAuthToken } = useContext(AppOptionsContext);

  // Auth token state
  const [authToken, setAuthToken] = useState<StoreState["auth"]["authToken"]>();

  // Callback for auth token updates
  const handleAuthTokenChange: NonNullable<SessionGateProps["onAuthTokenChange"]> = (token) =>
    setAuthToken(token);

  // Update redux data when both entries are fetched
  useEffect(() => {
    void (async (): Promise<void> => {
      const clientGroupId = await getClientGroupId();

      if (!clientGroupId) return;

      dispatch(
        Store.auth.setCredentials({
          clientGroupId,
          authToken,
        })
      );
    })();
  }, [dispatch, authToken, getClientGroupId]);

  return (
    <SessionGate
      getAuthToken={getAuthToken}
      {...{
        authErrorComponent,
        sessionExpireComponent,
        loadingComponent,
      }}
      onAuthTokenChange={handleAuthTokenChange}
    >
      {children}
      <Modal />
    </SessionGate>
  );
};
