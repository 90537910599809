/**
 * Form Validators
 * Naming convention: FORM_VALIDATOR_ELEMENT
 */
export const formValidators = {
  FORM_VALIDATOR_REQUIRED_FIELD: {
    en: "This field is required.",
  },
  FORM_VALIDATOR_MORTALITY_ASSUMPTION: {
    en: "{0} can't be higher than the {1} mortality assumption ({2}).",
  },
  FORM_VALIDATOR_INTEREST_RATE: {
    en: "The sum of Repayment Amounts by the Repayment Periods cannot be less than the Outstanding Balance.",
  },
  FORM_VALIDATOR_LESS_THAN: {
    en: "{0} can't be less than {1}.",
  },
  FORM_VALIDATOR_MORE_THAN: {
    en: "{0} can't be more than {1}.",
  },
  FORM_VALIDATOR_NO_DECIMALS: {
    en: "{0} cannot contain decimals.",
  },
};
