// React
import { useCallback, useEffect, useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import {
  ClientType,
  CreateClientPersonDto,
  CreateDependentPersonDto,
  UpdateClientPersonDto,
  UpdateDependentPersonDto,
} from "@advicefront/plan-client-axios";
import { FormModuleInstance, UseSubmitProps } from "@forms/modules/types";
import { FormData } from "@advicefront/fe-infra-form-schema";
import { FormKeys } from "@typings/routing";

export const useSubmit: FormModuleInstance["useSubmit"] = ({
  formKey,
  itemId,
  onSubmitSuccess,
}) => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);
  const people = Store.useSelector((state) => state.people);

  // Check if form submission was successful and trigger external handler
  useEffect(() => {
    if (!people.submitSuccess) return;
    onSubmitSuccess();
  }, [onSubmitSuccess, people.submitSuccess]);

  // Handle form submission
  const handleSubmit = useCallback(
    (values: FormData): void => {
      // Ensure we have the necessary data to submit the form
      if (!auth.authToken || !plan.data?._id) {
        throw new Error("Unable to submit form data: missing authToken or planId");
      }

      // Update person
      if (itemId) {
        void dispatch(
          Store.people.updatePerson({
            authToken: auth.authToken,
            planId: plan.data._id,
            personId: itemId,
            updatedPersonData: {
              // Force type matching of values to the expected object format to be received on API client
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              ...(values as unknown as UpdateClientPersonDto | UpdateDependentPersonDto),
              // Define specific Owner and Partner properties
              ...(formKey !== FormKeys.Dependent && {
                clientType: formKey === FormKeys.Owner ? ClientType.Owner : ClientType.Partner,
                retirementAge: Number(values.retirementAge),
              }),
              // Define specific Dependent properties
              ...(formKey === FormKeys.Dependent && {
                dependentUntilAge: Number(values.dependentUntilAge),
              }),
              yearOfBirth: Number(values.yearOfBirth),
            },
          })
        );
      }

      // Create person
      if (!itemId) {
        switch (formKey) {
          // Owner or Partner
          case FormKeys.Owner:
          case FormKeys.Partner:
            void dispatch(
              Store.people.createClient({
                authToken: auth.authToken,
                planId: plan.data._id,
                dto: {
                  // Force type matching of values to the expected object format to be received on API client
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  ...(values as unknown as CreateClientPersonDto),
                  clientType: formKey === FormKeys.Owner ? ClientType.Owner : ClientType.Partner,
                  retirementAge: Number(values.retirementAge),
                  yearOfBirth: Number(values.yearOfBirth),
                },
              })
            );
            break;

          // Dependent
          case FormKeys.Dependent:
            void dispatch(
              Store.people.createDependent({
                authToken: auth.authToken,
                planId: plan.data._id,
                dto: {
                  // Force type matching of values to the expected object format to be received on API client
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  ...(values as unknown as CreateDependentPersonDto),
                  dependentUntilAge: Number(values.dependentUntilAge),
                  yearOfBirth: Number(values.yearOfBirth),
                },
              })
            );
            break;
        }
      }
    },
    [dispatch, formKey, itemId, auth.authToken, plan.data?._id]
  );

  return useMemo(
    (): UseSubmitProps => ({
      loading: !!people.loading,
      handleSubmit,
    }),
    [handleSubmit, people.loading]
  );
};
