// Typings
import { Colors } from "@typings/colors";
import { ForecastInputGroups } from "@advicefront/plan-client-axios";
import { TaxGroups } from "@typings/tax-groups";

// Props
type GroupTypes = ForecastInputGroups | TaxGroups;

// Map each color for forecast and tax groups
const GROUP_COLORS: Partial<Record<GroupTypes, string>> = {
  // Forecast
  [ForecastInputGroups.IncomeInput]: Colors.Margaery,
  [ForecastInputGroups.ExpenseInput]: Colors.Yara,
  [ForecastInputGroups.AssetInput]: Colors.Brienne,
  [ForecastInputGroups.AccountInput]: Colors.Lyanna,
  [ForecastInputGroups.PensionInput]: Colors.Ellaria,
  [ForecastInputGroups.DebtInput]: Colors.Cersei,
  [ForecastInputGroups.Tax]: Colors.Nymeria,
  // Tax
  [TaxGroups.TaxNonSavingsIncome]: Colors.Nymeria,
  [TaxGroups.TotalNationalInsurance]: Colors.Mango,
  [TaxGroups.SavingsTaxSavingsIncome]: Colors.Apricot,
  [TaxGroups.TaxDividends]: Colors.BreadNButter,
  [TaxGroups.TotalCapitalGainsResidential]: Colors.JuneDay,
};

/**
 * Get group color
 * Util to get group color to be displayed on chart graph
 * @param value - value to check from forecast or tax groups
 * @returns group color or undefined
 */
export const getGroupColor = (value: GroupTypes | undefined): string | undefined =>
  value ? GROUP_COLORS[value] : undefined;
