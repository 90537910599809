// React
import { useMemo } from "react";
// Typings
import { FormModuleInputInstance, UseInputRendererProps } from "@forms/modules/types";
import { FormTypes } from "@advicefront/plan-client-axios";

export const useInputRenderer: FormModuleInputInstance["useInputRenderer"] = () =>
  useMemo(
    (): UseInputRendererProps => ({
      // Types
      inputTypes: {
        "select": {
          literal: ["income", "contributionSource", "account"],
          keywords: ["periodicity"],
        },
        "read-only": {
          literal: "interestRate",
        },
        "percentage": {
          keywords: ["range", "percentage", "rate", "growth"],
          literal: [
            "dividendsYield",
            "interestYield",
            "personalContributionsGrowth",
            "employerContribution",
            "employeeContribution",
          ],
        },
        "currency": {
          keywords: ["income"],
          literal: [
            "amount",
            "benefitsAmount",
            "bonusAmount",
            "dividendsAmount",
            "otherAmount",
            "currentValue",
            "regularContributions",
            "marketValue",
            "purchaseValue",
            "maxWithdrawal",
            "minBalance",
            "outstandingBalance",
            "personalContributions",
            "repaymentAmount",
          ],
        },
        "number": {
          literal: "paymentDuration",
        },
        "boolean": {
          keywords: ["periodicity"],
          literal: ["ownership"],
        },
        "collapsible": {
          literal: "accountLimit",
        },
        "toggle": {
          literal: ["adjustWithdrawalWithInflation", "adjustBalanceWithInflation"],
        },
      },

      // Props
      inputProps: {
        column: {
          size: {
            12: [
              "label",
              "ownership",
              "taxExempt",
              "taxDeductible",
              "reinvestYield",
              "accountLimit",
            ],
          },
          offsetAfter: {
            6: [
              "growthRate",
              "currentValue",
              "capitalGrowth",
              "asset",
              "feeRate",
              "contributionSource",
              "contributionsGrowthRate",
              "outstandingBalance",
              "availabilityAge",
              "employmentIncome",
              "interestRate",
            ],
          },
          overrides: {
            [FormTypes.SgpMainResidenceAssetInput]: {
              offsetAfter: {
                auto: ["growthRate"],
              },
            },
            [FormTypes.SgpPropertyAssetInput]: {
              offsetAfter: {
                auto: ["growthRate"],
              },
            },
            [FormTypes.SgpOtherAssetInput]: {
              offsetAfter: {
                auto: ["growthRate"],
              },
            },
          },
        },
      },
    }),
    []
  );
