// Typings
import { FormActions, FormKeys } from "@typings/routing";
import { FormTypes, InputGroups } from "@advicefront/plan-client-axios";
import { PlanCreationModes } from "@typings/plan-creation-modes";

/**
 * Type Guard for FormActions
 * @param value - value to check
 * @returns boolean - true if its Form action
 */
export function isFormAction(value: unknown): value is FormActions {
  return (
    typeof value === "string" &&
    Object.values(FormActions)
      .map((i) => i.toString())
      .includes(value)
  );
}

/**
 * Type Guard for dynamic form (key) types
 * @param value - value to check
 * @returns boolean - true if its an dynamic form key
 */
export function isFormKey(value: unknown): value is FormKeys {
  return (
    typeof value === "string" &&
    Object.values(FormKeys)
      .map((type) => type.toString())
      .includes(value)
  );
}

/**
 * Return a string typed as {@link FormKeys}
 * if the value does not match the type, throws error
 */
export function asFormKey(value: unknown): FormKeys {
  if (!isFormKey(value)) throw new Error(`Form type not supported - "${value}"`);
  return value;
}

/**
 * Type Guard for dynamic form selection (inputGroups)
 * @param value - value to check
 * @returns boolean - true if its an dynamic form selection
 */
export function isInputGroup(value: unknown): value is InputGroups {
  return (
    typeof value === "string" &&
    Object.values(InputGroups)
      .map((type) => type.toString())
      .includes(value)
  );
}

/**
 * Return a string typed as {@link InputGroups}
 * if the value does not match the type, throws error
 */
export function asInputGroup(value: unknown): InputGroups | null {
  if (!isInputGroup(value)) return null;
  return value;
}

/**
 * Type Guard for dynamic form selection (inputGroups and formKeys)
 * @param value - value to check
 * @returns boolean - true if key exists in FormKeys or Input
 */
export function isInputGroupOrFormKeys(value: unknown): FormKeys | InputGroups | null {
  if (isFormKey(value) || isInputGroup(value)) return value;

  return null;
}

/**
 * Type Guard for FormTypes
 * @param value - value to check
 * @returns boolean - true if it's Form type
 */
export function isFormType(value: string): value is FormTypes {
  return Object.keys(FormTypes).includes(value);
}

/**
 * Return a string typed as {@link FormTypes}
 * if the value does not match the type, throws error
 */
export function asFormType(value: string): FormTypes {
  if (!isFormType(value)) throw new Error(`Value "${value}" is not a form type`);
  return value;
}

/**
 * Type Guard for PlanCreationModes
 * @param value - value to check
 * @returns boolean - true if its PlanCreationMode type
 */
export function isPlanCreationMode(value: string): value is PlanCreationModes {
  return Object.keys(PlanCreationModes).includes(value);
}

/**
 * Return a string typed as {@link PlanCreationModes} or {@link FormTypes}
 * if the value does not match neither, throws error
 */
export function asSubtype(value: string | null): PlanCreationModes | FormTypes {
  if (value && (isPlanCreationMode(value) || isFormType(value))) return value;
  throw new Error(`Value "${value}" is not an acceptable subtype`);
}
