// Styles
import "./styles/index.scss";
// React
import React, { useCallback, useContext, useEffect, useState } from "react";
// Store
import { Store } from "@store/index";
// Context
import { AppOptionsContext } from "@context/app-options";
// Hooks
import {
  TableAssumptionsModuleEntry,
  TableAssumptionsModuleField,
  useTableAssumptions,
} from "@components/tables/assumptions/hooks";
// Typings
import {
  TableAssumptionsModulesName,
  TableAssumptionsModulesData,
  TableAssumptionsModulesPayload,
} from "@typings/tables/assumptions";
import { InputNodeFormat } from "@typings/input-node-format";
// Utils
import { formatPercentage, maskPercentage, unmaskPercentage } from "@utils/format-percentage";
import { maskCurrency, unmaskCurrency } from "@utils/format-currency";
import { isEmpty } from "@utils/is-empty";
// Translations
import { lang } from "@lang/index";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfTextField, AfTextFieldProps } from "@advicefront/ds-text-field";
import { AfTooltip, AfTooltipRenderProps } from "@advicefront/ds-tooltip";
import { AfButton } from "@advicefront/ds-button";
import { AfCard } from "@advicefront/ds-card";
import { AfCheckbox } from "@advicefront/ds-checkbox";
import { AfEmptyState } from "@advicefront/ds-empty-state";
import { AfIcon } from "@advicefront/ds-icon";
import { AfTable } from "@advicefront/ds-table";
import { AfToggle } from "@advicefront/ds-toggle";
import { AfTypography } from "@advicefront/ds-typography";
import { LoaderOverlaySpinner } from "@components/loaders/overlay-spinner";
import { LoaderSkeletonTable } from "@components/loaders/skeleton";

// Props
export interface TableAssumptionsProps {
  name: TableAssumptionsModulesName;
  data: TableAssumptionsModulesData;
  loading: boolean;
  error: boolean;
  admin?: boolean;
  handleSubmit: (payload: TableAssumptionsModulesPayload) => void;
}

export const TableAssumptions = ({
  name,
  data,
  loading,
  error,
  admin,
  handleSubmit,
}: TableAssumptionsProps): React.ReactElement => {
  // Store
  const plan = Store.useSelector((state) => state.plan);
  const firmPreferences = Store.useSelector((state) => state.firmPreferences);

  // Hook to get current table module data
  const { title, head, entries, customise, reset, empty, submitPayload } = useTableAssumptions({
    name,
    data,
    firmPreferences,
  });

  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // State to update table entries while editing
  const [tableEntries, setTableEntries] = useState<TableAssumptionsModuleEntry[]>([]);

  // State to enable or disable edit mode
  const [isEditing, setIsEditing] = useState(false);

  // State to display overlay spinner for current table
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Set table entries with initial values
  const setInitialValues = useCallback((): void => {
    // Stringify and parse to prevent referential equality
    setTableEntries(structuredClone(entries));
  }, [entries]);

  // Get props for text field component
  const getTextFieldProps = (
    field: TableAssumptionsModuleField
  ): Pick<AfTextFieldProps, "type" | "value" | "after" | "before"> => {
    // Define default props with empty object
    const props: Partial<AfTextFieldProps> = {};

    // Set props based on field type
    switch (field.type) {
      // Number
      case InputNodeFormat.number:
        props.type = "number";
        props.value = String(field.value);
        props.before = field.before;
        props.after = field.after;
        break;

      // Percent
      case InputNodeFormat.percent:
        props.type = "number";
        props.value = maskPercentage(String(field.value));
        props.before = "%";
        break;

      // Currency
      case InputNodeFormat.currency:
        props.value = maskCurrency(String(field.value));
        props.before = currencySymbol;
        break;
    }
    // Return text field props
    return props;
  };

  // Get text preview formatted
  const getTextPreview = (field: TableAssumptionsModuleField): string | undefined => {
    switch (field.type) {
      // Number
      case InputNodeFormat.number:
        return field.preview || `${field.before || ""} ${field.value} ${field.after || ""}`;

      // Percent
      case InputNodeFormat.percent:
        return field.preview || `${formatPercentage(String(field.value))}%`;

      // Currency
      case InputNodeFormat.currency:
        return field.preview || maskCurrency(String(field.value), currencySymbol);
    }
  };

  // Handle change
  const handleChange = (
    value: TableAssumptionsModuleField["value"],
    rowIndex: number,
    colIndex: number
  ): void => {
    setTableEntries((prevState) => {
      // Copy array to prevent a referential equality issue
      const newState = [...prevState];
      // Get current field
      const field = newState[rowIndex].fields[colIndex];
      // Set field value based on type
      switch (field.type) {
        // Percent
        case InputNodeFormat.percent:
          field.value = unmaskPercentage(String(value));
          break;

        // Currency
        case InputNodeFormat.currency:
          field.value = unmaskCurrency(String(value));
          break;

        // Number | Boolean
        default:
          field.value = value;
      }
      // Return the updated state
      return newState;
    });
  };

  // Handle cancel
  const handleCancel = useCallback((): void => {
    // Restore to initial values
    setInitialValues();
    // Close edit mode
    setIsEditing(false);
  }, [setInitialValues]);

  // Handle save changes
  const handleSaveChanges = useCallback((): void => {
    // Display overlay spinner
    setIsSubmitting(true);
    // Convert table entries to match with dto for submit action
    handleSubmit(submitPayload(tableEntries));
    // Close edit mode
    setIsEditing(false);
  }, [handleSubmit, submitPayload, tableEntries]);

  // Handle reset
  const handleReset = useCallback((): void => {
    // Return if reset prop was not set
    if (!reset) return;
    // Display overlay spinner
    setIsSubmitting(true);
    // Get data from firm preferences for submit action
    handleSubmit(reset.payload);
    // Close edit mode
    setIsEditing(false);
  }, [handleSubmit, reset]);

  // Handle customisation
  const handleCustomisation = useCallback((): void => {
    // Return if customise payload prop was not set
    if (!customise.payload) return;
    // Display overlay spinner
    setIsSubmitting(true);
    // Toggle value from firm preferences for submit action
    handleSubmit(customise.payload);
  }, [handleSubmit, customise.payload]);

  // Restore to initial values if there was an error on submit
  useEffect(() => {
    if (!loading && error) {
      setInitialValues();
    }
  }, [setInitialValues, loading, error]);

  // Hide current table overlay spinner after submit
  useEffect(() => {
    if (isSubmitting && !loading) {
      setIsSubmitting(false);
    }
  }, [isSubmitting, loading]);

  // Close edit mode if plan was moved to archived while editing
  useEffect(() => {
    if (isEditing && plan.data?.archived) {
      setIsEditing(false);
    }
  }, [isEditing, plan.data?.archived]);

  // Set table entries with initial values on init
  useEffect(() => {
    setInitialValues();
  }, [setInitialValues]);

  return (
    <>
      {!data && <LoaderSkeletonTable rows={3} columns={3} size="xl" />}

      {Array.isArray(data) && !data.length && empty && (
        <AfCard padding="none">
          <AfEmptyState heading={empty.title}>{empty.description}</AfEmptyState>
        </AfCard>
      )}

      {!isEmpty(data) && (
        <LoaderOverlaySpinner active={isSubmitting}>
          <AfTable border={["wrapper", "row"]} size="s">
            <thead>
              <tr>
                <th colSpan={head.length} className="table-assumptions__thead-col">
                  <AfRow justify="spread" align="center">
                    <AfCol>
                      <AfTypography hasMargin={false} type="h3">
                        {title}
                      </AfTypography>
                    </AfCol>

                    {isEditing && reset?.allowed && (
                      <AfCol>
                        <AfButton skin="ghost" onClick={handleReset}>
                          {lang("ACTION_RESET_TO_FIRM_VALUES")}
                        </AfButton>
                      </AfCol>
                    )}

                    {!isEditing && admin && (
                      <AfCol>
                        <AfToggle
                          inverted
                          size="s"
                          skin="default"
                          label={lang("ACTION_ALLOW_CUSTOMISATION")}
                          checked={customise.checked}
                          onChange={handleCustomisation}
                        />
                      </AfCol>
                    )}
                  </AfRow>
                </th>
              </tr>

              <tr>
                {head.map((entry, colIndex) => (
                  <th
                    key={`head-col-${colIndex}`}
                    align={entry.align}
                    className="table-assumptions__thead-labels"
                  >
                    {entry.label}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {tableEntries.map((entry, rowIndex) => (
                <tr key={`body-row-${rowIndex}`}>
                  <td align={head[0].align} className="table-assumptions__tbody-col">
                    {entry.label}
                  </td>

                  {entry.fields.map((field, colIndex) => (
                    <td
                      key={`body-col-${rowIndex}-${colIndex}`}
                      align={head[colIndex + 1].align}
                      className="table-assumptions__tbody-col"
                    >
                      {field.type !== InputNodeFormat.boolean && (
                        <>
                          {isEditing && !field.hidden ? (
                            <AfTextField
                              {...getTextFieldProps(field)}
                              placeholder={lang("FORM_PLACEHOLDER_VALUE")}
                              onChange={(ev): void =>
                                handleChange(ev.target.value, rowIndex, colIndex)
                              }
                            />
                          ) : (
                            <>{getTextPreview(field)}</>
                          )}
                        </>
                      )}

                      {field.type === InputNodeFormat.boolean && (
                        <AfCheckbox
                          checked={!!field.value}
                          disabled={!isEditing}
                          onChange={(ev): void =>
                            handleChange(ev.target.checked, rowIndex, colIndex)
                          }
                        />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>

            <tfoot>
              <tr>
                {!customise.checked && !admin && (
                  <td className="table-assumptions__tfoot-info">
                    <AfTypography hasMargin={false} type="small" skin="text-light">
                      {lang("TABLE_LABEL_ASSUMPTIONS_NOT_EDITABLE")}
                    </AfTypography>
                  </td>
                )}

                {(customise.checked || admin) && (
                  <td align="right" colSpan={head.length} className="table-assumptions__tfoot-col">
                    {isEditing && (
                      <AfRow justify="end" align="center">
                        <AfCol>
                          <AfButton skin="secondary" onClick={handleCancel}>
                            {lang("ACTION_CANCEL")}
                          </AfButton>
                        </AfCol>
                        <AfCol>
                          <AfButton onClick={handleSaveChanges}>
                            {lang("ACTION_SAVE_CHANGES")}
                          </AfButton>
                        </AfCol>
                      </AfRow>
                    )}

                    {!isEditing && (
                      <AfTooltip
                        position="left-center"
                        render={(
                          props: AfTooltipRenderProps<HTMLSpanElement>
                        ): React.ReactElement => (
                          <span {...(plan.data?.archived && props)}>
                            <AfButton
                              skin="secondary"
                              disabled={plan.data?.archived}
                              icon={<AfIcon name="content-edit" />}
                              onClick={(): void => setIsEditing(true)}
                            >
                              {lang("ACTION_EDIT_SECTION")}
                            </AfButton>
                          </span>
                        )}
                      >
                        {lang("TOOLTIP_PLAN_ARCHIVED")}
                      </AfTooltip>
                    )}
                  </td>
                )}
              </tr>
            </tfoot>
          </AfTable>
        </LoaderOverlaySpinner>
      )}
    </>
  );
};
