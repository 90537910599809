// React
import React, { useMemo } from "react";
// Typings
import { Field, ValueKey } from "@advicefront/fe-infra-form-schema";
import { UseSchemaProps } from "@forms/modules/types";
// Utils
import * as CC from "change-case";

/**
 * Map schema field object to specific HTML input types since there is
 * no need for the BE to be responsible for this kind of mapping
 * BE should provide info if its a single or multiple selection field
 */
enum INPUT_TYPES {
  "text",
  "textarea",
  "boolean",
  "select",
  "number",
  "percentage",
  "currency",
  "read-only",
  "group",
  "collapsible",
  "toggle",
}

// Props
export type InputTypesMap = Partial<
  Record<
    InputType,
    {
      keywords?: string[];
      literal: ValueKey | ValueKey[];
    }
  >
>;

type InputType = keyof typeof INPUT_TYPES;

/**
 * Is Input Type
 * @param value - string to check
 * @returns true if value is an input type
 */
function isInputType(value: string): value is InputType {
  return Object.keys(INPUT_TYPES).includes(value);
}

/**
 * Get Input Type From Key
 * @param inputTypes - inputTypes with {@link InputTypesMap} format
 * @param fieldKey - field key to check type
 * @returns - input type of fieldKey or undefined
 */
function getInputTypeFromKey(
  inputTypes: InputTypesMap | undefined,
  fieldKey: Field["key"]
): InputType | undefined {
  if (!inputTypes) return undefined;

  const [searchedType] =
    Object.entries(inputTypes).find(([, value]) => {
      const fieldKeywords = fieldKey.split(/(?=[A-Z])/);
      const typeKeywords = value.keywords;
      const literalKeys = Array.isArray(value.literal) ? value.literal : [value.literal];

      // Check if fieldKey is not in literal keys InputTypeMap and if type has defined keywords
      // If so, check if FieldKey has any key Word for that type
      if (!literalKeys.includes(fieldKey) && typeKeywords) {
        return !!fieldKeywords.filter((word) => typeKeywords.includes(word.toLowerCase())).length;
      } else {
        return literalKeys.includes(fieldKey);
      }
    }) || [];

  return searchedType && isInputType(searchedType) ? searchedType : undefined;
}

/**
 * Get Value Keys From Type
 * @param type - input type
 * @param inputTypes - inputTypes with {@link InputTypesMap} format
 * @param schema - current schema data
 * @returns - array of form keys with specific type from current schema
 */
export const getValueKeysFromType = (
  type: InputType,
  inputTypes: InputTypesMap,
  schema: UseSchemaProps["data"]
): ValueKey[] => {
  // Get literal keys present on all types
  const allLiterals = Object.entries(inputTypes)
    .map((entry) => entry[1].literal)
    .flat();

  const typeMap = Object.entries(inputTypes).filter(([entry]) => entry === type);
  const literals = typeMap.map((s) => s[1].literal).flat();
  const keywords = typeMap.map((s) => s[1].keywords).flat();

  const schemaFieldKeys = schema && Object.keys(schema);

  // Remove any form field key that is present on any type literals to avoid
  // conflict with literals that can have keywords defined on the searched type
  const schemaFieldKeysWithoutLiterals = schemaFieldKeys?.filter(
    (key) => !allLiterals.includes(key)
  );
  // get fields from Schema that have defined type keywords
  const fieldKeysWithKeywords: string[] | undefined = schemaFieldKeysWithoutLiterals?.filter(
    (fieldKey) =>
      keywords.length &&
      keywords.some((el) => !!el && CC.sentenceCase(fieldKey).toLowerCase().includes(el))
  );

  return fieldKeysWithKeywords ? [...literals, ...fieldKeysWithKeywords] : literals;
};

/**
 * Use Component From Input Type
 * @param fieldKey - key field from FormSchema
 * @param inputTypes - inputTypes with {@link InputTypesMap} format
 * @param types - array of components to render according to type defined in renderer instance
 * @returns - component to be render according form schema fieldKey
 */
export const useComponentFromInputType = (
  fieldKey: Field["key"] | undefined,
  inputTypes: InputTypesMap | undefined,
  types: Array<{
    name: InputType;
    component: React.ReactElement;
  }>
): React.ReactElement => {
  const allowedTypes = useMemo<InputType[]>(() => types.map((t) => t.name), [types]);
  const fallbackType: InputType = allowedTypes[0];
  const targetType = (fieldKey && getInputTypeFromKey(inputTypes, fieldKey)) || fallbackType;
  const component = types.find((entry) => entry.name === targetType)?.component;

  if (!component) {
    throw new Error(`\n\nUnable to compute component for "${fieldKey}"\n\n`);
  }

  if (!allowedTypes.includes(targetType)) {
    throw new Error(
      `\n\nBad SelectInput mapping: ${fieldKey} > "${targetType}"\nShould be one of ${allowedTypes}\n\n`
    );
  }

  return component;
};
