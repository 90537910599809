// Store
import { StoreState } from "@store/index";
// Typings
import {
  TableProtectionsModules,
  TableProtectionsModulesName,
  TableProtectionsModulesPayload,
} from "@typings/tables/protections";
import { InputNodeFormat } from "@typings/input-node-format";
// Modules
import {
  useCalculatorIllnessModule,
  useCalculatorProtectionModule,
  useCalculatorTpdModule,
} from "@components/tables/protections/hooks/modules";

// Props: Hooks
type UseCalculatorProtections = (props: {
  name: TableProtectionsModulesName;
  data: StoreState["calculators"]["data"];
}) => CalculatorProtectionsModuleSchema;

export type UseCalculatorProtectionsModule = (
  data: StoreState["calculators"]["data"]
) => CalculatorProtectionsModuleSchema;

// Props: Modules
export interface CalculatorProtectionsModuleTable {
  title: string;
  entries: CalculatorProtectionsModuleEntry[];
  totals: {
    label: string;
    value: string;
  }[];
}

export interface CalculatorProtectionsModuleEntry {
  label: string;
  tooltip?: string;
  field: CalculatorProtectionsModuleField;
}

export interface CalculatorProtectionsModuleField {
  type: InputNodeFormat;
  value: string;
  before?: string;
  after?: string;
}

export interface CalculatorProtectionsModuleSchema {
  tables: CalculatorProtectionsModuleTable[];
  gap: number;
  fetching: boolean;
  submitPayload: (
    updatedData: CalculatorProtectionsModuleTable[]
  ) => TableProtectionsModulesPayload;
}

/**
 * Maps each protection module to its corresponding calculator hook module
 * Each protection module has its own specific hook to retrieve its calculator data
 */
const CalculatorProtectionsMap: Record<
  TableProtectionsModulesName,
  UseCalculatorProtectionsModule
> = {
  [TableProtectionsModules.Illness]: useCalculatorIllnessModule,
  [TableProtectionsModules.Protection]: useCalculatorProtectionModule,
  [TableProtectionsModules.Tpd]: useCalculatorTpdModule,
};

/**
 * Returns calculator data for a specific protection module
 * Determines which module hook to use based on the protection module name
 * The hook will receive the current module name and store data.
 * @param name - name of the protection module
 * @param data - current store data
 * @returns the calculator data for the specified protection module
 */
export const useCalculatorProtections: UseCalculatorProtections = ({ name, data }) =>
  CalculatorProtectionsMap[name](data);
