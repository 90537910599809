// Redux
import {
  ClientType,
  CreateClientPersonDto,
  CreateDependentPersonDto,
  ResponseClientPersonDto,
  ResponseDependentPersonDto,
  ResponsePlanV2Dto,
  UpdateClientPersonDto,
  UpdateDependentPersonDto,
} from "@advicefront/plan-client-axios";
import { Store, StoreState } from "@store/index";
import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
// API
import { API } from "@services/api";
// Initial State
import { initialState } from "./initial-state";
// Types
import { StateProps } from "./types";
// Utils
import { getSubmitSuccessAction } from "@store/utils/submit-success";
import { isClient } from "@store/utils/type-guards/client";
import { isDependent } from "@store/utils/type-guards/dependent";
// Translations
import { lang } from "@lang/index";

/**
 * Internal Actions
 * ---------------------------------------------------------------------
 */

const { submitSuccessAction } = getSubmitSuccessAction<StateProps>(
  "accounts/set-submit-success-state"
);

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state
 * @example dispatch(People.reset())
 */

export const reset = createAction("people/reset");

/**
 * Fetch People
 *
 * Example of an async action / thunk
 * @example await/void dispatch(People.fetch(\{
 *  authToken,
 *  planId
 * \}));
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: ResponsePlanV2Dto["_id"];
  }>,
  { state: StoreState }
>("people/fetch", async ({ authToken, planId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;
  let data: StateProps["data"] = initialState.data;

  try {
    // TODO: implement infinite load as thus request is paginated
    const res = await API.People(authToken).findAllPeople({
      planId,
      orderBy: "clientType",
      orderDirection: "asc",
    });

    const clients = res.data.data.filter(isClient);

    const dependents = res.data.data.filter(isDependent);

    // Type assertion used as TS gives errors due to missing params that don't exist in both response types
    data = {
      clients,
      dependents,
    };
  } catch (e) {
    error = API.parseError(e);
  }

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Create Client
 *
 * Example of an async action / thunk
 * @example await/void dispatch(People.createClient(\{
 *  authToken,
 *  planId,
 *  dto
 * \}));
 */

export const createClient = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: ResponsePlanV2Dto["_id"];
    dto: CreateClientPersonDto;
  }>,
  { state: StoreState }
>("people/create-client", async ({ authToken, planId, dto }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.People(authToken).createClientPerson({
      planId,
      dto,
    });
  } catch (e) {
    error = API.parseError(e);
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_CREATED", lang("NOTIFICATION_LABEL_PERSON")),
        description: "",
      })
    );

    // Creating a partner changes the forecast through a worker on the BE so we need to open polling
    // Polling will automatically close when a the updated forecast is retrieved
    // NOTE: when creating an Owner we don't need to enable polling as the forecast won't exist before we add an Owner
    if (dto.clientType === ClientType.Partner) {
      void dispatch(
        Store.forecast.poll({
          authToken,
          planId,
        })
      );
    }
  }

  // Refresh people data (side effect)
  void dispatch(
    Store.people.fetch({
      authToken,
      planId,
    })
  );

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
  };
});

/**
 * Create Dependent
 *
 * Example of an async action / thunk
 * @example await/void dispatch(People.createDependent(\{
 *  authToken,
 *  planId,
 *  dto
 * \}));
 */

export const createDependent = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: ResponsePlanV2Dto["_id"];
    dto: CreateDependentPersonDto;
  }>,
  { state: StoreState }
>("people/create-dependent", async ({ authToken, planId, dto }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.People(authToken).createDependentPerson({
      planId,
      dto,
    });
  } catch (e) {
    error = API.parseError(e);
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_CREATED", lang("NOTIFICATION_LABEL_PERSON")),
        description: "",
      })
    );
  }

  // Creating a dependent changes the forecast through a worker on the BE so we need to open polling
  // Polling will automatically close when a the updated forecast is retrieved
  void dispatch(
    Store.forecast.poll({
      authToken,
      planId,
    })
  );

  // Refresh people data (side effect)
  void dispatch(
    Store.people.fetch({
      authToken,
      planId,
    })
  );

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
  };
});

/**
 * Update Person
 *
 * Example of an async action / thunk
 * @example await/void dispatch(People.updatePerson(\{
 *  authToken,
 *  personId,
 *  planId,
 *  updatedPersonData
 * \}));
 */

export const updatePerson = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    personId: ResponseClientPersonDto["_id"] | ResponseDependentPersonDto["_id"];
    planId: ResponsePlanV2Dto["_id"];
    updatedPersonData: UpdateClientPersonDto | UpdateDependentPersonDto;
  }>,
  { state: StoreState }
>(
  "people/update-person",
  async ({ authToken, personId, planId, updatedPersonData }, { dispatch }) => {
    let error: StateProps["error"] = initialState.error;

    try {
      await API.People(authToken).updatePerson({
        personId,
        planId,
        updatePersonRequest: updatedPersonData,
      });
    } catch (e) {
      error = API.parseError(e);
    }

    const submitSuccess = !error;

    void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

    if (error) {
      // Trigger error notification
      void dispatch(
        Store.notifications.addNotification({
          type: "error",
          title: lang("NOTIFICATION_TITLE_ERROR"),
          description: error,
        })
      );
    }

    if (submitSuccess) {
      // Trigger success notification
      void dispatch(
        Store.notifications.addNotification({
          type: "success",
          title: lang("NOTIFICATION_TITLE_UPDATED", lang("NOTIFICATION_LABEL_PERSON")),
          description: "",
        })
      );
    }

    // Updating a person changes the forecast through a worker on the BE so we need to open polling
    // Polling will automatically close when a the updated forecast is retrieved
    void dispatch(
      Store.forecast.poll({
        authToken,
        planId,
      })
    );

    // Refresh people data (side effect)
    void dispatch(
      Store.people.fetch({
        authToken,
        planId,
      })
    );

    // The value we return becomes the `fulfilled` action payload
    return {
      error,
      submitSuccess,
    };
  }
);

/**
 * Delete Person
 *
 * Example of an async action / thunk
 * @example await/void dispatch(People.deletePerson(\{
 *  authToken,
 *  personId,
 *  planId,
 * \}));
 */

export const deletePerson = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    personId: ResponseClientPersonDto["_id"] | ResponseDependentPersonDto["_id"];
    planId: ResponsePlanV2Dto["_id"];
  }>,
  { state: StoreState }
>("people/delete-person", async ({ authToken, personId, planId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.People(authToken).removePerson({
      personId,
      planId,
    });
  } catch (e) {
    error = API.parseError(e);
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_DELETED", lang("NOTIFICATION_LABEL_PERSON")),
        description: "",
      })
    );
  }

  // Deleting a person changes the forecast through a worker on the BE so we need to open polling
  // Polling will automatically close when a the updated forecast is retrieved
  void dispatch(
    Store.forecast.poll({
      authToken,
      planId,
    })
  );

  // Refresh people data (side effect)
  void dispatch(
    Store.people.fetch({
      authToken,
      planId,
    })
  );

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
  };
});
