// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Components
import { AfButton } from "@advicefront/ds-button";
import { AfDialog } from "@advicefront/ds-dialog";

// Props
interface DialogCloseFormProps {
  title: string;
  handleCancel: () => void;
  handleConfirm: () => void;
}

export const DialogCloseForm = ({
  title,
  handleCancel,
  handleConfirm,
}: DialogCloseFormProps): React.ReactElement => (
  <AfDialog
    isOpen
    skin="warning"
    title={lang("DIALOG_TITLE_CLOSE_FORM", title)}
    secondaryAction={
      <AfButton type="button" skin="ghost" onClick={handleCancel}>
        {lang("ACTION_CANCEL_CLOSE_FORM")}
      </AfButton>
    }
    primaryAction={
      <AfButton type="button" skin="danger" onClick={handleConfirm}>
        {lang("ACTION_CONFIRM_CLOSE_FORM")}
      </AfButton>
    }
  >
    <div className="align-center">
      {lang("DIALOG_DESCRIPTION_CLOSE_FORM", title)}
      <br />
      {lang("DIALOG_DESCRIPTION_CHANGES_LOST")}
    </div>
  </AfDialog>
);
