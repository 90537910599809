// Typings
import {
  ResponseEventInputDto,
  ResponseExpenseInputDto,
  ResponseAccountInputDto,
  ResponseAssetInputDto,
  ResponseDebtInputDto,
  ResponseIncomeInputDto,
  ResponsePensionInputDto,
} from "@advicefront/plan-client-axios";

// Props
type InputsGroupsDto =
  | ResponseIncomeInputDto[]
  | ResponseExpenseInputDto[]
  | ResponseDebtInputDto[]
  | ResponseAssetInputDto[]
  | ResponseAccountInputDto[]
  | ResponsePensionInputDto[]
  | ResponseEventInputDto[];

export type InputsGroupsData = {
  income: ResponseIncomeInputDto[];
  expense: ResponseExpenseInputDto[];
  debt: ResponseDebtInputDto[];
  asset: ResponseAssetInputDto[];
  account: ResponseAccountInputDto[];
  pension: ResponsePensionInputDto[];
  event: ResponseEventInputDto[];
};

/**
 * Get Inputs Groups
 * Method to group the inputs based on their type
 * @param inputs - array of inputs dto
 * @returns an object containing arrays for each group
 */
export const getInputsGroups = (inputs: InputsGroupsDto): InputsGroupsData => {
  // Initialize an empty object with arrays for each input group
  let groups: InputsGroupsData = {
    income: [], //"IncomeInput",
    expense: [], //"ExpenseInput",
    event: [], //"EventInput",
    debt: [], //"DebtInput",
    asset: [], //"AssetInput",
    account: [], //"AccountInput",
    pension: [], //"PensionInput",
  };

  // Iterate through each key in the groups object
  Object.keys(groups).forEach((key) => {
    groups = {
      ...groups,
      [key]: inputs.filter((input) => input.__bt.toLowerCase() === `${key}input`),
    };
  });

  // Return the updated groups object
  return groups;
};
