// Styles
import "./styles/index.scss";
// React
import React from "react";
// Typings
import { AfFormFieldProps } from "@advicefront/ds-text-area/dist/types/form-field/src/AfFormField";
import { ValidationData } from "@advicefront/fe-infra-form-schema-renderer";
// Component
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfCard } from "@advicefront/ds-card";
import { AfTypography } from "@advicefront/ds-typography";

// Props
interface ReadOnlyFieldProps {
  label: AfFormFieldProps["label"];
  value: string | undefined;
  validation: ValidationData;
}

export const ReadOnlyField = ({
  label,
  value,
  validation,
}: ReadOnlyFieldProps): React.ReactElement => (
  <>
    <AfCard skin="secondary">
      <AfCard.Content>
        <AfRow align="center">
          <AfCol>
            <AfTypography hasMargin={false} type="small-bold" skin="text-light">
              {label}
            </AfTypography>
          </AfCol>
          <AfCol>
            <AfTypography
              hasMargin={false}
              type="h4"
              skin={validation?.status === "error" ? "danger" : undefined}
            >
              {value}
            </AfTypography>
          </AfCol>
        </AfRow>
      </AfCard.Content>
    </AfCard>
    <AfTypography
      type="small"
      skin={validation?.status === "error" ? "danger" : undefined}
      className="form-read-only-field__validation"
    >
      {validation?.description}
    </AfTypography>
  </>
);
