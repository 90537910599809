// React
import React, { useEffect } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { LayoutProps } from "@layouts/types";
// Layouts
import { PlanDetailsLayout } from "@layouts/plan-details";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AssumptionsTabs } from "@layouts/assumptions/components/Tabs";

export const AssumptionsLayout = ({ children }: LayoutProps): React.ReactElement => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);
  const accounts = Store.useSelector((state) => state.accounts);
  const liquidation = Store.useSelector((state) => state.liquidation);
  const firmPreferences = Store.useSelector((state) => state.firmPreferences);

  // Fetch Accounts
  useEffect(() => {
    if (auth.authToken && plan.data && !accounts.data && !accounts.loading && !accounts.error) {
      void dispatch(
        Store.accounts.fetch({
          authToken: auth.authToken,
          planId: plan.data._id,
        })
      );
    }
  }, [dispatch, auth.authToken, plan.data, accounts.data, accounts.loading, accounts.error]);

  // Fetch Liquidation
  useEffect(() => {
    if (
      auth.authToken &&
      plan.data &&
      !liquidation.data &&
      !liquidation.loading &&
      !liquidation.error
    ) {
      void dispatch(
        Store.liquidation.fetch({
          authToken: auth.authToken,
          planId: plan.data._id,
          accountLiquidationOrderIds: plan.data.accountLiquidationOrder,
        })
      );
    }
  }, [dispatch, auth.authToken, plan.data, liquidation]);

  // Fetch Firm Preferences
  // Firm preferences are required in way to be able to reset assumptions values in edit mode
  useEffect(() => {
    if (
      auth.authToken &&
      !firmPreferences.data &&
      !firmPreferences.loading &&
      !firmPreferences.error
    ) {
      void dispatch(
        Store.firmPreferences.fetch({
          authToken: auth.authToken,
        })
      );
    }
  }, [
    dispatch,
    auth.authToken,
    firmPreferences.data,
    firmPreferences.loading,
    firmPreferences.error,
  ]);

  return (
    <PlanDetailsLayout>
      <AfRow>
        <AfCol>
          <AssumptionsTabs />
        </AfCol>
        <AfCol size="auto">{children}</AfCol>
      </AfRow>
    </PlanDetailsLayout>
  );
};
