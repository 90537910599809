// Constants
import { EMPTY_VALUE } from "@constants/index";

// Tier symbols
const TIER_SYMBOLS = ["", "k", "M", "G", "T", "P", "E"];

/**
 * Remove NaN
 * Removes any non-numeric characters from the input string
 * @param value - The input string
 * @returns The input string without non-numeric characters
 */
function removeNaN(value: string): string {
  return Array.from(value).reduce((prev, curr) => prev + (isNaN(parseInt(curr)) ? "" : curr), "");
}

/**
 * Mask Currency
 * Formats a numeric value as a currency string with an optional currency symbol
 * @param value - numeric value to format
 * @param currencySymbol - optional symbol to include in the formatted string
 * @example maskCurrency(9871267) = "9,871,267"
 * @example maskCurrency(78473, "£") = "£ 78,473"
 * @example maskCurrency(34562.49, "£") = "£ 34,562"
 * @example maskCurrency("example") = ""
 * @returns the formatted currency string
 */
export const maskCurrency = (value: string | number, currencySymbol?: string): string => {
  // Convert the value to a number
  const number = parseInt(value.toString());

  // Check if the number is non-numeric, and return an empty string if it is
  if (isNaN(number)) return "";

  // Format the number as a currency string using the "en-GB" locale
  const result = new Intl.NumberFormat("en-GB").format(number);

  // Concatenate the currency symbol and the formatted number,
  // filtering out any falsy values, and join them with a space
  return [currencySymbol, result].filter((val) => !!val).join(" ");
};

/**
 * Unmask Currency
 * Removes any non-numeric characters from the input string and returns the result
 * @param value - input string representing a currency value
 * @example unmaskCurrency("9,871,267") = "9871267"
 * @example unmaskCurrency("£ 78,473") = "78473"
 * @example unmaskCurrency("£ 34,562") = "34562"
 * @returns input string with non-numeric characters removed
 */
export const unmaskCurrency = (value: string): string => {
  // Split the input string into integer and decimal parts
  let [integers, decimals] = value.split(".");

  // Remove any non-numeric characters from the integer and decimal parts
  integers = removeNaN(integers || "");
  decimals = removeNaN(decimals || "");

  // Concatenate the integer and decimal parts, adding a decimal point if the decimal part is not empty
  const result = integers + (decimals.length ? "." + decimals : "");

  // Return the resulting string
  return result;
};

/**
 * Abbreviate currency
 * @param value - value to check
 * @param decimals - decimals to display
 * @example abbreviateCurrency(5000) = "5k"
 * @example abbreviateCurrency(152234, 2) = "152.23k"
 * @example abbreviateCurrency("932234988", 3, "£") = "£ 932.234M"
 * @example abbreviateCurrency(undefined) = "-";
 * @returns abbreviate number with currency and tier symbol
 */
export const abbreviateCurrency = (
  value: string | number | undefined | null,
  decimals = 0,
  currencySymbol?: string
): string => {
  if (value === undefined || value === null) return EMPTY_VALUE;

  // Convert value to number if string
  const computedValue = typeof value === "string" ? parseFloat(value) : value;

  // If is not a number return string
  if (isNaN(computedValue)) return value.toString();

  // Tier determines SI symbol
  const tier = (Math.log10(computedValue) / 3) | 0;

  // Get tier symbol and determine scale
  const tierSymbol = TIER_SYMBOLS[tier];
  const scale = computedValue / Math.pow(10, tier * 3);

  // Define currency symbol if defined
  const currencySymbolValue = currencySymbol ? `${currencySymbol} ` : "";

  // Don't round value (native rounds)
  decimals = Math.pow(10, decimals);
  const number = Math.floor(scale * decimals) / decimals;

  // Format number and add suffix
  return `${currencySymbolValue}${number}${tierSymbol}`;
};
