// React
import React, { useCallback } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { TableAssumptionsModules } from "@typings/tables/assumptions";
import { UpdatePlanAssumptionsRequest } from "@advicefront/plan-client-axios";
// Utils
import { isGbrAssumptions, isSgpAssumptions } from "@utils/type-guards/assumptions";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { TableAssumptions, TableAssumptionsProps } from "@components/tables/assumptions";

// Props
interface PreferencesGeneralTableProps {
  isAdvisorPreferences: boolean;
}

export const PreferencesGeneralTable = ({
  isAdvisorPreferences,
}: PreferencesGeneralTableProps): React.ReactElement => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);

  // Get advisor or firm preferences assumptions
  const { data, error, loading } = Store.useSelector((state) =>
    isAdvisorPreferences ? state.preferences : state.firmPreferences
  );

  // Handle submit
  const handleSubmit: TableAssumptionsProps["handleSubmit"] = useCallback(
    (payload) => {
      if (!auth.authToken) return;

      // Type assertion to ensure payload is of type UpdatePlanAssumptionsRequest
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const updatedPayload = payload as UpdatePlanAssumptionsRequest;

      // Update advisor or firm preferences
      if (isAdvisorPreferences) {
        void dispatch(
          Store.preferences.update({
            authToken: auth.authToken,
            updatePreferencesData: updatedPayload,
          })
        );
      } else {
        void dispatch(
          Store.firmPreferences.update({
            authToken: auth.authToken,
            updatedAssumptions: updatedPayload,
          })
        );
      }
    },
    [dispatch, auth.authToken, isAdvisorPreferences]
  );

  // Define list of assumptions tables to be displayed
  const assumptionsTables = [
    // GBR and SGP Modules
    TableAssumptionsModules.Mortality,
    TableAssumptionsModules.Inflation,
    // GBR Modules
    ...(isGbrAssumptions(data) ? [TableAssumptionsModules.IsaLimits] : []),
    // SGP Modules
    ...(isSgpAssumptions(data)
      ? [
          TableAssumptionsModules.CpfThresholds,
          TableAssumptionsModules.CpfEmployeeEmployer,
          TableAssumptionsModules.CpfCaps,
          TableAssumptionsModules.SrsCaps,
        ]
      : []),
  ];

  return (
    <AfRow orientation="vertical">
      {assumptionsTables.map((name, index) => (
        <AfCol key={`table-assumptions-${index}`}>
          <TableAssumptions
            name={name}
            data={data}
            loading={!!loading}
            error={!!error}
            admin={!isAdvisorPreferences}
            handleSubmit={handleSubmit}
          />
        </AfCol>
      ))}
    </AfRow>
  );
};
