// React
import React, { useMemo } from "react";
// Router
import { useParams, useLocation, generatePath, Link } from "react-router-dom";
import { getRoute } from "@routes/utils/get-route";
// Typings
import { RouteIndex } from "@routes/index";
// Translations
import { lang } from "@lang/index";
// Components
import { AfTabs } from "@advicefront/ds-tabs";

export const ProtectionsTabs = (): React.ReactElement => {
  // Route
  const params = useParams();
  const { pathname } = useLocation();

  // List of all tabs
  const tabs = useMemo(() => {
    // Define order of tabs
    const tabsOrdered: Record<string, RouteIndex> = {
      [lang("TAB_PROTECTION")]: "protectionProtection",
      [lang("TAB_TPD")]: "protectionTpd",
      [lang("TAB_MAJOR_ILLNESS")]: "protectionIllness",
    };

    // Return list of tabs ordered with title and route
    return Object.entries(tabsOrdered).map(([title, routeIndex]) => ({
      title,
      route: generatePath(getRoute(routeIndex), params),
    }));
  }, [params]);

  return (
    <AfTabs direction="vertical" className="sticky-tabs">
      {tabs.map((tab) => (
        <AfTabs.Item
          key={tab.route}
          active={pathname.endsWith(tab.route)}
          component={<Link to={tab.route} />}
        >
          {tab.title}
        </AfTabs.Item>
      ))}
    </AfTabs>
  );
};
