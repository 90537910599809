// React
import React, { useMemo, useState } from "react";
// Router
import { FormActions, FormKeys } from "@typings/routing";
import { useModalRouting } from "@hooks/modal-routing";
// Store
import { Store } from "@store/index";
// Typings
import { InputGroups, Regions } from "@advicefront/plan-client-axios";
// Utils
import * as CC from "change-case";
import { asFormKey, asInputGroup } from "@routes/utils/modal-routing-guards";
import { getInputLabel } from "@utils/get-input-label";
// Translations
import { lang } from "@lang/index";
// Components
import { AfRadio, AfRadioGroup } from "@advicefront/ds-radio";
import { AfButton } from "@advicefront/ds-button";
import { AfIcon } from "@advicefront/ds-icon";
import { AfModal } from "@advicefront/ds-modal";
import { LoaderSkeletonModal } from "@components/loaders/skeleton";

// Form id for submit button
const FORM_ID = "ModalFormSelection";

export const ModalFormSelection = (): React.ReactElement | null => {
  // Store
  const forms = Store.useSelector((state) => state.forms);
  const plan = Store.useSelector((state) => state.plan);

  // Route
  const { closeModal, openModal, formSelection } = useModalRouting();

  // Throw error if form selection param is not presented
  if (!formSelection) throw new Error("Missing formSelection url param");

  // State to handle the selected option
  const [selectedOption, setSelectedOption] = useState("");

  // Check if current form selection is an input group
  const isInputGroup = useMemo(() => !!asInputGroup(formSelection), [formSelection]);

  // Get all options list from form selection
  const optionsList = useMemo(() => {
    // Options for inputs
    if (isInputGroup) {
      // TODO: remove custom logic to change SGP Income subtypes once BE implements labels for inputsList
      if (formSelection === InputGroups.IncomeInput && plan.data?.region === Regions.Sgp) {
        // In SGP IncomeInput labels should be as follows:
        // Employment Income -> Income
        // Income -> Other Income
        return (
          forms.data?.inputsList?.data["IncomeInput"]
            .map((input) => ({
              ...input,
              base: input.base === "IncomeInput" ? "OtherIncomeInput" : "IncomeInput",
            }))
            // Sort array to display "Income" first
            .sort((a, b) => (a.base > b.base ? 1 : b.base > a.base ? -1 : 0))
        );
      }
      return forms.data?.inputsList?.data[formSelection];
    }

    // Options for people
    // TODO: refactor once BE implements people forms through Form Schema
    if (formSelection === FormKeys.People) {
      return [
        {
          dto: "DependentDto",
          base: "Dependent",
        },
        {
          dto: "PartnerDto",
          base: "Partner",
        },
      ];
    }
  }, [formSelection, isInputGroup, forms.data?.inputsList, plan.data?.region]);

  // Handle submit selected option
  const handleSubmit = (): void =>
    openModal({
      action: FormActions.create,
      ...(isInputGroup && {
        subType: optionsList?.find((opt) => opt.base === selectedOption)?.dto,
      }),
      // TODO: refactor once BE implements people forms through Form Schema
      ...(!isInputGroup && { type: asFormKey(selectedOption) }),
      formSelection: null,
      fromFormSelection: "true",
    });

  return (
    <AfModal closeOnClickOutside={false} onClose={closeModal}>
      <AfModal.Header heading={lang("ACTION_CREATE", getInputLabel(formSelection))} />
      <AfModal.Content
        scrollableContainerProps={{
          className: "form-modal",
          variation: ["vertical"],
        }}
      >
        {!optionsList?.length && <LoaderSkeletonModal />}

        {optionsList?.length && (
          <form
            id={FORM_ID}
            onSubmit={(ev): void => {
              ev.preventDefault();
              handleSubmit();
            }}
          >
            <AfRadioGroup direction="vertical">
              {optionsList.map((option) => (
                <AfRadio
                  key={option.base}
                  variation="boxed"
                  checked={option.base === selectedOption}
                  onChange={(): void => setSelectedOption(option.base)}
                >
                  {CC.capitalCase(option.base).replace("Input", "")}
                </AfRadio>
              ))}
            </AfRadioGroup>
          </form>
        )}
      </AfModal.Content>
      <AfModal.Footer
        actions={[
          <AfButton
            key="next"
            type="submit"
            skin="primary"
            icon={<AfIcon name="basic-arrow-tail-right" />}
            iconPosition="right"
            disabled={!selectedOption}
            form={FORM_ID}
          >
            {lang("ACTION_NEXT")}
          </AfButton>,
        ]}
      />
    </AfModal>
  );
};
