/**
 * Views Plan
 * Naming convention: PLAN_TITLE_ELEMENT or PLAN_DESCRIPTION_ELEMENT
 */
export const plan = {
  // Titles
  PLAN_TITLE_ARCHIVED: {
    en: "(Archived)",
  },

  // Import Errors
  PLAN_TITLE_IMPORT_ERRORS: {
    en: "{0} Input(s) could not be imported due to errors",
  },
  PLAN_DESCRIPTION_IMPORT_ERRORS: {
    en: "Review which inputs we're not imported. ",
  },
};
