// React
import { useContext, useMemo } from "react";
// Typings
import { FormModuleInstance, UseInputRendererProps } from "@forms/modules/types";
import { ClientNamingMode } from "@typings/client-naming-mode";
// Context
import { AppOptionsContext } from "@context/app-options";

export const useInputRenderer: FormModuleInstance["useInputRenderer"] = () => {
  // Context
  const { clientNamingMode } = useContext(AppOptionsContext);

  return useMemo(
    (): UseInputRendererProps => ({
      // Types
      inputTypes: {
        number: {
          literal: ["yearOfBirth", "retirementAge", "dependentUntilAge"],
        },
        boolean: {
          literal: ["gender"],
        },
      },

      // Props
      inputProps: {
        column: {
          ...(clientNamingMode === ClientNamingMode.full && {
            size: {
              12: ["firstName"],
            },
          }),
          offsetAfter: {
            6: ["retirementAge", "relationshipType", "dependentUntilAge"],
          },
        },
      },
    }),
    [clientNamingMode]
  );
};
