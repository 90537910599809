// React
import React, { useContext } from "react";
// Typings
import { FormElement, SchemaRenderer } from "@advicefront/fe-infra-form-schema-renderer";
import { FormRendererOptions } from "@forms/renderer/types";
// Context
import { AppOptionsContext } from "@context/app-options";
// Utils
import * as CC from "change-case";
import { getColumnProps } from "@forms/utils/get-column-props";
import { getFieldProps } from "@forms/utils/get-field-props";
import { useComponentFromInputType } from "@forms/utils/input-types-map";
// Hooks
import { useFieldTranslations, useFieldValidation, useScrollToError } from "@forms/hooks";
// Translations
import { lang } from "@lang/index";
// Components
import { LabelField, ReadOnlyField, TitleField } from "@forms/renderer/fragments";
import { AfCol } from "@advicefront/ds-grid";
import { AfTextArea } from "@advicefront/ds-text-area";
import { AfTextField } from "@advicefront/ds-text-field";

// Props
type TextFieldProps = Parameters<SchemaRenderer["TextField"]>[0] & {
  options: FormRendererOptions;
};

export const TextField = ({
  field,
  validation,
  computedValues,
  setValue,
  options,
}: TextFieldProps): React.ReactElement => {
  // Get currency symbol
  const { currencySymbol } = useContext(AppOptionsContext);

  // Create component reference to be able to scroll to a field with an error
  const componentRef = useScrollToError(field.key, options.formValidation);

  // Get translations of the specific field key
  // It will return the title, label, placeholder, description and tooltip props
  const fieldTranslations = useFieldTranslations(options.formKey, computedValues)[field.key];

  // Get validation of the specific field
  // It will return the skin, validationDescription, onBlur and onFocus props
  const fieldValidation = useFieldValidation(options.formValidation?.valid, validation);

  // Get input props field set on the input renderer hook of each module
  const { disabled } = getFieldProps(options.inputRenderer.inputProps?.field, field.key);

  // Define on change handler where it will update the form values state accordingly
  const onChange: React.ChangeEventHandler<FormElement> = (ev) => {
    setValue(ev.target.value);
  };

  // Define the label prop for each field including an optional tooltip
  const labelProp = (
    <LabelField tooltip={fieldTranslations?.tooltip}>
      {fieldTranslations?.label || CC.capitalCase(field?.label || field.key)}
    </LabelField>
  );

  // Define the placeholder prop for each field using the translation or the default one
  const placeholderProp =
    fieldTranslations?.placeholder ||
    lang("FORM_PLACEHOLDER_DEFAULT", CC.noCase(field?.label || field.key));

  // Define all common props used on each component
  const commonProps = {
    ...fieldValidation,
    name: field.key,
    label: labelProp,
    placeholder: placeholderProp,
    description: fieldTranslations?.description,
    before: fieldTranslations?.before,
    after: fieldTranslations?.after,
    value: field.value,
    defaultValue: field.defaultValue,
    disabled,
    onChange,
  };

  // Get input props column set on the input renderer hook of each module
  const { size, offsetBefore, offsetAfter } = getColumnProps(
    options.inputRenderer.inputProps?.column,
    options.formDto,
    field.key
  );

  // Define component to be render according the field key and input types
  const component = useComponentFromInputType(field.key, options.inputRenderer.inputTypes, [
    // Text
    {
      name: "text",
      component: <AfTextField {...commonProps} />,
    },

    // Number
    {
      name: "number",
      component: <AfTextField {...commonProps} type="number" />,
    },

    // Percentage
    {
      name: "percentage",
      component: <AfTextField {...commonProps} type="number" before="%" />,
    },

    // Currency
    {
      name: "currency",
      component: <AfTextField {...commonProps} before={currencySymbol} />,
    },

    // Textarea
    {
      name: "textarea",
      component: <AfTextArea {...commonProps} />,
    },

    // Read Only
    {
      name: "read-only",
      component: (
        <ReadOnlyField
          label={commonProps.label}
          value={commonProps.value}
          validation={validation}
        />
      ),
    },
  ]);

  return (
    <>
      {fieldTranslations?.title && <TitleField title={fieldTranslations.title} />}
      <AfCol ref={componentRef} size={size} offsetBefore={offsetBefore} offsetAfter={offsetAfter}>
        {component}
      </AfCol>
    </>
  );
};
