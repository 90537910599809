// React
import React from "react";
// Typings
import { FormRendererOptions } from "@forms/renderer/types";
import { SchemaRenderer } from "@advicefront/fe-infra-form-schema-renderer";
// Utils
import * as CC from "change-case";
import { getColumnProps } from "@forms/utils/get-column-props";
import { getFieldProps } from "@forms/utils/get-field-props";
import { useComponentFromInputType } from "@forms/utils/input-types-map";
// Hooks
import {
  useFieldSelectOptions,
  useFieldTranslations,
  useFieldValidation,
  useScrollToError,
} from "@forms/hooks";
import { useUniqueId } from "@advicefront/ds-base";
// Translations
import { lang } from "@lang/index";
// Components
import { AfRadio, AfRadioGroup } from "@advicefront/ds-radio";
import { LabelField, TitleField } from "@forms/renderer/fragments";
import { AfCol } from "@advicefront/ds-grid";
import { AfSelect } from "@advicefront/ds-select";

// Props
type SelectionFieldSingleProps = Parameters<SchemaRenderer["SelectionFieldSingle"]>[0] & {
  options: FormRendererOptions;
};

export const SelectionFieldSingle = ({
  field,
  validation,
  computedValues,
  setValue,
  options,
}: SelectionFieldSingleProps): React.ReactElement => {
  // Create component reference to be able to scroll to a field with an error
  const componentRef = useScrollToError(field.key, options.formValidation);

  // Generate a unique identifier for the field
  const fieldId = useUniqueId();

  // Get translations of the specific field key
  // It will return the title, label, placeholder, description and tooltip props
  const fieldTranslations = useFieldTranslations(options.formKey, computedValues)[field.key];

  // Get validation of the specific field
  // It will return the skin, validationDescription, onBlur and onFocus props
  const fieldValidation = useFieldValidation(options.formValidation?.valid, validation);

  // Get select options of the specific field with our without sections
  const fieldSelectOptions = useFieldSelectOptions(field.options);

  // Get input props field set on the input renderer hook of each module
  const { disabled } = getFieldProps(options.inputRenderer.inputProps?.field, field.key);

  // Define the label prop for each field including an optional tooltip
  const labelProp = (
    <LabelField tooltip={fieldTranslations?.tooltip}>
      {fieldTranslations?.label || CC.capitalCase(field?.label || field.key)}
    </LabelField>
  );

  // Define the placeholder prop for each field using the translation or the default one
  const placeholderProp = fieldTranslations?.placeholder || lang("FORM_PLACEHOLDER_SELECT_DEFAULT");

  // Define all common props used on each component
  const commonProps = {
    ...fieldValidation,
    id: `field-${field.key}-${fieldId}`,
    name: field.key,
    label: labelProp,
    placeholder: placeholderProp,
    description: fieldTranslations?.description,
    value: field.value,
    defaultValue: field.defaultValue,
    disabled,
  };

  // Get input props column set on the input renderer hook of each module
  const { size, offsetBefore, offsetAfter } = getColumnProps(
    options.inputRenderer.inputProps?.column,
    options.formDto,
    field.key
  );

  // Define component to be render according the field key and input types
  const component = useComponentFromInputType(field.key, options.inputRenderer.inputTypes, [
    // Select
    {
      name: "select",
      component: (
        <AfSelect
          {...commonProps}
          options={fieldSelectOptions}
          contentProps={{
            onFocus: fieldValidation.onFocus,
          }}
          onClickOutside={fieldValidation.onBlur}
          onChange={(value): void => setValue(value || null)}
        />
      ),
    },

    // Radio
    {
      name: "boolean",
      component: (
        <AfRadioGroup {...commonProps} direction="horizontal">
          {field.options.map((option, index) => (
            <AfRadio
              key={`radio-${commonProps.id}-${option.value}-${index}`}
              variation="boxed"
              value={option.value}
              checked={option.value === field.value}
              onChange={(ev): void => setValue(ev.target.value)}
            >
              {option.label}
            </AfRadio>
          ))}
        </AfRadioGroup>
      ),
    },
  ]);

  return (
    <>
      {fieldTranslations?.title && <TitleField title={fieldTranslations.title} />}
      <AfCol ref={componentRef} size={size} offsetBefore={offsetBefore} offsetAfter={offsetAfter}>
        {component}
      </AfCol>
    </>
  );
};
