// React
import React, { useEffect, useMemo, useState } from "react";
// Router
import { useModalRouting } from "@hooks/modal-routing";
// Store
import { Store } from "@store/index";
// Translations
import { lang } from "@lang/index";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfIcon, AfIconProps } from "@advicefront/ds-icon";
import { AfButton } from "@advicefront/ds-button";
import { AfCard } from "@advicefront/ds-card";
import { AfCollapse } from "@advicefront/ds-collapse";
import { AfModal } from "@advicefront/ds-modal";
import { AfTypography } from "@advicefront/ds-typography";
import { LoaderSkeletonModal } from "@components/loaders/skeleton";

// List of input types icons
// TODO: Change type of icons list: https://advicefront.atlassian.net/browse/PROD-2655
const INPUT_TYPES_ICONS: Record<string, AfIconProps["name"]> = {
  Income: "money-dollar-circle",
  Employment: "money-dollar-circle",
  Expense: "money-tag",
  Account: "money-empty-wallet",
  Asset: "building-house",
  Residence: "building-house",
  Pension: "security-user",
  Debt: "building-buildings",
  Partner: "user",
  Dependent: "user",
};

export const ModalPlanImportErrors = (): React.ReactElement => {
  // Store
  const plan = Store.useSelector((state) => state.plan);

  // Route
  const { closeModal } = useModalRouting();

  // State to expand and collapse errors
  const [expandErrors, setExpandErrors] = useState<Record<number, boolean>>({});

  // Expand or collapse an error
  const toggleError = (index: number): void => {
    setExpandErrors((prevState) => ({
      ...prevState,
      [index]: !expandErrors[index],
    }));
  };

  // Extract errors details from each input and flatten them
  const errorsDetails = useMemo(
    () => plan.data?.importDetails?.inputErrors?.flatMap(({ details }) => details),
    [plan.data?.importDetails?.inputErrors]
  );

  // Prevent opening modal by url if the user has already dismissed
  useEffect(() => {
    if (!!plan.data && (!plan.data?.importDetails || plan.data?.importDetails?.userDismissed)) {
      closeModal();
    }
  }, [plan.data, closeModal]);

  return (
    <AfModal closeOnClickOutside={false} onClose={closeModal}>
      <AfModal.Header heading={lang("MODAL_TITLE_IMPORT_ERRORS")} />
      <AfModal.Content
        scrollableContainerProps={{
          className: "form-modal",
          variation: ["vertical"],
        }}
      >
        {!plan.data && <LoaderSkeletonModal />}

        {!!plan.data && (
          <AfRow orientation="vertical">
            <AfCol>
              <AfTypography hasMargin={false} type="body-bold">
                {lang("MODAL_DESCRIPTION_IMPORT_ERRORS")}
                <br />
                {lang("MODAL_DESCRIPTION_IMPORT_ERRORS_ACTIONS")}
              </AfTypography>
              <ul>
                <li>{lang("MODAL_DESCRIPTION_IMPORT_ERRORS_FIRST_ACTION")}</li>
                <li>{lang("MODAL_DESCRIPTION_IMPORT_ERRORS_SECOND_ACTION")}</li>
              </ul>
            </AfCol>

            {errorsDetails?.map((entry, index) => (
              <AfCol key={`plan-import-errors-${index}`}>
                <AfCard>
                  <AfCard.Header>
                    <AfRow align="center">
                      {entry?.inputType && (
                        <AfCol>
                          <AfIcon size="m" name={INPUT_TYPES_ICONS[entry.inputType]} />
                        </AfCol>
                      )}
                      <AfCol size="auto">
                        <AfTypography hasMargin={false} type="body-bold">
                          {entry?.inputName || lang("MODAL_LABEL_IMPORT_ERRORS_UNTITLED")}
                        </AfTypography>
                        <AfTypography hasMargin={false} type="small" skin="text-light">
                          {entry?.inputType}
                        </AfTypography>
                      </AfCol>
                      <AfCol>
                        <AfButton
                          skin="secondary"
                          size="s"
                          icon={
                            <AfIcon name={`basic-arrow-${expandErrors[index] ? "up" : "down"}`} />
                          }
                          onClick={(): void => toggleError(index)}
                        />
                      </AfCol>
                    </AfRow>
                  </AfCard.Header>

                  {expandErrors[index] && (
                    <AfCard.Content>
                      <AfCollapse open>
                        {entry?.list.map((message) => (
                          <AfTypography
                            key={`plan-import-errors-${index}-${message}`}
                            hasMargin={false}
                            type="small"
                            skin="danger"
                          >
                            {message}
                          </AfTypography>
                        ))}
                      </AfCollapse>
                    </AfCard.Content>
                  )}
                </AfCard>
              </AfCol>
            ))}
          </AfRow>
        )}
      </AfModal.Content>
      <AfModal.Footer
        actions={[
          <AfButton key="cancel" skin="ghost" onClick={closeModal}>
            {lang("ACTION_CLOSE")}
          </AfButton>,
        ]}
      />
    </AfModal>
  );
};
