// React
import { useContext, useMemo } from "react";
// Typings
import { FormData, ValueKey } from "@advicefront/fe-infra-form-schema";
import { AfFormFieldProps } from "@advicefront/ds-text-area/dist/types/form-field/src/AfFormField";
import { AfTextFieldProps } from "@advicefront/ds-text-field";
import { ClientNamingMode } from "@typings/client-naming-mode";
import { FormKeys } from "@typings/routing";
import { Periodicity } from "@advicefront/plan-client-axios";
// Context
import { AppOptionsContext } from "@context/app-options";
// Utils
import { deepMerge } from "@utils/deep-merge";
// Translation
import { lang } from "@lang/index";

// Props
type FieldTranslationsOutput = Record<
  ValueKey,
  {
    title?: AfFormFieldProps["title"];
    label?: AfFormFieldProps["label"];
    placeholder?: AfFormFieldProps["placeholder"];
    description?: AfFormFieldProps["description"];
    before?: AfTextFieldProps["before"];
    after?: AfTextFieldProps["after"];
    tooltip?: string;
  }
>;

/**
 * Periodicity Fields
 * Define the periodicity fields that change the
 * description based on the value of another field
 * The keys represent the fields that changes the description, and the
 * values represent the fields whose value affects the keys description
 */
const PERIODICITY_FIELDS: Record<string, string> = {
  amount: "amountPeriodicity",
  bonusAmount: "bonusAmountPeriodicity",
  benefitsAmount: "benefitsAmountPeriodicity",
  dividendsAmount: "dividendsAmountPeriodicity",
  otherAmount: "otherAmountPeriodicity",
  regularContributions: "contributionPeriodicity",
  repaymentAmount: "repaymentPeriodicity",
};

/**
 * Use Field Translations
 * A custom react hook that retrieves the translations for a
 * specific field keys based on the provided form key
 * @param formKey - key identifying the current type of form
 * @param computedValues - object containing the values of the form fields
 * @returns object containing translations of specific field keys from the specified form key
 */
export const useFieldTranslations = (
  formKey: FormKeys,
  computedValues: FormData
): FieldTranslationsOutput => {
  // Context
  const { clientNamingMode } = useContext(AppOptionsContext);

  // Define default translations based on the form key
  const defaultTranslations = useMemo((): FieldTranslationsOutput => {
    switch (formKey) {
      // Plan
      case FormKeys.Plan:
        return {
          name: {
            label: lang("FORM_LABEL_PLAN_NAME"),
          },
          startYear: {
            placeholder: lang("FORM_PLACEHOLDER_SELECT_YEAR"),
          },
          net: {
            label: lang("FORM_LABEL_PLAN_TYPE"),
          },
          legalGender: {
            label: lang("FORM_LABEL_GENDER"),
          },
          planId: {
            label: lang("FORM_LABEL_DUPLICATE_PLAN"),
            placeholder: lang("FORM_PLACEHOLDER_SELECT_PLAN"),
          },
          firstName: {
            title: lang("FORM_TITLE_OWNERS_INFO"),
            ...(clientNamingMode === ClientNamingMode.full && {
              label: lang("FORM_LABEL_NAME"),
              placeholder: lang("FORM_PLACEHOLDER_NAME"),
            }),
          },
        };

      // People
      case FormKeys.Owner:
      case FormKeys.Partner:
      case FormKeys.Dependent:
        return {
          legalGender: {
            label: lang("FORM_LABEL_GENDER"),
          },
          retirementAge: {
            after: lang("FORM_LABEL_YEARS"),
          },
          dependentUntilAge: {
            after: lang("FORM_LABEL_YEARS"),
          },
        };

      // Event
      case FormKeys.EventInput:
        return {
          label: {
            label: lang("FORM_LABEL_NAME"),
            placeholder: lang("FORM_PLACEHOLDER_EVENT_NAME"),
          },
          startPeriod: {
            title: lang("FORM_TITLE_TIMEFRAME"),
            label: lang("FORM_LABEL_OWNER_AGE"),
          },
        };

      // Income
      case FormKeys.IncomeInput:
        return {
          label: {
            label: lang("FORM_LABEL_NAME"),
            placeholder: lang("FORM_PLACEHOLDER_INCOME_NAME"),
          },
          amount: {
            placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
          },
          amountPeriodicity: {
            label: lang("FORM_LABEL_FREQUENCY"),
          },
          bonusAmount: {
            label: lang("FORM_LABEL_BONUS"),
            placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
          },
          bonusAmountPeriodicity: {
            label: lang("FORM_LABEL_FREQUENCY"),
          },
          bonusGrowthRate: {
            label: lang("FORM_LABEL_GROWTH_RATE"),
            placeholder: lang("FORM_PLACEHOLDER_PERCENTAGE_VALUE"),
          },
          benefitsAmount: {
            label: lang("FORM_LABEL_BENEFITS"),
            placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
          },
          benefitsAmountPeriodicity: {
            label: lang("FORM_LABEL_FREQUENCY"),
          },
          dividendsAmount: {
            label: lang("FORM_LABEL_DIVIDENDS"),
            placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
          },
          dividendsAmountPeriodicity: {
            label: lang("FORM_LABEL_FREQUENCY"),
          },
          otherAmount: {
            label: lang("FORM_LABEL_OTHER"),
            placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
          },
          otherAmountPeriodicity: {
            label: lang("FORM_LABEL_FREQUENCY"),
          },
          growthRate: {
            label: lang("FORM_LABEL_GROWTH_RATE"),
            placeholder: lang("FORM_PLACEHOLDER_PERCENTAGE_VALUE"),
          },
        };

      // Expense
      case FormKeys.ExpenseInput:
        return {
          label: {
            label: lang("FORM_LABEL_NAME"),
            placeholder: lang("FORM_PLACEHOLDER_EXPENSE_NAME"),
          },
          amountPeriodicity: {
            label: lang("FORM_LABEL_FREQUENCY"),
          },
          growthRate: {
            label: lang("FORM_LABEL_GROWTH_RATE"),
            placeholder: lang("FORM_PLACEHOLDER_PERCENTAGE_VALUE"),
          },
        };

      // Account
      case FormKeys.AccountInput:
        return {
          label: {
            label: lang("FORM_LABEL_NAME"),
            placeholder: lang("FORM_PLACEHOLDER_ACCOUNT_NAME"),
          },
          currentValue: {
            placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
          },
          growthRate: {
            label: lang("FORM_LABEL_ESTIMATED_RETURN"),
            placeholder: lang("FORM_PLACEHOLDER_PERCENTAGE_VALUE"),
          },
          dividendsYield: {
            placeholder: lang("FORM_PLACEHOLDER_PERCENTAGE_VALUE"),
            description: lang("FORM_DESCRIPTION_GROWTH_RATE"),
          },
          interestYield: {
            placeholder: lang("FORM_PLACEHOLDER_PERCENTAGE_VALUE"),
            description: lang("FORM_DESCRIPTION_GROWTH_RATE"),
          },
          feeRate: {
            placeholder: lang("FORM_PLACEHOLDER_PERCENTAGE_VALUE"),
          },
          regularContributions: {
            title: lang("FORM_TITLE_CONTRIBUTIONS"),
            placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
          },
          contributionPeriodicity: {
            label: lang("FORM_LABEL_FREQUENCY"),
          },
          contributionsGrowthRate: {
            label: lang("FORM_LABEL_CONTRIBUTIONS_GROWTH"),
            placeholder: lang("FORM_PLACEHOLDER_PERCENTAGE_VALUE"),
          },
        };

      // Asset
      case FormKeys.AssetInput:
        return {
          label: {
            label: lang("FORM_LABEL_NAME"),
            placeholder: lang("FORM_PLACEHOLDER_ASSET_NAME"),
          },
          purchaseValue: {
            placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
          },
          marketValue: {
            placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
            description: lang("FORM_DESCRIPTION_CURRENT_MARKET_VALUE"),
          },
          growthRate: {
            label: lang("FORM_LABEL_GROWTH_RATE"),
            placeholder: lang("FORM_PLACEHOLDER_PERCENTAGE_VALUE"),
          },
          account: {
            label: lang("FORM_LABEL_ASSET_SALE_FUNDS"),
            placeholder: lang("FORM_PLACEHOLDER_SELECT_ACCOUNT"),
            tooltip: lang("FORM_TOOLTIP_ACCOUNT"),
          },
        };

      // Pension
      case FormKeys.PensionInput:
        return {
          label: {
            label: lang("FORM_LABEL_NAME"),
            placeholder: lang("FORM_PLACEHOLDER_PENSION_NAME"),
          },
          currentValue: {
            placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
          },
          income: {
            label: lang("FORM_LABEL_RELATED_INCOME"),
            placeholder: lang("FORM_PLACEHOLDER_SELECT_INCOME"),
          },
          growthRate: {
            label: lang("FORM_LABEL_GROWTH_RATE"),
            placeholder: lang("FORM_PLACEHOLDER_PERCENTAGE_VALUE"),
          },
          contributionSource: {
            title: lang("FORM_TITLE_EMPLOYMENT_CONTRIBUTIONS"),
            placeholder: lang("FORM_PLACEHOLDER_SELECT_INCOME"),
          },
          employerContribution: {
            label: lang("FORM_LABEL_EMPLOYER_CONTRIBUTIONS"),
            placeholder: lang("FORM_PLACEHOLDER_PERCENTAGE_VALUE"),
          },
          employeeContribution: {
            label: lang("FORM_LABEL_EMPLOYEE_CONTRIBUTIONS"),
            placeholder: lang("FORM_PLACEHOLDER_PERCENTAGE_VALUE"),
          },
          personalContributions: {
            title: lang("FORM_TITLE_PERSONAL_CONTRIBUTIONS"),
            label: lang("FORM_LABEL_PERSONAL_CONTRIBUTIONS"),
            placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
          },
          personalContributionsGrowth: {
            label: lang("FORM_LABEL_CONTRIBUTIONS_GROWTH"),
            placeholder: lang("FORM_PLACEHOLDER_PERCENTAGE_VALUE"),
          },
          regularContributions: {
            placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
          },
          contributionPeriodicity: {
            label: lang("FORM_LABEL_FREQUENCY"),
          },
          contributionsGrowthRate: {
            label: lang("FORM_LABEL_CONTRIBUTIONS_GROWTH"),
            placeholder: lang("FORM_PLACEHOLDER_PERCENTAGE_VALUE"),
          },
        };

      // Debt
      case FormKeys.DebtInput:
        return {
          label: {
            label: lang("FORM_LABEL_NAME"),
            placeholder: lang("FORM_PLACEHOLDER_DEBT_NAME"),
          },
          outstandingBalance: {
            placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
          },
          repaymentAmount: {
            placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
          },
          repaymentPeriodicity: {
            label: lang("FORM_LABEL_FREQUENCY"),
          },
          paymentDuration: {
            placeholder: lang("FORM_PLACEHOLDER_NUMBER_OF_YEARS"),
            after: lang("FORM_LABEL_YEARS"),
          },
          startPeriod: {
            title: lang("FORM_TITLE_TIMEFRAME"),
            label: lang("FORM_LABEL_OWNER_AGE"),
          },
        };

      // Default
      default:
        return {};
    }
  }, [formKey, clientNamingMode]);

  // Define ownership translations that are used in all form types
  const ownershipTranslations = useMemo(
    (): FieldTranslationsOutput => ({
      ownership: {
        label: lang("FORM_LABEL_OWNER"),
      },
    }),
    []
  );

  // Define timeframe translations that are used in all form types
  const timeframeTranslations = useMemo(
    (): FieldTranslationsOutput => ({
      startPeriod: {
        title: lang("FORM_TITLE_TIMEFRAME"),
        label: lang("FORM_LABEL_OWNER_STARTING_AGE"),
        placeholder: lang("FORM_PLACEHOLDER_SELECT_AGE"),
      },
      endPeriod: {
        label: lang("FORM_LABEL_OWNER_ENDING_AGE"),
        placeholder: lang("FORM_PLACEHOLDER_SELECT_AGE"),
      },
    }),
    []
  );

  // Define account limits translations that are used in "Account" and "Pension" form types
  const accountLimitsTranslations = useMemo(
    (): FieldTranslationsOutput => ({
      accountLimit: {
        label: lang("FORM_LABEL_ACCOUNT_LIMITS"),
        description: lang("FORM_DESCRIPTION_ACCOUNT_LIMITS"),
      },
      maxWithdrawal: {
        label: lang("FORM_LABEL_MAX_WITHDRAWAL"),
        placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
      },
      adjustWithdrawalWithInflation: {
        label: lang("FORM_LABEL_ADJUST_WITH_INFLATION"),
      },
      minBalance: {
        label: lang("FORM_LABEL_MIN_BALANCE"),
        placeholder: lang("FORM_PLACEHOLDER_AMOUNT"),
      },
      adjustBalanceWithInflation: {
        label: lang("FORM_LABEL_ADJUST_WITH_INFLATION"),
      },
      availabilityAge: {
        label: lang("FORM_LABEL_AVAILABILITY_AGE"),
        after: lang("FORM_LABEL_YEARS"),
      },
    }),
    []
  );

  // Define periodicity translations based on the computed values
  const periodicityTranslations = useMemo(
    (): FieldTranslationsOutput =>
      Object.entries(PERIODICITY_FIELDS).reduce((prev, [key, value]) => {
        // Check if the computed value exists for the current periodicity field
        if (!computedValues[value]) return prev;
        // Return the current periodicity field's description
        return {
          ...prev,
          [key]: {
            description:
              computedValues[value] === Periodicity.Yearly
                ? lang("FORM_DESCRIPTION_AMOUNT_PER_YEAR")
                : lang("FORM_DESCRIPTION_AMOUNT_PER_MONTH"),
          },
        };
      }, {}),
    [computedValues]
  );

  // Return all fields translations
  return useMemo(
    (): FieldTranslationsOutput =>
      deepMerge(
        ownershipTranslations,
        timeframeTranslations,
        accountLimitsTranslations,
        periodicityTranslations,
        defaultTranslations
      ),
    [
      ownershipTranslations,
      timeframeTranslations,
      accountLimitsTranslations,
      periodicityTranslations,
      defaultTranslations,
    ]
  );
};
