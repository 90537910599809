// Redux
import { Store, StoreState } from "@store/index";
// Types
import { ClientType, ResponseClientPersonDto } from "@advicefront/plan-client-axios";

/**
 * Selector to get summarized data of clients
 */

interface SelectClientSummaryData extends ResponseClientPersonDto {
  retirementAgeYear?: number;
  mortalityAge: number;
  mortalityYear: number;
  age: number;
}

export const selectClientSummary = Store.createSelector(
  () =>
    (
      state: StoreState
    ): Omit<StoreState["people"], "loading"> & {
      loading: boolean;
      summary?: SelectClientSummaryData[];
    } => {
      const { people, assumptions } = state;

      const assumptionsData = assumptions.data;

      const loading = !!people.loading || !!assumptions.loading;

      if (!people.data?.clients.length || !assumptionsData)
        return {
          ...people,
          loading: loading,
          summary: undefined,
        };

      return {
        ...people,
        loading,
        summary: people.data?.clients
          .map((i) => {
            const averageLifespan =
              i.legalGender === "M"
                ? assumptionsData.livelihoodIndicators.averageLifespan.male
                : assumptionsData.livelihoodIndicators.averageLifespan.female;

            return {
              ...i,
              age: new Date().getFullYear() - i.yearOfBirth,
              retirementAgeYear: (i.retirementAge && i.yearOfBirth + i.retirementAge) || undefined,
              mortalityAge: averageLifespan,
              mortalityYear: i.yearOfBirth + averageLifespan,
            };
          })
          .sort((a, b) =>
            a.clientType === ClientType.Owner && b.clientType !== ClientType.Owner ? -1 : 1
          ),
      };
    }
);
