// React
import { useCallback, useContext, useEffect, useMemo } from "react";
// Router
import { useNavigate, generatePath } from "react-router-dom";
import { getRoute } from "@routes/utils/get-route";
// Store
import { Store } from "@store/index";
// Typings
import { CreatePlanV2Dto, ImportPlanDto, LegalGender } from "@advicefront/plan-client-axios";
import { FormModuleInstance, UseSubmitProps } from "@forms/modules/types";
import { FormData } from "@advicefront/fe-infra-form-schema";
import { PlanCreationModes } from "@typings/plan-creation-modes";
// Context
import { AppOptionsContext } from "@context/app-options";

export const useSubmit: FormModuleInstance["useSubmit"] = ({ formDto }) => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);

  // Route
  const navigate = useNavigate();

  // Context
  const { clientRegion } = useContext(AppOptionsContext);

  // Check if form submission was successful and redirect to plan
  useEffect(() => {
    if (!plan.submitSuccess) return;
    // Identifier for redirection
    let planId = undefined;
    // Extract the plan id from current formDto
    switch (formDto) {
      // Blank and Import
      case PlanCreationModes.Blank:
      case PlanCreationModes.Import:
        planId = plan.data?._id;
        break;
      // Duplicate
      case PlanCreationModes.Duplicate:
        planId = plan.duplicatedPlan?.newPlanId;
        break;
    }
    // Redirect to the plan details after submit
    planId && navigate(generatePath(getRoute("overview"), { id: planId }));
  }, [navigate, formDto, plan.submitSuccess, plan.data?._id, plan.duplicatedPlan?.newPlanId]);

  // Handle form submission
  const handleSubmit = useCallback(
    (values: FormData): void => {
      // Ensure we have the necessary data to submit the form
      if (!auth.authToken || !auth.clientGroupId) {
        throw new Error("Unable to submit form data: missing auth");
      }

      // Convert formData into create or import dto request payload
      const planData: CreatePlanV2Dto | ImportPlanDto = {
        name: String(values.name),
        startYear: Number(values.startYear),
        // Set 'net' undefined when net options are not available (eg. SGP plan)
        net: !values.net ? undefined : values.net === "netBasis",
        clientGroupId: auth.clientGroupId,
        visibleToClients: false,
        region: clientRegion,
        owner: {
          firstName: String(values.firstName),
          lastName: values.lastName?.toString() || null,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          legalGender: values.legalGender as LegalGender,
          yearOfBirth: Number(values.yearOfBirth),
          retirementAge: values.retirementAge ? Number(values.retirementAge) : null,
        },
      };

      // Dispatch the action to create, import or duplicate a plan
      switch (formDto) {
        // Blank
        case PlanCreationModes.Blank:
          void dispatch(
            Store.plan.createPlan({
              authToken: auth.authToken,
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              planData: planData as CreatePlanV2Dto,
            })
          );
          break;

        // Import
        case PlanCreationModes.Import:
          void dispatch(
            Store.plan.importPlan({
              authToken: auth.authToken,
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              planData: planData as ImportPlanDto,
            })
          );
          break;

        // Duplicate
        case PlanCreationModes.Duplicate:
          void dispatch(
            Store.plan.duplicatePlan({
              authToken: auth.authToken,
              planId: String(values.planId),
              planName: {
                name: String(values.name),
              },
            })
          );
          break;
      }
    },
    [dispatch, formDto, clientRegion, auth.authToken, auth.clientGroupId]
  );

  return useMemo(
    (): UseSubmitProps => ({
      loading: !!plan.loading,
      handleSubmit,
    }),
    [handleSubmit, plan.loading]
  );
};
