/**
 * Notifications
 * Naming convention: NOTIFICATION_TITLE_ELEMENT, NOTIFICATION_LABEL_ELEMENT or NOTIFICATION_DESCRIPTION_ELEMENT
 */
export const notifications = {
  // Titles
  NOTIFICATION_TITLE_CREATED: {
    en: "{0} created successfully",
  },
  NOTIFICATION_TITLE_UPDATED: {
    en: "{0} updated successfully",
  },
  NOTIFICATION_TITLE_DELETED: {
    en: "{0} deleted successfully",
  },
  NOTIFICATION_TITLE_EXPORTED: {
    en: "Plan exported successfully",
  },
  NOTIFICATION_TITLE_DUPLICATED: {
    en: "Plan duplicated successfully",
  },
  NOTIFICATION_TITLE_ARCHIVED: {
    en: "Plan archived successfully",
  },
  NOTIFICATION_TITLE_UNARCHIVED: {
    en: "Plan unarchived successfully",
  },
  NOTIFICATION_TITLE_ERROR: {
    en: "We could not complete the requested action",
  },

  // Labels
  NOTIFICATION_LABEL_PLAN: {
    en: "Plan",
  },
  NOTIFICATION_LABEL_PERSON: {
    en: "Person",
  },
  NOTIFICATION_LABEL_INPUT: {
    en: "Input",
  },
  NOTIFICATION_LABEL_ASSUMPTIONS: {
    en: "Assumptions",
  },
  NOTIFICATION_LABEL_LIQUIDATION: {
    en: "Liquidation",
  },
  NOTIFICATION_LABEL_ACCOUNT_LIMITS: {
    en: "Account Limits",
  },
  NOTIFICATION_LABEL_MAJOR_ILLNESS: {
    en: "Major Illness",
  },
  NOTIFICATION_LABEL_PROTECTION: {
    en: "Protection",
  },
  NOTIFICATION_LABEL_TPD: {
    en: "Total Permanent Disability",
  },
  NOTIFICATION_LABEL_PREFERENCES: {
    en: "Preferences",
  },
  NOTIFICATION_LABEL_FIRM_PREFERENCES: {
    en: "Firm Preferences",
  },

  // Descriptions
  NOTIFICATION_DESCRIPTION_ARCHIVED: {
    en: "You can find this plan in the archived plans tab",
  },
  NOTIFICATION_DESCRIPTION_UNARCHIVED: {
    en: "You can find this plan in the active plans tab",
  },
};
