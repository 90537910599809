// Styles
import "./styles/index.scss";
// React
import React, { useMemo } from "react";
import { renderToStaticMarkup } from "react-dom/server";
// Highcharts
import { HighchartsReact as Chart } from "highcharts-react-official";
import Dumbbell from "highcharts/modules/dumbbell";
import HC_more from "highcharts/highcharts-more";
import Highcharts from "highcharts";
import Lollipop from "highcharts/modules/lollipop";
// Typings
import { CSSInlineStyles } from "@typings/utils/css-inline-styles";
import { ChartTimelineModulesName } from "@typings/charts/timeline";
import { Colors } from "@typings/colors";
// Hooks
import { useChartTimeline } from "@components/charts/timeline/hooks";
// Components
import { AfCard } from "@advicefront/ds-card";

// Highcharts : Lollipop
HC_more(Highcharts);
Dumbbell(Highcharts);
Lollipop(Highcharts);

// Props
interface ChartTimelineProps {
  name: ChartTimelineModulesName;
}

export const ChartTimeline = ({ name }: ChartTimelineProps): React.ReactElement => {
  // Hook to get current chart module data
  const { height, categories, subCategories, data } = useChartTimeline(name);

  // Define column chart options
  const chartOptions = useMemo(
    (): Highcharts.Options => ({
      // Defaults
      title: undefined,
      credits: undefined,
      accessibility: {
        enabled: false,
      },

      // Chart
      chart: {
        height,
        marginLeft: 52,
        backgroundColor: "transparent",
      },

      // Plot
      plotOptions: {
        lollipop: {
          groupPadding: 0,
          animation: {
            duration: 500,
          },
        },
      },

      // X Axis
      xAxis: {
        categories: categories?.values,
        lineWidth: 0,
        tickLength: 0,
        labels: {
          useHTML: true,
          distance: 16,
          rotation: 0,
          step: 2,
          style: {
            textAlign: "center",
          },
          formatter: function (): string {
            // Display all categories values and subcategories values if defined
            return renderToStaticMarkup(
              <>
                <div className="chart-timeline__axis-category">{this.value}</div>
                {subCategories?.values?.map((entry, key) => (
                  <React.Fragment key={`chart-timeline-axis-value-${key}`}>
                    {entry && (
                      <div className="chart-timeline__axis-sub-category">{entry[this.pos]}</div>
                    )}
                  </React.Fragment>
                ))}
              </>
            );
          },
        },
      },

      // Y Axis
      yAxis: {
        title: undefined,
        gridLineWidth: 0,
        labels: {
          useHTML: true,
          distance: 16,
          style: {
            color: Colors.TextLight,
          },
          formatter: function (): string {
            // Display categories and subcategories titles if defined
            if (this.isFirst && (categories?.title || subCategories?.titles)) {
              return renderToStaticMarkup(
                <div className="chart-timeline__axis-titles">
                  <div className="chart-timeline__axis-category">{categories?.title}</div>
                  {subCategories?.titles?.map((title, key) => (
                    <React.Fragment key={`chart-timeline-axis-title-${key}`}>
                      {title && <div className="chart-timeline__axis-sub-category">{title}</div>}
                    </React.Fragment>
                  ))}
                </div>
              );
            }

            // Don't display y axis values
            return "";
          },
        },
      },

      // Legend
      legend: {
        enabled: false,
      },

      // Responsive
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 1280,
            },
            chartOptions: {
              xAxis: {
                labels: {
                  step: 4,
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 768,
            },
            chartOptions: {
              xAxis: {
                labels: {
                  step: 6,
                },
              },
            },
          },
        ],
      },

      // Series
      series: [
        {
          type: "lollipop",
          enableMouseTracking: false,
          color: Colors.TextLight,
          data,
          marker: {
            enabled: false,
          },
          dataLabels: {
            useHTML: true,
            enabled: true,
            allowOverlap: true,
            y: 5,
            formatter: function (): string {
              return renderToStaticMarkup(<div className="chart-timeline__label">{this.key}</div>);
            },
          },
        },
      ],
    }),
    [height, categories, subCategories, data]
  );

  // Define length of sub categories for background height
  const cssVars: CSSInlineStyles = useMemo(
    () => ({
      "--sub-categories": subCategories?.values?.filter(Boolean).length || 0,
    }),
    [subCategories?.values]
  );

  return (
    <AfCard padding="none" className="chart-timeline" style={cssVars}>
      <Chart highcharts={Highcharts} options={chartOptions} />
    </AfCard>
  );
};
