// Typings
import { FindOneInput200Response, FindOnePerson200Response } from "@advicefront/plan-client-axios";
import { UseSchemaProps } from "@forms/modules/types";
// Constants
// Utils
import { convertValuesToString } from "@forms/utils/convert-values-to-string";

/**
 * Get Initial Values
 * Remove all Dto keys not presented in form schema
 * and return form data initial values
 * @param dto - Dto api response
 * @param schemaData - current form schema data
 * @returns form data initial values
 */
export const getInitialValues = (
  dto: FindOnePerson200Response | FindOneInput200Response | undefined,
  schemaData: UseSchemaProps["data"] | undefined
): Record<string, string | string[]> | undefined => {
  // Return if no Dto or schema data
  if (!dto || !schemaData) return;

  // Convert all values of an object like numbers to string
  const formData = convertValuesToString(dto);

  // Remove form data keys not presented in schema and return initial values
  return Object.fromEntries(Object.entries(formData).filter(([key]) => schemaData[key]));
};
