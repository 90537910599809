// React
import React, { useCallback } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { TableAssumptionsModules } from "@typings/tables/assumptions";
import { UpdatePlanAssumptionsRequest } from "@advicefront/plan-client-axios";
// Utils
import { isGbrAssumptions, isSgpAssumptions } from "@utils/type-guards/assumptions";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { TableAssumptions, TableAssumptionsProps } from "@components/tables/assumptions";

export const AssumptionsGeneralTable = (): React.ReactElement => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);

  // Get general assumptions
  const { data, error, loading } = Store.useSelector((state) => state.assumptions);

  // Handle submit
  const handleSubmit: TableAssumptionsProps["handleSubmit"] = useCallback(
    (payload) => {
      if (!auth.authToken || !plan.data) return;

      // Update assumptions
      void dispatch(
        Store.assumptions.update({
          authToken: auth.authToken,
          planId: plan.data._id,
          // Type assertion to ensure payload is of type UpdatePlanAssumptionsRequest
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          updatedAssumptions: payload as UpdatePlanAssumptionsRequest,
        })
      );
    },
    [dispatch, auth.authToken, plan.data]
  );

  // Define list of assumptions tables to be displayed
  const assumptionsTables = [
    // GBR and SGP Modules
    TableAssumptionsModules.Mortality,
    TableAssumptionsModules.Inflation,
    // GBR Modules
    ...(isGbrAssumptions(data) ? [TableAssumptionsModules.IsaLimits] : []),
    // SGP Modules
    ...(isSgpAssumptions(data)
      ? [
          TableAssumptionsModules.CpfThresholds,
          TableAssumptionsModules.CpfEmployeeEmployer,
          TableAssumptionsModules.CpfCaps,
          TableAssumptionsModules.SrsCaps,
        ]
      : []),
  ];

  return (
    <AfRow orientation="vertical">
      {assumptionsTables.map((name, index) => (
        <AfCol key={`table-assumptions-${index}`}>
          <TableAssumptions
            name={name}
            data={data}
            loading={!!loading}
            error={!!error}
            handleSubmit={handleSubmit}
          />
        </AfCol>
      ))}
    </AfRow>
  );
};
