/**
 * Table Titles
 * Naming convention: TABLE_TITLE_ELEMENT
 */
export const tableTitles = {
  // Plans
  TABLE_TITLE_PLANS: {
    en: "Plans",
  },

  // Overview
  TABLE_TITLE_EVENTS_AND_TIMELINE: {
    en: "Events and Timeline",
  },

  // Inputs
  TABLE_TITLE_HOUSEHOLD: {
    en: "Household",
  },
  TABLE_TITLE_FINANCIAL_SUMMARY: {
    en: "Financial Summary",
  },
  TABLE_TITLE_INCOME: {
    en: "Income",
  },
  TABLE_TITLE_EXPENSE: {
    en: "Expense",
  },
  TABLE_TITLE_SAVINGS_AND_INVESTMENTS: {
    en: "Savings and Investments",
  },
  TABLE_TITLE_PROPERTY_AND_ASSETS: {
    en: "Property and Assets",
  },
  TABLE_TITLE_PENSION: {
    en: "Pension",
  },
  TABLE_TITLE_DEBT: {
    en: "Debt",
  },

  // Cashflow
  TABLE_TITLE_CASHFLOW_FORECAST: {
    en: "Cashflow Forecast",
  },

  // Assets & Liabilities
  TABLE_TITLE_ASSETS_AND_LIABILITIES_FORECAST: {
    en: "Assets & Liabilities Forecast",
  },

  // Tax
  TABLE_TITLE_TAX_DETAILED: {
    en: "Tax Detailed",
  },

  // Assumptions
  TABLE_TITLE_MORTALITY_ASSUMPTION: {
    en: "Mortality Assumption",
  },
  TABLE_TITLE_INFLATION_ASSUMPTION: {
    en: "Inflation Assumption",
  },
  TABLE_TITLE_ISA_LIMITS_ASSUMPTION: {
    en: "ISA Limits Assumption",
  },
  TABLE_TITLE_LIQUIDATION: {
    en: "Liquidation",
  },
  TABLE_TITLE_LIQUIDATION_ORDER: {
    en: "Liquidation Order",
  },
  TABLE_TITLE_SAVINGS_INCOME: {
    en: "Savings Income",
  },
  TABLE_TITLE_NON_SAVINGS_INCOME: {
    en: "Non Savings Income",
  },
  TABLE_TITLE_NATIONAL_INSURANCE_CLASS: {
    en: "National Insurance Class {0}",
  },
  TABLE_TITLE_DIVIDENDS: {
    en: "Dividends",
  },
  TABLE_TITLE_ACCOUNT_LIMITS: {
    en: "Account Limits",
  },
  TABLE_TITLE_CPF_CONTRIBUTIONS_THRESHOLDS: {
    en: "CPF Contributions Thresholds",
  },
  TABLE_TITLE_CPF_EMPLOYEE_EMPLOYER_CONTRIBUTIONS: {
    en: "CPF: Employee vs Employer contributions",
  },
  TABLE_TITLE_CPF_CAPS: {
    en: "CPF Caps",
  },
  TABLE_TITLE_SRS_CAPS: {
    en: "SRS Caps",
  },

  // Protections
  TABLE_TITLE_PROTECTION_GAP: {
    en: "Protection Gap",
  },
  TABLE_TITLE_PROTECTION_GAP_CALCULATOR: {
    en: "Protection Gap Calculator",
  },
  TABLE_TITLE_TPD_GAP_CALCULATOR: {
    en: "Total Permanent Disability Gap Calculator",
  },
  TABLE_TITLE_MAJOR_ILLNESS_NEEDS: {
    en: "Major Illness Needs",
  },
  TABLE_TITLE_MAJOR_ILLNESS_GAP_CALCULATOR: {
    en: "Major Illness Gap Calculator",
  },
  TABLE_TITLE_CURRENT_COVERAGE_AND_ASSETS: {
    en: "Current Coverage & Assets",
  },
  TABLE_TITLE_LIABILITIES: {
    en: "Liabilities",
  },
  TABLE_TITLE_AVAILABLE_FUNDS: {
    en: "Available Funds",
  },
  TABLE_TITLE_DISABILITY_NEEDS: {
    en: "Disability needs",
  },
};
