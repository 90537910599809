// Store
import { Store, StoreState } from "@store/index";
// Typings
import { TableBasicModules, TableBasicModulesName } from "@typings/tables/basic";
import { AfIconProps } from "@advicefront/ds-icon";
// Translations
import { lang } from "@lang/index";

// Props
interface FinancialSummaryEntry {
  module: TableBasicModulesName;
  title: string;
  icon: AfIconProps["name"];
  count: number;
}

export const selectFinancialSummary = Store.createSelector(
  () =>
    ({ inputs }: StoreState): FinancialSummaryEntry[] =>
      [
        // Income
        {
          module: TableBasicModules.Income,
          title: lang("TABLE_TITLE_INCOME"),
          icon: "money-dollar-circle",
          count: inputs.data?.income.length || 0,
        },

        // Expense
        {
          module: TableBasicModules.Expense,
          title: lang("TABLE_TITLE_EXPENSE"),
          icon: "money-tag",
          count: inputs.data?.expense.length || 0,
        },

        // Savings and Investments
        {
          module: TableBasicModules.SavingsAndInvestments,
          title: lang("TABLE_TITLE_SAVINGS_AND_INVESTMENTS"),
          icon: "money-empty-wallet",
          count: inputs.data?.account.length || 0,
        },

        // Property and Assets
        {
          module: TableBasicModules.PropertyAndAssets,
          title: lang("TABLE_TITLE_PROPERTY_AND_ASSETS"),
          icon: "building-house",
          count: inputs.data?.asset.length || 0,
        },

        // Pension
        {
          module: TableBasicModules.Pension,
          title: lang("TABLE_TITLE_PENSION"),
          icon: "security-user",
          count: inputs.data?.pension.length || 0,
        },
        // Debt
        {
          module: TableBasicModules.Debt,
          title: lang("TABLE_TITLE_DEBT"),
          icon: "building-buildings",
          count: inputs.data?.debt.length || 0,
        },
      ]
);
