// Styles
import "./styles/index.scss";
// React
import React from "react";
// Component
import { AfCol } from "@advicefront/ds-grid";
import { AfTypography } from "@advicefront/ds-typography";

// Props
interface TitleFieldProps {
  title: string;
}

export const TitleField = ({ title }: TitleFieldProps): React.ReactElement => (
  <AfCol size={12}>
    <AfTypography type="h4" className="form-title-field">
      {title}
    </AfTypography>
  </AfCol>
);
