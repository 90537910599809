// React
import React, { useMemo } from "react";
// Router
import { generatePath, Link } from "react-router-dom";
import { getRoute } from "@routes/utils/get-route";
// Store
import { Store } from "@store/index";
// Typings
import { Regions, ResponsePlanListDto } from "@advicefront/plan-client-axios";
// Utils
import { formatDate } from "@utils/format-date";
// Translations
import { lang } from "@lang/index";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfBadge } from "@advicefront/ds-badge";
import { AfButton } from "@advicefront/ds-button";
import { AfEmptyState } from "@advicefront/ds-empty-state";
import { AfTable } from "@advicefront/ds-table";
import { AfTypography } from "@advicefront/ds-typography";
import { DropdownCreatePlan } from "@components/dropdowns/CreatePlan";
import { DropdownPlanOptions } from "@components/dropdowns/PlanOptions";
import { LoaderSkeletonTable } from "@components/loaders/skeleton";

// Props
interface PlansListTableProps {
  isArchived: ResponsePlanListDto["archived"];
}

export const PlansListTable = ({ isArchived }: PlansListTableProps): React.ReactElement => {
  // Store
  const plans = Store.useSelector((state) => state.plans);

  // Get active or archived plans
  const currentPlans = useMemo(
    () => plans.data?.filter((plan) => plan.archived === isArchived),
    [isArchived, plans]
  );

  // Display loading component
  if (plans.loading) {
    return <LoaderSkeletonTable rows={10} columns={4} />;
  }

  // Display empty state when there is no plans
  if (!currentPlans?.length) {
    return (
      <AfEmptyState
        heading={
          isArchived
            ? lang("EMPTY_DESCRIPTION_PLANS_ARCHIVED")
            : lang("EMPTY_DESCRIPTION_PLANS_ACTIVE")
        }
        action={
          !plans.data?.length ? <DropdownCreatePlan dropdownPosition="bottom-center" /> : undefined
        }
      />
    );
  }

  return (
    <AfTable size="s" border={["wrapper", "row"]}>
      <tbody>
        {currentPlans.map((item) => (
          <tr key={item._id}>
            <td>
              <AfTypography hasMargin={false} type="body-bold">
                {item.name}
              </AfTypography>
              <AfTypography hasMargin={false} type="extra-small" skin="text-light">
                {lang("TABLE_LABEL_CREATED", formatDate(item.createdAt))}
              </AfTypography>
            </td>
            <td>
              {item.region === Regions.Gbr && (
                <AfBadge size="s" skin="lavender">
                  {item.net ? lang("TABLE_LABEL_NET_BASIS") : lang("TABLE_LABEL_GROSS_BASIS")}
                </AfBadge>
              )}
            </td>
            <td>
              <AfTypography hasMargin={false} type="body" skin="text-light">
                {lang(
                  isArchived ? "TABLE_LABEL_ARCHIVED" : "TABLE_LABEL_UPDATED",
                  formatDate(item?.updatedAt || item.createdAt)
                )}
              </AfTypography>
            </td>
            <td>
              <AfRow justify="end" align="center" spacing="md">
                <AfCol>
                  <AfButton
                    skin="ghost"
                    component={<Link to={generatePath(getRoute("overview"), { id: item._id })} />}
                  >
                    {lang("ACTION_OPEN")}
                  </AfButton>
                </AfCol>
                <AfCol>
                  <DropdownPlanOptions buttonSkin="ghost" currentPlan={item} />
                </AfCol>
              </AfRow>
            </td>
          </tr>
        ))}
      </tbody>
    </AfTable>
  );
};
