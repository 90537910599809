// Redux
import { Store, StoreState } from "@store/index";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import {
  ResponseInputQueryOptionsDto,
  ResponsePlanV2Dto,
  CreateInputV2Request,
  FormTypes,
} from "@advicefront/plan-client-axios";
import { API } from "@services/api";
// Initial State
import { initialState } from "./initial-state";
// Types
import { StateProps } from "./types";
// Utils
import { getInputsGroups } from "@utils/get-inputs-groups";
import { getSubmitSuccessAction } from "@store/utils/submit-success";
// Translations
import { lang } from "@lang/index";

/**
 * Internal Actions
 * ---------------------------------------------------------------------
 */

const { submitSuccessAction } = getSubmitSuccessAction<StateProps>(
  "inputs/set-submit-success-state"
);

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state
 * @example dispatch(Inputs.reset())
 */

export const reset = createAction("inputs/reset");

/**
 * Fetch Inputs
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Inputs.fetch(\{
 *  authToken,
 *  planId
 * \}));
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: ResponsePlanV2Dto["_id"];
  }>,
  { state: StoreState }
>("inputs/fetch", async ({ authToken, planId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;
  let data: StateProps["data"] = initialState.data;
  let count: StateProps["count"] = initialState.count;

  try {
    let inputs: ResponseInputQueryOptionsDto["data"] = [];

    await (async function load(): Promise<void> {
      const res = await API.Inputs(authToken).findAllInputsV2({
        planId,
        limit: 50,
        startAfter: inputs.length,
        orderBy: "createdAt",
        orderDirection: "asc",
      });

      inputs = [...inputs, ...res.data.data];

      if (res.data.hasNextPage) await load();
    })();

    data = getInputsGroups(inputs);
    count = Object.values(data).reduce<number>((a, b) => a + b.length, 0);
  } catch (e) {
    error = API.parseError(e);
  }

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    count,
    data,
    error,
  };
});

/**
 * Create Input
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Inputs.create(\{
 *  authToken,
 *  planId,
 *  inputData,
 *  type
 * \}));
 */

export const create = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    planId: ResponsePlanV2Dto["_id"];
    inputData: CreateInputV2Request;
    type: FormTypes;
  }>,
  { state: StoreState }
>("input/create", async ({ authToken, planId, inputData, type }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.Inputs(authToken).createInputV2({
      planId,
      type,
      createInputV2Request: inputData,
    });
  } catch (e) {
    error = API.parseError(e);
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_CREATED", lang("NOTIFICATION_LABEL_INPUT")),
        description: "",
      })
    );

    // Refresh inputs data (side effect)
    void dispatch(
      Store.inputs.fetch({
        authToken,
        planId,
      })
    );

    // Creating an input changes the forecast through a worker on the BE so we need to open polling
    // Polling will automatically close when a the updated forecast is retrieved
    void dispatch(
      Store.forecast.poll({
        authToken,
        planId,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
  };
});

/**
 * Delete Input
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Inputs.deleteInput(\{
 *  authToken,
 *  inputId,
 *  planId
 * \}));
 */

export const deleteInput = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    inputId: string;
    planId: ResponsePlanV2Dto["_id"];
  }>,
  { state: StoreState }
>("input/delete", async ({ authToken, inputId, planId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.Inputs(authToken).removeInputs({ inputId, planId });
  } catch (e) {
    error = API.parseError(e);
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_DELETED", lang("NOTIFICATION_LABEL_INPUT")),
        description: "",
      })
    );

    // Refresh inputs data (side effect)
    void dispatch(
      Store.inputs.fetch({
        authToken,
        planId,
      })
    );

    // Deleting an input changes the forecast through a worker on the BE so we need to open polling
    // Polling will automatically close when a the updated forecast is retrieved
    void dispatch(
      Store.forecast.poll({
        authToken,
        planId,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
  };
});

/**
 * Update Input
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Inputs.update(\{
 *  authToken,
 *  inputId,
 *  planId,
 *  inputData
 * \}));
 */

export const update = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    inputId: string;
    planId: ResponsePlanV2Dto["_id"];
    inputData: CreateInputV2Request;
  }>,
  { state: StoreState }
>("input/update", async ({ authToken, inputId, planId, inputData }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.Inputs(authToken).updateInput({
      inputId,
      planId,
      updateInputRequest: inputData,
    });
  } catch (e) {
    error = API.parseError(e);
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_UPDATED", lang("NOTIFICATION_LABEL_INPUT")),
        description: "",
      })
    );

    // Refresh inputs data (side effect)
    void dispatch(
      Store.inputs.fetch({
        authToken,
        planId,
      })
    );

    // Updating an input changes the forecast through a worker on the BE so we need to open polling
    // Polling will automatically close when a the updated forecast is retrieved
    void dispatch(
      Store.forecast.poll({
        authToken,
        planId,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
  };
});
