// Router
import { ModalRoutingProps } from "@hooks/modal-routing";
// Typings
import { FormKeys } from "@typings/routing";
// Modules
import {
  useDialogInputModule,
  useDialogPersonModule,
  useDialogPlanModule,
} from "@components/dialogs/delete/hooks/modules";

// Props
type UseDialogDelete = (props: {
  type: ModalRoutingProps["type"];
  id: ModalRoutingProps["id"];
}) => DialogDeleteModuleSchema;

export type UseDialogDeleteModule = (
  id: NonNullable<ModalRoutingProps["id"]>
) => DialogDeleteModuleSchema;

export interface DialogDeleteModuleSchema {
  title: string;
  name: string | undefined;
  loading: boolean;
  submitting: boolean;
  submitSuccess: boolean;
  handleDelete: () => void;
}

/**
 * Maps a form key to its corresponding dialog hook module
 * Each form key has its own specific hook to retrieve its dialog data
 */
const DialogDeleteMap: Partial<Record<FormKeys, UseDialogDeleteModule>> = {
  // Plan
  [FormKeys.Plan]: useDialogPlanModule,
  // Person
  [FormKeys.Owner]: useDialogPersonModule,
  [FormKeys.Partner]: useDialogPersonModule,
  [FormKeys.Dependent]: useDialogPersonModule,
  // Input
  [FormKeys.IncomeInput]: useDialogInputModule,
  [FormKeys.ExpenseInput]: useDialogInputModule,
  [FormKeys.AssetInput]: useDialogInputModule,
  [FormKeys.AccountInput]: useDialogInputModule,
  [FormKeys.PensionInput]: useDialogInputModule,
  [FormKeys.DebtInput]: useDialogInputModule,
  [FormKeys.EventInput]: useDialogInputModule,
  [FormKeys.AccountTransferInput]: useDialogInputModule,
};

/**
 * Returns dialog delete data for a specific form key
 * Determines which module hook to use based on the form key
 * The hook will receive the type and id from route params
 * @param type - form key, undefined or null
 * @param id - item id, undefined or null
 * @returns the dialog delete data for the specified form key
 */
export const useDialogDelete: UseDialogDelete = ({ type, id }) => {
  // Throw error if type or id is not defined
  if (!type || !id) {
    throw new Error("Type or Id is not defined");
  }

  // Get specific form key delete module
  const deleteModule = DialogDeleteMap[type];

  // Throw error if delete module is not defined
  if (!deleteModule) {
    throw new Error(`Module "${type}" is not defined`);
  }

  // Return delete module data
  return deleteModule(id);
};
