// React
import React from "react";
// Router
import { View } from "@routes/index";
import { ViewRedirect } from "@routes/components/ViewRedirect";
// Store
import { Store } from "@store/index";
// Layouts
import { AssumptionsLayout } from "@layouts/assumptions";
// Views
import { AssumptionsTaxTable } from "@views/assumptions/tax/components/Table";
import { assumptionsGeneral } from "@views/assumptions/general";

// Define element for the view
const Element = (): React.ReactElement => {
  // Store
  const plan = Store.useSelector((state) => state.plan);

  // If is net plan type redirect user to general assumptions page
  // This will prevent user to see tax tab by changing the url
  if (plan.data?.net) return <ViewRedirect route={assumptionsGeneral} />;

  return (
    <AssumptionsLayout>
      <AssumptionsTaxTable />
    </AssumptionsLayout>
  );
};

// Route view options
export const assumptionsTax: View = {
  name: "Assumptions Tax",
  options: {
    path: "/plan/:id/assumptions/tax",
    element: <Element />,
  },
};
