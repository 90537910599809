// React
import { useContext, useEffect, useMemo, useState } from "react";
// Typings
import { FormModuleInstance, UseInputRendererProps } from "@forms/modules/types";
import { ClientNamingMode } from "@typings/client-naming-mode";
import { ClientType } from "@advicefront/plan-client-axios";
// Context
import { AppOptionsContext } from "@context/app-options";

export const useInputRenderer: FormModuleInstance["useInputRenderer"] = () => {
  // Context
  const { getClientGroupInfo, clientNamingMode } = useContext(AppOptionsContext);

  // Define state to keep track of disabled fields
  const [disabledFields, setDisabledFields] = useState<string[]>([]);

  // Retrieve the client group info from mono repo and set disabled fields
  useEffect(() => {
    void (async (): Promise<void> => {
      const data = (await getClientGroupInfo())?.find((client) => client.type === ClientType.Owner);
      // If no data was found for the owner client, return
      if (!data) return;
      // Create a set of fields to disable based on the data from the mono repo
      const fieldsToDisable = new Set<string>();
      // Disable fields that are sent by mono if value is filled
      Object.entries(data).map(([key, value]) => {
        // Retirement age should always be editable
        if (value && key !== "retirementAge") fieldsToDisable.add(key);
      });
      // Update the disabled fields state with the new set
      setDisabledFields(Array.from(fieldsToDisable));
    })();
  }, [getClientGroupInfo]);

  return useMemo(
    (): UseInputRendererProps => ({
      // Types
      inputTypes: {
        number: {
          literal: ["yearOfBirth", "retirementAge"],
        },
        boolean: {
          literal: ["legalGender", "net"],
        },
      },

      // Props
      inputProps: {
        column: {
          size: {
            12: [
              "name",
              "net",
              ...(clientNamingMode === ClientNamingMode.full ? ["firstName"] : []),
            ],
          },
        },
        field: {
          disabled: disabledFields,
        },
      },
    }),
    [clientNamingMode, disabledFields]
  );
};
