import { matchRoutes, matchPath } from "react-router";
import { View } from "..";

/**
 * Get's all views matched with the provided pathname.
 * @param pathname - route that we want to check against the views array
 * @param views - array of {@link View} that we want to match our route to
 * @returns View[] of matched views
 */
export const pathnameMatchedViews = (pathname: string, views: View[]): View[] | undefined => {
  const matchedRoutes = matchRoutes(
    views.map((view) => ({
      path: view.options.path,
    })),
    pathname
  );

  if (matchedRoutes === null || matchedRoutes.length === 0) return undefined;

  // convert matched routes array back to View array
  const matchedViews = matchedRoutes.map((route) => {
    const matchedView = views.find(
      (view) => view.options.path && matchPath(view.options.path, route.pathname) !== null
    );

    if (!matchedView) {
      throw new Error(`Can't find view with pathname '${route.pathname}'`);
    }

    return matchedView;
  });

  return matchedViews;
};
