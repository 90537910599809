// Styles
import "./styles/index.scss";
// React
import React from "react";
// Store
import { Store } from "@store/index";
import { selectClientSummary } from "@store/selectors/client-summary";
// Typings
import { FormActions, FormKeys } from "@typings/routing";
import { ClientType } from "@advicefront/plan-client-axios";
// Constants
import { EMPTY_VALUE } from "@constants/index";
// Hooks
import { useModalRouting } from "@hooks/modal-routing";
// Translations
import { lang } from "@lang/index";
// Components
import { AfRow, AfCol } from "@advicefront/ds-grid";
import { AfTooltip, AfTooltipRenderProps } from "@advicefront/ds-tooltip";
import { AfAvatar } from "@advicefront/ds-avatar";
import { AfButton } from "@advicefront/ds-button";
import { AfCard } from "@advicefront/ds-card";
import { AfIcon } from "@advicefront/ds-icon";
import { AfTypography } from "@advicefront/ds-typography";
import { LoaderSkeletonProfile } from "@components/loaders/skeleton";

export const OverviewPeople = (): React.ReactElement => {
  // Store
  const plan = Store.useSelector((state) => state.plan);
  const people = Store.useSelector((state) => state.people);
  const { summary } = Store.useSelector(selectClientSummary());

  // Route
  const { openModal } = useModalRouting();

  // Check if summary has already a partner
  const hasPartner = summary?.some((client) => client.clientType === ClientType.Partner);

  return (
    <>
      {!people.data && (
        <AfRow>
          {Array.from(Array(2).keys()).map((key) => (
            <AfCol size={6} key={`overview-people-placeholder-${key}`}>
              <LoaderSkeletonProfile />
            </AfCol>
          ))}
        </AfRow>
      )}

      {people.data && (
        <AfRow>
          {summary?.map((client) => (
            <AfCol size={6} key={`overview-people-${client._id}`}>
              <AfCard>
                <AfCard.Content>
                  <AfRow wrap align="center" justify="spread">
                    <AfCol>
                      <AfAvatar size="l" />
                    </AfCol>
                    <AfCol size="auto">
                      <AfTypography type="large-semi" className="overview-people__name">
                        {client.firstName} {client.lastName}
                      </AfTypography>
                      <AfTypography hasMargin={false} type="body" skin="text-light">
                        {`${lang("OVERVIEW_TITLE_CURRENT_AGE")}: `}
                        <AfTypography type="body-bold" component={<span />}>
                          {client.age}
                        </AfTypography>
                      </AfTypography>
                      <AfTypography hasMargin={false} type="body" skin="text-light">
                        {`${lang("OVERVIEW_TITLE_RETIREMENT_AGE")}: `}
                        <AfTypography type="body-bold" component={<span />}>
                          {client.retirementAge || EMPTY_VALUE}
                          {client.retirementAgeYear && ` (${client.retirementAgeYear})`}
                        </AfTypography>
                      </AfTypography>
                      <AfTypography hasMargin={false} type="body" skin="text-light">
                        {`${lang("OVERVIEW_TITLE_MORTALITY_AGE")}: `}
                        <AfTypography type="body-bold" component={<span />}>
                          {client.mortalityAge || EMPTY_VALUE}
                          {client.mortalityYear && ` (${client.mortalityYear})`}
                        </AfTypography>
                      </AfTypography>
                    </AfCol>
                    <AfCol>
                      <AfTooltip
                        position="top-end"
                        render={(
                          props: AfTooltipRenderProps<HTMLDivElement>
                        ): React.ReactElement => (
                          <div {...(plan.data?.archived && props)}>
                            <AfButton
                              type="button"
                              skin="ghost"
                              disabled={plan.data?.archived}
                              icon={<AfIcon name="content-edit" />}
                              onClick={(): void =>
                                openModal({
                                  id: client._id,
                                  action: FormActions.edit,
                                  type:
                                    client.clientType === ClientType.Owner
                                      ? FormKeys.Owner
                                      : FormKeys.Partner,
                                })
                              }
                            />
                          </div>
                        )}
                      >
                        {lang("TOOLTIP_PLAN_ARCHIVED")}
                      </AfTooltip>
                    </AfCol>
                  </AfRow>
                </AfCard.Content>
              </AfCard>
            </AfCol>
          ))}
          {!hasPartner && (
            <AfCol size={6}>
              <AfCard>
                <AfCard.Content>
                  <AfRow
                    spacing="lg"
                    justify="center"
                    align="center"
                    className="overview-people__placeholder"
                  >
                    <AfCol>
                      <AfTooltip
                        position="left-center"
                        render={(
                          props: AfTooltipRenderProps<HTMLDivElement>
                        ): React.ReactElement => (
                          <div {...(plan.data?.archived && props)}>
                            <AfButton
                              type="button"
                              skin="ghost"
                              disabled={plan.data?.archived}
                              icon={<AfIcon name="basic-add-circle" />}
                              onClick={(): void =>
                                openModal({
                                  action: FormActions.create,
                                  type: FormKeys.Partner,
                                })
                              }
                            >
                              {lang("ACTION_ADD_PARTNER")}
                            </AfButton>
                          </div>
                        )}
                      >
                        {lang("TOOLTIP_PLAN_ARCHIVED")}
                      </AfTooltip>
                    </AfCol>
                  </AfRow>
                </AfCard.Content>
              </AfCard>
            </AfCol>
          )}
        </AfRow>
      )}
    </>
  );
};
