// React
import React, { StrictMode } from "react";
// Utils
import { ErrorBoundary } from "@utils/error-handling";
// App
import { App } from "./App";

export const AppWrapper = (): React.ReactElement => (
  <StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </StrictMode>
);
