// Typings
import { Periodicity } from "@advicefront/plan-client-axios";

/**
 * Calculates the periodicity of a given amount based on the amount's periodicity
 * @param amount - amount to calculate the periodicity for
 * @param periodicity - periodicity of the amount, either 'Monthly' or 'Yearly'
 * @example calculatePeriodicity("5670", Periodicity.Yearly) = 5670
 * @example calculatePeriodicity("1543", Periodicity.Monthly) = 18516 (amount * 12)
 * @returns the calculated periodicity of the amount as a number
 */
export const calculatePeriodicity = (amount: string | number, periodicity: Periodicity): number =>
  periodicity === Periodicity.Monthly ? Number(amount) * 12 : Number(amount);
