// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
import { selectPersonById } from "@store/selectors/person-by-id";
// Typings
import { FormData, isFormData } from "@advicefront/fe-infra-form-schema";
import { FormModuleInstance } from "@forms/modules/types";
// Utils
import { getInitialValues } from "@forms/utils/get-initial-values";
// Hooks
import { useSchema } from "@forms/modules/people/hooks";

export const useInitialValues: FormModuleInstance["useInitialValues"] = (props) => {
  // Get person dto on edit mode otherwise dto is undefined
  const personDto = Store.useSelector(
    props.itemId ? selectPersonById(props.itemId) : (): undefined => undefined
  );

  // Get the form schema
  const schema = useSchema(props);

  return useMemo((): FormData | undefined => {
    // Remove all Dto keys not presented in form schema and get initial values
    const formData = getInitialValues(personDto, schema?.data);

    // Return undefined if the form data is not valid
    if (!isFormData(formData)) return;

    // Return the form data
    return formData;

    // Ignore "schema" dependency in way to avoid form re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personDto]);
};
