// Typings
import { TaxGroups } from "@typings/tax-groups";

/**
 * Is Tax Group
 * Type Guard for Tax Group type
 * @param value - value to check
 * @returns boolean - true if its Tax Group type
 */
const isTaxGroup = (value: string): value is TaxGroups =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  Object.values(TaxGroups).includes(value as TaxGroups);

/**
 * As Tax Group
 * Define value as Tax Group or throw error if does not match with the type
 * @param value - value to check
 * @returns a string typed as {@link TaxGroups}
 */
export function asTaxGroup(value: string): TaxGroups {
  if (!isTaxGroup(value)) throw new Error(`Tax group not supported - "${value}"`);
  return value;
}
