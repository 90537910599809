// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
import { selectInputById } from "@store/selectors/input-by-id";
// Typings
import { DialogDeleteModuleSchema, UseDialogDeleteModule } from "@components/dialogs/delete/hooks";
// Translations
import { lang } from "@lang/index";

export const useDialogInputModule: UseDialogDeleteModule = (id) => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);
  const inputs = Store.useSelector((state) => state.inputs);

  // Selected input
  const selectedInput = Store.useSelector(selectInputById(id));

  // Return title, name, loading, submitting, submit success, and delete action
  return useMemo(
    (): DialogDeleteModuleSchema => ({
      title: lang("DIALOG_TITLE_DELETE_INPUT"),
      name: selectedInput?.label,
      loading: !inputs.data,
      submitting: !!inputs.loading,
      submitSuccess: inputs.submitSuccess,
      handleDelete: (): void => {
        if (!selectedInput?._id || !plan.data?._id) {
          throw new Error("Input Id or Plan Id is not defined");
        }

        void dispatch(
          Store.inputs.deleteInput({
            authToken: auth.authToken,
            planId: plan.data._id,
            inputId: selectedInput._id,
          })
        );
      },
    }),
    [
      dispatch,
      inputs.data,
      inputs.loading,
      inputs.submitSuccess,
      auth.authToken,
      plan.data?._id,
      selectedInput?._id,
      selectedInput?.label,
    ]
  );
};
