// React
import React, { useCallback } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { TableDraggableModules } from "@typings/tables/draggable";
// Translations
import { lang } from "@lang/index";
// Components
import { TableDraggable, TableDraggableProps } from "@components/tables/draggable";

export const AssumptionsLiquidationTable = (): React.ReactElement => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const plan = Store.useSelector((state) => state.plan);

  // Handle submit
  const handleSubmit: TableDraggableProps["handleSubmit"] = useCallback(
    (payload) => {
      if (!auth.authToken || !auth.clientGroupId || !plan.data) return;

      // Get accounts ids from payload to match with dto
      const accountsIds = payload.map((item) => item.id);

      // Update plan liquidation account order
      void dispatch(
        Store.plan.updatePlan({
          authToken: auth.authToken,
          clientGroupId: auth.clientGroupId,
          planId: plan.data._id,
          archived: plan.data.archived,
          updatedPlanData: {
            accountLiquidationOrder: accountsIds,
          },
          options: {
            notificationTitle: lang(
              "NOTIFICATION_TITLE_UPDATED",
              lang("NOTIFICATION_LABEL_LIQUIDATION")
            ),
          },
        })
      );

      // Refresh liquidation data (side effect)
      void dispatch(
        Store.liquidation.fetch({
          authToken: auth.authToken,
          planId: plan.data._id,
          accountLiquidationOrderIds: accountsIds,
        })
      );
    },
    [dispatch, auth, plan.data]
  );

  return <TableDraggable name={TableDraggableModules.Liquidation} handleSubmit={handleSubmit} />;
};
