// React
import React, { useState } from "react";
// Store
import { Store } from "@store/index";
import { selectTaxDetails } from "@store/selectors/tax-details";
// Typings
import { TableScrollableModules, TableScrollableModulesName } from "@typings/tables/scrollable";
// Translations
import { lang } from "@lang/index";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfChip } from "@advicefront/ds-chip";
import { AfTypography } from "@advicefront/ds-typography";
import { TableScrollable } from "@components/tables/scrollable";

// Props
interface PeopleTabs {
  title: string;
  module: TableScrollableModulesName;
}

export const TaxTable = (): React.ReactElement => {
  // Get forecast with tax details
  const { data, empty, polling } = Store.useSelector(selectTaxDetails());

  // State for current tax module
  const [taxModule, setTaxModule] = useState<TableScrollableModulesName>(
    TableScrollableModules.TaxOwner
  );

  // Define tabs for owner and partner
  const peopleTabs: PeopleTabs[] = [
    {
      title: lang("CHIP_TAX_OWNER"),
      module: TableScrollableModules.TaxOwner,
    },
    {
      title: lang("CHIP_TAX_PARTNER"),
      module: TableScrollableModules.TaxPartner,
    },
  ];

  return (
    <AfRow orientation="vertical">
      <AfCol size="auto">
        <AfTypography hasMargin={false} type="h2">
          {lang("TABLE_TITLE_TAX_DETAILED")}
        </AfTypography>
      </AfCol>

      {!empty && !!data?.clients.partner && (
        <AfCol size="auto">
          <AfRow align="center" spacing="sm">
            {peopleTabs.map((tab, key) => (
              <AfCol key={`${tab.title}-${key}`}>
                <AfChip
                  skin={tab.module === taxModule ? "active" : "default"}
                  disabled={!!polling}
                  onClick={(): void => setTaxModule(tab.module)}
                >
                  {tab.title}
                </AfChip>
              </AfCol>
            ))}
          </AfRow>
        </AfCol>
      )}

      <AfCol size="auto">
        <TableScrollable name={taxModule} />
      </AfCol>
    </AfRow>
  );
};
