// React
import { useCallback, useRef } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { FormModuleInputInstance } from "@forms/modules/types";
import { FormSchemaRendererOnChangeProps } from "@advicefront/fe-infra-form-schema-renderer";
// Services
import { InterestRateCalculator } from "@forms/modules/input/services/interest-rate-calculator";

export const useOnChange: FormModuleInputInstance["useOnChange"] = ({ formData, setValues }) => {
  // Store
  const auth = Store.useSelector((state) => state.auth);

  // Use a ref to store interest rate timeout
  const interestRateTimeout = useRef<number>();

  return useCallback(
    (changedData: FormSchemaRendererOnChangeProps): void => {
      // Clear timeframe when Ownership changes
      if (changedData.valueKey === "ownership") {
        setValues({
          ...formData,
          startPeriod: null,
          endPeriod: null,
        });
      }

      // Calculate Interest Rate when form data changes
      if (formData?.interestRate) {
        clearTimeout(interestRateTimeout.current);
        // Debounce to prevent excessive number of requests
        interestRateTimeout.current = window.setTimeout(() => {
          new InterestRateCalculator({
            authToken: auth.authToken,
            changedData,
            formData,
            setValues,
          }).update();
        }, 500);
      }
    },
    [auth.authToken, formData, setValues]
  );
};
