// React
import React, { useMemo } from "react";
// Router
import { useLocation, Link } from "react-router-dom";
import { getRoute } from "@routes/utils/get-route";
// Typings
import { RouteIndex } from "@routes/index";
// Translations
import { lang } from "@lang/index";
// Components
import { AfTabs } from "@advicefront/ds-tabs";

// Props
interface PreferencesTabsProps {
  isAdvisorPreferences: boolean;
}

export const PreferencesTabs = ({
  isAdvisorPreferences,
}: PreferencesTabsProps): React.ReactElement => {
  // Route
  const { pathname } = useLocation();

  // List of all tabs
  const tabs = useMemo(() => {
    // Define order of tabs
    const tabsOrdered: Record<string, RouteIndex> = {
      // Advisor preferences tabs
      ...(isAdvisorPreferences && {
        [lang("TAB_GENERAL")]: "preferencesGeneral",
        [lang("TAB_TAX")]: "preferencesTax",
      }),
      // Firm preferences tabs
      ...(!isAdvisorPreferences && {
        [lang("TAB_GENERAL")]: "firmPreferencesGeneral",
        [lang("TAB_TAX")]: "firmPreferencesTax",
      }),
    };

    // Return list of tabs ordered with title and route
    return Object.entries(tabsOrdered).map(([title, routeIndex]) => ({
      title,
      route: getRoute(routeIndex),
    }));
  }, [isAdvisorPreferences]);

  return (
    <AfTabs direction="vertical" className="sticky-tabs sticky-tabs--preferences">
      {tabs.map((tab) => (
        <AfTabs.Item
          key={tab.route}
          active={pathname.endsWith(tab.route)}
          component={<Link to={tab.route} />}
        >
          {tab.title}
        </AfTabs.Item>
      ))}
    </AfTabs>
  );
};
