/**
 * Actions
 * Naming convention: ACTION_ELEMENT
 */
export const actions = {
  // Plans
  ACTION_CREATE_PLAN: {
    en: "Create Plan",
  },
  ACTION_UPDATE_PLAN_NAME: {
    en: "Update Plan Name",
  },
  ACTION_DUPLICATE_PLAN: {
    en: "Duplicate Plan",
  },
  ACTION_CREATE_BLANK_PLAN: {
    en: "Create blank Plan",
  },
  ACTION_CREATE_IMPORT_PLAN: {
    en: "Import from Fact-Find",
  },
  ACTION_CREATE_DUPLICATE_PLAN: {
    en: "Duplicate existing Plan",
  },
  ACTION_ADD_INPUT: {
    en: "Add Input",
  },
  ACTION_ADD_PARTNER: {
    en: "Add Partner",
  },
  ACTION_ADD_EVENT: {
    en: "Add Event",
  },
  ACTION_OPEN: {
    en: "Open",
  },
  ACTION_EDIT_NAME: {
    en: "Edit name",
  },
  ACTION_DUPLICATE: {
    en: "Duplicate",
  },
  ACTION_DOWNLOAD: {
    en: "Download PDF",
  },
  ACTION_ARCHIVE: {
    en: "Archive",
  },
  ACTION_UNARCHIVE: {
    en: "Unarchive",
  },
  ACTION_SAVE_CHANGES: {
    en: "Save Changes",
  },
  ACTION_DELETE: {
    en: "Delete",
  },
  ACTION_COLLAPSE_ALL: {
    en: "Collapse All",
  },
  ACTION_EXPAND_ALL: {
    en: "Expand All",
  },

  // Inputs
  ACTION_CREATE_SAVINGS_AND_INVESTMENTS: {
    en: "Savings and Investments",
  },
  ACTION_CREATE_PROPERTY_AND_ASSETS: {
    en: "Property and Assets",
  },
  ACTION_CREATE_EXPENSES: {
    en: "Expenses",
  },
  ACTION_CREATE_PEOPLE: {
    en: "People",
  },
  ACTION_CREATE_DEPENDENT: {
    en: "Dependent",
  },

  // Forms
  ACTION_CREATE: {
    en: "Create {0}",
  },
  ACTION_PREVIOUS: {
    en: "Previous",
  },
  ACTION_NEXT: {
    en: "Next",
  },
  ACTION_CANCEL: {
    en: "Cancel",
  },
  ACTION_CONFIRM: {
    en: "Confirm",
  },
  ACTION_REMOVE: {
    en: "Remove",
  },

  // Dialogs
  ACTION_CANCEL_DELETE: {
    en: "No, don't delete",
  },
  ACTION_CONFIRM_DELETE: {
    en: "Yes, delete",
  },
  ACTION_CANCEL_CLOSE_FORM: {
    en: "No, don't close",
  },
  ACTION_CONFIRM_CLOSE_FORM: {
    en: "Yes, close",
  },
  ACTION_CANCEL_GO_BACK: {
    en: "No, don't go back",
  },
  ACTION_CONFIRM_GO_BACK: {
    en: "Yes, go back",
  },

  // Charts
  ACTION_VIEW_OPTIONS: {
    en: "View Options",
  },
  ACTION_DETAILED: {
    en: "Detailed",
  },
  ACTION_ZOOM: {
    en: "Zoom",
  },
  ACTION_RESET_ZOOM: {
    en: "Reset Zoom",
  },

  // Import Errors
  ACTION_SEE_DETAILS: {
    en: "See Details",
  },
  ACTION_DISCARD: {
    en: "Yes, discard",
  },
  ACTION_CLOSE: {
    en: "Close",
  },

  // Assumptions
  ACTION_ALLOW_CUSTOMISATION: {
    en: "Allow Customisation",
  },
  ACTION_EDIT_SECTION: {
    en: "Edit this section",
  },
  ACTION_RESET_TO_FIRM_VALUES: {
    en: "Reset to firm values",
  },

  // Protections
  ACTION_CALCULATE: {
    en: "Calculate",
  },
};
