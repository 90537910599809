// React
import { useCallback, useMemo } from "react";
// Typings
import {
  TableAssumptionsModuleSchema,
  UseTableAssumptionsModule,
} from "@components/tables/assumptions/hooks";
import { InputNodeFormat } from "@typings/input-node-format";
// Utils
import { isAccountsAssumptions } from "@utils/type-guards/assumptions";
import { isEqual } from "@utils/is-equal";
// Translations
import { lang } from "@lang/index";

export const useTableInflationModule: UseTableAssumptionsModule = ({
  name,
  data,
  firmPreferences,
}) => {
  // Throw error if data is not valid for Inflation module
  if (data && isAccountsAssumptions(data)) {
    throw new Error(`${name} data not valid`);
  }

  // Get firm data
  const firmData = firmPreferences.data;

  // Define submit payload function
  const submitPayload = useCallback<TableAssumptionsModuleSchema["submitPayload"]>(
    (updatedData) => ({
      marketIndicators: {
        baseInflation: Number(updatedData[0].fields[0].value),
      },
    }),
    []
  );

  // Return table assumptions data object
  return useMemo(
    (): TableAssumptionsModuleSchema => ({
      title: lang("TABLE_TITLE_INFLATION_ASSUMPTION"),
      head: [
        {
          label: lang("TABLE_COLUMN_NAME"),
          align: "left",
        },
        {
          label: lang("TABLE_COLUMN_VALUE_UNIT", "%"),
          align: "right",
        },
      ],
      entries: [
        {
          label: lang("TABLE_LABEL_INFLATION"),
          fields: [
            {
              type: InputNodeFormat.percent,
              value: data?.marketIndicators.baseInflation,
            },
          ],
        },
      ],
      customise: {
        checked: !!data?.settings.marketIndicators,
        payload: {
          settings: {
            marketIndicators: !firmData?.settings.marketIndicators,
          },
        },
      },
      reset: {
        allowed: !isEqual(data?.marketIndicators, firmData?.marketIndicators),
        payload: {
          marketIndicators: {
            baseInflation: firmData?.marketIndicators.baseInflation,
          },
        },
      },
      submitPayload,
    }),
    [
      submitPayload,
      data?.settings.marketIndicators,
      data?.marketIndicators,
      firmData?.settings.marketIndicators,
      firmData?.marketIndicators,
    ]
  );
};
