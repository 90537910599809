/**
 * Views Overview
 * Naming convention: OVERVIEW_TITLE_ELEMENT or OVERVIEW_DESCRIPTION_ELEMENT
 */
export const overview = {
  // People
  OVERVIEW_TITLE_CURRENT_AGE: {
    en: "Current Age",
  },
  OVERVIEW_TITLE_RETIREMENT_AGE: {
    en: "Retirement Age",
  },
  OVERVIEW_TITLE_MORTALITY_AGE: {
    en: "Mortality Age",
  },

  // Coverage
  OVERVIEW_TITLE_COVERAGE: {
    en: "Plan Coverage",
  },
  OVERVIEW_DESCRIPTION_SHORTFALL: {
    en: "Client will have {0} shortfall periods in this plan. Look for extra sources of income.",
  },
  OVERVIEW_DESCRIPTION_NO_SHORTFALL: {
    en: "There are no shortfall periods in this plan.",
  },
};
