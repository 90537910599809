// React
import React from "react";
// Router
import { View } from "@routes/index";
// Layouts
import { AssumptionsLayout } from "@layouts/assumptions";
// Views
import { AssumptionsAccountsTable } from "@views/assumptions/accounts/components/Table";

// Define element for the view
const Element = (): React.ReactElement => (
  <AssumptionsLayout>
    <AssumptionsAccountsTable />
  </AssumptionsLayout>
);

// Route view options
export const assumptionsAccounts: View = {
  name: "Assumptions Accounts",
  options: {
    path: "/plan/:id/assumptions/accounts",
    element: <Element />,
  },
};
