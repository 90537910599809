// Typings
import { Field } from "@advicefront/fe-infra-form-schema";

/**
 * Define field props map used on
 * "inputPropsMap" field prop
 */
export interface FieldPropsMap {
  disabled?: Field["key"][];
}

/**
 * Define field props output used on the
 * renderer FormSchema DS component
 */
interface FieldPropsOutput {
  disabled: boolean;
}

/**
 * Define initial field props
 */
const INITIAL_FIELD_PROPS: FieldPropsOutput = {
  disabled: false,
};

/**
 * Get Field Props
 * @param fieldProps - object with interface of field props map
 * @param fieldKey - field key from FormSchema instance
 * @returns - fields props output
 */
export const getFieldProps = (
  fieldProps: FieldPropsMap | undefined,
  fieldKey: Field["key"]
): FieldPropsOutput => {
  // Return initial field props if not defined
  if (!fieldProps) {
    return INITIAL_FIELD_PROPS;
  }

  return {
    disabled: !!fieldProps.disabled?.includes(fieldKey),
  };
};
