// React
import React, { useRef, useState } from "react";
// Router
import { useModalRouting } from "@hooks/modal-routing";
// Typings
import { FormActions, FormKeys } from "@typings/routing";
import { PlanCreationModes } from "@typings/plan-creation-modes";
// Translations
import { lang } from "@lang/index";
// Components
import { AfDropdown, AfDropdownProps } from "@advicefront/ds-dropdown";
import { AfIcon, AfIconProps } from "@advicefront/ds-icon";
import { AfActionList } from "@advicefront/ds-action-list";
import { AfButton } from "@advicefront/ds-button";

// Props
interface DropdownCreatePlanProps {
  dropdownPosition?: AfDropdownProps["position"];
}

interface DropdownCreatePlanItem {
  label: string;
  subtype: PlanCreationModes;
  iconName: AfIconProps["name"];
}

export const DropdownCreatePlan = ({
  dropdownPosition = "bottom-end",
}: DropdownCreatePlanProps): React.ReactElement => {
  // State to handle dropdown open state
  const [isOpen, setIsOpen] = useState(false);

  // Dropdown trigger ref
  const triggerRef = useRef(null);

  // Route
  const { openModal } = useModalRouting();

  // List of items ordered
  const createPlanItems: DropdownCreatePlanItem[] = [
    // Blank plan
    {
      iconName: "basic-document",
      label: lang("ACTION_CREATE_BLANK_PLAN"),
      subtype: PlanCreationModes.Blank,
    },

    // Import plan
    {
      iconName: "arrow-import",
      label: lang("ACTION_CREATE_IMPORT_PLAN"),
      subtype: PlanCreationModes.Import,
    },

    // Duplicate plan
    {
      iconName: "basic-document-copy",
      label: lang("ACTION_CREATE_DUPLICATE_PLAN"),
      subtype: PlanCreationModes.Duplicate,
    },
  ];

  return (
    <>
      <AfButton
        ref={triggerRef}
        size="m"
        skin="primary"
        iconPosition="right"
        icon={<AfIcon name={`basic-arrow-${isOpen ? "up" : "down"}`} />}
        onClick={(): void => setIsOpen(!isOpen)}
      >
        {lang("ACTION_CREATE_PLAN")}
      </AfButton>
      <AfDropdown
        isOpen={isOpen}
        anchorRef={triggerRef}
        position={dropdownPosition}
        onClose={(): void => setIsOpen(false)}
      >
        <AfActionList>
          {createPlanItems.map((item, i) => (
            <AfActionList.Item
              key={`${item.subtype}-${i}`}
              leftNode={<AfIcon size="s" name={item.iconName} />}
              onClick={(): void =>
                openModal({
                  action: FormActions.create,
                  type: FormKeys.Plan,
                  subType: item.subtype,
                })
              }
            >
              {item.label}
            </AfActionList.Item>
          ))}
        </AfActionList>
      </AfDropdown>
    </>
  );
};
