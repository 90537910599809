/**
 * Errors
 * Naming convention: ERROR_TITLE_ELEMENT or ERROR_DESCRIPTION_ELEMENT
 */
export const errors = {
  ERROR_TITLE: {
    en: "Something's missing",
  },
  ERROR_DESCRIPTION: {
    en: "The requested page was not found.",
  },
  ERROR_PDF_DOWNLOAD: {
    en: "Unable to generate PDF",
  },
  ERROR_MISSING_FORM: {
    en: "Missing form implementation",
  },
  ERROR_API_CONFIG: {
    en: "API must be configured",
  },
  ERROR_API_REQUEST: {
    en: "Something went wrong while processing the request",
  },
};
