// React
import React from "react";
// Router
import { View } from "@routes/index";
// Layouts
import { ProtectionLayout } from "@layouts/protections";
// Views
import { ProtectionsTpdTable } from "@views/protections/tpd/components/Table";

// Define element for the view
const Element = (): React.ReactElement => (
  <ProtectionLayout>
    <ProtectionsTpdTable />
  </ProtectionLayout>
);

// Route view options
export const protectionTpd: View = {
  name: "Protections Total Permanent Disability",
  options: {
    path: "/plan/:id/protections/total-permanent-disability",
    element: <Element />,
  },
};
