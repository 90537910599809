// React
import { useContext, useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { FormModuleInstance, UseSchemaProps } from "@forms/modules/types";
import { PlanCreationModes } from "@typings/plan-creation-modes";
// Context
import { AppOptionsContext } from "@context/app-options";
// Schemas
import { blankSchema, importSchema, duplicateSchema } from "@forms/modules/plan/schemas";

export const useSchema: FormModuleInstance["useSchema"] = ({ formDto }) => {
  // Store
  const plans = Store.useSelector((state) => state.plans);

  // Context
  const { clientNamingMode, clientRegion } = useContext(AppOptionsContext);

  // Get current plan schema based on formDto
  const planSchema = useMemo(() => {
    switch (formDto) {
      // Blank
      case PlanCreationModes.Blank:
        return blankSchema(clientNamingMode, clientRegion);
      // Import
      case PlanCreationModes.Import:
        return importSchema(clientNamingMode);
      // Duplicate
      case PlanCreationModes.Duplicate:
        return duplicateSchema(plans.data);
    }
  }, [formDto, clientNamingMode, clientRegion, plans.data]);

  return useMemo(
    (): UseSchemaProps => ({
      loading: !plans.data,
      data: planSchema,
    }),
    [planSchema, plans.data]
  );
};
