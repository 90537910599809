// React
import { useContext, useMemo } from "react";
// Store
import { Store } from "@store/index";
import { selectTaxDetails } from "@store/selectors/tax-details";
// Typings
import {
  TableScrollableModuleEntry,
  TableScrollableModuleSchema,
  UseTableScrollableModule,
} from "@components/tables/scrollable/hooks";
// Context
import { AppOptionsContext } from "@context/app-options";
// Utils
import * as CC from "change-case";
import { maskCurrency } from "@utils/format-currency";
// Translations
import { lang } from "@lang/index";

export const useTableTaxOwnerModule: UseTableScrollableModule = () => {
  // Store
  const tax = Store.useSelector(selectTaxDetails());

  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // Check if data was already loaded
  const isFetching = useMemo((): boolean => !tax.data, [tax.data]);

  // Return table scrollable data object
  return useMemo(
    (): TableScrollableModuleSchema => ({
      // Add empty value for first column
      head: ["", ...(tax.data?.years || [])],
      entries: !tax.empty
        ? [
            // Owner
            ...[tax.data?.clients.owner].map(
              (client): TableScrollableModuleEntry => ({
                label: lang("TABLE_LABEL_AGE", client?.name.split(" ")[0]),
                values: client?.ageRange,
              })
            ),

            // Details
            ...(tax.data?.tax?.owner || [])
              .map((section): TableScrollableModuleEntry[] => [
                // Label
                ...[section.label].filter(Boolean).map(
                  (label): TableScrollableModuleEntry => ({
                    label: `${CC.capitalCase(String(label))} (${currencySymbol})`,
                    type: "body-bold",
                  })
                ),
                // Data
                ...section.subSections
                  .map((subSection) =>
                    subSection.entries.map(
                      (entry): TableScrollableModuleEntry => ({
                        label: CC.capitalCase(entry.label),
                        values: entry.values.map((value) => maskCurrency(value || 0)),
                        type: entry.isCalculated ? "body-bold" : "body",
                        highlighted: entry.isCalculated,
                      })
                    )
                  )
                  .flat(),
              ])
              .flat(),
          ]
        : [],
      fetching: isFetching,
      polling: !!tax.polling,
      empty: {
        title: lang("EMPTY_TITLE_TAX"),
      },
    }),
    [
      tax.empty,
      tax.polling,
      tax.data?.years,
      tax.data?.clients.owner,
      tax.data?.tax?.owner,
      isFetching,
      currencySymbol,
    ]
  );
};
