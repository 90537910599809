// React
import { useCallback, useMemo } from "react";
// Typings
import {
  TableAssumptionsModuleSchema,
  UseTableAssumptionsModule,
} from "@components/tables/assumptions/hooks";
import { InputNodeFormat } from "@typings/input-node-format";
// Utils
import { isAccountsAssumptions, isSgpAssumptions } from "@utils/type-guards/assumptions";
import { isEqual } from "@utils/is-equal";
// Translations
import { lang } from "@lang/index";

export const useTableSrsCapsModule: UseTableAssumptionsModule = ({
  name,
  data,
  firmPreferences,
}) => {
  // Throw error if data is not valid for SrsCaps module
  if (data && (isAccountsAssumptions(data) || !isSgpAssumptions(data))) {
    throw new Error(`${name} data not valid`);
  }

  // Define firm data as SGP region
  const firmData = isSgpAssumptions(firmPreferences.data) ? firmPreferences.data : undefined;

  // Define payload for submission to match with dto
  const submitPayload = useCallback<TableAssumptionsModuleSchema["submitPayload"]>(
    (updatedData) => ({
      contributionCaps: {
        srs: {
          national: Number(updatedData[0].fields[0].value),
          foreigner: Number(updatedData[1].fields[0].value),
        },
      },
    }),
    []
  );

  // Return table assumptions data object
  return useMemo(
    (): TableAssumptionsModuleSchema => ({
      title: lang("TABLE_TITLE_SRS_CAPS"),
      head: [
        {
          label: lang("TABLE_COLUMN_NAME"),
          align: "left",
        },
        {
          label: lang("TABLE_COLUMN_VALUE"),
          align: "right",
        },
      ],
      entries: [
        {
          label: lang("TABLE_LABEL_SRS_CAP_NATIONAL"),
          fields: [
            {
              type: InputNodeFormat.number,
              value: data?.contributionCaps.srs.national,
            },
          ],
        },
        {
          label: lang("TABLE_LABEL_SRS_CAP_FOREIGNER"),
          fields: [
            {
              type: InputNodeFormat.number,
              value: data?.contributionCaps.srs.foreigner,
            },
          ],
        },
      ],
      customise: {
        checked: !!data?.settings.srsCaps,
        payload: {
          settings: {
            srsCaps: !firmData?.settings.srsCaps,
          },
        },
      },
      reset: {
        allowed: !isEqual(data?.contributionCaps.srs, firmData?.contributionCaps.srs),
        payload: {
          contributionCaps: {
            srs: {
              national: firmData?.contributionCaps.srs.national,
              foreigner: firmData?.contributionCaps.srs.foreigner,
            },
          },
        },
      },
      submitPayload,
    }),
    [
      submitPayload,
      data?.settings.srsCaps,
      data?.contributionCaps.srs,
      firmData?.settings.srsCaps,
      firmData?.contributionCaps.srs,
    ]
  );
};
