// React
import React from "react";
// Router
import { RouteProps } from "react-router-dom";
// Views
import * as Views from "@views/index";
// Components
import { IndexRedirect } from "./components/IndexRedirect";

export type RouteIndex = keyof typeof allRoutes;

// DOCS: https://beta.reactrouter.com/en/v6.3.0/upgrading/v5#upgrade-all-switch-elements-to-routes

export interface View {
  // Route name / title
  name: string;
  // https://reactrouter.com/web/example/basic
  options: RouteProps;
}

const redirects = {
  index: {
    name: "Index",
    options: {
      path: "/",
      element: <IndexRedirect />,
    },
  },
};

export const allRoutes = {
  ...redirects,
  ...Views,
};

// Generate routes list from grouped imports object
export const routes: View[] = Object.values(allRoutes);

// Make sure that we don't allow 2 routes with the same name
// because we use route names to compare them in functions like "getRoutes"
((): void => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const routesNames = Object.keys(allRoutes).map((r) => allRoutes[r as RouteIndex].name);
  const uniqueRouteNames = new Set(routesNames);

  const duplicates = routesNames.filter((item) => {
    if (uniqueRouteNames.has(item)) {
      uniqueRouteNames.delete(item);
    } else {
      return item;
    }
  });

  if (duplicates.length) {
    throw new Error(
      `Routes should have unique "name". The following names are being used in more than one route: ${duplicates.join(
        ""
      )}.`
    );
  }
})();
