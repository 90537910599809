// React
import React from "react";
// Typings
import { TableBasicModules } from "@typings/tables/basic";
// Translations
import { lang } from "@lang/index";
// Components
import { AfRow, AfCol } from "@advicefront/ds-grid";
import { AfTypography } from "@advicefront/ds-typography";
import { TableBasic } from "@components/tables/basic";

export const InputsHouseHold = (): React.ReactElement => (
  <AfRow orientation="vertical">
    <AfCol size="auto">
      <AfTypography hasMargin={false} type="h2">
        {lang("TABLE_TITLE_HOUSEHOLD")}
      </AfTypography>
    </AfCol>
    <AfCol size="auto">
      <TableBasic name={TableBasicModules.Household} />
    </AfCol>
  </AfRow>
);
