// React
import { useCallback, useContext, useMemo } from "react";
// Typings
import {
  TableAssumptionsModuleEntry,
  TableAssumptionsModuleSchema,
  UseTableAssumptionsModule,
} from "@components/tables/assumptions/hooks";
import { InputNodeFormat } from "@typings/input-node-format";
// Context
import { AppOptionsContext } from "@context/app-options";
// Constants
import { EMPTY_VALUE } from "@constants/index";
// Utils
import { isAccountsAssumptions, isGbrAssumptions } from "@utils/type-guards/assumptions";
import { isEqual } from "@utils/is-equal";
// Translations
import { lang } from "@lang/index";

export const useTableDividendsModule: UseTableAssumptionsModule = ({
  name,
  data,
  firmPreferences,
}) => {
  // Throw error if data is not valid for Dividends module
  if (data && (isAccountsAssumptions(data) || !isGbrAssumptions(data))) {
    throw new Error(`${name} data not valid`);
  }

  // Define firm data as GBR region
  const firmData = isGbrAssumptions(firmPreferences.data) ? firmPreferences.data : undefined;

  // Context
  const { currencySymbol } = useContext(AppOptionsContext);

  // Define payload for submission to match with dto
  const submitPayload = useCallback<TableAssumptionsModuleSchema["submitPayload"]>(
    (updatedData) => ({
      taxes: {
        dividends: {
          taxFreeAmount: Number(updatedData[0].fields[0].value) || 0,
          // Slice updatedData to create a new array that includes all rows
          // except the first one which contains the taxFreeAmount
          taxBands: updatedData.slice(1).map((row) => ({
            taxableIncome: Number(row.fields[0].value) || 0,
            taxRate: Number(row.fields[1].value),
          })),
        },
      },
    }),
    []
  );

  // Get name base on the index of each tax band
  const getTableRowName = useMemo(
    (): {
      [key: number]: string;
    } => ({
      0: lang("TABLE_LABEL_BASIC_RATE"),
      1: lang("TABLE_LABEL_HIGHER_RATE"),
      2: lang("TABLE_LABEL_ADDITIONAL_RATE"),
    }),
    []
  );

  // Return table assumptions data object
  return useMemo(
    (): TableAssumptionsModuleSchema => ({
      title: lang("TABLE_TITLE_DIVIDENDS"),
      head: [
        {
          label: lang("TABLE_COLUMN_NAME"),
          align: "left",
        },
        {
          label: lang("TABLE_COLUMN_VALUE_UNIT", currencySymbol),
          align: "right",
        },
        {
          label: lang("TABLE_COLUMN_VALUE_UNIT", "%"),
          align: "right",
        },
      ],
      entries: [
        {
          label: lang("TABLE_LABEL_DIVIDENDS_TAX_FREE"),
          fields: [
            {
              type: InputNodeFormat.currency,
              value: data?.taxes.dividends.taxFreeAmount,
            },
            // This field is need in way to fix the columns table structure
            {
              type: InputNodeFormat.number,
              value: EMPTY_VALUE,
              hidden: true,
            },
          ],
        },
        ...(data?.taxes.dividends.taxBands || []).map(
          (values, index): TableAssumptionsModuleEntry => ({
            label: getTableRowName[index],
            fields: [
              {
                type: InputNodeFormat.currency,
                value: values.taxableIncome,
              },
              {
                type: InputNodeFormat.percent,
                value: values.taxRate,
              },
            ],
          })
        ),
      ],
      customise: {
        checked: !!data?.settings.dividends,
        payload: {
          settings: {
            dividends: !firmData?.settings.dividends,
          },
        },
      },
      reset: {
        allowed: !isEqual(data?.taxes.dividends, firmData?.taxes.dividends),
        payload: {
          taxes: {
            dividends: firmData?.taxes.dividends,
          },
        },
      },
      submitPayload,
    }),
    [
      currencySymbol,
      getTableRowName,
      submitPayload,
      data?.settings.dividends,
      data?.taxes.dividends,
      firmData?.settings.dividends,
      firmData?.taxes.dividends,
    ]
  );
};
