// Redux
import { Store, StoreState } from "@store/index";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import { UpdateGbrAssumptionsDto, UpdateSgpAssumptionsDto } from "@advicefront/plan-client-axios";
import { API } from "@services/api";
// Initial State
import { initialState } from "./initial-state";
// Types
import { PreferencesData, StateProps } from "./types";
// Utils
import { getSubmitSuccessAction } from "@store/utils/submit-success";
// Translations
import { lang } from "@lang/index";

/**
 * Internal Actions
 * ---------------------------------------------------------------------
 */

const { submitSuccessAction } = getSubmitSuccessAction<StateProps>(
  "preferences/set-submit-success-state"
);

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state
 * @example dispatch(Preferences.reset())
 */

export const reset = createAction("preferences/reset");

/**
 * Fetch Preferences
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Preferences.fetch(\{
 *  authToken,
 * \}));
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
  }>,
  { state: StoreState }
>("preferences/fetch", async ({ authToken }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;
  let data: StateProps["data"] = initialState.data;

  try {
    // FIXME: forcing type because we're not using UK Assumptions at the moment
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const res = await API.Assumptions(authToken).findUserAssumptions();

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    data = res.data as PreferencesData;
  } catch (e) {
    error = API.parseError(e);
  }

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Preferences
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Preferences.update(\{
 *  authToken,
 *  updatePreferencesData,
 * \}));
 */

export const update = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    updatePreferencesData: UpdateSgpAssumptionsDto | UpdateGbrAssumptionsDto;
  }>,
  { state: StoreState }
>("preferences/update", async ({ authToken, updatePreferencesData }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.Assumptions(authToken).updateUserAssumptions({
      updatePlanAssumptionsRequest: updatePreferencesData,
    });
  } catch (e) {
    error = API.parseError(e);
  }

  const submitSuccess = !error;

  void dispatch(submitSuccessAction({ dispatch, submitSuccess }));

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  if (submitSuccess) {
    // Trigger success notification
    void dispatch(
      Store.notifications.addNotification({
        type: "success",
        title: lang("NOTIFICATION_TITLE_UPDATED", lang("NOTIFICATION_LABEL_PREFERENCES")),
        description: "",
      })
    );

    // Fetch Preferences
    void dispatch(Store.preferences.fetch({ authToken }));
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
  };
});
