// React
import { useEffect } from "react";
// Store
import { Store } from "@store/index";
import { resetPlanData } from "@store/utils/reset-plan-data";

export const useResetPlanData = (): void => {
  // Store
  const dispatch = Store.useDispatch();
  const plan = Store.useSelector((state) => state.plan);

  useEffect(() => {
    if (plan.data) {
      resetPlanData(dispatch);
    }
  }, [dispatch, plan.data]);
};
