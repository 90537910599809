// Redux
import { Store, StoreState } from "@store/index";
// Types
import { ResponsePlanV2Dto } from "@advicefront/plan-client-axios";

/**
 * Selector to find any plan by ID
 */

export const selectPlanById = Store.createSelector<string, ResponsePlanV2Dto | undefined>(
  (id: string) =>
    (state: StoreState): ResponsePlanV2Dto | undefined =>
      state.plans.data?.find((i) => i._id === id)
);
