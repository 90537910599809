// Redux
import { Store, StoreState } from "@store/index";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// API
import { API } from "@services/api";
import { FormsApiGetInputListv2Request } from "@advicefront/plan-client-axios";
// Initial State
import { initialState } from "./initial-state";
// Types
import { StateProps } from "./types";
// Translations
import { lang } from "@lang/index";

/**
 * Reset to initial data state
 * @example dispatch(Forms.reset())
 */

export const reset = createAction("forms/reset");

/**
 * Get Forms data
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Forms.fetch(\{
 *  authToken,
 *  region
 * \}));
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: StoreState["auth"]["authToken"];
    region: FormsApiGetInputListv2Request["region"];
  }>,
  { state: StoreState }
>("forms/fetch", async ({ authToken, region }, { dispatch }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  try {
    // Await request in parallel
    const [inputsList, forms] = await Promise.all([
      (
        await API.Forms(authToken).getInputListv2({
          region,
        })
      ).data,
      (
        await API.Forms(authToken).getFormsV2({
          region,
        })
      ).data,
    ]);

    data = {
      inputsList,
      forms,
    };
  } catch (e) {
    error = API.parseError(e);
  }

  if (error) {
    // Trigger error notification
    void dispatch(
      Store.notifications.addNotification({
        type: "error",
        title: lang("NOTIFICATION_TITLE_ERROR"),
        description: error,
      })
    );
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
