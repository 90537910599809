// React
import React from "react";
// Router
import { View } from "@routes/index";
// Layouts
import { PreferencesLayout } from "@layouts/preferences";
// Views
import { PreferencesTaxTable } from "@views/preferences/tax/components/Table";

// Define element for the view
const Element = (): React.ReactElement => (
  <PreferencesLayout isAdvisorPreferences>
    <PreferencesTaxTable isAdvisorPreferences />
  </PreferencesLayout>
);

// Route view options
export const preferencesTax: View = {
  name: "Preferences Tax",
  options: {
    path: "/preferences/tax",
    element: <Element />,
  },
};
