// Redux
import { Store, StoreState } from "@store/index";
// API
import { FindOneInput200Response } from "@advicefront/plan-client-axios";

/**
 * Selector to find any input by ID
 */

export const selectInputById = Store.createSelector<string, FindOneInput200Response | undefined>(
  (id: string) =>
    (state: StoreState): FindOneInput200Response | undefined =>
      state.inputs.data &&
      Object.values(state.inputs.data)
        .flat()
        .find((i) => i._id === id)
);
