// Styles
import "./styles/index.scss";
// React
import React from "react";
// Typings
import { FormRendererOptions } from "@forms/renderer/types";
import { SchemaRenderer } from "@advicefront/fe-infra-form-schema-renderer";
// Utils
import * as CC from "change-case";
import { getColumnProps } from "@forms/utils/get-column-props";
import { getFieldProps } from "@forms/utils/get-field-props";
import { useComponentFromInputType } from "@forms/utils/input-types-map";
// Hooks
import { useFieldTranslations, useFieldValidation, useScrollToError } from "@forms/hooks";
// Components
import { AfCheckbox, AfCheckboxGroup } from "@advicefront/ds-checkbox";
import { AfCol } from "@advicefront/ds-grid";
import { AfToggle } from "@advicefront/ds-toggle";
import { TitleField } from "@forms/renderer/fragments";

// Props
type BooleanFieldProps = Parameters<SchemaRenderer["BooleanField"]>[0] & {
  options: FormRendererOptions;
};

export const BooleanField = ({
  field,
  validation,
  computedValues,
  setValue,
  options,
}: BooleanFieldProps): React.ReactElement => {
  // Create component reference to be able to scroll to a field with an error
  const componentRef = useScrollToError(field.key, options.formValidation);

  // Get translations of the specific field key
  // It will return the title, label, placeholder, description and tooltip props
  const fieldTranslations = useFieldTranslations(options.formKey, computedValues)[field.key];

  // Get validation of the specific field
  // It will return the skin, validationDescription, onBlur and onFocus props
  const fieldValidation = useFieldValidation(options.formValidation?.valid, validation);

  // Get input props field set on the input renderer hook of each module
  const { disabled } = getFieldProps(options.inputRenderer.inputProps?.field, field.key);

  // Define the label prop for each field using the translation or the default one
  const labelProp = fieldTranslations?.label || CC.capitalCase(field?.label || field.key);

  // Get input props column set on the input renderer hook of each module
  const { size, offsetBefore, offsetAfter } = getColumnProps(
    options.inputRenderer.inputProps?.column,
    options.formDto,
    field.key
  );

  // Define component to be render according the field key and input types
  const component = useComponentFromInputType(field.key, options.inputRenderer.inputTypes, [
    {
      // Checkbox
      name: "boolean",
      component: (
        <AfCheckboxGroup
          direction="horizontal"
          disabled={disabled}
          description={fieldTranslations?.description}
          skin={fieldValidation.skin}
          validationDescription={fieldValidation.validationDescription}
          className="form-checkbox-field"
        >
          <AfCheckbox
            variation="boxed"
            name={field.key}
            checked={field.value}
            onBlur={fieldValidation.onBlur}
            onFocus={fieldValidation.onFocus}
            onChange={(ev): void => {
              setValue(ev.target.checked);
            }}
          >
            {labelProp}
          </AfCheckbox>
        </AfCheckboxGroup>
      ),
    },

    // Toggle
    {
      name: "toggle",
      component: (
        <AfToggle
          inverted
          size="m"
          label={labelProp}
          checked={field.value}
          className="form-toggle-field"
          onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
            setValue(ev.target.checked);
          }}
        />
      ),
    },
  ]);

  return (
    <>
      {fieldTranslations?.title && <TitleField title={fieldTranslations.title} />}
      <AfCol ref={componentRef} size={size} offsetBefore={offsetBefore} offsetAfter={offsetAfter}>
        {component}
      </AfCol>
    </>
  );
};
