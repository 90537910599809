// React
import React, { useContext, useEffect } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { LayoutProps } from "@layouts/types";
import { Regions } from "@advicefront/plan-client-axios";
// Context
import { AppOptionsContext } from "@context/app-options";
// Layouts
import { AuthProtectedLayout } from "@layouts/AuthProtected";
import { DefaultLayout } from "@layouts/Default";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { PreferencesTabs } from "@layouts/preferences/components/Tabs";

// Props
interface PreferencesLayoutProps extends LayoutProps {
  isAdvisorPreferences: boolean;
}

export const PreferencesLayout = ({
  isAdvisorPreferences,
  children,
}: PreferencesLayoutProps): React.ReactElement => {
  // Store
  const dispatch = Store.useDispatch();
  const auth = Store.useSelector((state) => state.auth);
  const preferences = Store.useSelector((state) => state.preferences);
  const firmPreferences = Store.useSelector((state) => state.firmPreferences);

  // Context
  const { clientRegion } = useContext(AppOptionsContext);

  // Fetch Advisor Preferences
  useEffect(() => {
    if (
      auth.authToken &&
      isAdvisorPreferences &&
      !preferences.data &&
      !preferences.loading &&
      !preferences.error
    ) {
      void dispatch(
        Store.preferences.fetch({
          authToken: auth.authToken,
        })
      );
    }
  }, [
    dispatch,
    isAdvisorPreferences,
    auth.authToken,
    preferences.data,
    preferences.loading,
    preferences.error,
  ]);

  // Fetch Firm Preferences
  useEffect(() => {
    if (
      auth.authToken &&
      !firmPreferences.data &&
      !firmPreferences.loading &&
      !firmPreferences.error
    ) {
      void dispatch(
        Store.firmPreferences.fetch({
          authToken: auth.authToken,
        })
      );
    }
  }, [
    dispatch,
    auth.authToken,
    firmPreferences.data,
    firmPreferences.loading,
    firmPreferences.error,
  ]);

  return (
    <AuthProtectedLayout>
      <DefaultLayout>
        <AfRow>
          {clientRegion === Regions.Gbr && (
            <AfCol>
              <PreferencesTabs isAdvisorPreferences={isAdvisorPreferences} />
            </AfCol>
          )}
          <AfCol size="auto">{children}</AfCol>
        </AfRow>
      </DefaultLayout>
    </AuthProtectedLayout>
  );
};
