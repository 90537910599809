// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./intial-state";
// Actions
import { reset, fetch } from "./actions";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder
    // Reset to initial data state
    .addCase(reset, () => initialState)
    // Store a new token
    .addCase(fetch.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }))
    // Loading start
    .addMatcher(isAnyOf(fetch.pending), (state) => ({
      ...state,
      loading: state.loading + 1,
    }))
    // Loading end
    .addMatcher(isAnyOf(fetch.rejected, fetch.fulfilled), (state) => ({
      ...state,
      loading: state.loading - 1,
    }));
});
