// Router config
import { routes, allRoutes, RouteIndex } from "../index";

/**
 * Returns a valid route url
 * @param name - route name to find
 * @returns route url
 */

export const getRoute = (index: RouteIndex): string => {
  const name = allRoutes[index].name;

  const validRoutes = routes.filter((r) => r.options.path);

  const res = validRoutes.find((r) => r.name === name);

  if (!res || !res.options.path) {
    throw new Error(
      `Could not find route with "${name}" name.\nAvailable routes are: ${validRoutes
        .map((r) => `"${r.name}"`)
        .join(", ")}`
    );
  }

  return res.options.path;
};
