// Store
import { StoreState } from "@store/index";
// Typings
import { AccountLimitUpdateProps, InputWithAccountLimit } from "@store/modules/accounts";
import { UpdatePlanAssumptionsRequest } from "@advicefront/plan-client-axios";

// Represents the different types of data that can be used for assumptions modules
// This can include data from the assumptions store, user preferences,
// firm preferences and input with account limit information
export type TableAssumptionsModulesData =
  | StoreState["assumptions"]["data"]
  | StoreState["preferences"]["data"]
  | StoreState["firmPreferences"]["data"]
  | InputWithAccountLimit[];

// Represents the different types of data formats that can be used to
// update the assumptions modules in a client's plan
export type TableAssumptionsModulesPayload =
  | UpdatePlanAssumptionsRequest
  | AccountLimitUpdateProps[];

// Enum defining all supported assumptions tables modules
// Each module is represented by a unique key and a corresponding string value
export enum TableAssumptionsModules {
  Mortality = "Mortality",
  Inflation = "Inflation",
  IsaLimits = "IsaLimits",
  CpfThresholds = "CpfThresholds",
  CpfEmployeeEmployer = "CpfEmployeeEmployer",
  CpfCaps = "CpfCaps",
  SrsCaps = "SrsCaps",
  Accounts = "Accounts",
  NonSavingsIncome = "NonSavingsIncome",
  NationalInsuranceC1 = "NationalInsuranceC1",
  NationalInsuranceC2 = "NationalInsuranceC2",
  NationalInsuranceC4 = "NationalInsuranceC4",
  SavingsIncome = "SavingsIncome",
  Dividends = "Dividends",
}

// Type alias for the keys of the TableAssumptionsModules enum
// This is used to ensure type safety when working with assumptions tables in the codebase
export type TableAssumptionsModulesName = keyof typeof TableAssumptionsModules;
