// Redux
import { Store, StoreState } from "@store/index";
// Types
import { FindOnePerson200Response } from "@advicefront/plan-client-axios";

/**
 * Selector to find any person by ID
 */

export const selectPersonById = Store.createSelector<string, FindOnePerson200Response | undefined>(
  (id: string) =>
    (state: StoreState): FindOnePerson200Response | undefined => {
      const clients = state.people.data?.clients || [];
      const dependents = state.people.data?.dependents || [];

      return [...clients, ...dependents].find((i) => i._id === id);
    }
);
