// Typings
import { Colors } from "@typings/colors";
import { ResponseForecastDtoClients } from "@advicefront/plan-client-axios";
// Highcharts
import Highcharts from "highcharts";
// Translations
import { lang } from "@lang/index";

// Props
interface ClientsRetirementOutput {
  xLines: Highcharts.XAxisPlotLinesOptions[];
  series: Highcharts.SeriesOptionsType[];
}

/**
 * Get Clients Retirement
 * Retrieves data to display clients retirement periods on the chart
 * @param clients - data from forecast
 * @returns array of highcharts "xLines" and "series" for retirement periods
 */
export const getClientsRetirement = (
  clients: ResponseForecastDtoClients | undefined
): ClientsRetirementOutput | undefined => {
  // Return if there are no clients
  if (!clients) return;

  // Get clients retirement periods
  const ownerPeriod = clients.owner.retirementPeriod;
  const partnerPeriod = clients.partner?.retirementPeriod;

  return {
    // Lines
    xLines: [
      {
        color: Colors.Text,
        value: ownerPeriod || undefined,
        dashStyle: "ShortDash",
        width: 2,
        zIndex: 10,
      },
      {
        color: Colors.TextInactive,
        value: partnerPeriod || undefined,
        dashStyle: "ShortDash",
        width: 2,
        zIndex: 10,
      },
    ],
    // Legends
    series: [
      {
        type: "line",
        id: lang("CHART_OWNER_RETIREMENT"),
        name: lang("CHART_OWNER_RETIREMENT"),
        color: Colors.Text,
        data: undefined,
        legendIndex: 100,
        showInLegend: !!ownerPeriod,
        dashStyle: "ShortDash",
        className: "readonly",
        marker: {
          enabled: false,
        },
        events: {
          legendItemClick: () => false,
        },
      },
      {
        type: "line",
        id: lang("CHART_PARTNER_RETIREMENT"),
        name: lang("CHART_PARTNER_RETIREMENT"),
        color: Colors.TextInactive,
        data: undefined,
        legendIndex: 105,
        showInLegend: !!partnerPeriod,
        dashStyle: "ShortDash",
        className: "readonly",
        marker: {
          enabled: false,
        },
        events: {
          legendItemClick: () => false,
        },
      },
    ],
  };
};
