// Typings
import { ChartRingModules, ChartRingModulesName } from "@typings/charts/ring";
import { PointOptionsObject } from "highcharts";
// Modules
import {
  useChartAssetsModule,
  useChartInflowModule,
  useChartLiabilitiesModule,
  useChartOutflowModule,
} from "@components/charts/ring/hooks/modules";

// Props : Hooks
type UseChartRing = (name: ChartRingModulesName) => ChartRingModuleSchema;

export type UseChartRingModule = () => ChartRingModuleSchema;

// Props : Modules
export interface ChartRingModuleEntry {
  name?: PointOptionsObject["name"];
  color?: PointOptionsObject["color"];
  y?: PointOptionsObject["y"];
}

export type ChartRingModuleSchema = ChartRingModuleEntry[] | undefined;

/**
 * Maps each chart ring module to its corresponding chart hook module
 * Each chart ring module has its own specific hook to retrieve its chart data
 */
const ChartRingMap: Record<ChartRingModulesName, UseChartRingModule> = {
  [ChartRingModules.Inflow]: useChartInflowModule,
  [ChartRingModules.Outflow]: useChartOutflowModule,
  [ChartRingModules.Assets]: useChartAssetsModule,
  [ChartRingModules.Liabilities]: useChartLiabilitiesModule,
};

/**
 * Returns chart data for a specific chart ring module
 * Determines which module hook to use based on the chart ring module name
 * The hook will receive the current chart ring module name
 * @param name - name of the chart ring module
 * @returns the chart data for the specified chart ring module
 */
export const useChartRing: UseChartRing = (name) => ChartRingMap[name]();
