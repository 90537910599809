/**
 * Form Labels
 * Most of form labels will convert the key
 * Naming convention: FORM_LABEL_ELEMENT
 */
export const formLabels = {
  FORM_LABEL_PLAN_NAME: {
    en: "Plan Name",
  },
  FORM_LABEL_PLAN_TYPE: {
    en: "Choose Plan Type",
  },
  FORM_LABEL_NET_BASIS: {
    en: "Net Basis - Create a simple cashflow plan without tax calculations",
  },
  FORM_LABEL_GROSS_BASIS: {
    en: "Gross Basis - Create a detailed cashflow plan with tax calculations",
  },
  FORM_LABEL_DUPLICATE_PLAN: {
    en: "Duplicate Plan",
  },
  FORM_LABEL_NAME: {
    en: "Name",
  },
  FORM_LABEL_GENDER: {
    en: "Gender",
  },
  FORM_LABEL_MALE: {
    en: "Male",
  },
  FORM_LABEL_FEMALE: {
    en: "Female",
  },
  FORM_LABEL_YEARS: {
    en: "years",
  },
  FORM_LABEL_FREQUENCY: {
    en: "Frequency",
  },
  FORM_LABEL_BONUS: {
    en: "Bonus",
  },
  FORM_LABEL_BENEFITS: {
    en: "Benefits",
  },
  FORM_LABEL_DIVIDENDS: {
    en: "Dividends",
  },
  FORM_LABEL_OTHER: {
    en: "Other",
  },
  FORM_LABEL_RELATED_INCOME: {
    en: "Related Income",
  },
  FORM_LABEL_GROWTH_RATE: {
    en: "Annual Growth Rate",
  },
  FORM_LABEL_CONTRIBUTIONS_GROWTH: {
    en: "Annual Contributions Growth",
  },
  FORM_LABEL_ESTIMATED_RETURN: {
    en: "Estimated Return",
  },
  FORM_LABEL_ASSET_SALE_FUNDS: {
    en: "Asset Sale Funds (Optional)",
  },
  FORM_LABEL_ACCOUNTS: {
    en: "Accounts",
  },
  FORM_LABEL_PENSIONS: {
    en: "Pensions",
  },
  FORM_LABEL_EMPLOYER_CONTRIBUTIONS: {
    en: "Employer Contributions",
  },
  FORM_LABEL_EMPLOYEE_CONTRIBUTIONS: {
    en: "Employee Contributions",
  },
  FORM_LABEL_PERSONAL_CONTRIBUTIONS: {
    en: "Annual Personal Contributions",
  },
  FORM_LABEL_OWNER: {
    en: "Owner",
  },
  FORM_LABEL_OWNER_AGE: {
    en: "Owner's Age",
  },
  FORM_LABEL_OWNER_STARTING_AGE: {
    en: "Owner's Starting Age",
  },
  FORM_LABEL_OWNER_ENDING_AGE: {
    en: "Owner's Ending Age",
  },
  FORM_LABEL_TIMEFRAME_JOINT: {
    en: "{0} and {1}",
  },
  FORM_LABEL_TIMEFRAME_EVENTS: {
    en: "{0}'s events",
  },
  FORM_LABEL_TIMEFRAME_AGE: {
    en: "{0}'s age",
  },
  FORM_LABEL_ACCOUNT_LIMITS: {
    en: "Account Limits",
  },
  FORM_LABEL_MAX_WITHDRAWAL: {
    en: "Maximum Withdrawal",
  },
  FORM_LABEL_MIN_BALANCE: {
    en: "Minimum Account Balance",
  },
  FORM_LABEL_ADJUST_WITH_INFLATION: {
    en: "Adjust with inflation",
  },
  FORM_LABEL_AVAILABILITY_AGE: {
    en: "Availability to Owner's age",
  },
  FORM_LABEL_EMPTY_OPTION: {
    en: "-- None --",
  },
};
