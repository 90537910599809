// React
import React, { useState } from "react";
// Typings
import { FormRendererOptions } from "@forms/renderer/types";
import { SchemaRenderer } from "@advicefront/fe-infra-form-schema-renderer";
// Utils
import * as CC from "change-case";
import { getColumnProps } from "@forms/utils/get-column-props";
import { useComponentFromInputType } from "@forms/utils/input-types-map";
// Hooks
import { useFieldTranslations, useScrollToError } from "@forms/hooks";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { LabelField, TitleField } from "@forms/renderer/fragments";
import { AfButton } from "@advicefront/ds-button";
import { AfCard } from "@advicefront/ds-card";
import { AfIcon } from "@advicefront/ds-icon";
import { AfTypography } from "@advicefront/ds-typography";

// Props
type GroupFieldProps = Parameters<SchemaRenderer["GroupField"]>[0] & {
  options: FormRendererOptions;
};

export const GroupField = ({
  field,
  items,
  computedValues,
  options,
}: GroupFieldProps): React.ReactElement => {
  // Create component reference to be able to scroll to a field with an error
  const componentRef = useScrollToError(field.key, options.formValidation);

  // State to control the visibility of the collapsible component
  const [isCollapsibleOpen, setCollapsibleIsOpen] = useState<boolean>(false);

  // Get translations of the specific field key
  // It will return the title, label, placeholder, description and tooltip props
  const fieldTranslations = useFieldTranslations(options.formKey, computedValues)[field.key];

  // Define the label prop for each field including an optional tooltip
  const labelProp = (
    <LabelField tooltip={fieldTranslations?.tooltip}>
      {fieldTranslations?.label || CC.capitalCase(field?.label || field.key)}
    </LabelField>
  );

  // Get input props column set on the input renderer hook of each module
  const { size, offsetBefore, offsetAfter } = getColumnProps(
    options.inputRenderer.inputProps?.column,
    options.formDto,
    field.key
  );

  // Define component to be render according the field key and input types
  const component = useComponentFromInputType(field.key, options.inputRenderer.inputTypes, [
    // Group
    // NOTE: Component not implemented
    {
      name: "group",
      component: (
        <AfCard>
          {(labelProp || fieldTranslations.description) && (
            <AfCard.Header>
              {labelProp && (
                <AfTypography hasMargin={false} type="h4">
                  {labelProp}
                </AfTypography>
              )}
              {fieldTranslations.description && (
                <AfTypography hasMargin={false} type="small" skin="text-light">
                  {fieldTranslations.description}
                </AfTypography>
              )}
            </AfCard.Header>
          )}
          <AfRow wrap>
            {items.map((item, index) => (
              <React.Fragment key={`group-item-${field.key}-${index}`}>{item}</React.Fragment>
            ))}
          </AfRow>
        </AfCard>
      ),
    },

    // Collapsible
    {
      name: "collapsible",
      component: (
        <AfCard skin="secondary">
          <AfCard.Header
            actions={
              <AfButton
                skin="ghost"
                icon={<AfIcon name={`basic-arrow-${isCollapsibleOpen ? "up" : "down"}`} />}
                onClick={(ev): void => {
                  ev.preventDefault();
                  setCollapsibleIsOpen(!isCollapsibleOpen);
                }}
              />
            }
          >
            {labelProp && (
              <AfTypography hasMargin={false} type="h4">
                {labelProp}
              </AfTypography>
            )}
            {fieldTranslations.description && (
              <AfTypography hasMargin={false} type="small" skin="text-light">
                {fieldTranslations.description}
              </AfTypography>
            )}
          </AfCard.Header>
          {isCollapsibleOpen && (
            <AfCard.Content>
              <AfRow wrap>
                {items.map((item, index) => (
                  <React.Fragment key={`group-item-${field.key}-${index}`}>{item}</React.Fragment>
                ))}
              </AfRow>
            </AfCard.Content>
          )}
        </AfCard>
      ),
    },
  ]);

  return (
    <>
      {fieldTranslations?.title && <TitleField title={fieldTranslations.title} />}
      <AfCol ref={componentRef} size={size} offsetBefore={offsetBefore} offsetAfter={offsetAfter}>
        {component}
      </AfCol>
    </>
  );
};
