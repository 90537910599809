// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Utils
import { getSubmitSuccessAction } from "@store/utils/submit-success";
// Actions
import { reset, fetch, update } from "./actions";

const { applySubmitSuccessCases } = getSubmitSuccessAction<StateProps>(
  "accounts/set-submit-success-state"
);

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  applySubmitSuccessCases(builder);

  builder
    // Reset to initial data state
    .addCase(reset, () => initialState)
    // Get + Update accounts fulfilled
    .addMatcher(isAnyOf(fetch.fulfilled, update.fulfilled), (state, action) => ({
      ...state,
      ...action.payload,
    }))
    // Loading start
    .addMatcher(isAnyOf(fetch.pending, update.pending), (state) => ({
      ...state,
      loading: state.loading + 1,
    }))
    // Loading end
    .addMatcher(
      isAnyOf(fetch.fulfilled, fetch.rejected, update.fulfilled, update.rejected),
      (state) => ({
        ...state,
        loading: state.loading - 1,
      })
    );
});
