// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import {
  TableDraggableModuleEntry,
  TableDraggableModuleSchema,
  UseTableDraggableModule,
} from "@components/tables/draggable/hooks";
// Constants
import { EMPTY_VALUE } from "@constants/index";
// Utils
import { getInputName } from "@utils/get-input-name";
// Translations
import { lang } from "@lang/index";

export const useTableLiquidationModule: UseTableDraggableModule = () => {
  // Store
  const liquidation = Store.useSelector((state) => state.liquidation);

  // Return table draggable data object
  return useMemo(
    (): TableDraggableModuleSchema => ({
      title: lang("TABLE_TITLE_LIQUIDATION_ORDER"),
      tooltip: lang("TOOLTIP_LIQUIDATION_ORDER"),
      head: [lang("TABLE_COLUMN_ACCOUNT"), lang("TABLE_COLUMN_ACCOUNT_TYPE")],
      entries: (liquidation.data || []).map(
        (entry): TableDraggableModuleEntry => ({
          id: entry._id,
          label: entry.label,
          value: getInputName(entry.__st) || EMPTY_VALUE,
        })
      ),
      fetching: !liquidation.data,
      loading: !!liquidation.loading,
      error: !!liquidation.error,
      empty: {
        title: lang("EMPTY_TITLE_LIQUIDATION"),
        description: lang("EMPTY_DESCRIPTION_LIQUIDATION"),
      },
    }),
    [liquidation.data, liquidation.loading, liquidation.error]
  );
};
