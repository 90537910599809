// React
import { useMemo } from "react";
// Store
import { Store } from "@store/index";
// Typings
import { ChartRingModuleSchema, UseChartRingModule } from "@components/charts/ring/hooks";
// Utils
import { getGroupColor } from "@utils/charts";

export const useChartInflowModule: UseChartRingModule = () => {
  // Store
  const forecast = Store.useSelector((state) => state.forecast);

  // Return chart data object
  return useMemo(
    (): ChartRingModuleSchema =>
      forecast.data?.cashflow.incomes
        .map((income) => ({
          name: income.label,
          color: getGroupColor(income.baseType),
          y: Math.abs(income.values[0]), // Positive value
        }))
        .filter(({ y }) => y !== 0),
    [forecast.data?.cashflow.incomes]
  );
};
